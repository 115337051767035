import React, { useEffect, useState, Fragment } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import SimpleButton from "../../common/SimpleButton"
import LoadingSpinner from "../../common/LoadingSpinner"
import EmptyPageResults from "../../common/EmptyPageResults"
import GenericTable from "../../common/GenericTable"
import ExpandableText from "../../common/ExpandableText"
import { useWindowSize } from "../../../libs/hooks"
import { BackSimpleIcon, CaretDownIcon } from "../../../icons"
import { getSubscriptionsPayments, getSubscriptionsPaymentsCsv } from "../../../utils/requests/subscriptionsAPI"
import { convertToSimpleDateWithFullTime } from "../../../utils/filters/date"
import { downloadFile } from '../../../utils/functions'

const PaymentHistory = (props) => {
  const { t } = useTranslation()
  const isMobile = useWindowSize()[0] <= 768
  const { handleBack } = props
  const [payments, setPayments] = useState([])
  const [paymentsTableData, setPaymentsTableData] = useState([])
  const [orderBy, setOrderBy] = useState({ field: "date", headerField: "date", order: "desc", key: "date_desc" })
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const [showSpinner, setShowSpinner] = useState(null)
  const [canLoadMore, setCanLoadMore] = useState(false)
  const [selectedPage, setSelectedPage] = useState(1)
  const [expandedPayment, setExpandedPayment] = useState(0)
  const maxCsvResults = 1000

  useEffect(() => {
    const tableData = payments.map((payment) => {
      return {
        date: convertToSimpleDateWithFullTime(payment.date),
        price: ((Number(payment.amount) || 0) - (Number(payment.taxAmount) || 0))?.toFixed(2),
        tax: payment.taxAmount?.split(".")?.[0] + "." + payment.taxAmount?.split(".")?.[1]?.slice(0, 2),
        amount: payment.amount?.split(".")?.[0] + "." + payment.amount?.split(".")?.[1]?.slice(0, 2),
        currency: payment.currency?.toUpperCase(),
        planTitle: payment.planTitle,
        planType: t([`coltene_store.admin_order_history.${payment.planType?.toLowerCase()}_plan`, payment.planType?.toLowerCase()]),
      }
    })

    setPaymentsTableData(tableData)
  }, [payments])

  useEffect(() => {
    if (selectedPage && selectedPage > 1) {
      handleLoadPayments(false)
    }
  }, [selectedPage])

  useEffect(() => {
    let changeValueTimeout = window.setTimeout(
      () => {
        setSelectedPage(1)
        handleLoadPayments(true)
      },
      0
    )

    return () => {
      clearTimeout(changeValueTimeout)
    }       
  },[orderBy])

  const handleLoadPayments = (withReset) => {
    let queryParams = {
      startPage: withReset ? 1 : selectedPage,
      maxResults: process.env.REACT_APP_PAGINATION_SIZE,
    }

    if (orderBy?.field && orderBy?.order) {
      queryParams = { ...queryParams, orderBy: orderBy.field, order: orderBy.order }
    }

    setShowSpinner(true)

    getSubscriptionsPayments(queryParams)
      .then((data) => {
        if (data?.data) {
          setPayments(withReset ? data.data : [...payments, ...data.data])
          setCanLoadMore(data.data.length === Number(process.env.REACT_APP_PAGINATION_SIZE) &&
            data.currentPage !== data.totalPages)
        }

        setShowSpinner(false)
        setIsFirstLoad(false)
      })
      .catch(() => {
        toast.dismiss()
        toast.error(t('error.something_wrong'))

        setCanLoadMore(false)
        setShowSpinner(false)
        setIsFirstLoad(false)
      })
  }

  const handleSort = (orderingField) => {
    setOrderBy(orderingField)
  }

  const handleLoadMore = () => {
    setSelectedPage((prevValue) => (prevValue ? prevValue + 1 : 2))
  }

  const handleDownloadPayments = () => {
    if (showSpinner) {
      return
    }

    let queryParams = {
      limit: maxCsvResults,
    }

    if (orderBy?.field && orderBy?.order) {
      queryParams = { ...queryParams, orderBy: orderBy.field, order: orderBy.order }
    }

    setShowSpinner(true)

    getSubscriptionsPaymentsCsv(queryParams)
      .then((res) => {
        const date = new Date().toISOString().split('T')
        const dateNow = date[0].split('-').reverse().join('_')
        const timeNow = date[1].split('.')[0].split(':').join('_')
        
        downloadFile({
          data: res,
          fileName: `payment_history_${dateNow}_${timeNow}.csv`,
          fileType: 'text/csv',
        })

        setShowSpinner(false)
        toast.success(t("coltene_store.subscription_history.download-success"))
      })
      .catch(() => {
        setShowSpinner(false)
        toast.error(t("error.failure_msg"))
      })
  }

  return (
    <Fragment>
      <div className="wrapper-header">
        <div className="header">
          <BackSimpleIcon className="back-icon" onClick={() => handleBack()} />
          <div className="header-title">
            {t("coltene_store.subscription_history.payment-history")}
          </div>
        </div>
        <SimpleButton 
          className="action-button"
          onClick={() => handleDownloadPayments()}
        >
          {t("events.download")}
        </SimpleButton>
      </div>
      {!isMobile && paymentsTableData.length > 0 &&
        <GenericTable
          data={paymentsTableData}
          headers={[
            {
              title: t("coltene_store.subscription_history.date"),
              orderKey: 'date'
            },
            {
              title: t("coltene_store.subscription_history.price")
            },
            {
              title: t("coltene_store.subscription_history.tax")
            },
            {
              title: t("coltene_store.subscription_history.total_price"),
              orderKey: 'amount'
            },
            {
              title: t("coltene_store.subscription_history.currency"),
              orderKey: 'currency'
            },
            {
              title: t("coltene_store.subscription_history.planTitle"),
              orderKey: 'planTitle'
            },
            {
              title: t("coltene_store.subscription_history.planType"),
              orderKey: 'planType'
            },
          ]}
          keys={[
            'date',
            'price',
            'tax',
            'amount',
            'currency',
            'planTitle',
            'planType',
          ]}
          keyRenderer={{
            planTitle: (item) => {
              return (
                <div className="plan-title-wrapper">
                  <ExpandableText text={item.planTitle} />
                </div>
              )
            },
            planType: (item) => {
              return (
                <div className="plan-type-wrapper">
                  {item.planType}
                </div>
              )
            }            
          }}
          activeSort={orderBy}
          onSort={(orderingField) => handleSort(orderingField)}
          isLoading={showSpinner}
        />
      }
      {isMobile && paymentsTableData.map((item, index) =>
        <div
          key={index}
          className={"card" + (expandedPayment === index ? " with-padding-bottom" : "")} 
          onClick={() => setExpandedPayment(index)}
        >
          <div className={"card-item"}>
            <div className="card-item-title">{t("coltene_store.subscription_history.date")}</div>
            <div className={"card-item-body"}>
              <p className="card-description">{item.date}</p>
            </div>
          </div>
          {expandedPayment === index && (
            <>
              <div className="card-item">
                <div className="card-item-title">{t("coltene_store.subscription_history.price")}</div>
                <div className="card-item-body">{item.price}</div>
              </div>
              <div className="card-item">
                <div className="card-item-title">{t("coltene_store.subscription_history.tax")}</div>
                <div className="card-item-body">{item.tax}</div>
              </div>
              <div className="card-item">
                <div className="card-item-title">{t("coltene_store.subscription_history.total_price")}</div>
                <div className="card-item-body">{item.amount}</div>
              </div>
              <div className="card-item">
                <div className="card-item-title">{t("coltene_store.subscription_history.currency")}</div>
                <div className="card-item-body">{item.currency}</div>
              </div>
              <div className="card-item">
                <div className="card-item-title">{t("coltene_store.subscription_history.planTitle")}</div>
                <div className="card-item-body">{item.planTitle}</div>
              </div>
              <div className="card-item">
                <div className="card-item-title">{t("coltene_store.subscription_history.planType")}</div>
                <div className="card-item-body with-capitalize">{item.planType}</div>
              </div>
            </>
          )}
          {expandedPayment !== index && 
            <div className="expander-wrapper">
              <CaretDownIcon />
            </div>
          }
        </div>)
      }
      {canLoadMore && (
        <div className="buttons-wrapper">
          <SimpleButton className="load-more-button" onClick={() => handleLoadMore()}>
            {t('buttons.load_more')}
          </SimpleButton>
        </div>)
      }
      {!showSpinner && !isFirstLoad && paymentsTableData.length === 0 && 
        <EmptyPageResults 
          title={t("coltene_store.subscription_history.no-payments")} 
        />
      }
      {showSpinner && (
        <div className={"spinner-wrapper"}>
          <LoadingSpinner />
        </div>
      )}
    </Fragment>
  )
}

export default PaymentHistory
