import React, { Fragment, useState, useEffect, useRef } from "react"
import { useHistory, useLocation } from 'react-router-dom'
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import AnalyticsDetailsModal from './AnalyticsDetailsModal'
import LoadingSpinner from "../common/LoadingSpinner"
import SearchWithFilter from "../common/SearchWithFilter"
import FiltersModal, { FilterType } from "../common/FiltersModal"
import EmptyPageResults from "../common/EmptyPageResults"
import ClickOutside from "../common/ClickOutside"
import GenericTable from "../common/GenericTable"
import ActionsMenu from '../common/ActionsMenu'
import CheckboxInput from "../common/CheckboxInput"
import SimpleButton from "../common/SimpleButton"
import WrappedTabs from "../common/WrappedTabs"
import { setBackNavigationState, clearBackNavigationState } from "../../actions/backNavigationState"
import { useDeepCompareEffect } from '../../hooks/useDeepCompareEffect'
import { currentDay } from "../../utils/filters/date"
import { downloadFile } from '../../utils/functions'
import { CaretDownIcon, ExpandLessIcon, ExpandMoreIcon, MenuKebabVerticalIcon, MenuKebabVerticalBackgroundIcon } from "../../icons"
import { getAnalyticsFaultsOverview, getAnalyticsFaultsCountries, getAnalyticsFaultsSummary, getAnalyticsFaultsList, getAnalyticsFaultsListCsv, getAnalyticsFaultsCodes } from "../../utils/requests/cyclesAPI"
import './cycle-analytics.scss'

const CycleAnalytics = (props) => {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()
  const [countries, setCountries] = useState([])
  const [filterQuery, setFilterQuery] = useState(null)
  const [selectedFilterUnit, setSelectedFilterUnit] = useState(null)
  const [selectedFilterCountries, setSelectedFilterCountries] = useState([])
  const [shouldApplyFilters, setShouldApplyFilters] = useState(true)
  const [overviewTableData, setOverviewTableData] = useState([])
  const [isOverviewExpanded, setIsOverviewExpanded] = useState(false)
  const [totalUnitsSold, setTotalUnitsSold] = useState(0)
  const [totalUnitsSoldWidth, setTotalUnitsSoldWidth] = useState(0)
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [selectedCfNumber, setSelectedCfNumber] = useState(-1)
  const [selectedDuration, setSelectedDuration] = useState(30)
  const [cfNumberOptions, setCfNumberOptions] = useState([-1])
  const [checkedModels, setCheckedModels] = useState({})
  const [selectedTab, setSelectedTab] = useState(0)
  const [summaryTableData, setSummaryTableData] = useState([])
  const [listTableData, setListTableData] = useState([])
  const [showSpinner, setShowSpinner] = useState(null)
  const [showFiltersModal, setShowFiltersModal] = useState(false)
  const [isSerialNumberSearch, setIsSerialNumberSearch] = useState(false)
  const [areFiltersSelected, setAreFiltersSelected] = useState(false)
  const [showActionsMenu, setShowActionsMenu] = useState(null)
  const [analyticsDetailsCf, setAnalyticsDetailsCf] = useState(null)
  const [showAnalyticsDetailsModal, setShowAnalyticsDetailsModal] = useState(false)
  const durationOptions = [30, 180]
  const tabs = [
    {
      key: 0,
      label: t("monitoring.summary"),
    },
    {
      key: 1,
      label: t("monitoring.list"),
    }
  ]

  useEffect(() => {
    window.addEventListener('resize', updateTotalSoldWidth)

    return () => {
      window.removeEventListener('resize', updateTotalSoldWidth)
    }
  }, [])

  useEffect(() => {
    setShowSpinner(true)

    getAnalyticsFaultsCountries()
      .then((response) => {
        const countryData = response.data
        setCountries(countryData)

        setShowSpinner(false)
      })
      .catch(() => {
        toast.dismiss()
        toast.error(t("error.something_wrong"))

        setShowSpinner(false)
      })
  }, [])

  useEffect(() => {
    if (selectedProduct) {
      setShowSpinner(true)

      let queryParams = {
        duration: selectedDuration,
      }

      if (isSerialNumberSearch) {
        queryParams = { ...queryParams, sn: selectedProduct }
      } else {
        queryParams = { ...queryParams, product_type: selectedProduct }
      }

      getAnalyticsFaultsCodes(queryParams)
        .then((response) => {
          const codes = response.data[0]?.codes || []
          setCfNumberOptions([-1, ...codes])

          setShowSpinner(false)
        })
        .catch(() => {
          toast.dismiss()
          toast.error(t("error.something_wrong"))

          setShowSpinner(false)
        })
    }
  }, [selectedProduct, selectedDuration, isSerialNumberSearch])

  useDeepCompareEffect(() => {
    setShowSpinner(true)

    let queryParams = {}

    if (selectedFilterUnit) {
      queryParams = { ...queryParams, product_type: selectedFilterUnit }
    }

    if (selectedFilterCountries.length > 0) {
      queryParams = { ...queryParams, country: selectedFilterCountries }
    }

    if (shouldApplyFilters) {
      getAnalyticsFaultsOverview(queryParams)
        .then((response) => {
          const tableData = response?.data?.map((product) => {
            return {
              productType: product.product_type,
              totalFaults: product.total_faults,
              totalSold: product.total_sold,
              uniqueSn: product.unique_sn,
            }
          }) || []

          const totalUnitsSold = tableData.reduce((acc, product) => acc + product.totalSold, 0)
          
          setTotalUnitsSold(totalUnitsSold)
          setOverviewTableData(tableData)
          setIsOverviewExpanded(tableData.length > 0)

          updateTotalSoldWidth()

          setShowSpinner(false)
        })
        .catch(() => {
          toast.dismiss()
          toast.error(t("error.something_wrong"))

          setShowSpinner(false)
        })
    }

    if (!selectedFilterUnit && selectedFilterCountries.length === 0) {
      handleResetFilters()
    }

    setShouldApplyFilters(true)
  },[selectedFilterUnit, selectedFilterCountries])

  useDeepCompareEffect(() => {
    let changeValueTimeout = window.setTimeout(
      () => {
        if (filterQuery && filterQuery.length >= 6 && filterQuery.length <= 13) {
          setIsSerialNumberSearch(true)
          setSelectedTab(0)
          setSummaryTableData([])
          setListTableData([])
          setSelectedCfNumber(-1)

          setSelectedProduct(filterQuery)
        }
      },
      filterQuery ? 1000 : 0
    )
    return () => {
      clearTimeout(changeValueTimeout)
    }    
  },[filterQuery])

  useDeepCompareEffect(() => { 
    if (selectedProduct) {
      if (selectedTab === 0) {
        handleLoadCycleFaultsSummary()
      }

      if (selectedTab === 1) {
        handleLoadCycleFaultsList()
      }
    }
  },[selectedProduct, isSerialNumberSearch, selectedTab, selectedCfNumber, selectedDuration])

  const handleLoadCycleFaultsSummary = () => {
    setShowSpinner(true)

    let queryParams = {
      duration: selectedDuration,
    }
    
    if (selectedCfNumber !== -1) {
      queryParams = { ...queryParams, cycle_fault: selectedCfNumber }
    }

    if (isSerialNumberSearch) {
      queryParams = { ...queryParams, sn: selectedProduct }
    } else {
      queryParams = { ...queryParams, product_type: selectedProduct }
    }

    getAnalyticsFaultsSummary(queryParams)
      .then((response) => {
        const summaryData = response.data

        const groupedData = {}

        summaryData.forEach((item) => {
          const model = item.model

          if (!groupedData[model]) {
            groupedData[model] = []
          }

          groupedData[model].push(item)
        })

        const modelTables = Object.entries(groupedData).map(([modelName, modelData]) => {
          const tableData = modelData.flatMap((item) => {
            return item.cycle_faults.map((fault, idx) => {
              return {
                identifier: `${modelName}-${idx}`,
                code: fault.code,
                uniqueSn: fault.unique_sn,
                totalCf: fault.total,
                actions: [
                  {
                    label: t("monitoring.cycle_fault_details"),
                    onActionClick: (e) => { e.stopPropagation(); handleOpenAnalyticsDetails(fault.code) },
                  },
                  {
                    label: t("monitoring.troubleshoot"),
                    onActionClick: (e) => { e.stopPropagation(); navigateToTroubleshoot(modelName, fault.code) },
                  }
                ],
              }
            })
          })

          return {
            modelName,
            tableData,
          }
        })

        setSummaryTableData(modelTables)

        const initialCheckedModels = modelTables.reduce((acc, modelTable) => {
          acc[modelTable.modelName] = true

          return acc
        }, {})

        setCheckedModels(initialCheckedModels)

        setShowSpinner(false)
      })
      .catch(() => {
        toast.dismiss()
        toast.error(t("error.something_wrong"))

        setShowSpinner(false)
      })
  }

  const handleLoadCycleFaultsList = () => {
    setShowSpinner(true)

    let queryParams = {
      duration: selectedDuration,
    }
    
    if (selectedCfNumber !== -1) {
      queryParams = { ...queryParams, cycle_fault: selectedCfNumber }
    }

    if (isSerialNumberSearch) {
      queryParams = { ...queryParams, sn: selectedProduct }
    } else {
      queryParams = { ...queryParams, product_type: selectedProduct }
    }

    getAnalyticsFaultsList(queryParams)
      .then((response) => {
        const listData = response.data

        const tableData = listData.map((item) => {
          return {
            sn: item.sn,
            date: item.date,
            fault: item.fault,
          }
        })

        setListTableData(tableData)
        setShowSpinner(false)
      })
      .catch(() => {
        toast.dismiss()
        toast.error(t("error.something_wrong"))

        setShowSpinner(false)
      })
  }

  const updateTotalSoldWidth = () => {
    const lastOverviewTableColumnWidth = document.querySelectorAll(
      `.overview-table .table tr:last-child td:last-child`)[0]?.getBoundingClientRect()?.width || 150

    setTotalUnitsSoldWidth(lastOverviewTableColumnWidth)
  }

  const handleSearch = (value) => {
    if (value?.length > 6) {
      setFilterQuery(value)
    } else if (value?.length === 0 && filterQuery?.length > 0) {
      setSelectedProduct(null)
      handleResetFilters()
    }
  }

  const handleResetFilters = () => {
    setSelectedFilterUnit(null)
    setSelectedFilterCountries([])
    setAreFiltersSelected(false)
    setFilterQuery(null)
    setSelectedProduct(null)
    setIsSerialNumberSearch(false)
    setSelectedTab(0)
    setSelectedCfNumber(-1)
    setSelectedDuration(30)
    setCfNumberOptions([-1])
  }

  const onSelectInput = (isComponentVisible, toggleVisibility) => {
    if (toggleVisibility) {
      toggleVisibility(!isComponentVisible)
    }    
  }

  const selectCfNumber = (cfNumber, toggleVisibility) => {
    setSelectedCfNumber(cfNumber)

    if (toggleVisibility) {
      toggleVisibility(false)
    }
  }

  const selectCfNumberInput = ({toggleVisibility, isComponentVisible}) => (
    <div className={"select-input"}>
      <div 
        className="height d-flex flex-align-center flex-justify-between" 
        onClick={() => onSelectInput(isComponentVisible, toggleVisibility)}
      >
        <div className="text-wrapper">
          {selectedCfNumber !== -1 ? selectedCfNumber : t("monitoring.all_cf_selected")}
        </div>
        <CaretDownIcon className={"mr-15 caret-dropdown-icon" + (isComponentVisible ? " icon-dropdown-open" : "")}/>
      </div>
    </div>
  )

  const selectCfNumberOptions = ({toggleVisibility}) => (
    <div className="options-wrapper d-flex flex-align-center flex-column">
      {cfNumberOptions.map((cfNumber, index) => 
        <div 
          className={"option cursor-pointer no-wrap" + (cfNumber === selectedCfNumber ? " selected-option" : "")}
          key={index}
          onClick={() => selectCfNumber(cfNumber, toggleVisibility)}
        >
          {cfNumber === -1 ? t("monitoring.all_cf_selected") : cfNumber}
        </div>)
      }
    </div>
  )

  const selectDuration = (duration, toggleVisibility) => {
    if (toggleVisibility) {
      toggleVisibility(false)
    }

    if (duration === selectedDuration) {
      return
    }

    setSelectedCfNumber(-1)
    setSelectedDuration(duration)
  }

  const selectDurationInput = ({toggleVisibility, isComponentVisible}) => (
    <div className={"select-input"}>
      <div 
        className="height d-flex flex-align-center flex-justify-between" 
        onClick={() => onSelectInput(isComponentVisible, toggleVisibility)}
      >
        <div className="text-wrapper">
          {selectedDuration ? formatDurationOption(selectedDuration) : ''}
        </div>
        <CaretDownIcon className={"mr-15 caret-dropdown-icon" + (isComponentVisible ? " icon-dropdown-open" : "")}/>
      </div>
    </div>
  )

  const selectDurationOptions = ({toggleVisibility}) => (
    <div className="options-wrapper d-flex flex-align-center flex-column">
      {durationOptions.map((durationOption, index) => (
        <div
          key={index}
          className={"option cursor-pointer no-wrap" + (durationOption === selectedDuration ? " selected-option" : "")} 
          onClick={() => selectDuration(durationOption, toggleVisibility)}
        >
          {formatDurationOption(durationOption)} 
        </div>
      ))}
    </div>
  )

  const formatDurationOption = (duration) => {
    const currentDate = new Date(currentDay())

    const computedStartDate = new Date(currentDate)
    computedStartDate.setDate(computedStartDate.getDate() - duration)

    const startMonth = computedStartDate.toLocaleString('default', { month: 'short' })
    const startDay = computedStartDate.getDate()
    const startDate = `${startMonth} ${startDay}`

    const currentDateParts = currentDay().split(",")
    const currentMonth = currentDateParts[1].trim().split(" ")[0].slice(0, 3)
    const currentDayFormatted = currentDateParts[1].trim().split(" ")[1]
    const currentYear = currentDateParts[2].trim()
    const currentDateFormatted = `${currentMonth} ${currentDayFormatted}, ${currentYear}`

    if (duration === 30) {
      return (
        <span>
          <span className="duration">{`${duration}  ${t('monitoring.days')}`}</span>
          <span className="date">{`${startDate} - ${currentDateFormatted}`}</span>
        </span>
      )
    }

    const durationInMonths = Math.floor(duration / 30)

    return (
      <span>
        <span className="duration">{`${durationInMonths} ${t('monitoring.months')}`}</span>
        <span className="date">{`${startDate} - ${currentDateFormatted}`}</span>
      </span>
    )
  }

  const handleSelectAll = () => {
    if (Object.values(checkedModels).every((value) => value)) {
      return
    }

    const updatedCheckedModels = {}

    summaryTableData.forEach((modelTable) => {
      updatedCheckedModels[modelTable.modelName] = true
    })
    
    setCheckedModels(updatedCheckedModels)
  }

  const toggleModelCheckbox = (modelName) => {
    const updatedCheckedModels = { ...checkedModels, [modelName]: !checkedModels[modelName] }

    setCheckedModels(updatedCheckedModels)
  }

  const handleTabChange = (tabLabel) => {
    setSelectedTab(tabLabel)
  }

  const renderCycleSummary = () => {
    return (
      <div className="grid-container">
        {summaryTableData.map((modelTable) =>
          checkedModels[modelTable.modelName] && (
            <div key={modelTable.modelName}>
              <div className="summary-header">{modelTable.modelName}</div>
              <GenericTable
                className="summary-table"
                data={modelTable.tableData}
                headers={[
                  {
                    title: t("monitoring.cf")
                  },
                  {
                    title: t("monitoring.unique_sn_cf")
                  },
                  {
                    title: t("monitoring.total_cf")
                  },
                  {
                    title: ''
                  },
                ]}
                keys={[
                  'code',
                  'uniqueSn',
                  'totalCf',
                  'action',
                ]}
                keyRenderer={{
                  code: (item) => {
                    return (
                      <div className={`product-type-wrapper`}>
                        {item.code}
                      </div>
                    )
                  },
                  action: (item) => {
                    return (
                      <div className="table-actions-wrapper" onClick={(e) => { e.stopPropagation(); toggleShowActionsMenu(item["identifier"]) }}>
                        {showActionsMenu === item["identifier"] ? <MenuKebabVerticalBackgroundIcon /> : <MenuKebabVerticalIcon />}
                        {showActionsMenu === item["identifier"] && (
                          <div className="actions-menu">
                            <ActionsMenu
                              setHideMenu={() => setShowActionsMenu(null)}
                              actions={item.actions}
                              isLoading={showSpinner}
                            />
                          </div>
                        )}
                      </div>
                    )
                  }
                }}
              />
            </div>
          )
        )}
      </div>
    )
  }

  const renderCycleList = () => {
    return (
      <Fragment>
        <div className="list-download-wrapper">
          <div className="list-download d-flex">
            <SimpleButton className="action-button" disabled={showSpinner} onClick={() => exportToCsv()}>
              {t("download.download")}
            </SimpleButton>
          </div>
        </div>
        <div className="grid-container">
          {listTableData.length > 0 &&
            <GenericTable
              data={listTableData}
              headers={[
                {
                  title: t("monitoring.sn")
                },
                {
                  title: t("monitoring.date")
                },
                {
                  title: t("monitoring.cycle_fault")
                },
              ]}
              keys={[
                'sn',
                'date',
                'fault',
              ]}
              keyRenderer={{
                code: (item) => {
                  return (
                    <div className={`product-type-wrapper`}>
                      {item.code}
                    </div>
                  )
                }
              }}
            />
          }
        </div>
      </Fragment>
    )
  }

  const exportToCsv = () => {
    let queryParams = {
      duration: selectedDuration,
    }
    
    if (selectedCfNumber !== -1) {
      queryParams = { ...queryParams, cycle_fault: selectedCfNumber }
    }

    if (isSerialNumberSearch) {
      queryParams = { ...queryParams, sn: selectedProduct }
    } else {
      queryParams = { ...queryParams, product_type: selectedProduct }
    }

    setShowSpinner(true)

    getAnalyticsFaultsListCsv(queryParams)
      .then((res) => {
        const date = new Date().toISOString().split('T')
        const dateNow = date[0].split('-').reverse().join('_')
        const timeNow = date[1].split('.')[0].split(':').join('_')

        downloadFile({
          data: res,
          fileName: `analytics_${dateNow}_${timeNow}.csv`,
          fileType: 'text/csv',
        })

        setShowSpinner(false)

        toast.dismiss()
        toast.success(t("monitoring.download_success"))
      })
      .catch(() => {
        setShowSpinner(false)

        toast.dismiss()
        toast.error(t("error.failure_msg"))
      })
  }

  const toggleShowActionsMenu = (identifier) => {
    setShowActionsMenu(showActionsMenu === identifier ? null : identifier)
  }

  const navigateToTroubleshoot = (model, cycleFault) => {
    handleNavigationWithState()

    history.push({
      pathname: `/troubleshoot`,
      state: {
        model,
        cycleFault: cycleFault < 10 ? `0${cycleFault}` : cycleFault,
        withBackNavigation: true,
      }
    })
  }

  const handleNavigationWithState = () => {
    props.actions.setBackNavigationState({
      backNavPage: location.pathname,
      activeIndex: 1
    })
  }

  const handleOpenAnalyticsDetails = (cycleFault) => {
    setAnalyticsDetailsCf(cycleFault)
    setShowAnalyticsDetailsModal(true)
  }

  return (
    <div className="cycle-analytics-content-wrapper">
      <div className="actions-wrapper">
        <SearchWithFilter
          placeholder={t("search.search_by_serial_number")}
          onSearch={(e) => handleSearch(e)}
          showFiltersModal={() => setShowFiltersModal(true)}
          onResetFilters={() => handleResetFilters()}
          showCounterBadge={selectedFilterUnit || selectedFilterCountries.length > 0}
          counterBadge={(selectedFilterUnit && 1) + selectedFilterCountries.length}
          className={`${areFiltersSelected ? "disable-search-bar " : ""} ${isSerialNumberSearch ? "disabled-filters-button" : "no-reset-button"}`}
          searchText={filterQuery} 
        />
        {(selectedFilterUnit || selectedFilterCountries.length > 0 || filterQuery) && (
          <div className="clear-filters" onClick={() => handleResetFilters()}>
            {t("monitoring.clear_filters")}
          </div>
        )}
        <p className="current-date">
          <span>{currentDay().split(",")[0]},</span>
          <span className="medium">{currentDay().split(",")[1]},{currentDay().split(",")[2]}</span>
        </p>
      </div>
      {!isSerialNumberSearch &&
        <div className="overview-expand-wrapper">
          <div
            className="overview-expand-header"
            onClick={() => setIsOverviewExpanded((prev) => !prev)}
          >
            <div className="title">
              {t("monitoring.overview")}
            </div>
            <div className="icon">{isOverviewExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}</div>
          </div>
          {isOverviewExpanded &&
            <div className="overview-expand-body">
              <div className="separator"/>
              {overviewTableData.length > 0 &&
                <>
                  <GenericTable
                    className="overview-table"
                    data={overviewTableData}
                    headers={[
                      {
                        title: t("monitoring.product")
                      },
                      {
                        title: t("monitoring.unique_sn_cf")
                      },
                      {
                        title: t("monitoring.total_cf")
                      },
                      {
                        title: t("monitoring.units_in_field")
                      },
                    ]}
                    keys={[
                      'productType',
                      'uniqueSn',
                      'totalFaults',
                      'totalSold',
                    ]}
                    keyRenderer={{
                      productType: (item) => {
                        return (
                          <div className={"product-type-wrapper" + (item.productType === selectedProduct ? " bold-font" : "")}>
                            {item.productType}
                          </div>
                        )
                      },
                    }}
                    onRowClick={(item) => { setSelectedCfNumber(-1); setCfNumberOptions([-1]); setSelectedProduct(item.productType)} }
                  />
                  <div className="total-units-wrapper">
                    <div className="label">{t("monitoring.total_units")}</div>
                    <div className="value" style={{width: totalUnitsSoldWidth}}>{totalUnitsSold}</div>
                  </div>
                </>
              }
            </div>
          }
        </div>
      }
      <div className="product-details-wrapper">
        <div className="details-header">
          {selectedProduct &&
            <div className="product-name">{selectedProduct}</div>
          }
          <div className="filter-options-wrapper">
            <div className="filter-option">
              <div className="input-title">{t("monitoring.cf_number")}</div>
              <ClickOutside 
                itemRef="click-outside-wrapper"
                className={!selectedProduct ? " dropdown-disabled" : ""}
                eventItem={selectCfNumberInput}
                toDisplayItem={selectCfNumberOptions}
              />
            </div>
            <div className="filter-option">
              <div className="input-title">{t("monitoring.trend")}</div>
              <ClickOutside 
                itemRef="click-outside-wrapper"
                className={!selectedProduct ? " dropdown-disabled" : ""}
                eventItem={selectDurationInput}
                toDisplayItem={selectDurationOptions}
              />
            </div>
          </div>
        </div>
        {selectedProduct &&
          <>
            {!isSerialNumberSearch &&
              <div className="models-container">
                <div className="title">{t("monitoring.models")}</div>
                <div className="models-checkboxes">
                  <div className="models-checkbox" onClick={() => handleSelectAll()}>
                    <CheckboxInput
                      checked={Object.values(checkedModels)?.every((value) => value) || false}
                    />
                    <span>{t("monitoring.all_models")}</span>
                  </div>
                  {summaryTableData.map((modelTable) => (
                    <div key={modelTable.modelName} className="models-checkbox">
                      <CheckboxInput
                        checked={checkedModels[modelTable.modelName]}
                        onChange={() => toggleModelCheckbox(modelTable.modelName)}
                      />
                      {modelTable.modelName}
                    </div>
                  ))}
                </div>
              </div>
            }
            <WrappedTabs tabs={tabs.map((tab) => ({ id: tab.key, name: tab.label }))} activeTab={selectedTab} changeTab={handleTabChange} />
            {selectedTab === 0 && renderCycleSummary()}
            {selectedTab === 1 && renderCycleList()}
          </>
        }
        {!selectedProduct && (
          <div className="no-product-wrapper">
            <EmptyPageResults
              title={t("monitoring.no_product_selected")}
              subtitle={t("monitoring.no_product_selected_subtitle")}
            />
          </div>
        )}
      </div>
      {showFiltersModal &&
        <FiltersModal
          filters={[
            {
              name: t("monitoring.products"),
              type: FilterType.singleSelect,
              dataset: overviewTableData.map((item) => item.productType),
              input: selectedFilterUnit,
              output: (filterUnit) => {
                setSelectedFilterUnit(filterUnit)
                setAreFiltersSelected(true)
              },
            },
            {
              name: t("monitoring.country"),
              type: FilterType.multiSelect,
              dataset: countries,
              input: selectedFilterCountries,
              output: (filterCountries) => {
                setSelectedFilterCountries(filterCountries)
                setAreFiltersSelected(true)
              },
            },
          ]}
          shouldFilter={(shouldApply) => setShouldApplyFilters(shouldApply)}
          resetFilters={() => handleResetFilters()}
          closeFilters={() => setShowFiltersModal(false)}
        />
      }
      {showAnalyticsDetailsModal &&
        <AnalyticsDetailsModal
          cf={analyticsDetailsCf}
          isSerialNumberSearch={isSerialNumberSearch}
          selectedProduct={selectedProduct}
          closeModal={() => setShowAnalyticsDetailsModal(false)}
        />
      }
      {showSpinner &&
        <div className="spinner-wrapper">
          <LoadingSpinner/>
        </div>
      }
    </div>
  )
}

function stateToProps({ backNavigationState }) {
  return {
    backNavigationState
  }
}

function dispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        setBackNavigationState,
        clearBackNavigationState
      },
      dispatch
    ),
  }
}

export default connect(stateToProps, dispatchToProps)(CycleAnalytics)
