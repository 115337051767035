import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import {toast} from "react-toastify"
import { flatMap } from "lodash"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import NotifiedGroup from "./NotifiedGroup"
import PageModal from "../common/PageModal"
import ClickOutside from "../common/ClickOutside"
import ToggleSwitch from "../common/ToggleSwitch"
import SimpleButton from '../common/SimpleButton'
import DropdownMenuWithCheckbox from "../common/DropdownMenuWithCheckbox"
import CheckboxInput from "../common/CheckboxInput"
import { CaretDownIcon, CloseCircleIcon, CalendarIcon } from "../../icons"
import {
  createNotificationMsg,
  updateNotificationMsg
} from "../../actions/notifications"
import { convertToSimpleDate } from "../../utils/filters/date"

const AddEditNotificationMessage = (props) => {
  const { t } = useTranslation()
  const {
    selectedMessage,
    methods,
    notificationSettings,
    notificationRegions,
    closeModal,
    isLoading,
    modelData
  } = props
  const [title, setTitle] = useState('')
  const [type, setType] = useState('')
  const [notifyNewUsers, setNotifyNewUsers] = useState(false)
  const [expiryDate, setExpiryDate] = useState(null)
  const [notificationMessage, setNotificationMessage] = useState('')
  const [roles, setRoles] = useState([])
  const [regions, setRegions] = useState([])
  const [models, setModels] = useState([])
  const [version, setVersion] = useState('')
  const [deliveryMethod, setDeliveryMethod] = useState('')
  const [haveError, setHaveError] = useState({})
  const [isEnabled, setIsEnabled] = useState(false)
  const neededFieldsMapping = {
    regions: ['Bulletin'],
    models: ['Firmware update', 'Bulletin'],
    version: ['Firmware update']
  }

  useEffect(() => {
    if (selectedMessage) {
      setTitle(selectedMessage.title)
      setType(+selectedMessage.notificationId)
      setNotificationMessage(selectedMessage.message)
      setNotifyNewUsers(selectedMessage.notifyNewUsers)
      setRoles(selectedMessage.roles?.map(role => +role))
      setRegions(selectedMessage.regions?.map(region => +region))
      setModels(selectedMessage.models)
      setVersion(selectedMessage.firmwareVersion ?? '')
      setDeliveryMethod(selectedMessage.deliveryMethod)
      setIsEnabled(selectedMessage.isEnabled)

      const dateString = selectedMessage.expiryDate
      const dateParts = dateString?.split("T")[0]?.split("-")
      setExpiryDate(dateParts ? new Date(dateParts[0], dateParts[1] - 1, dateParts[2]) : null)
    }
  },[selectedMessage])

  const notificationHeaderContent = () => {
    return (
      <div className="status-wrapper">
        <p className="input-option">{t('events.status')}</p>
        <div className="status-option">
          <ToggleSwitch checked={isEnabled} onChange={() => setIsEnabled((prevValue) => !prevValue)} />
          <p className="text-status-option">
          {
            isEnabled ? t('events.active') : t('events.inactive')
          }
          </p>
        </div>
      </div>
    )
  }

  const notificationFooterContent = () => {
    return (
      <>
        <SimpleButton 
          className="cancel-button" 
          onClick={() => closeModal()}
        >
          {t('buttons.cancel')}
        </SimpleButton>
        <SimpleButton 
          className="submit-button" 
          onClick={() => handleAddEditMessage()}
          disabled={isLoading}
        >
          {t('labels.save')}
        </SimpleButton>
      </>
    )
  }

  const CustomDateInput = React.forwardRef(({ value, onClick }, ref) => (
    <div className={"custom-date-input"} ref={ref} onClick={onClick}>
      <div className="date-text">{value ? value : `${t("events.select-expiry-date")}`}</div>
      {value && (
        <button
          className="reset-date-button" 
          onClick={(e) => {e.stopPropagation(); setExpiryDate(null)}}
        >
          <CloseCircleIcon/>
        </button>)
      }
      <CalendarIcon className="calendar-icon" />
    </div>
  ))

  const onSelectInput = (isComponentVisible, toggleVisibility) => {
    if (toggleVisibility) {
      toggleVisibility(!isComponentVisible)
    }    
  }

  const selectType = (type, toggleVisibility) => {
    setType(type)
    setDeliveryMethod(type && notificationSettings?.find(e => e.id === type).type)
    if (toggleVisibility) {
      toggleVisibility(false)
    }
  }

  const selectTypeInput = ({toggleVisibility, isComponentVisible}) => (
    <div className={"select-input"}>
      <div 
        className="height d-flex flex-align-center flex-justify-between" 
        onClick={() => onSelectInput(isComponentVisible, toggleVisibility)}
      >
        <div className="d-flex flex-align-center h-100">
          {
            type ? 
            t(
              [`filters.${getEventGroupKeyById(getSettingsByType(type)?.eventGroupId)}`, 
              getEventGroupKeyById(getSettingsByType(type)?.eventGroupId)]
            ) : 
            t("events.select-one")
          }
        </div>
        <CaretDownIcon className={"mr-15 caret-dropdown-icon" + (isComponentVisible ? " icon-dropdown-open" : "")}/>
      </div>
    </div>
  )

  const selectTypeOptions = ({toggleVisibility}) => (
    <div className="options-wrapper d-flex flex-align-center flex-column">
      {getNonDeviceSettings(notificationSettings)?.map((e, index) => (
        <div 
          className={"option cursor-pointer no-wrap" + (e.id === type ? " selected-option" : "")} 
          key={index}
          onClick={() => selectType(e.id, toggleVisibility)}
        >
          {t([`filters.${getEventGroupKeyById(e.eventGroupId)}`, getEventGroupKeyById(e.eventGroupId)])}
        </div>
      ))
      }
    </div>
  )

  const selectDeliveryMethod = (deliveryMethod, toggleVisibility) => {
    setDeliveryMethod(deliveryMethod)

    if (toggleVisibility) {
      toggleVisibility(false)
    }
  }

  const selectDeliveryMethodInput = ({toggleVisibility, isComponentVisible}) => (
    <div className={"select-input"}>
      <div 
        className="height d-flex flex-align-center flex-justify-between" 
        onClick={() => onSelectInput(isComponentVisible, toggleVisibility)}
      >
        <div className="d-flex flex-align-center h-100">
          {deliveryMethod ? t([`filters.${deliveryMethod}`, deliveryMethod]) : t("events.select-one")}
        </div>
        <CaretDownIcon className={"mr-15 caret-dropdown-icon" + (isComponentVisible ? " icon-dropdown-open" : "")}/>
      </div>
    </div>
  )

  const selectDeliveryMethodOptions = ({toggleVisibility}) => (
    <div className="options-wrapper d-flex flex-align-center flex-column">
      {methods?.map((e, index) => (
        <div 
          className={"option cursor-pointer no-wrap" + (e === deliveryMethod ? " selected-option" : "")} 
          key={index}
          onClick={() => selectDeliveryMethod(e, toggleVisibility)}
        >
          {t([`filters.${e}`, e])}
        </div>
      ))
      }
    </div>
  )

  const handleRegionsChange = (data) => {
    const regionIds = notificationRegions.filter( e => data.includes(e.region)).map(e => +e.id)
    setRegions(regionIds)
  }
  const handleModelsChange = (data) => {setModels(data)}
  const handleRolesChange = (data) => {
    setRoles(data.map(e => +e))
  }

  const isValidInput = () => {
    let errors = {}
    let isValid = true

    if (!title.trim()) {
      errors = { ...errors, title: true }
      isValid = false
    }

    if (!type) {
      errors = { ...errors, type: true }
      isValid = false
    }

    if (roles?.length === 0) {
      errors = { ...errors, roles: true }
      isValid = false
    }

    if ((!type || getSettingsByType(type)?.name === 'Firmware update') && models?.length === 0) {
      errors = { ...errors, models: true }
      isValid = false
    }
    
    if ((!type || shouldShowField('version', type)) && version?.trim()?.length === 0) {
      errors = { ...errors, version: true }
      isValid = false
    }

    if (deliveryMethod?.length === 0) {
      errors = { ...errors, deliveryMethod: true }
      isValid = false
    }

    setHaveError(errors)
    return isValid
  }

  const handleAddEditMessage = () => {
    if (!isValidInput()) {
      toast.dismiss()
      toast.error(t("error.fill_all_fields"))
      return
    }

    const bodyParams = {
      title: title?.trim(),
      notificationId: type,
      message: notificationMessage?.trim(),
      notifyNewUsers: notifyNewUsers,
      expiryDate: expiryDate ? convertToSimpleDate(expiryDate) : "",
      deliveryMethod: deliveryMethod,
      isEnabled: isEnabled ? true : false,
      roles: roles,
      regions: shouldShowField('regions', type) ? regions : [],
      models: shouldShowField('models', type) ? models : [],
      firmwareVersion: shouldShowField('version', type) ? version.trim() : "",
      version: shouldShowField('version', type) ? version.trim() : "",
    }

    if (selectedMessage) {
      props.actions.updateNotificationMsg(selectedMessage.id, bodyParams)
    } else {
      props.actions.createNotificationMsg(bodyParams)
    }

    closeModal()
  }
  const getEventGroupKeyById = (id) => {
    const eventGroup = props.eventGroups.find((e) => e.id === id && !!!e.isDeviceEvent)
    return eventGroup?.key ?? ""
  }

  const getSettingsByType = (type) => notificationSettings.find(e => e.id === type)

  const shouldShowField = (field, type) => {
    const typeName = getSettingsByType(type)?.name
    return neededFieldsMapping[field].includes(typeName)
  }

  const getNonDeviceSettings = (notifications) => {
    return notifications.filter(
      notification => props.eventGroups.find(eventGroup => eventGroup.id === notification.eventGroupId)?.isDeviceEvent !== 1
    )
  }
  
  return (
    <PageModal
      toggle
      onToggle={() => closeModal()}
      className="notification-message-modal"
      title={selectedMessage ? t("events.edit-message-title") : t("events.new-message-title")}
      headerContent={notificationHeaderContent()}
      footerContent={notificationFooterContent()}
    >
      <div className="notification-modal-content">
        <p className="input-option">{t('events.title')}*</p>
        <input 
          type="text" 
          className={"name-input notification-title" + (haveError['title'] ? ' has-error' : '')}
          value={title ?? ""}
          onChange={(e) => setTitle(e.target.value)}>
        </input>
        <div className="dropdown-wrapper">
          <div className="non-device-type-wrapper">
            <p className="input-option">{t('events.notification-type')}*</p>
            <ClickOutside 
              itemRef="click-outside-wrapper"
              className={haveError['type'] ? ' has-error' : ''}
              eventItem={selectTypeInput}
              toDisplayItem={selectTypeOptions}
            />
          </div>
        </div>
        <p className="input-option">{t('events.new-users-notification')}</p>
        <div className="info-wrapper">
          {t("events.new-users-notification-info")}
        </div>
        <CheckboxInput
          className={"notify-checkbox"}
          checked={notifyNewUsers} 
          text={t("events.notify")}
          onChange={() => setNotifyNewUsers((prevValue) => !prevValue)}
        />
        {notifyNewUsers && (
          <div className="notification-expiry-date-wrapper">
            <DatePicker
              selected={expiryDate}
              onChange={(date) => setExpiryDate(date)}
              dateFormat={"yyyy-MM-dd"}
              yearItemNumber={9}
              customInput={<CustomDateInput />}
              showYearDropdown
              showMonthDropdown
              minDate={new Date()}
            />
          </div>)
        }
        <p className="input-option">{t('events.notification-message')}</p>
        <textarea 
          className="description-input notification-message"
          value={notificationMessage ?? ""}
          onChange={(e) => setNotificationMessage(e.target.value)}>            
        </textarea>
        <p className="input-option">{t('events.who-to-notify')}*</p>
        <NotifiedGroup 
          onChange={handleRolesChange}
          initialRoles={selectedMessage?.roles || []}
        />
        {(!type || shouldShowField('regions', type)) && 
          <div className="dropdown-wrapper without-margin-bottom">
            <div className="non-device-type-wrapper">
              <p className="input-option">{t('events.region')}</p>
              <DropdownMenuWithCheckbox 
                options={notificationRegions.map(e => e.region)}
                placeholder={t('events.select-one-or-more')}
                initialData={selectedMessage?.regions?.map(region => notificationRegions.find(notifRegion => notifRegion.id === +region)?.region) || []}
                onChange={handleRegionsChange}
                hasError={haveError['regions']}
              />
            </div>
          </div>
        }
        {(!type || shouldShowField('models', type)) &&
          <div className="dropdown-wrapper without-margin-bottom">
            <div className="non-device-type-wrapper">
              <p className="input-option">{t('events.model')}{getSettingsByType(type)?.name === 'Bulletin' ? '' : '*'}</p>
              <DropdownMenuWithCheckbox 
                options={modelData}
                placeholder={t('events.select-one-or-more')}
                initialData={selectedMessage?.models || []}
                onChange={handleModelsChange}
                hasError={haveError['models']}
              />
            </div>
          </div>
        }
        {(!type || shouldShowField('version', type)) &&
          <>
            <p className="input-option">{t('events.version')}*</p>
            <input 
              type="text" 
              className={"name-input notification-title" + (haveError['version'] ? ' has-error' : '')}
              value={version ?? ""}
              onChange={(e) => setVersion(e.target.value)}>
            </input>
          </>
        }
        <div className="dropdown-wrapper">
          <div className="non-device-type-wrapper">
            <p className="input-option">{t('events.delivery-method')}*</p>
            <ClickOutside 
              itemRef="click-outside-wrapper"
              className={haveError['deliveryMethod'] ? ' has-error' : ''}
              eventItem={selectDeliveryMethodInput}
              toDisplayItem={selectDeliveryMethodOptions}
            />
          </div>
        </div>
      </div>
    </PageModal>
  )
}

function stateToProps({ notifications, productTypes }) {
  return {
    eventGroups: notifications?.eventGroups || [],
    modelData: flatMap(productTypes, 'models').map(e => e.name) || [],
    notificationSettings: notifications?.notificationSettings || [],
    notificationRegions: notifications?.notificationRegions || [],
    methods: notifications?.deliveryMethods || [],
  }
}

function dispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        createNotificationMsg,
        updateNotificationMsg,
      },
      dispatch
    ),
  }
}

export default connect(stateToProps,dispatchToProps)(AddEditNotificationMessage)
