import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"
import DashboardHeader from "../common/DashboardHeader/DashboardHeader"
import Breadcrumb from "../common/Breadcrumb"
import SearchWithFilter from "../common/SearchWithFilter"
import FiltersModal, { FilterType } from "../common/FiltersModal"
import EmptyPageResults from "../common/EmptyPageResults"
import GenericTable from "../common/GenericTable"
import { useTable } from "../../hooks/useTable"
import { getHeaders, dealerBulletinsRenderer, dealerBulletinsTableKeys } from "./table-config"
import { Storage } from "aws-amplify"

import "./dealer-bulletins.scss"
import { DownloadModernIcon, PdfModernIcon } from "../../icons"
import { uniq } from "lodash"
import { getDealerBulletins } from "../../utils/requests/dealersAPI"

export function groupDataByProperty(data, propertyName) {
  return uniq(data.map(p => p[propertyName]))
}

function DealerBulletins(props) {
  const { t } = useTranslation()
  const hideBreadcrumb = props.hideBreadcrumb
  const [showFiltersModal, setShowFiltersModal] = useState(false)
  const [collapsed, setCollapsed] = useState([])
  const [modelFilter, setModelFilter] = useState([])
  const [typeFilter, setTypeFilter] = useState([])
  const [shouldApplyFilters, setShouldApplyFilters] = useState(true)
  const [orderBy, setOrderBy] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  
  
  const {tableData, setTableData, selectedRowIds, handleRowSelect} = useTable({
    initialData: [],
    initialOrderBy: { field: "title", order: "desc", key: "title_desc" },
    rowIdentifier: "title",
  })
  
  useEffect(() => {  
    async function startFetching() {
      const response = await getDealerBulletins()
      setTableData(response.data)
      setIsLoading(false)
    }
    
    startFetching()
  }, [setTableData])
  
  const handleResetFilters = () => {
    setModelFilter([])
    setTypeFilter([])
  }

  async function handleDownload(filePath, fileName) {
    Storage.configure({
      customPrefix: {
        public: "",
        protected: "",
        private: "",
      },
    })

    const result = await Storage.get(`${filePath}${fileName}`)
    window.open(result, "_blank")
  }
  
  const filters = {
    model: modelFilter,
    type: typeFilter
  }
  const filteredBulletins = applyFilters(tableData, filters)
  
  const handleSort = (orderingField) => {
    setOrderBy(orderingField)
  }

  return (
    <div className={`dealer-bulletins-wrapper`}>
      {!hideBreadcrumb && <Breadcrumb path={window.location.pathname} />}
      <DashboardHeader headerText={t("nav.dealer-bulletins")} />
      <div className="dealer-content-wrapper">
        <div className="filters-wrapper">
          <SearchWithFilter
            onSearch={() => null}
            showFiltersModal={() => setShowFiltersModal(true)}
            onResetFilters={handleResetFilters}
            showCounterBadge={modelFilter.length > 0 || typeFilter.length > 0}
            counterBadge={modelFilter.length + typeFilter.length}
          />
        </div>
      </div>
      {showFiltersModal && (
        <FiltersModal
          filters={[
            {
              name: t("model"),
              type: FilterType.multiSelect,
              dataset: groupDataByProperty(tableData, "model"),
              input: modelFilter,
              output: (selectedModelFilter) => {
                setModelFilter(selectedModelFilter)
              },
            },
            {
              name: t("type"),
              type: FilterType.multiSelect,
              dataset: groupDataByProperty(tableData, "type"),
              input: typeFilter,
              output: (selectedTypeFilter) => {
                setTypeFilter(selectedTypeFilter)
              },
            },
          ]}
          shouldFilter={(shouldApply) => setShouldApplyFilters(shouldApply)}
          resetFilters={() => handleResetFilters()}
          closeFilters={() => setShowFiltersModal(false)}
        />
      )}
      {!isLoading && tableData.length === 0 ? (
        <EmptyPageResults className="with-margin-top" title={t("firmware.no-data")} subtitle={t("policies.no-policies-found-extra")} />
      ) : (
        <div>
          <GenericTable
            data={filteredBulletins}
            headers={getHeaders(t)}
            keys={dealerBulletinsTableKeys}
            keyRenderer={dealerBulletinsRenderer({
              selectedItems: selectedRowIds,
              handleSelectReport: handleRowSelect,
              handleDownload,
            })}
            onRowClick={handleRowSelect}
            activeSort={orderBy}
            onSort={(orderingField) => handleSort(orderingField)}
          />
          <div className="cards-container">
            {filteredBulletins.map((row, index) => {
              return (
                <div
                  className="card"
                  onClick={() => {
                    const nextCollapsed = [...collapsed]
                    nextCollapsed[index] = !nextCollapsed[index]
                    setCollapsed(nextCollapsed)
                  }}
                  >
                  <div className="icons">
                    <input type="checkbox" name="" id="" checked={selectedRowIds[index]} />
                    <button
                      className="download"
                      onClick={(e) => {
                        e.stopPropagation()
                        handleDownload(row.filePath, row.fileName)
                      }}
                    >
                      <DownloadModernIcon />
                    </button>
                  </div>
                  <div className="file-name">
                    <div className="pdf-icon"><PdfModernIcon /></div>
                    <div className="value">{row.documentTitle}</div>
                  </div>
                  {collapsed[index] && (
                    <>
                      <div className="bold">Description</div>
                      <div className="value">{row.bulletinType}</div>
                      <div className="bold">Updated</div>
                      <div className="value">{row.createdAt}</div>
                    </>
                  )}
                </div>
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
}

function applyFilters(data, filters) {
  return data.filter((product) => {
    for (let key in filters) {
      if (filters[key].length > 0 && !filters[key].includes(product[key])) return false
    }
    return true
  })
}

function mapStateToProps({ authedUser }) {
  return {
    authedUser,
  }
}

export default connect(mapStateToProps)(DealerBulletins)
