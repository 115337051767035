import { convertToSimpleDateWithFullTime } from "../../utils/filters/date"
import CheckboxInput from "../common/CheckboxInput"
import ExpandableText from "../common/ExpandableText"

export function getPriceListHeaders(t) {
  return [
    {
      title: "",
      isMultiSelect: true,
    },
    {
      title: t("dealer-portal.partNo"),
      orderKey: "partNo",
    },
    {
      title: t("dealer-portal.description"),
      orderKey: "description",
    },
    {
      title: t("dealer-portal.segment"),
      orderKey: "segment",
    },
    {
      title: t("dealer-portal.productGroup"),
      orderKey: "productGroup",
    },
    {
      title: t("dealer-portal.productLine"),
      orderKey: "productLine",
    },
    {
      title: t("dealer-portal.brand"),
      orderKey: "brand",
    },
    {
      title: t("dealer-portal.trademark"),
      orderKey: "trademark",
    },
    {
      title: t("dealer-portal.sellingEntity"),
      orderKey: "sellingEntity",
    },
    {
      title: t("dealer-portal.shipper"),
      orderKey: "shipper",
    },
    {
      title: t("dealer-portal.rrp"),
      orderKey: "rrp",
    },
    {
      title: t("dealer-portal.myPrice"),
      orderKey: "myPrice",
    },
    {
      title: t("dealer-portal.rrpCurrency"),
      orderKey: "rrpCurrency",
    },
    {
      title: t("dealer-portal.priceListType"),
      orderKey: "priceListType",
    },
    {
      title: t("dealer-portal.market"),
      orderKey: "market",
    },
    {
      title: t("dealer-portal.date"),
      orderKey: "date",
    },
  ]
}

export const priceListTableKeys = [
  "selectable",
  "partNo",
  "description",
  "segment",
  "productGroup",
  "productLine",
  "brand",
  "trademark",
  "sellingEntity",
  "shipper",
  "rrp",
  "myPrice",
  "rrpCurrency",
  "priceListType",
  "market",
  "date",
]

export const priceListRenderer = ({ selectedRowIds, handleRowCheck, rowIdentifier }) => {
  return {
    selectable: (item) => {
      return (
        <div className="selectable-wrapper">
          <CheckboxInput
            checked={selectedRowIds.includes(item[rowIdentifier])}
            onChange={() => handleRowCheck(item[rowIdentifier])}
          />
        </div>
      )
    },
    partNo: (item) => {
      return (
        <div className="part-no-wrapper">
          <ExpandableText text={item.partNo} />
        </div>
      )
    },
    description: (item) => {
      return (
        <div className="description-wrapper">
          <ExpandableText text={item.description} />
        </div>
      )
    },
    productType: (item) => {
      return (
        <div className="product-type-wrapper">
          <ExpandableText text={item.productType} />
        </div>
      )
    },
    date: (item) => {
      return (
        <div className="date-wrapper">
          <ExpandableText text={convertToSimpleDateWithFullTime(item.updatedAt)} />
        </div>
      )
      },
    productGroup: (item) => {
      return (
        <div className="product-group-wrapper">
          <ExpandableText text={item.productGroup} />
        </div>
      )
    },
    productLine: (item) => {
      return (
        <div className="product-line-wrapper">
          <ExpandableText text={item.productLine} />
        </div>
      )
    },
    brand: (item) => {
      return (
        <div className="brand-wrapper">
          <ExpandableText text={item.brand} />
        </div>
      )
    },
    trademark: (item) => {
      return (
        <div className="trademark-wrapper">
          <ExpandableText text={item.trademark} />
        </div>
      )
    },
    sellingEntity: (item) => {
      return (
        <div className="selling-entity-wrapper">
          <ExpandableText text={item.manufacturer} />
        </div>
      )
    },
    shipper: (item) => {
      return (
        <div className="shipper-wrapper">
          <ExpandableText text={item.shipperQuantity} />
        </div>
      )
    },
    rrp: (item) => {  
      return (
        <div className="rrp-wrapper">
          <ExpandableText text={item.rrp} />
        </div>
      )
    },
    myPrice: (item) => {
      return (
        <div className="my-price-wrapper">
          <ExpandableText text={item.myPrice} />
        </div>
      )
    },
    rrpCurrency: (item) => {
      return (
        <div className="rrp-currency-wrapper">
          <ExpandableText text={item.rrpCurrency} />
        </div>
      )
    },
    priceListType: (item) => {
      return (
        <div className="price-list-type-wrapper">
          <ExpandableText text={item.priceListType} />
        </div>
      )
    },
    market: (item) => {
      return (
        <div className="market-wrapper">
          <ExpandableText text={item.market} />
        </div>
      )
    },
    segment: (item) => {
      return (
        <div className="segment-wrapper">
          <ExpandableText text={item.segment} />
        </div>
      )
    },
  }
}