import React from "react";
import "./text-input-field.scss";

const TextInputField = ({
  inputIcon,
  type,
  name,
  placeholder,
  value = "",
  isRequired = false,
  error = "",
  showError = true,
  isSubmitting = false,
  sublabel = "",
  rightIcon = null,
  ...props
}) => {
  return (
    <div className={"input-col-wrapper" + (inputIcon ? " has-icon " : "")}>
      <div className={"input-box " + (error && isSubmitting ? "input-error" : "")}>
        {inputIcon !== null && inputIcon}
        <input type={type} name={name} value={value} {...props} className={error && isSubmitting ? "input-error" : ""} />
        <div className="placeholder">
          {placeholder}
          {isRequired && <span>*</span>}
        </div>
        {rightIcon !== null && rightIcon}
        {/* { isSubmitting && ( error
                    ? <ErrorCircleActiveIcon className="user-icon error-icon" /> 
                    : value ? <CheckCircleFilledIcon className="user-icon success-icon" /> : ''
                    )
                } */}
      </div>
      {sublabel && <div className="sub-label">{sublabel}</div>}
      {error && isSubmitting && showError && <div className="error-message">{error}</div>}
    </div>
  );
};

export default TextInputField;
