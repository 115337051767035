import { useState } from "react";
import { useTranslation } from "react-i18next";
import PageModal from "../common/PageModal";

import "./price-list.scss";
import { postRequestMissingItems } from "../../utils/requests/dealersAPI";

export function RequestMissingItemRowModal({ isRequestListTable, row, handleClose }) {
	const { t } = useTranslation();

	const [formCount, setFormCount] = useState(1);
	const [isFormSubmitting, setIsFormSubmitting] = useState(false)

	const isRequestListTableRowSelection = isRequestListTable && row;
	const [formState, setFormState] = useState(() => {
		return Array.from({ length: formCount }).map(() => {
			return {
				partNumber: isRequestListTableRowSelection ? row?.partNo : "",
				itemName: isRequestListTableRowSelection ? row?.description : "",
				comments: isRequestListTableRowSelection ? row?.comments : "",
			};
		});
	});

	function handleAddItem() {
		setFormState([
			...formState,
			{
				partNumber: "",
				itemName: "",
				comments: "",
			},
		]);
		setFormCount(formCount + 1);
  }
  
  async function handleSubmit() {
    setIsFormSubmitting(true)
    // const filteredFormState = formState.map((state) => {
    //   const { request_type, ...rest } = state
    //   return rest
    // })
    await postRequestMissingItems(formState)
    setIsFormSubmitting(false)
    handleClose()
  }

	return (
		<div className={`${isRequestListTableRowSelection ? "hide-footer" : ""}`}>
			<PageModal
				toggle
				onToggle={handleClose}
				title={"Request Missing Item"}
				headerContent={<div></div>}
			>
				<div className="request-missing-modal">
					<div className="header-info">
						{isRequestListTableRowSelection ? (
							<>
								<p>
									Hello, <br /> Thank you for contacting us. We’ve received your
									query and our team is looking into it.
								</p>
								<p>
									We do our best to respond to every email within 24 hours, so
									you should be hearing back from one of our customer service
									team members soon.{" "}
								</p>
								<p>Many thanks, Gepy</p>
							</>
						) : (
							<>
								Here is the text, or short advice for user what he/she need to
								to on this modal for a better results.
							</>
						)}
					</div>
					<div className="missing-title">
						{t("dealer-portal.missing-items")}
					</div>
					{Array.from({ length: formCount }).map((_, index) => {
						return (
							<>
								<div className="form-group">
									<label htmlFor="partNo">
										{t("dealer-portal.part-number")}
									</label>
									{formState[index]?.partNumber}
								</div>
								<div className="form-group">
									<label htmlFor="name">{t("dealer-portal.item-name")}</label>
									{formState[index]?.itemName}
								</div>
								<div className="form-group">
									<label htmlFor="comments">
										{t("dealer-portal.comments")}
									</label>
									{formState[index]?.comments}
								</div>
							</>
						);
					})}
					{!isRequestListTableRowSelection && (
						<button className="add-btn" onClick={handleAddItem}>
							{t("dealer-portal.add-missing")}
						</button>
					)}
				</div>
			</PageModal>
		</div>
	);
}
