import React, { useMemo, useState } from "react"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"
import DashboardHeader from "../common/DashboardHeader/DashboardHeader"
import Breadcrumb from "../common/Breadcrumb"
import SimpleButton from "../common/SimpleButton"

import "./members.scss"
import WrappedTabs from "../common/WrappedTabs"
import TabTable from "./TabTable"

const getTabs = (t) => [
  {
    id: 0,
    name: t("dealer-portal.all"),
    tabType: "Active",
  },
  {
    id: 1,
    name: t("dealer-portal.requests"),
    tabType: "Requests",
  },
]

function Members(props) {
  const { t } = useTranslation()
  const hideBreadcrumb = props.hideBreadcrumb
  const [activeTabId, setActiveTabId] = useState(0)

  const [activeModal, setActiveModal] = useState(null)
  const [showFiltersModal, setShowFiltersModal] = useState(false)

  const tabs = useMemo(() => {
    return getTabs(t)
  }, [t])

  const isRequestListTable = activeTabId === 1
  const tableProps = tabs.find((tab) => tab.id === activeTabId).tableProps

  function handleTabSwitch(tabId) {
    setActiveTabId(tabId)
    tableProps.clearSelections()
  }

  const tabType = tabs.find((tab) => tab.id === activeTabId).tabType

  return (
    <div className={`members-list-wrapper`}>
      {!hideBreadcrumb && <Breadcrumb path={window.location.pathname} />}
      <div className="header-wrapper">
        <DashboardHeader headerText={t("nav.members")}></DashboardHeader>
        <SimpleButton
          className="submit-button"
          onClick={() => {
            setActiveModal("invite-user")
          }}
        >
          {t("dealer-portal.invite_user")}
        </SimpleButton>
      </div>
      <div>
        <div className="members-list-wrapper">
          <WrappedTabs tabs={tabs.map((tab) => ({ id: tab.id, name: t(tab.name) }))} activeTab={activeTabId} changeTab={handleTabSwitch} />
          <TabTable
            key={tabType}
            tabType={tabType}
            activeModal={activeModal}
            setActiveModal={setActiveModal}
            showFiltersModal={showFiltersModal}
            setShowFiltersModal={setShowFiltersModal}
          />
        </div>
      </div>
    </div>
  )
}

function mapStateToProps({ authedUser }) {
  return {
    authedUser,
  }
}

export default connect(mapStateToProps)(Members)
