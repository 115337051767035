import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import i18n from 'i18next';

// const errorCodes = {
//   UsernameExistsException: "An account with the given email already exists.",
//   InvalidPasswordException: "Password did not conform with policy: Password must have symbol characters",
//   NotAuthorizedException: "Username or password is incorrect",
//   UserNotConfirmedException: "User is not confirmed.",
//   CodeMismatchException: "User does not exist or is disabled",
//   ExpiredCodeException: "Code has expired",
//   CodeMismatchException: "Invalid verification code provided, please try again.",
//   CodeDeliveryDetails : "", //for ResendConfirmationCode. returned for disabled user or a user that doesn't exist. Amazon Cognito sends a confirmation code to the existing user's email or phone number.
//   network: "Network Error"
// }

const generalErrorCodes = {
  UnexpectedLambdaException: "UnexpectedLambdaException",
}

export const processError = (error) => {
  console.log('-- Processing ERROR --', error)

  if (error && error.code === generalErrorCodes.UnexpectedLambdaException) {
    toast.error(i18n.t("error.failure_msg"))
    return;
  }
  
  if ((error instanceof Error && error.message ) || (error && error.message)) {
    const errorMessages = ["Refresh Token has been revoked", "Refresh Token has expired"];

    if(errorMessages.includes(error.message) && error.code === "NotAuthorizedException") {
      console.error(error.message)
    }else {
      toast.error(error.message)
    }
  }
}