import {ApiEnum, _baseGetFetch, _basePutFetch, _baseDeleteFetch, _basePostFetch} from "./baseFetch"

const path = "/policies"

export const getPolicies = async () => {
  return _baseGetFetch(`${path}`, undefined, ApiEnum.admin)
}

export const getPolicyTypes = async () => {
  return _baseGetFetch(`/policytypes`, undefined, ApiEnum.admin)
}

export const getPolicyPermissions = async (id) => {
  return _baseGetFetch(`${path}/${id}/permissions`, undefined, ApiEnum.admin)
}

export const getPermissions = async () => {
  return _baseGetFetch(`/permissions`, undefined, ApiEnum.admin)
}

export const createPolicy = async (bodyParams) => {
  return _basePostFetch(`/policies`, { ...bodyParams }, ApiEnum.admin)
}

export const createPolicyType = async (bodyParams) => {
  return _basePostFetch(`/policytypes`, { ...bodyParams }, ApiEnum.admin)
}

export const putPolicy = async (id, bodyParams) => {
  return _basePutFetch(`${path}/${id}`, { ...bodyParams }, ApiEnum.admin);
}

export const putPolicyType = async (id, bodyParams) => {
  return _basePutFetch(`/policytypes/${id}`, { ...bodyParams }, ApiEnum.admin);
}

export const putPolicyPermissions = async (id, bodyParams) => {
  return _basePutFetch(`${path}/${id}/permissions`, bodyParams, ApiEnum.admin);
}

export const deletePolicy = async (id) => {
  return _baseDeleteFetch(`${path}/${id}`, undefined, ApiEnum.admin);
}

export const deletePolicyType = async (id) => {
  return _baseDeleteFetch(`/policytypes/${id}`, undefined, ApiEnum.admin);
}
