import "./members.scss"
import SimpleButton from "../common/SimpleButton"
import PageModal from "../common/PageModal"
import { useTranslation } from "react-i18next"
import { useState } from "react"
import { inviteUserToCompany } from "../../utils/requests/company"
import LoadingSpinner from "../common/LoadingSpinner"
import { SelectMenu } from "../common/SelectMenu"
import { toast } from "react-toastify"

export function InviteUserModal({ handleClose, branches, companyId }) {
  const { t } = useTranslation()
  const [selectedBranchOption, setSelectedBranchOption] = useState(branches[0])
  const [email, setEmail] = useState("")
  const [showSpinner, setShowSpinner] = useState(false)

  const formDisabled = !selectedBranchOption || !email

  async function handleSubmit() {
    toast.dismiss()
    try {
      setShowSpinner(true)
      await inviteUserToCompany(companyId, {
        email,
        branch: selectedBranchOption.id,
      })
      toast.success(t("dealer-portal.invitation_success"))
      setShowSpinner(false)
    } catch (error) {
      toast.error(t("error.something_wrong"))
      setShowSpinner(false)
    }
    handleClose()
  }

  function getFooter() {
    return (
      <>
        <SimpleButton className="cancel-button" onClick={handleClose}>
          {t("labels.cancel")}
        </SimpleButton>
        <SimpleButton disabled={formDisabled} className="submit-button" onClick={handleSubmit}>
          {t("labels.add")}
        </SimpleButton>
      </>
    )
  }

  return (
    <PageModal
      toggle
      onToggle={handleClose}
      title={
        <div>
          <span>{t("dealer-portal.invite_user_to_company")}</span>
        </div>
      }
      headerContent={<div></div>}
      footerContent={getFooter()}
    >
      {showSpinner && (
        <div className="spinner-wrapper">
          <LoadingSpinner />
        </div>
      )}
      <div className="invite-user-modal">
        <div className="form-group">
          <label htmlFor="user-email">{t("dealer-portal.user_email")}*</label>
          <input type="text" id="user-email" value={email} onChange={(e) => setEmail(e.target.value)} />
        </div>
        <div className="form-group half">
          <label>{t("dealer-portal.branch")}</label>
          {branches.length > 0 && <SelectMenu
            options={branches?.map((b) => ({ ...b, key: b.id, value: b.branchName }))}
            initialSelectedKey={branches[0].id}
            onChange={(key) => {
              setSelectedBranchOption(branches.find((b) => b.id === key))
            }}
          />}
        </div>
      </div>
    </PageModal>
  )
}
