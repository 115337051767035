import ExpandableText from "../common/ExpandableText"
import { MenuKebabVerticalIcon, MenuKebabVerticalBackgroundIcon, EnabledIcon, PendingIcon, DisabledIcon } from "../../icons"
import ActionsMenu from "../common/ActionsMenu"

export function getMembersListHeaders(t) {
  return [
    {
      title: t("dealer-portal.email"),
      orderKey: "email",
    },
    {
      title: t("dealer-portal.name"),
      orderKey: "name",
    },
    {
      title: t("dealer-portal.role"),
      orderKey: "role",
    },
    {
      title: t("dealer-portal.branch"),
      orderKey: "branch",
    },
    {
      title: t("dealer-portal.status"),
      orderKey: "status",
    },
    {
      title: ''
    },
  ]
}

export const membersTableKeys = ["email", "name", "role", "branch", "status", "actions"]
const statuses = ["activated", "pending", "disabled"]

const renderUserStatus = (t, status) => {
  switch (status) {
    case statuses[0]:
      return (
        <div className="status-active">
          <EnabledIcon />
          <p>{t("filters.enabled")}</p>
        </div>
      )
    case statuses[1]:
      return (
        <div className="status-pending">
          <PendingIcon />
          <p>{t("filters.pending")}</p>
        </div>
      )
    case statuses[2]:
      return (
        <div className="status-inactive">
          <DisabledIcon />
          <p>{t("filters.disabled")}</p>
        </div>
      )
    default:
      return null
  }
}

export const membersListRenderer = ({ t, toggleShowActionsMenu, showActionsMenu, setShowActionsMenu, rowIdentifier, handleRemove, handleEdit, branchName, updateStatus }) => {
  return {
    email: (item) => {
      return (
        <div className="email-wrapper">
          <ExpandableText text={item.email} />
        </div>
      )
    },
    name: (item) => {
      return (
        <div className="name-wrapper">
          <ExpandableText text={item.firstName} />
        </div>
      )
    },
    role: (item) => {
      return (
        <div className="role-wrapper">
          <ExpandableText text={item.role} />
        </div>
      )
    },
    branch: (item) => {
      return (
        <div className="branch-wrapper">
          <ExpandableText text={branchName} />
        </div>
      )
    },
    status: (item) => {
      return (
        <div className="status-wrapper">
          <ExpandableText text={renderUserStatus(t, item.status)} />
        </div>
      )
    },
    actions: (item) => {
      return (
        <div
          className="table-actions-wrapper"
          onClick={(e) => {
            e.stopPropagation()
            toggleShowActionsMenu(item[rowIdentifier])
          }}
        >
          {showActionsMenu === item[rowIdentifier] ? <MenuKebabVerticalBackgroundIcon /> : <MenuKebabVerticalIcon />}
          {showActionsMenu === item[rowIdentifier] && (
            <ActionsMenu
              setHideMenu={() => setShowActionsMenu(null)}
              actions={[
                {
                  label: t("labels.edit"),
                  onActionClick: (e) => {
                    e.stopPropagation()
                    handleEdit(item)
                  },
                },
                {
                  label: t("labels.remove"),
                  onActionClick: (e) => {
                    e.stopPropagation()
                    handleRemove(item)
                  },
                },
                {
                  label: item.status === "disabled" ? t("labels.enabled") : t("labels.disabled"),
                  onActionClick: (e) => {
                    e.stopPropagation()
                    updateStatus(item)
                  },
                },
              ]}
              // isLoading={showSpinner}
            />
          )}
        </div>
      )
    },
  }
}
