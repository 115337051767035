import React, { useCallback, useEffect, useState, useMemo } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { toast } from "react-toastify"
import { useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { debounce } from "lodash"
import VideoModal from "../common/VideoModal"
import SimpleButton from "../common/SimpleButton"
import SearchWithFilter from "../common/SearchWithFilter"
import FiltersModal, { FilterType } from "../common/FiltersModal"
import LoadingSpinner from "../common/LoadingSpinner"
import EmptyPageResults from "../common/EmptyPageResults"
import GenericTable from "../common/GenericTable"
import ExpandableText from "../common/ExpandableText"
import { useAppContext } from "../../libs/contextLib"
import { useWindowSize } from "../../libs/hooks"
import { handleSetQueryParams } from "../../actions/queryParams"
import { loadVideoModels, loadVideoCategories } from "../../actions/videos"
import { FilledFavIcon, UnFilledFavIcon, VideoPlayIcon } from "../../icons"
import { formatDateMonthDayYear } from "../../utils/filters/date"
import {
  getVideosListFavorites,
  getVideosModels,
  getVideosList,
  postVideoFavourites,
  putVideoFavourites,
  getVideosCategories,
} from "../../utils/requests/videosAPI"
import { checkPermission, videosWritePermission } from "../../utils/permissionValidation"

function VideosTable(props) {
  const { t } = useTranslation()
  const history = useHistory()
  const { isAuthenticated } = useAppContext()
  const isMobile = useWindowSize()[0] <= 768
  const userId = props.authedUser?.cognitoSub
  const [models, setModels] = useState([])
  const [videos, setVideos] = useState([])
  const [videoCategories, setVideoCategories] = useState([])
  const [expandedVideo, setExpandedVideo] = useState(0)
  const [selectedVideo, setSelectedVideo] = useState(null)
  const [canLoadMore, setCanLoadMore] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [selectedModel, setSelectedModel] = useState(props.model || null)
  const [isSearchDirty, setIsSearchDirty] = useState(false)
  const [showSpinner, setShowSpinner] = useState(false)
  const [showFiltersModal, setShowFiltersModal] = useState(false)
  const [queryParamsState, setQueryParamsState] = useState(() => ({
    page: 1,
    limit: parseInt(process.env.REACT_APP_PAGINATION_SIZE),
    orderBy: "title",
    order: "asc",
    ...(selectedModel ? { model: selectedModel } : {}),
    ...(props.isFavourite ? { username: userId } : {}),
  }))

  const fetcher = useMemo(() => (props.isFavourite ? getVideosListFavorites : getVideosList), [props.isFavourite])
  const [isFirstLoad, setIsFirstLoad] = useState(true)

  useEffect(() => {
    if (props.videoModels.length === 0) {
      props.actions.loadVideoModels()
    }

    if (props.videoCategories.length === 0) {
      props.actions.loadVideoCategories()
    }
  }, [props.actions])

  useEffect(() => {
    if (props.videoModels.length > 0) {
      setModels(props.videoModels)
    }
  }, [props.videoModels])

  useEffect(() => {
    if (props.videoCategories.length > 0) {
      setVideoCategories(props.videoCategories)
    }
  }, [props.videoCategories])
  
  const fetchVideos = useCallback(
    async (queryParams, videos) => {
      try {
        if (!userId) {
          return []
        }

        const page = await fetcher({ ...queryParams })
        const totalPages = page?.totalPages || 1

        setCanLoadMore(totalPages > queryParams.page)
        
        if (queryParams.page === 1) {
          return page?.data || []
        }

        const newVideos = videos.concat(page?.data)
        return newVideos
      } catch (e) {
        console.error(e.message)

        toast.dismiss()
        toast.error(t("error.failure_msg"))
        setShowSpinner(false)
      }
      finally {
        setIsFirstLoad(false)
      }
    },
    [fetcher, t]
  )
  
  const videosTableData = (videos ?? []).map((video) => {
    return {
      identifier: video.id,
      title: video.title,
      model: video.model,
      category: video.category,
      lastUpdated: video.video_updated_at ? formatDateMonthDayYear(video.video_updated_at) : "",
      isFavourite: video.is_favourite,
      favouriteId: video.favourite_id,
      thumbnailUrl: video.thumbnail_url,
      video_id: video.video_id
    }
  })

  const openVideoModal = useCallback(
    (video) => {
      setSelectedVideo(video)
      const params = new URLSearchParams({ vid: video.video_id })
      if (!selectedModel) {
        history.push({
          search: params.toString(),
        })
      }
    },
    [history, selectedModel]
  )

  const closeVideoModal = useCallback(() => {
    setSelectedVideo(null)

    const params = new URLSearchParams({})
    history.push({
      search: params.toString(),
    })
  }, [history])

  useEffect(() => {
    async function loadVideoModal() {
      if (!isAuthenticated) {
        history.push({ pathname: "/" })
      }

      if (props.queryParams.vid) {
        props.dispatch(handleSetQueryParams({ vid: null }))
      }

      const params = new URLSearchParams(window.location.search)
      const vid = params.get("vid")
      if ((videos && videos.length === 0) || !vid) {
        return
      }

      const response = await getVideosList({
        vid,
      })
      const requestedVideo = response.data[0]
      
      if (requestedVideo) {
        openVideoModal(requestedVideo)
      } else {
        const params = new URLSearchParams({})

        toast.dismiss()
        toast.error(t(`error.video_not_found`))

        history.push({
          search: params.toString(),
          state: {
            errorMessage: "Something went wrong",
          },
        })
      }
    }
    loadVideoModal()
  }, [history, isAuthenticated, openVideoModal, videos])

  const handleSort = async (orderBy) => {
    const nextQueryParams = {
      ...queryParamsState,
      page: 1,
      orderBy: orderBy.field,
      order: orderBy.order,
    }

    setShowSpinner(true)
    setQueryParamsState(nextQueryParams)
    const newVideos = await fetchVideos(nextQueryParams, videos)
    setVideos(newVideos)
    setShowSpinner(false)
  }

  useEffect(() => {
    async function handleFilters() {
      let isMounted = true
      setShowSpinner(true)
      const { category, model, ...queryParams } = queryParamsState
      const nextQueryParams = {
        ...queryParams,
        ...(selectedModel ? { model: selectedModel } : {}),
        ...(selectedCategory ? { category: selectedCategory } : {}),
        page: 1,
      }
      setQueryParamsState(nextQueryParams)
      const newVideos = await fetchVideos(nextQueryParams, videos)
      if (isMounted) {
        setVideos(newVideos)
      }
      setShowSpinner(false)
      
      return () => {
        isMounted = false
      }
    }

    return handleFilters()
  }, [fetchVideos, selectedCategory, selectedModel])

  const handleSearch = async (searchTerm) => {
    setShowSpinner(true)
    const isSearchValid = searchTerm.length > 2
    if (isSearchValid || isSearchDirty) {
      const { query, ...queryParams } = queryParamsState
      const nextQueryParams = {
        ...queryParams,
        ...(searchTerm.length > 2 && { query: searchTerm }),
        page: 1,
      }
      setQueryParamsState(nextQueryParams)
      const newVideos = await fetchVideos(nextQueryParams, videos)
      setVideos(newVideos)

      if (isSearchValid) {
        setIsSearchDirty(true)
      }
    }

    setShowSpinner(false)
  }

  const debouncedHandleSearch = useCallback(debounce(handleSearch, 200), [handleSearch])

  const handleLoadMore = async () => {
    setShowSpinner(true)
    const nextQueryParams = {
      ...queryParamsState,
      page: queryParamsState.page + 1,
    }
    setQueryParamsState(nextQueryParams)
    const newVideos = await fetchVideos(nextQueryParams, videos)
    setVideos(newVideos)
    setShowSpinner(false)
  }

  const handleResetFilters = async () => {
    const { model, category, ...queryParams } = queryParamsState
    const nextQueryParams = { ...queryParams, page: 1 }
    setQueryParamsState(nextQueryParams)

    setSelectedModel(null)
    setSelectedCategory(null)
  }

  const handleFavourites = async (favourite_id, video_id, is_favourite) => {
    if (showSpinner || !checkPermission(props.userPermissions, videosWritePermission)) {
      return
    }
    
    setShowSpinner(true)

    if (favourite_id) {
      let isFav
      if (props.isFavourite) {
        isFav = false
      } else {
        isFav = is_favourite ? false : true
      }

      try {
        await putVideoFavourites(userId, favourite_id, isFav)

        if (props.isFavourite) {
          const updatedVideos = videos.filter((video) => video.id !== video_id)
          setVideos(updatedVideos)

          return
        }

        const updatedVideos = videos.map((video) => {
          if (video.id === video_id) {
            return { ...video, is_favourite: isFav }
          }
          return video
        })
        setVideos(updatedVideos)
      } catch (e) {
        console.error(e.message)
      } finally {
        setShowSpinner(false)
      }

      return
    }
      
    try {
      const favouriteData = await postVideoFavourites(userId, video_id, true)

      const updatedVideos = videos.map((video) => {
        if (video.id === video_id) {
          return { ...video, favourite_id: favouriteData?.data?.insert_id || 0, is_favourite: true }
        }
        return video
      })

      setVideos(updatedVideos)
    } catch (e) {
      console.error(e.message)
    } finally {
      setShowSpinner(false)
    }
  }

  const getDesktopView = () => {
    return (
      <>
        {videosTableData.length > 0 && (
          <GenericTable
            data={videosTableData}
            headers={[
              {
                title: t("videos.title"),
                orderKey: "title",
              },
              {
                title: t("model"),
                orderKey: "model",
              },
              {
                title: t("videos.category"),
                orderKey: "category",
              },
              {
                title: t("videos.lastUpdated"),
                orderKey: "video_updated_at",
              },
              {
                title: "",
              },
            ]}
            keys={["title", "model", "category", "lastUpdated", "action"]}
            keyRenderer={{
              title: (item) => {
                return (
                  <div className="title-wrapper">
                    <div className="thumbnail-mask">
                      <div className="thumbnail-icon">
                        <VideoPlayIcon />
                      </div>
                      <img className="thumbnail" src={item.thumbnailUrl} alt={item.title} />
                    </div>  
                    <div className="title">
                      {item.title}
                    </div>
                  </div>
                )
              },
              action: (item) => {
                return (
                  <div className="table-actions-wrapper">
                    {item.isFavourite || props.isFavourite ? (
                      <FilledFavIcon
                        className="star-icon filled"
                        onClick={(e) => {
                          e.stopPropagation()
                          handleFavourites(item.favouriteId, item.identifier, item.isFavourite)
                        }}
                      />
                    ) : (
                      <UnFilledFavIcon
                        className="star-icon"
                        onClick={(e) => {
                          e.stopPropagation()
                          handleFavourites(item.favouriteId, item.identifier, item.isFavourite)
                        }}
                      />
                    )}
                  </div>
                )
              },
            }}
            onRowClick={(video) => openVideoModal(video)}
            activeSort={{ field: queryParamsState.orderBy, order: queryParamsState.order }}
            onSort={(orderingField) => handleSort(orderingField)}
            isLoading={showSpinner}
          />
        )}
      </>
    )
  }

  const getMobileView = () => {
    return (
      <>
        {videosTableData.map((video, index) => (
          <div key={index} className={"card"} onClick={() => setExpandedVideo(index)}>
            <div className={"card-item" + (expandedVideo !== index ? " align-center" : "")}>
              <div
                className="card-item-title video-icon-title"
                onClick={(e) => {
                  e.stopPropagation()
                  openVideoModal(video)
                }}
              >
                <div className="thumbnail-mask">
                  <div className="thumbnail-icon">
                    <VideoPlayIcon />
                  </div>
                  <img className="thumbnail" src={video.thumbnailUrl} alt={video.title} />
                </div>
              </div>
              <div className={"card-item-body" + (expandedVideo !== index ? " align-center" : "")}>
                <div>{video.title}</div>
                <div className="card-actions">
                  {video.isFavourite || props.isFavourite ? (
                    <FilledFavIcon
                      className="star-icon filled"
                      onClick={(e) => {
                        e.stopPropagation()
                        handleFavourites(video.favouriteId, video.identifier, video.isFavourite)
                      }}
                    />
                  ) : (
                    <UnFilledFavIcon
                      className="star-icon"
                      onClick={(e) => {
                        e.stopPropagation()
                        handleFavourites(video.favouriteId, video.identifier, video.isFavourite)
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
            {expandedVideo === index && (
              <>
                <div className="card-item">
                  <div className="card-item-title">{t("model")}</div>
                  <div className="card-item-body">{video.model}</div>
                </div>
                <div className="card-item">
                  <div className="card-item-title">{t("videos.category")}</div>
                  <div className="card-item-body">{video.category}</div>
                </div>
                <div className="card-item">
                  <div className="card-item-title">{t("videos.lastUpdated")}</div>
                  <div className="card-item-body">{video.lastUpdated}</div>
                </div>
              </>
            )}
          </div>
        ))}
      </>
    )
  }

  return (
    <>
      {!props.hideFilters && (
        <div className="actions-wrapper">
          <SearchWithFilter
            onSearch={debouncedHandleSearch}
            showFiltersModal={() => setShowFiltersModal(true)}
            onResetFilters={() => handleResetFilters()}
            showCounterBadge={selectedModel || selectedCategory}
            counterBadge={(selectedModel && 1) + (selectedCategory && 1)}
          />
        </div>
      )}
      {!isMobile && getDesktopView()}
      {isMobile && getMobileView()}
      {!showSpinner && !isFirstLoad && videosTableData.length === 0 && (
        <EmptyPageResults className={"empty-results-margin"} title={t("videos.no-video-found")} subtitle={t("documents.no-documents-found-extra")} />
      )}
      {canLoadMore && (
        <div className="buttons-wrapper">
          <SimpleButton className="load-more-button" onClick={() => handleLoadMore()}>
            {t("buttons.load_more")}
          </SimpleButton>
        </div>
      )}
      {showFiltersModal && (
        <FiltersModal
          filters={[
            {
              name: t("model"),
              type: FilterType.singleSelect,
              dataset: models,
              input: selectedModel,
              output: (newModel) => {
                setSelectedModel(newModel)
              },
            },
            {
              name: t("videos.category"),
              type: FilterType.singleSelect,
              dataset: videoCategories,
              input: selectedCategory,
              output: (newCategory) => {
                setSelectedCategory(newCategory)
              },
            },
          ]}
          resetFilters={() => handleResetFilters()}
          closeFilters={() => setShowFiltersModal(false)}
        />
      )}
      {showSpinner && (
        <div className={"spinner-wrapper"}>
          <LoadingSpinner />
        </div>
      )}
      {selectedVideo && <VideoModal title={selectedVideo.title} videoId={selectedVideo.video_id} onCloseVideo={closeVideoModal} />}
    </>
  )
}

function mapStateToProps({ authedUser, userPermissions, productTypes, queryParams, videos }) {
  return {
    authedUser,
    productTypes: productTypes || {},
    userPermissions,
    queryParams,
    videoModels: videos?.models || [],
    videoCategories: videos?.categories || [],
  }
}

function dispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        loadVideoModels,
        loadVideoCategories,
      },
      dispatch
    ),
  }
}

export default connect(mapStateToProps, dispatchToProps)(VideosTable)
