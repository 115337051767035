import React, { Fragment, useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import {toast} from "react-toastify"
import LoadingSpinner from "../common/LoadingSpinner"
import FiltersModal, { FilterType } from '../common/FiltersModal'
import ClickOutside from "../common/ClickOutside"
import EmptyPageResults from '../common/EmptyPageResults'
import SearchWithFilter from '../common/SearchWithFilter'
import SimpleButton from '../common/SimpleButton'
import GenericTable from "../common/GenericTable"
import ExpandableText from "../common/ExpandableText"
import {useWindowSize} from "../../libs/hooks"
import { CaretDownIcon, DownloadIcon } from "../../icons"
import { loadSystemEvents, loadSystemEventsTypes, clearSystemEventsMessages } from "../../actions/events"
import { getSystemEventsCsv } from '../../utils/requests/eventsAPI'
import { downloadFile } from '../../utils/functions'
import { convertTimeWithTAndZ } from '../../utils/filters/date'
import { useDeepCompareEffect } from '../../hooks/useDeepCompareEffect'

const orderingFields = [
  {
    field: "eventType",
    order: "asc",
    key: "eventType_asc"
  },
  {
    field: "eventType",
    order: "desc",
    key: "eventType_desc"
  },
  {
    field: "email",
    order: "asc",
    key: "email_asc"
  },
  {
    field: "email",
    order: "desc",
    key: "email_desc"
  },
  {
    field: "eventDate",
    order: "asc",
    key: "eventDate_asc"
  },
  {
    field: "eventDate",
    order: "desc",
    key: "eventDate_desc"
  },
]

const SystemEvents = (props) => {
  const { t } = useTranslation()
  const isMobile = useWindowSize()[0] <= 768
  const [systemEventsTableData, setSystemEventsTableData] = useState([])
  const [eventTypes, setEventTypes] = useState([])
  const [shouldApplyFilters, setShouldApplyFilters] = useState(true)
  const [selectedFilterStartDate, setSelectedFilterStartDate] = useState(null)
  const [selectedFilterEndDate, setSelectedFilterEndDate] = useState(null)
  const [selectedFilterEventTypes, setSelectedFilterEventTypes] = useState([])
  const [filterQuery, setFilterQuery] = useState(null)
  const [orderBy, setOrderBy] = useState({ field: "eventDate", order: "desc", key: "eventDate_desc" })
  const [showSpinner, setShowSpinner] = useState(null)
  const [showFiltersModal, setShowFiltersModal] = useState(false)
  const [canLoadMore, setCanLoadMore] = useState(false)
  const [selectedPage, setSelectedPage] = useState(1)
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const [expandedEventType, setExpandedEventType] = useState(0)

  useEffect(() => {
    if (props.systemEventsTypes.length === 0) {
      props.actions.loadSystemEventsTypes()
    }    
  }, [props.actions])

  useEffect(() => {
    const tableData = props.systemEvents.map((systemEvent) => {
      return {
        eventType: t([`filters.${systemEvent.eventType}`, systemEvent.eventType]),
        description: systemEvent.description,
        email: systemEvent.email,
        eventDate: convertTimeWithTAndZ(systemEvent.eventDate),
      }
    })

    setSystemEventsTableData(tableData)
  }, [props.systemEvents])

  useEffect(() => {
    setEventTypes(props.systemEventsTypes)
  }, [props.systemEventsTypes])

  useEffect(() => {
    setCanLoadMore(props.canLoadMore)
  }, [props.canLoadMore])

   useEffect(() => {
    if (props.errorMessage) {
      toast.dismiss()
      toast.error(props.errorMessage)

      props.actions.clearSystemEventsMessages()
    }
  }, [props.errorMessage])

  useEffect(() => {
    if (selectedPage && selectedPage > 1) {
      handleLoadSystemEvents(false)
    }
  }, [selectedPage])

  useDeepCompareEffect(() => {
    let changeValueTimeout = window.setTimeout(
      () => {
        if (shouldApplyFilters) {
          setSelectedPage(1)
          handleLoadSystemEvents(true)
        }
        
        setShouldApplyFilters(true)       
      },
      filterQuery ? 1000 : 0
    )
    return () => {
      clearTimeout(changeValueTimeout)
    }    
  },[filterQuery, selectedFilterStartDate, selectedFilterEndDate, selectedFilterEventTypes, orderBy])

  useEffect(() => {
    setShowSpinner(props.isLoading)
  }, [props.isLoading])

  const handleLoadSystemEvents = (withReset) => {
    let queryParams = createQueryParams()

    queryParams = {
      ...queryParams,
      startPage: withReset ? 1 : selectedPage
    }

    props.actions.loadSystemEvents(queryParams, withReset)
      .then(() => setIsFirstLoad(false))
  }

  const handleSearch = (value) => {
    if (value?.length > 2) {
      setShowSpinner(true)
      setFilterQuery(value)
    } else if (value?.length === 0 && filterQuery?.length > 0) {
      setShowSpinner(true)
      setFilterQuery(null)
    }
  }

  const handleSort = (orderingField) => {
    setOrderBy(orderingField)
  }

  const handleLoadMore = () => {
    setSelectedPage((prevValue) => (prevValue ? prevValue + 1 : 2))
  }

  const handleResetFilters = () => {
    setSelectedFilterStartDate(null)
    setSelectedFilterEndDate(null)
    setSelectedFilterEventTypes([])
  }

  const handleDownloadSystemEvents = () => {
    toast.dismiss()
    if (showSpinner) {
      return
    }

    const queryParams = createQueryParams()

    setShowSpinner(true)

    getSystemEventsCsv(queryParams)
      .then((res) => {
        const date = new Date().toISOString().split('T')
        const dateNow = date[0].split('-').reverse().join('_')
        const timeNow = date[1].split('.')[0].split(':').join('_')
        
        downloadFile({
          data: res,
          fileName: `system-events_${dateNow}_${timeNow}.csv`,
          fileType: 'text/csv',
        })

        setShowSpinner(false)
        toast.success(t("events.download-success"))
      })
      .catch(() => {
        setShowSpinner(false)
        toast.error(t("error.failure_msg"))
      })
  }

  const createQueryParams = () => {
    let queryParams = {}

    if (filterQuery) {
      queryParams = { ...queryParams, query: filterQuery}
    }

    if (selectedFilterStartDate) {
      queryParams = { ...queryParams, startDate: selectedFilterStartDate}
    }

    if (selectedFilterEndDate) {
      queryParams = { ...queryParams, endDate: selectedFilterEndDate}
    }

    if (selectedFilterEventTypes.length > 0) { 
      queryParams = { ...queryParams, eventType: selectedFilterEventTypes}
    }

    if (orderBy?.field && orderBy?.order) {
      queryParams = { ...queryParams, orderBy: orderBy.field, order: orderBy.order}
    }

    return queryParams
  }

  const onSelectInput = (isComponentVisible, toggleVisibility) => {
    if (toggleVisibility) {
      toggleVisibility(!isComponentVisible)
    }    
  }

  const selectOrderByOptions = (orderingField, toggleVisibility) => {
    handleSort(orderingField)

    if (toggleVisibility) {
      toggleVisibility(false)
    }
  }

  const selectOrderByOptionsInput = ({toggleVisibility, isComponentVisible}) => (
    <div className={"select-input"}>
      <div 
        className="height d-flex flex-align-center flex-justify-between" 
        onClick={() => onSelectInput(isComponentVisible, toggleVisibility)}
      >
        <div className="d-flex flex-align-center h-100">
          {orderBy?.key ? t(`events.${orderBy.key}`) : t("events.select_ordering")}
        </div>
        <CaretDownIcon className={"mr-15 caret-dropdown-icon" + (isComponentVisible ? " icon-dropdown-open" : "")}/>
      </div>
    </div>
  )

  const selectOrderByDropdownOptions = ({toggleVisibility}) => (
    <div className="options-wrapper d-flex flex-align-center flex-column">
      {orderingFields?.map((orderingField, index) => (
        <div 
          className={"option cursor-pointer no-wrap" + (orderingField === orderBy ? " selected-option" : "")} 
          key={index}
          onClick={() => selectOrderByOptions(orderingField, toggleVisibility)}
        >
          {t(`events.${orderingField.key}`)}
        </div>
      ))
      }
    </div>
  )

  return (
    <Fragment>
      <div className="system-events-content-wrapper">
        <div className="actions-wrapper">
          <SearchWithFilter
            onSearch={(e) => handleSearch(e)}
            showFiltersModal={() => setShowFiltersModal(true)}
            onResetFilters={() => handleResetFilters()}
            showCounterBadge={(selectedFilterStartDate && selectedFilterEndDate) || selectedFilterEventTypes?.length > 0}
            counterBadge={selectedFilterEventTypes?.length + (selectedFilterStartDate && selectedFilterEndDate ? 1 : 0)}
          />
          {isMobile && 
            <div className="order-by-mobile-wrapper">
              <ClickOutside 
                itemRef="click-outside-wrapper"
                eventItem={selectOrderByOptionsInput}
                toDisplayItem={selectOrderByDropdownOptions}
              />
            </div>
          }
          <button 
            className="download-button" 
            onClick={() => handleDownloadSystemEvents()}
          >
            <DownloadIcon/>
            {t("events.download")}
          </button>
        </div>
        {!isMobile && systemEventsTableData.length > 0 &&
          <GenericTable
            data={systemEventsTableData}
            headers={[
              {
                title: t('events.event-type'),
                orderKey: 'eventType'
              },
              {
                title: t('events.description')
              },
              {
                title: t('events.user'),
                orderKey: 'email'
              },
              {
                title: t('events.date-time'),
                orderKey: 'eventDate'
              },
            ]}
            keys={[
              'eventType',
              'description',
              'email',
              'eventDate',
            ]}
            activeSort={orderBy}
            onSort={(orderingField) => handleSort(orderingField)}
            isLoading={showSpinner}
          />
        }
        {isMobile && systemEventsTableData.map((systemEvent, index) =>
          <div 
            key={index}
            className="card" 
            onClick={() => setExpandedEventType(index)}
          >
            <div className={"card-item" + (expandedEventType !== index ? " align-center" : "")}>
              <div className="card-item-title">{t('events.event-type')}</div>
              <div className={"card-item-body" + (expandedEventType !== index ? " align-center" : "")}>
                {systemEvent.eventType}
              </div>
            </div>
            {expandedEventType === index && (
              <>
                <div className="card-item">
                  <div className="card-item-title">{t('events.description')}</div>
                  <div className="card-item-body">{systemEvent.description}</div>
                </div>
                <div className="card-item">
                  <div className="card-item-title">{t('events.user')}</div>
                  <div className="card-item-body">{systemEvent.email}</div>
                </div>
                <div className="card-item">
                  <div className="card-item-title">{t('events.date-time')}</div>
                  <div className="card-item-body">{systemEvent.eventDate}</div>
                </div>
              </>
            )}
          </div>)
        }
        {!showSpinner && !isFirstLoad && systemEventsTableData.length === 0 && (
          <EmptyPageResults 
            title={t("events.no-system-events-found")}
            subtitle={t("events.no-system-events-found-extra")}
          />)
        }
        {canLoadMore && (
          <div className="buttons-wrapper">
            <SimpleButton className="load-more-button" onClick={() => handleLoadMore()}>
              {t('buttons.load_more')}
            </SimpleButton>
          </div>)
        }
      </div>
      {showFiltersModal && (
        <FiltersModal 
          filters = {[
            {
              name: t('events.date'),
              type: FilterType.dateRange, 
              dataset: null,
              input : { startDate: selectedFilterStartDate, endDate: selectedFilterEndDate },
              output: (startDate, endDate) => 
                {
                  setSelectedFilterStartDate(startDate); 
                  setSelectedFilterEndDate(endDate) 
                }
            },
            {
              name: t('events.event-type'),
              type: FilterType.multiSelect,
              dataset: eventTypes,
              input: selectedFilterEventTypes,
              output: (filterEventTypes) => { setSelectedFilterEventTypes(filterEventTypes) }
            },
          ]}
          shouldFilter={(shouldApply) => setShouldApplyFilters(shouldApply)}
          resetFilters={() => handleResetFilters()}
          closeFilters={() => setShowFiltersModal(false)}
        />)
      }
      {showSpinner &&
        <div className={"spinner-wrapper"}>
          <LoadingSpinner/>
        </div>
      }
    </Fragment>
  )
}

function stateToProps({ systemEvents }) {
  return {
    systemEvents: systemEvents?.systemEvents || [],
    isLoading: systemEvents?.isLoading,
    canLoadMore: systemEvents?.canLoadMore,
    systemEventsTypes: systemEvents?.systemEventsTypes || [],
    errorMessage: systemEvents?.errorMessage,
  }
}

function dispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        loadSystemEvents,
        loadSystemEventsTypes,
        clearSystemEventsMessages
      },
      dispatch
    ),
  }
}

export default connect(stateToProps, dispatchToProps)(SystemEvents)
