import React, {useEffect, useState} from 'react'
import {useTranslation} from "react-i18next"
import { useHistory } from 'react-router-dom'
import PageModal from '../common/PageModal'
import Search from "../common/Search"
import ClickOutside from "../common/ClickOutside"
import LoadingSpinner from "../common/LoadingSpinner"
import EmptyPageResults from '../common/EmptyPageResults'
import SimpleButton from '../common/SimpleButton'
import VideoModal from '../common/VideoModal'
import { CaretDownIcon, ArrowBackThickIcon, VideoPlayIcon } from "../../icons"
import { getImageByModel } from '../../utils/functions'
import './troubleshoot-details-modal.scss'

const TroubleshootDetailsModal = (props) => {
  const {t} = useTranslation()
  const history = useHistory()
  const { 
    selectedProduct,
    rawCycles,
    selectedCycle,
    videos,
    selectedVideo,
    showSpinner,
    onSelectCycle,
    openVideoModal,
    closeVideoModal,
    closeModal, 
  } = props
  const [cycles, setCycles] = useState([])
  
  useEffect(() => {
    if (selectedProduct) {
      setCycles(rawCycles)
    }
  }, [selectedProduct])

  const troubleshootHeaderContent = () => {
    return (
      <div className="troubleshoot-header" onClick={() => handleCloseModal()}>
        <div className="back-button" >
          <div className="back-icon">
            <ArrowBackThickIcon />
          </div>
        </div>
        <p className="title">{t("buttons.back")}</p>
      </div>
    )
  }

  const searchCycle = (value) => {
    if (value) {
      const filteredCycles = rawCycles.filter(cycle => cycle.fault.toLowerCase().includes(value.toLowerCase()))
      setCycles(filteredCycles)
    } else {
      setCycles(rawCycles)
    }
  }

  const selectCycle = (cycle, toggleVisibility) => {    
    onSelectCycle(cycle, toggleVisibility)
    toggleVisibility(false)
  }

  const onSelectCycleInput = (isComponentVisible, toggleVisibility) => {
    if (isComponentVisible) {
      toggleVisibility(false)
      setCycles(rawCycles)
    } else {
      setCycles(rawCycles)
      toggleVisibility(true)
    }
  }

  const selectCycleInput = ({toggleVisibility, isComponentVisible}) => (
    <div className={"select-input"}>
      <div 
        className="height d-flex flex-align-center flex-justify-between" 
        onClick={() => onSelectCycleInput(isComponentVisible, toggleVisibility)}
      >
        <div className="d-flex flex-align-center h-100">
          {selectedCycle ? selectedCycle.fault : t('troubleshoot.no-option')}
        </div>
        <CaretDownIcon className={"mr-15 caret-dropdown-icon" + (isComponentVisible ? " icon-dropdown-open" : "")}/>
      </div>
    </div>
  )

  const selectCycleOptions = ({toggleVisibility}) => (
    <div className="options-wrapper d-flex flex-align-center flex-column">
      <div className="options-filter">
        <Search
          className="search"
          onSearch={(e) => searchCycle(e)}
          placeholder={t('placeholders.search-filters')}
        />
      </div>
      {cycles?.map((cycle, index) => 
        <div 
          className={"option cursor-pointer no-wrap" + (cycle === selectedCycle ? " selected-option" : "")} 
          key={index}
          onClick={() => selectCycle(cycle, toggleVisibility)}
        >
          {cycle.fault}
        </div>)
      }
    </div>
  )

  const handleCloseModal = () => {
    if (closeModal) {
      closeModal()
    }
  }

  const handleNavigateToVideos = () => {
    history.push({
      pathname: `/videos`,
      state: {
        model: selectedProduct,
      }
    })
  }
  
  return (
    <div className="troubleshoot-details-modal-wrapper">
      <PageModal
        toggle
        onToggle={() => handleCloseModal()}
        headerContent={troubleshootHeaderContent()}
      >
        <div className="troubleshoot-details-modal-content">
          <div className="product-card">
            <div className="image-wrapper d-flex flex-align-center">
              <img
                src={getImageByModel(selectedProduct)}
                className="img"
                alt={selectedProduct}
              />
            </div>
            <p className="product-name">{selectedProduct}</p>
          </div>
          <p className="cycle-select-title">{t('troubleshoot.select-option')}</p>
          <ClickOutside 
            itemRef="click-outside-wrapper"
            eventItem={selectCycleInput}
            toDisplayItem={selectCycleOptions}
          />

          {selectedCycle &&
            <div className="cycle-details-wrapper">
              <div className="cycle-title-wrapper">
                <div className="cycle-title">
                  <span>{t('troubleshoot.fault')}</span>
                  <span className="cycle-title-number">{selectedCycle.fault}</span>
                </div>
                <div className="cycle-description">
                  <span>{selectedCycle.description}</span>
                </div>
              </div>
              {selectedCycle.probable_cause?.length > 0 && 
                <div className="cycle-category d-flex flex-column">
                  <h3 className="cycle-category-title d-flex flex-align-center">
                    {t('troubleshoot.probable-cause')}
                  </h3>
                  <ul className="list-none">
                    {selectedCycle.probable_cause.map((el, index) => (
                      <li key={index} className="cycle-categories">
                        <ul className="cycle-category-issue-list">
                          {el.split("\\n").map((el, index) => <li key={index} className="cycle-category-issue">{el}</li>)}
                        </ul>
                      </li>
                      ))
                    }
                  </ul>
                </div>
              }
              {selectedCycle.tech_troubleshooting?.length > 0 && 
                <div className="cycle-category d-flex flex-column">
                  <h3 className="cycle-category-title d-flex flex-align-center">
                    {t('troubleshoot.tech')}
                  </h3>
                  <ul className="list-none">
                    {selectedCycle.tech_troubleshooting.map((el, index) => (
                      <li key={index} className="cycle-categories">
                        <ul className="cycle-category-issue-list">
                          {el.split("\\n").map((el, index) => <li  key={index} className="cycle-category-issue">{el}</li>)}
                        </ul>
                      </li>
                      ))
                    }
                  </ul>
                </div>
              }
              {selectedCycle.user_troubleshooting?.length > 0 && 
                <div className="cycle-category d-flex flex-column">
                  <h3 className="cycle-category-title d-flex flex-align-center">
                    {t('troubleshoot.user-troubleshoot')}
                  </h3>
                  <ul className="list-none">
                    {selectedCycle.user_troubleshooting.map((el, index) => (
                      <li key={index} className="cycle-categories">
                        <ul className="cycle-category-issue-list">
                          {el.split("\\n").map((el, index) => <li key={index} className="cycle-category-issue">{el}</li>)}
                        </ul>
                      </li>
                      ))
                    }
                  </ul>
                </div>
              }
              {/*TODO: Add back section once videos for troubelshooting are ready */}
              {/* <div className="product-videos-wrapper">
                <div className="section-title">{t("products.videos")}</div>
                <div className="videos-wrapper">
                  {videos.map((video, index) => (
                    <div
                      key={index}
                      className="video-item-wrapper"
                      onClick = {() => openVideoModal(video)}
                    >
                      <div className="thumbnail-wrapper">
                        <div className="thumbnail-icon">
                          <VideoPlayIcon />
                        </div>
                        <img
                          src={video.thumbnail_url}
                          alt="video"
                        />
                      </div>                
                      <p className="title">{video.title}</p>
                    </div>))
                  }
                  {videos.length === 0 && 
                    <div className="no-videos-wrapper">
                      <p className="disclaimer">{t("products.no_videos_available")}</p>
                    </div>
                  }
                </div>
                <SimpleButton className="action-button" onClick={() => handleNavigateToVideos()}>
                  {t("products.view_all_videos")}
                </SimpleButton>
              </div> */}
            </div>
          }
          {!selectedCycle && 
            <EmptyPageResults
              className="fit-within-page"
              title={t('troubleshoot.no-cycle-selected')}
              subtitle={t('troubleshoot.select-cycle')}
            />
          }
        </div>
      </PageModal>
      {selectedVideo &&
        <VideoModal
          title={selectedVideo.title}
          videoId={selectedVideo.video_id}
          onCloseVideo={closeVideoModal}
        />
      }
      {showSpinner &&
        <div className="spinner-wrapper">
          <LoadingSpinner/>
        </div>
      }
    </div>
  )
}

export default TroubleshootDetailsModal
