import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import {ImagePlaceholder, TrashCanFull, UploadCloudFull } from "../../../icons"

const ImageContainer = ({ index, fileSrc, hasError, canUpload, handleUploadImage, handleRemoveImage }) => {
  const { t } = useTranslation()
  const [imageSource, setImageSource] = useState(fileSrc)
  const [showDeleteButton, setShowDeleteButton] = useState(false)
  const fileInput = useRef(null)
  const acceptedFileTypes = ["image/png", "image/jpg", "image/jpeg"]
  const maxFileSize = 5000000

  useEffect(() => {
    setImageSource(fileSrc)
  }, [fileSrc])

  const handleAdd = () => {
    if (imageSource || !canUpload) {
      return
    }

    fileInput.current.click()
  }

  const handleRemove = () => {
    handleRemoveImage(index)
  }

  const uploadSingleFile = (e) => {
    const file = e.target.files[0]

    if (file.size > maxFileSize || !acceptedFileTypes.includes(file.type)) {
      toast.dismiss()
      toast.error(t("coltene_store.create_product.image-error"))

      return
    }

    handleUploadImage({
      imageSrc: URL.createObjectURL(file),
      blob: file,
      extension: file.type.split("/")[1],
      isNewImage: true
    })
  }

  return (
    <div
      className={"image-wrapper" + (hasError ? " input-error" : "")}
      onMouseEnter={() => setShowDeleteButton(true)}
      onMouseLeave={() => setShowDeleteButton(false)}
      onClick={() => handleAdd()}
    >
      <input
        ref={fileInput}
        type="file" 
        accept="image/png, image/jpg, image/jpeg" 
        style={{ display: "none" }} 
        onChange={uploadSingleFile} 
      />
      {imageSource &&
        <img 
          className={"image" + (showDeleteButton ? " with-transparency" : "")}
          src={imageSource}
          alt="product"
        />
      }
      {!imageSource &&
        <>
          {canUpload ?
            <div className="add-image-wrapper">
              <UploadCloudFull className="upload-svg" />
              {t("coltene_store.create_product.upload-photo")}
            </div>
          :
            <ImagePlaceholder />
          }
        </>
      }
      {imageSource && showDeleteButton && (
        <div className="remove-image" onClick={() => handleRemove()}>
          <TrashCanFull />
        </div>
      )}
    </div>
  )
}

export default ImageContainer
