
import React, { useEffect, useLayoutEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { CaretDownIcon, TrashCanFull } from "../../../icons"
import { useWindowSize } from "../../../libs/hooks"
import ClickOutside from "../../common/ClickOutside"
import PriceInput from "../../common/PriceInput"
import { loadTrials } from "../../../actions/businessRules"

const FormDigitalProductPrice = (props) => {
  const { t } = useTranslation()
  const isMobile = useWindowSize()[0] <= 768
  const { isEditMode, pricesFormData, productPricesErrors, countries, resetPricesErrors, updatePrices } = props
  const [trialsOptions, setTrialsOptions] = useState([])
  
  useLayoutEffect(() => {
    if (pricesFormData.length === 0) {
      const newPrice = {
        amount: "",
        currencyCode: "",
        countryCode: "",
        trialBusinessRuleId: null,
        recurrenceInterval: null,
        recurrenceIntervalCount: null,
      }

      updatePrices([newPrice])
    }
  }, [pricesFormData])

  useLayoutEffect(() => {
    if (props.priceTrials === 0) {
      props.actions.loadTrials({ isEnabled: true }, true)
    }
  }, [props.actions])

  useEffect(() => {
    if (props.priceTrials.length > 0) {
      const trials = props.priceTrials.map((trial) => {
        return { id: trial.id, name: trial.name }
      })

      setTrialsOptions(trials)
    }
  }, [props.priceTrials])

  const onSelectInput = (isComponentVisible, toggleVisibility) => {
    if (toggleVisibility) {
      toggleVisibility(!isComponentVisible)
    }    
  }

  const selectCountry = (country, idx, toggleVisibility) => {
    if (toggleVisibility) {
      toggleVisibility(false)
    }

    const updatedPrices = pricesFormData.map((price, index) => {
      if (index === idx) {
        return { ...price, countryCode: country.countryCode, currencyCode: country.currencyCode }
      }

      return price
    })

    updatePrices(updatedPrices)    
  }

  const selectCountryInput = ({toggleVisibility, isComponentVisible}, idx) => (
    <div className={"select-input"}>
      <div 
        className={`height d-flex flex-align-center flex-justify-between ${isEditMode && pricesFormData[idx].id ? "disabled" : ""}`}
        onClick={() => onSelectInput(isComponentVisible, toggleVisibility)}
      >
        <div className="text-wrapper">
          {pricesFormData[idx].countryCode ? countries.find((c) => c.countryCode === pricesFormData[idx].countryCode)?.countryName : ""}
        </div>
        <CaretDownIcon className={"mr-15 caret-dropdown-icon" + (isComponentVisible ? " icon-dropdown-open" : "")}/>
      </div>
    </div>
  )

  const selectCountryOptions = ({toggleVisibility}, idx) => (
    <div className="options-wrapper d-flex flex-align-center flex-column">
      {countries.filter((c) => pricesFormData.every((p) => p.countryCode !== c.countryCode)).map((countryOption, index) =>
        <div
          key={index}
          className={"option cursor-pointer no-wrap"}
          onClick={() => selectCountry(countryOption, idx, toggleVisibility)}
        >
          {countryOption.countryName}
        </div>)
      }
    </div>
  )

  const selectTrialBusinessRule = (trialBusinessRuleId, idx, toggleVisibility) => {
    if (toggleVisibility) {
      toggleVisibility(false)
    }
    
    if (pricesFormData[idx].trialBusinessRuleId === trialBusinessRuleId) {
      return
    }

    const updatedPrices = pricesFormData.map((price, index) => {
      if (index === idx) {
        return { ...price, trialBusinessRuleId: trialBusinessRuleId }
      }

      return price
    })

    updatePrices(updatedPrices)
  }

  const selectTrialBusinessRuleInput = ({toggleVisibility, isComponentVisible}, idx) => (
    <div className={"select-input"}>
      <div 
        className="height d-flex flex-align-center flex-justify-between" 
        onClick={() => onSelectInput(isComponentVisible, toggleVisibility)}
      >
        <div className="text-wrapper">
          {pricesFormData[idx].trialBusinessRuleId ? trialsOptions.find((d) => d.id === pricesFormData[idx]?.trialBusinessRuleId)?.name : ""}
        </div>
        <CaretDownIcon className={"mr-15 caret-dropdown-icon" + (isComponentVisible ? " icon-dropdown-open" : "")}/>
      </div>
    </div>
  )

  const selectTrialBusinessRuleOptions = ({toggleVisibility}, idx) => (
    <div className="options-wrapper d-flex flex-align-center flex-column">
      {trialsOptions.map((trialOption, index) =>
        <div
          key={index}
          className={"option cursor-pointer no-wrap" + (trialOption.id === pricesFormData[idx].trialBusinessRuleId ? " selected-option" : "")}
          onClick={() => selectTrialBusinessRule(trialOption.id, idx, toggleVisibility)}
        >
          {trialOption.name}
        </div>)
      }
    </div>
  )

  const handleAddPrice = () => {
    const newPrice = {
      amount: "",
      currencyCode: "", 
      countryCode: "", 
      trialBusinessRuleId: null,
      recurrenceInterval: null,
      recurrenceIntervalCount: null,
    }

    updatePrices([...pricesFormData, newPrice])
  }

  const handleRemovePrice = (idx) => {
    const newPrices = pricesFormData.filter((price, index) => idx !== index)

    resetPricesErrors()
    updatePrices(newPrices)
  }

  const handleChangeAmount = (amount, idx) => {
    const updatedPrices = pricesFormData.map((price, index) => {
      if (index === idx) {
        return { ...price, amount: amount }
      }

      return price
    })

    updatePrices(updatedPrices)
  }

  return (
    <div className="price-plans-wrapper">
      {pricesFormData.map((price, index) =>
        <div
          key={index}
          className={`prices-options-wrapper ${isMobile && "flex-column"} ${isMobile && index !== 0 && "with-big-margin-top"}`}
        >
          <div className="fixed-input-wrapper one-third-wrapper">
            <div className="input-label">
              {t("coltene_store.create_product.country")}
              <span className="required-mark">*</span>
            </div>
            <div className="title-with-action-wrapper-mobile">
              <ClickOutside
                itemRef="click-outside-wrapper"
                className={productPricesErrors?.includes(`digital-${index}-countryCode`) ? "input-error" : ""}
                eventItem={(e) => selectCountryInput(e, index)}
                toDisplayItem={(e) => selectCountryOptions(e, index)}
              />
              {isMobile &&
                <div className="delete-option-wrapper">
                  <TrashCanFull
                    onClick={() => {
                      handleRemovePrice(index)
                    }}
                  />
                </div>
              }
            </div>
          </div>
          <div className={"fixed-input-wrapper one-third-wrapper" + (isMobile ? " with-reduced-width" : "")}>
            <div className="input-label">
              {t("coltene_store.create_product.price")}
              <span className="required-mark">*</span>
            </div>
            <PriceInput
              value={price.amount}
              hasError={productPricesErrors?.includes(`digital-${index}-amount`)}
              onChange={(value) => handleChangeAmount(value, index)}
              currency={price.currencyCode}
            />
          </div>
          <div className={"fixed-input-wrapper with-flex-grow" + (isMobile ? " with-reduced-width" : "")}>
            <div className="input-label">
              {t("coltene_store.create_product.business-rule")}
            </div>
            <ClickOutside
              itemRef="click-outside-wrapper"
              className={productPricesErrors?.includes(`digital-${index}-trialBusinessRuleId`) ? "input-error" : ""}
              eventItem={(e) => selectTrialBusinessRuleInput(e, index)}
              toDisplayItem={(e) => selectTrialBusinessRuleOptions(e, index)}
            />
          </div>
          {!isMobile &&
            <div className="delete-option-wrapper">
              <TrashCanFull
                onClick={() => {
                  handleRemovePrice(index)
                }}
              />
            </div>
          }
        </div>)
      }
      {pricesFormData.length < countries.length &&
        <div className="add-option-wrapper" onClick={() => handleAddPrice()}>
          {`+  ${t("coltene_store.create_product.add-new-country")}`}
        </div>
      }
    </div>
  )
}

function stateToProps({ businessRules }) {
  return {
    priceTrials: businessRules?.trials || [],
  }
}

function dispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        loadTrials,
      },
      dispatch
    ),
  }
}

export default connect(stateToProps, dispatchToProps)(FormDigitalProductPrice)
