import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { toast } from "react-toastify"
import ClickOutside from "../common/ClickOutside"
import ToggleSwitch from "../common/ToggleSwitch"
import CalendarInput from "../common/CalendarInput"
import Search from "../common/Search"
import SimpleButton from "../common/SimpleButton"
import PageModal from "../common/PageModal"
import { CaretDownIcon, CartModern, CloseCircleIcon, SearchUsersIcon } from "../../icons"
import { changeBusinessRuleStatus, createBusinessRule, updateBusinessRule } from "../../actions/businessRules"
import { getDiscountValidationSchema, getTestDriveValidationSchema, getTrialValidationSchema, validateForm } from "../../utils/formValidation"
import { businessRulesWritePermission } from "../../utils/permissionValidation"
import "./business-rule-modal.scss"

const defaultFormState = {
  name: "",
  description: "",
  type: "test_drive",
  isDigital: false,
  isEnabled: false,

  duration: 30,

  startDate: "",
  finishDate: "",
  users: [],
  products: [],

  discountPercentage: 0,
}

const BusinessRuleModal = (props) => {
  const { t } = useTranslation()
  const {
    handleClose,
    businessRule,
    businessRuleLoading,
    users,
    searchUsers,
    loadMoreUsers,
    canLoadMoreUsers,
    storeProducts,
    searchProducts,
    loadMoreProducts,
    canLoadMoreProducts,
    types,
  } = props
  const [formData, setFormData] = useState(defaultFormState)
  const [selectedUsers, setSelectedUsers] = useState([])
  const [usersSearchParams, setUsersSearchParams] = useState(null)
  const [selectedProducts, setSelectedProducts] = useState([])
  const [productsSearchParams, setProductsSearchParams] = useState(null)
  const [areUsersResultsVisible, setAreUsersResultsVisible] = useState(false)
  const [areProductsResultsVisible, setAreProductsResultsVisible] = useState(false)
  const [errors, setErrors] = useState({})
  const userResultsRef = useRef(null)
  const productsResultsRef = useRef(null)
  const productActionKeep = "keep"
  const productActionDelete = "delete"
  const productActionAdd = "add"
  
  useEffect(() => { 
    toast.dismiss()
  },[])

  useEffect(() => {
    if (businessRule) {
      const mappedProducts = businessRule.products?.map((product) => {
        return {
          ...product,
          action: productActionKeep
        }
      }) || []

      setFormData({
        id: businessRule.id,
        name: businessRule.name || "",
        description: businessRule.description || "",
        type: businessRule.type,
        isDigital: businessRule.type === "discount" ? false : true,
        isEnabled: businessRule.isEnabled || false,
        duration: businessRule.value?.days || 0,
        startDate: businessRule.value?.startDate ? businessRule.value.startDate?.substring(0, 10) : "",
        finishDate: businessRule.value?.endDate ? businessRule.value.endDate?.substring(0, 10) : "",
        users: businessRule.value?.users || [],
        products: mappedProducts,
        discountPercentage: businessRule.value.percentage || 0,
      })

      if (businessRule.type === "test_drive") {
        setSelectedUsers(businessRule.value?.users || [])
        setSelectedProducts(mappedProducts)
      }
    }
  }, [businessRule])

  useEffect(() => {
    const handleClickOutsideUsersResults = (event) => {
      if (userResultsRef.current && !userResultsRef.current.contains(event.target)) {
        setAreUsersResultsVisible(false)
      }
    }

    const handleClickOutsideProductsResults = (event) => {
      if (productsResultsRef.current && !productsResultsRef.current.contains(event.target)) {
        setAreProductsResultsVisible(false)
      }
    }

    document.addEventListener("click", handleClickOutsideUsersResults, true)
    document.addEventListener("click", handleClickOutsideProductsResults, true)

    return () => {
      document.removeEventListener("click", handleClickOutsideUsersResults, true)
      document.removeEventListener("click", handleClickOutsideProductsResults, true)
    }
  }, [])

  useEffect(() => {
    setAreUsersResultsVisible(usersSearchParams || usersSearchParams === "" ? true : false)
  }, [usersSearchParams])

  useEffect(() => {
    setAreProductsResultsVisible(productsSearchParams || productsSearchParams === "" ? true : false)
  }, [productsSearchParams])

  const businessRulesHeaderContent = () => {
    return (
      <div className="status-wrapper">
        <div className="label">{t("business-rules.status")}</div>
        <ToggleSwitch
          checked={formData.isEnabled}
          onChange={() => setFormData({ ...formData, isEnabled: !formData.isEnabled })}
          isDisabled={businessRule?.isEnabled && (businessRule?.value?.users?.length > 0 || businessRule?.products?.length > 0)}
        />
        <div className="status">{formData.isEnabled ? t("business-rules.active") : t("business-rules.inactive")}</div>
    </div>
    )
  }

  const businessRuleFooter = () => {
    return (
      <>
        <SimpleButton
          className="cancel-button"
          onClick={() => handleClose()}
        >
          {t("labels.cancel")}
        </SimpleButton>
        <SimpleButton 
          className="submit-button"
          disabled={businessRuleLoading}
          onClick={businessRule ? handleEditBusinessRule : handleCreateBusinessRule}
          requiredPermission={businessRulesWritePermission}
        >
          {t("labels.save")}
        </SimpleButton>
      </>
    )
  }

  const handleChangeCalendarDate = (e, type) => {
    setFormData({ ...formData, [type]: e })
  }

  const handleInputChange = (e) => {
    return setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    })
  }

  const handleUsersSearch = (value) => {
    if (value?.length > 2 || value?.length === 0) {
      setUsersSearchParams(value)
      searchUsers(value)
    }
  }

  const handleProductsSearch = (value) => {
    if (value?.length > 2 || value?.length === 0) {
      setProductsSearchParams(value)
      searchProducts(value)
    }
  }

  const selectType = (type, toggleVisibility) => {
    setFormData({ ...formData, type: type })

    if (toggleVisibility) {
      toggleVisibility(false)
    }
  }

  const onSelectInput = (isComponentVisible, toggleVisibility) => {
    if (toggleVisibility) {
      toggleVisibility(!isComponentVisible)
    }
  }

  const selectTypeInput = ({ toggleVisibility, isComponentVisible }) => (
    <div className={"select-input" + (isComponentVisible ? " highlighted" : "")}>
      <div className="height d-flex flex-align-center flex-justify-between" onClick={() => onSelectInput(isComponentVisible, toggleVisibility)}>
        <div className="d-flex flex-align-center h-100">{formData.type ? t(`business-rules.${formData.type}`) : t("business-rules.select_type")}</div>
        <CaretDownIcon className={"mr-15 caret-dropdown-icon" + (isComponentVisible ? " icon-dropdown-open" : "")} />
      </div>
    </div>
  )

  const selectTypeOptions = ({ toggleVisibility }) => (
    <div className="options-wrapper d-flex flex-align-center flex-column">
      {types.map((type, index) => (
        <div
          className={"option cursor-pointer no-wrap" + (type === formData.type ? " selected-option" : "")}
          key={index}
          onClick={() => selectType(type, toggleVisibility)}
        >
          {t(`business-rules.${type}`)}
        </div>
      ))}
    </div>
  )

  const handleSelectUser = (userEmail) => {
    setSelectedUsers([...selectedUsers, userEmail])

    setFormData({ ...formData, users: [...formData.users, userEmail] })
  }

  const handleRemoveUser = (userEmail) => {
    const users = [...selectedUsers]
    users.splice(users.indexOf(userEmail), 1)
    
    setSelectedUsers(users)

    setFormData({ ...formData, users: users })
  }

  const handleSelectProduct = (storeProduct) => {
    const product = selectedProducts.find((p) => p.id === storeProduct.id)

    if (!product) {
      const newProduct = {
        id: storeProduct.id,
        title: storeProduct.title,
        action: productActionAdd
      }

      setSelectedProducts([...selectedProducts, newProduct])

      setFormData({ ...formData, products: [...formData.products, newProduct] })

      return
    }

    const products = selectedProducts
      .map((p) => {
        if (p.id === storeProduct.id) {
          return {
            ...p,
            action: productActionKeep,
          }
        }

        return p
      })

    setSelectedProducts(products)

    setFormData({ ...formData, products: products })
  }

  const handleRemoveProduct = (productId) => {
    const products = selectedProducts
      .map((p) => {
        if (p.id === productId) {
          if (p.action === productActionKeep) {
            return {
              ...p,
              action: productActionDelete,
            }
          }

          if (p.action === productActionAdd) {
            return null
          }
        }

        return p
      })
      .filter((p) => p)
    
    setSelectedProducts(products)

    setFormData({ ...formData, products: products })
  }

  const validateFormFields = async () => {
    toast.dismiss()
    setErrors({})

    let validationSchema = getDiscountValidationSchema()

    if (formData.type === "test_drive") {
      validationSchema = getTestDriveValidationSchema()
    }

    if (formData.type === "trial") {
      validationSchema = getTrialValidationSchema()
    }
    
    const validateData = await validateForm(validationSchema, formData)
    
    if(Object.values(validateData).length === 0 && formData.type === "test_drive") {
      const currentDate = new Date();
      const startDate = new Date(formData.startDate);
      const finishDate = new Date(formData.finishDate);
      
      // Check if endDate is in the future
      if (finishDate > currentDate) {
        // Check if startDate comes before endDate
        if (startDate > finishDate) {
          toast.error(t("error.start_date_error"))
          return Promise.reject()
        }
      } else {
        toast.error(t("error.finish_date_error"))
        return Promise.reject()
      }
    } else {
      return new Promise(function (resolve, reject) {
        if (Object.keys(validateData).length > 0) {
          reject({ type: "validationError", data: validateData })
        } else {
          resolve()
        }
      })
    }
  }

  const handleCreateBusinessRule = async () => {
    try {
            
      await validateFormFields()

      const bodyParams = {
        name: formData.name.trim(),
        description: formData.description.trim(),
        type: formData.type.toLowerCase().replace(" ", "_"),
        ...(formData.products.length > 0 && 
          { products: formData.products.map((product) => { return { id: product.id, delete: product.action === productActionDelete } }) 
        }),
        value:
          formData.type === "test_drive"
            ? {
                startDate: new Date(formData.startDate),
                endDate: new Date(new Date(formData.finishDate).setUTCHours(23, 59, 59)),
                users: formData.users,
              }
            : formData.type === "trial"
            ? {
                days: Number(formData.duration) || 0,
              }
            : {
                percentage: Number(formData.discountPercentage),
              },
        isEnabled: formData.isEnabled,
      }

      await props.actions.createBusinessRule(bodyParams)

      handleClose()
    } catch (error) {
      if (error.type === "validationError") {
        setErrors(error.data)

        toast.dismiss()
        toast.error(t("error.fill_all_fields"))
      }
    }
  }

  const handleEditBusinessRule = async () => {
    try {
      await validateFormFields()

      const bodyParams = {
        name: formData.name.trim(),
        description: formData.description.trim(),
        ...(formData.products.filter((p) => p.action !== productActionKeep).length > 0 && 
          { products: formData.products.map((product) => { return { id: product.id, delete: product.action === productActionDelete } }) 
        }),
        value:
          formData.type === "test_drive"
            ? {
                startDate: new Date(formData.startDate),
                endDate: new Date(new Date(formData.finishDate).setUTCHours(23, 59, 59)),
                users: formData.users,
              }
            : formData.type === "trial"
            ? {
                days:  Number(formData.duration) || 0
              }
            : {
                percentage: Number(formData.discountPercentage),
              },
        isEnabled: formData.isEnabled,
      }

      await props.actions.updateBusinessRule(formData.id, bodyParams)

      handleClose()
    } catch (error) {
      if (error.type === "validationError") {
        setErrors(error.data)

        toast.dismiss()
        toast.error(t("error.fill_all_fields"))
      }
    }
  }

  return (
    <PageModal
      toggle
      onToggle={() => handleClose()}
      className="business-rule-modal"
      title={businessRule ? t("business-rules.edit_rule") : t("business-rules.add_new_business_rule")}
      headerContent={businessRulesHeaderContent()}
      footerContent={businessRuleFooter()}
    >
      <div className="input-wrapper">
        <div className="input-option d-flex">
          {t("business-rules.name")}<span>*</span>
        </div>
        <input
          type="text"
          className={`name-input ${errors.name ? "input-error" : ""}`}
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          disabled={businessRule}
        />
      </div>
      <div className="input-wrapper">
        <div className="input-option d-flex">
          {t("business-rules.description")}
        </div>
        <textarea
          className="description-input"
          name="description"
          value={formData.description}
          onChange={handleInputChange}
        />
      </div>
      <div className="type-row-wrapper d-flex flex-justify-between">
        {!businessRule && (
          <div className="dropdown-wrapper">
            <div className="input-option d-flex">
              {t("business-rules.type")}
              <span>*</span>
            </div>
            <ClickOutside itemRef="click-outside-wrapper" eventItem={selectTypeInput} toDisplayItem={selectTypeOptions} />
          </div>
        )}
        {businessRule && (
          <div className="input-wrapper">
            <div className="input-option">{t("business-rules.type")}</div>
            <input type="text" className="type-input" disabled value={t(`business-rules.${formData.type}`)} />
          </div>
        )}
        <div className="input-wrapper">
          <div className="input-option">{t("business-rules.digital_product")}</div>
          <input type="text" className="is-digital-input" disabled value={formData.type === "discount" ? t("no") : t("yes")} />
        </div>
      </div>
      {formData.type === "trial" && (
        <div className="trial-details-wrapper d-flex flex-justify-between">
          <div className="input-wrapper">
            <div className="input-option d-flex">
              {t("business-rules.duration_days")} <span>*</span>
            </div>
            <input
              type="number"
              className={`duration ${errors.duration ? "input-error" : ""}`}
              name="duration"
              value={formData.duration}
              onChange={handleInputChange}
            />
          </div>
        </div>
      )}
      {formData.type === "discount" && (
        <div className="discount-details-wrapper">
          <div className="input-option d-flex">
            {t("business-rules.discount_percentage")}
            <span>*</span>
          </div>
          <div className="input-placeholder-wrapper">
            <input
              type="number"
              className={`discount-percentage ${errors.discountPercentage ? "input-error" : ""}`}
              name="discountPercentage"
              value={formData.discountPercentage}
              onChange={handleInputChange}
            />
            <div className="placeholder">%</div>
          </div>
        </div>
      )}
      {formData.type === "test_drive" && (
        <div className="test-drive-wrapper">
          <div className="dates-wrapper d-flex flex-justify-between">
            <div className="input-wrapper">
              <div className="input-option d-flex">
                {t("business-rules.start_date")}
                <span>*</span>
              </div>
              <CalendarInput 
                onChangeDay={(e) => handleChangeCalendarDate(e, "startDate")} 
                value={formData.startDate}
                error={errors.startDate}
              />
            </div>
            <div className="input-wrapper">
              <div className="input-option d-flex">
                {t("business-rules.finish_date")}
                <span>*</span>
              </div>
              <CalendarInput 
                onChangeDay={(e) => handleChangeCalendarDate(e, "finishDate")}
                value={formData.finishDate}
                error={errors.finishDate} 
              />
            </div>
          </div>
          <div className="add-users-wrapper input-wrapper">
            <div className="input-option">{t("business-rules.add_users")}</div>
            <div className="search-with-options-wrapper">
              <div onClick={() => setAreUsersResultsVisible(true)}>
                <Search 
                  onSearch={handleUsersSearch} 
                  placeholder={t("business-rules.search_by_name_email")}
                  icon={<SearchUsersIcon />}
                  onKeyDown={(e) => { e.key === "Enter" && setAreUsersResultsVisible(false) }}
                />
              </div>
              {areUsersResultsVisible && (
                <div ref={userResultsRef} className="options-wrapper">
                  {users.length > 0 && (
                    <>
                      {users.map((user, index) => {
                        return (
                          <div
                            key={`user-option-${index}`}
                            className="result-wrapper d-flex"
                            onClick={() => {
                              if (!selectedUsers.find((selected) => selected === user.email)) {
                                handleSelectUser(user.email)
                              }
                            }}
                          >
                            <div className="user-name">{`${user.firstName} ${user.middleName || ""} ${user.lastName}`}</div>
                            <div className="user-email">{user.email}</div>
                            {selectedUsers.find((selected) => selected === user.email) && (
                              <button className="remove-user-btn" onClick={() => handleRemoveUser(user.email)}>
                                <CloseCircleIcon />
                              </button>
                            )}
                          </div>
                        )
                      })}
                      {canLoadMoreUsers &&
                        <div className="buttons-wrapper">
                          <SimpleButton className="load-more-button" onClick={() => loadMoreUsers()}>
                            {t("buttons.load_more")}
                          </SimpleButton>
                        </div>
                      }
                    </>
                  )}
                  {users.length === 0 && 
                    <div className="no-results">
                      {t("no_data_available")}
                    </div>
                  }
                </div>
              )}
            </div>
            <div className="emails-wrapper d-flex">
              {selectedUsers.map((user, index) =>
                <div
                  key={`user-${index}`}
                  className="user-wrapper d-flex flex-align-center flex-justify-center"
                >
                  <div className="user-email">{user}</div>
                  <button className="remove-user" onClick={() => handleRemoveUser(user)}>
                    x
                  </button>
                </div>)
              }
            </div>
          </div>
          <div className="add-products-wrapper input-wrapper">
            <div className="input-option">{t("business-rules.add_products")}</div>
            <div className="search-with-options-wrapper">
              <div onClick={() => setAreProductsResultsVisible(true)}>
                <Search 
                  onSearch={handleProductsSearch} 
                  placeholder={t("business-rules.search_by_title")}
                  icon={<CartModern />}
                  onKeyDown={(e) => { e.key === "Enter" && setAreProductsResultsVisible(false) }}
                />
              </div>
              {areProductsResultsVisible && (
                <div ref={productsResultsRef} className="options-wrapper">
                  {storeProducts.length > 0 && (
                    <>
                      {storeProducts.map((product, index) => {
                        return (
                          <div
                            key={`product-option-${index}`}
                            className={"result-wrapper d-flex"}
                            onClick={() => {
                              if (selectedProducts.filter((p) => (p.action === productActionAdd) || (p.action === productActionKeep)).length === 0) {
                                handleSelectProduct(product)
                              }
                            }}
                          >
                            <div className="product-title">{product.title}</div>
                            <div className="product-partnumber">{product.partNumber}</div>
                            {selectedProducts.find((selected) => selected.id === product.id && (selected.action === productActionAdd || selected.action === productActionKeep)) && (
                              <button className="remove-product-btn" onClick={() => handleRemoveProduct(product.id)}>
                                <CloseCircleIcon />
                              </button>
                            )}
                          </div>
                        )
                      })}
                      {canLoadMoreProducts &&
                        <div className="buttons-wrapper">
                          <SimpleButton className="load-more-button" onClick={() => loadMoreProducts()}>
                            {t("buttons.load_more")}
                          </SimpleButton>
                        </div>
                      }
                    </>
                  )}
                  {storeProducts.length === 0 && 
                    <div className="no-results">
                      {t("no_data_available")}
                    </div>
                  }
                </div>
              )}
            </div>
            <div className="products-wrapper d-flex">
              {selectedProducts.filter((p) => (p.action === productActionAdd) || (p.action === productActionKeep)).map((product, index) =>
                <div
                  key={`product-${index}`}
                  className="product-wrapper d-flex flex-align-center flex-justify-center"
                >
                  <div className="product-title">{product.title}</div>
                  <button className="remove-product" onClick={() => handleRemoveProduct(product.id)}>
                    x
                  </button>
                </div>)
              }
            </div>
          </div>
        </div>
      )}
    </PageModal>
  )
}

function stateToProps({ users, businessRules, adminStore }) {
  return {
    types: businessRules?.busRuleTypes || [],
    users: users?.users || [],
    storeProducts: adminStore?.products || [],
    businessRuleLoading: businessRules?.busRuleLoading,
    canLoadMoreUsers: users?.canLoadMoreUsers,
    canLoadMore: businessRules?.canLoadMore,
    canLoadMoreProducts: adminStore?.canLoadMore,
  }
}

function dispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({
      createBusinessRule,
      updateBusinessRule,
      changeBusinessRuleStatus
    }, 
    dispatch),
  }
}

export default connect(stateToProps, dispatchToProps)(BusinessRuleModal)
