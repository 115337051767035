import React from "react"
import { useTranslation } from "react-i18next"
import Search from "../Search"
import { CloseIcon, FilterTableIcon } from "../../../icons"
import "./search-with-filter.scss"

const SearchWithFilter = (props) => {
  const { t } = useTranslation()
  const {
    onSearch,
    showFiltersModal,
    onResetFilters,
    onKeyDown,
    showCounterBadge,
    counterBadge,
    searchText,
    placeholder,
    disabled = false,
  } = props

  return (
    <div className={`search-with-filter-wrapper ${props.className}`}>
      <Search
        placeholder={placeholder || t("events.search")}
        onSearch={(e) => onSearch(e.trim())}
        searchText={searchText || ""}
        onKeyDown={onKeyDown}
        disabled={disabled}
      />
      <div className="filter-button-wrapper">
        <div className="filter-button" onClick={() => showFiltersModal()}>
          <div className="icon-wrapper">
            <FilterTableIcon />
          </div>          
          <span>{t("events.filter")}</span>
        </div>              
        {showCounterBadge && (
          <div className="filters-counter">
            <div className="counter-badge">{counterBadge}</div>
            <button className="reset-filters-button" onClick={() => onResetFilters()}>
              <CloseIcon/>
            </button>
          </div>)
        }
      </div>
    </div>
  )
}

export default SearchWithFilter
