import "./members.scss"
import SimpleButton from "../common/SimpleButton"
import PageModal from "../common/PageModal"
import { useTranslation } from "react-i18next"
import { useState } from "react"
import { SelectMenu } from "../common/SelectMenu"
import ToggleSwitch from "../common/ToggleSwitch"
import { editMember } from "../../utils/requests/company"
import LoadingSpinner from "../common/LoadingSpinner"
import { toast } from "react-toastify"

export function AddUserModal({ companyId, row, handleClose, isEdit = false, branches = [], roles = [], initialBranchName }) {
  const { t } = useTranslation()

  const [firstName, setFirstName] = useState(() => {
    if (row) {
      return row.firstName
    }

    return ""
  })
  const [lastName, setLastName] = useState(() => {
    if (row) {
      return row.lastName
    }

    return ""
  })
  const [email, setEmail] = useState(() => {
    if (row) {
      return row.email
    }

    return ""
  })
  const [selectedBranchOption, setSelectedBranchOption] = useState(() => branches.find((b) => b.branchName === initialBranchName))
  const [selectedRoleOption, setSelectedRoleOption] = useState(() => roles.find((r) => r.name === row.role) || roles[0])
  const [showSpinner, setShowSpinner] = useState(false)

  async function handleSubmit() {
    toast.dismiss()
    try {
      setShowSpinner(true)
      await editMember(companyId, row.userUuid, {
        firstName,
        lastName,
        email,
        roleId: selectedRoleOption.id,
        branchId: selectedBranchOption.id,
      })
      toast.success(t("dealer-portal.user_edited"))
      setShowSpinner(false)
    } catch (error) {
      toast.error(t("error.something_wrong"))
      setShowSpinner(false)
    }
    handleClose()
  }

  function getHeader() {
    return (
      <div className="status">
        <label>Status</label>
        <div onClick={() => {}}>
          <ToggleSwitch checked={true} />
        </div>
        <p className="active">{t("filters.enabled")}</p>
      </div>
    )
  }

  function getFooter() {
    return (
      <>
        <SimpleButton className="cancel-button" onClick={handleClose}>
          {t("labels.cancel")}
        </SimpleButton>
        <SimpleButton className="submit-button" onClick={handleSubmit}>
          {t("labels.submit")}
        </SimpleButton>
      </>
    )
  }

  return (
    <PageModal toggle onToggle={handleClose} title={isEdit ? "Edit User" : "Add User"} headerContent={getHeader()} footerContent={getFooter()}>
      {showSpinner && (
        <div className="spinner-wrapper">
          <LoadingSpinner />
        </div>
      )}
      <div className="edit-user-modal hide-footer">
        <div className="form">
          <div className="form-row">
            <div className="form-input">
              <label htmlFor="first-name">{t("profile-labels.first_name")}</label>
              <input type="text" name="" id="first-name" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
            </div>
            <div className="form-input">
              <label htmlFor="last-name">{t("profile-labels.last_name")}</label>
              <input type="text" name="" id="last-name" value={lastName} onChange={(e) => setLastName(e.target.value)} />
            </div>
          </div>
          <div className="form-row">
            <div className="form-input">
              <label htmlFor="email">{t("profile-labels.email")}</label>
              <input type="email" name="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} />
            </div>
          </div>
          <div className="form-row">
            <div className="form-input">
              <label htmlFor="role">{t("table.headers.role")}</label>
              {roles.length > 0 && (
                <SelectMenu
                  options={roles?.map((b) => ({ ...b, key: b.id, value: b.name }))}
                  initialSelectedKey={selectedRoleOption.id}
                  onChange={(key) => {
                    setSelectedRoleOption(roles.find((b) => b.id === key))
                  }}
                />
              )}
            </div>
            <div className="form-input">
              <label htmlFor="role">{t("dealer-portal.branch")}</label>
              {branches.length > 0 && (
                <SelectMenu
                  options={branches?.map((b) => ({ ...b, key: b.id, value: b.branchName }))}
                  initialSelectedKey={selectedBranchOption.id}
                  onChange={(key) => {
                    setSelectedBranchOption(branches.find((b) => b.id === key))
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </PageModal>
  )
}
