import { getPriceListsFilterOptions } from "../utils/requests/dealersAPI";

export const LOAD_PRICE_LIST_FILTERS = "LOAD_PRICE_LIST_FILTERS";
export const LOAD_PRICE_LIST_FILTERS_PENDING = "LOAD_PRICE_LIST_FILTERS_PENDING";
export const LOAD_PRICE_LIST_FILTERS_FULFILLED = "LOAD_PRICE_LIST_FILTERS_FULFILLED";
export const LOAD_PRICE_LIST_FILTERS_REJECTED = "LOAD_PRICE_LIST_FILTERS_REJECTED";

export const LOAD_REVIEW_OPTIONS = "LOAD_REVIEW_OPTIONS";
export const LOAD_REVIEW_OPTIONS_PENDING = "LOAD_REVIEW_OPTIONS_PENDING";
export const LOAD_REVIEW_OPTIONS_FULFILLED = "LOAD_REVIEW_OPTIONS_FULFILLED";
export const LOAD_REVIEW_OPTIONS_REJECTED = "LOAD_REVIEW_OPTIONS_REJECTED";

export function loadPriceListFilters() {
  return {
    type: LOAD_PRICE_LIST_FILTERS,
    payload: getPriceListsFilterOptions()
  }
}
