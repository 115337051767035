import React, { useEffect, useRef, useState } from 'react'
import "./language.scss"
import { useWindowSize } from '../../../libs/hooks'
import languages from '../../../utils/languages'
import { useTranslation } from "react-i18next";
import {
  CaretDownIcon,
  CaretUpIcon
  } from '../../../icons'
import { useOnClickOutside } from '../../../hooks/useOnClickOutside'
import { connect } from 'react-redux';

const Language = (props) => {
  // const [ optionsExpanded, setOptionsExpanded ] = useState(false)
  const [ showDropdown, setShowDropdown ] = useState(false)
  const { t, i18n } = useTranslation()
  const [langCode, setLangCode] = useState(languages[props.authedUser?.language] ? props.authedUser?.language : 'en-CA') //(i18n.language).substring(0,2)
  
  const [width] = useWindowSize();
  const ref = useRef(null)
  
  useEffect(() => { 
    const nextLangCode = languages[props.authedUser?.language] ? props.authedUser?.language : 'en-CA'
    i18n.changeLanguage(nextLangCode)
    setLangCode(nextLangCode)
  }, [i18n, props.authedUser?.language])

  // const toggleShowOptions = (e) => {
  //   const parentElem = getParentElem(e)
  //   const targetElem = parentElem.querySelector('.language-options')
  //   const elemVisible = elementDisplay(targetElem)
    
  //   if(elemVisible === 'none') {
  //     targetElem.style.display = 'block'
      
  //     if ( width < 760 ) {
  //       setOptionsExpanded(true)
  //     }
  //   } else {
  //     targetElem.style.display = 'none'
  //     setOptionsExpanded(false)
  //   }
  // }

  // const elementDisplay = (elem) => {
  //   return elem.currentStyle ? elem.currentStyle.display : getComputedStyle(elem, null).display;
  // }

  // const getParentElem = (e) => {
  //   let parentElem = e.currentTarget.parentNode || ''
  //   const pClass  = (parentElem !== null) ? parentElem.className : ''

  //   if (parentElem.tagName.toLowerCase() === 'svg' || pClass.indexOf('container') === -1) {
  //     parentElem = parentElem.parentNode

  //     if(parentElem.className.indexOf('-col-') === -1) {
  //       parentElem = parentElem.parentNode
  //   }
  //   }

  //   return parentElem
  // }

  useOnClickOutside(ref, () => {
    setShowDropdown(false)
  })

  const updatePreferredLanguage = (e) => {
    e.stopPropagation();
    const value = e.target.getAttribute('data-code');
    i18n.changeLanguage(value)
    setLangCode(value)
    setShowDropdown(false)
  }
  
  return (
    <div className="language-container" ref={ref} onClick={() => {
      setShowDropdown((prevState) => {
        return !prevState
      })
    }}>
      <div className="language-wrapper">
        <span className="lang-name">
          {t(`language.${languages[langCode]}`)}
        </span>{" "}
        &nbsp;
        {showDropdown ? (
          width < 760 ? (
            <CaretUpIcon className="icon" />
          ) : (
            <CaretDownIcon className="icon" />
          )
        ) : width < 760 ? (
          <CaretDownIcon className="icon" />
        ) : (
          <CaretDownIcon className="icon" />
        )}
      </div>
      {showDropdown && (
        <div className="language-options">
          {Object.keys(languages).map((k) => (
            <div
              key={k}
              className="lang-option"
              onClick={updatePreferredLanguage}
              data-code={k}
            >
              {t(`language.${languages[k]}`)}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

function mapStateToProps({ authedUser }) {
  return {
    authedUser
  };
}

export default connect(mapStateToProps)(Language);

