import React, { Fragment, useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import {toast} from "react-toastify"
import { useHistory } from 'react-router-dom'
import PageModal from "../common/PageModal"
import ConfirmationModal from '../common/ConfirmationModal'
import ClickOutside from "../common/ClickOutside"
import ToggleSwitch from '../common/ToggleSwitch'
import SimpleButton from '../common/SimpleButton'
import { CaretDownIcon, ExpandLessIcon, ExpandMoreIcon } from "../../icons"
import { loadUserDevices, loadUserSubscriptions, loadUserPermissions, partiallyUpdateUser, clearUserDevices, clearUserSubscriptions, clearUserPermissions } from "../../actions/users"
import countries from "../../utils/countries.json"
import { postSendResetPasswordEmail, postVerifyUser } from '../../utils/requests/usersAPI'
import { getRoles } from "../../utils/requests/accountManagementAPI"
import { formatPhone, getImageByModel } from '../../utils/functions'
import { no_image_200x200 } from '../../images'
import languages, { getLocaleFromIso639_2, getLanguageFromLocale } from '../../utils/languages'
import { deleteOnlineAccessAssociation, putOnlineAccessAssociation } from "../../utils/requests/productsAPI"
import { productsWritePermission } from "../../utils/permissionValidation"
import { checkPermission, hasDealerSiteAccess, hasEndUserSiteAccess, usersDeletePermission, usersWritePermission } from '../../utils/permissionValidation'

const ViewEditUser = (props) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { isEditMode, selectedUser, accountsWithRoles, statuses, deleteUser, confirmUser, closeModal, isLoading, showSpinner, hideSpinner, handleNavigationWithState } = props
  const [userDevices, setUserDevices] = useState([])
  const [userSubscriptions, setUserSubscriptions] = useState([])
  const [latestAccountsWithRoles, setLatestAccountsWithRoles] = useState([])
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [language, setLanguage] = useState(null)
  const [phone, setPhone] = useState("")
  const [country, setCountry]= useState("")
  const [company, setCompany] = useState("")
  const [account, setAccount] = useState(null)
  const [role, setRole] = useState(null)
  const [hasSiteAccess, setHasSiteAccess] = useState(false)
  const [hasDealerTechPermissions, setHasDealerTechPermissions] = useState(false)
  const [hasEndUserSitePermissions, setHasEndUserSitePermissions] = useState(false)
  const [status, setStatus] = useState("")
  const [initialLanguage, setInitialLanguage] = useState(null)
  const [initialPhone, setInitialPhone] = useState("")
  const [initialCountry, setInitialCountry] = useState("")
  const [initialCompany, setInitialCompany] = useState("")
  const [initialAccount, setInitialAccount] = useState(null)
  const [initialRole, setInitialRole] = useState(null)
  const [initialHasSiteAccess, setInitialHasSiteAccess] = useState(false)
  const [initialStatus, setInitialStatus] = useState("")
  const [isUnconfirmedUser, setIsUnconfirmedUser] = useState(false)
  const [areDevicesExpanded, setAreDevicesExpanded] = useState(false)
  const [areSubscriptionsExpanded, setAreSubscriptionsExpanded] = useState(false)
  const [isEditEnabled, setIsEditEnabled] = useState(false)
  const [showPasswordResetConfirmModal, setShowPasswordResetConfirmModal] = useState(false)
  const [showVerifyUserModal, setShowVerifyUserModal] = useState(false)
  const [isFirstDevicesLoad, setIsFirstDevicesLoad] = useState(true)
  const [isFirstSubscriptionsLoad, setIsFirstSubscriptionsLoad] = useState(true)
  const [haveError, setHaveError] = useState({})

  useEffect(() => {
    const user = props.users.find((user) => user.uuid === selectedUser.uuid)
    if (user) {
      setFirstName(user.firstName)
      setLastName(user.lastName)
      setEmail(user.email)
      setLanguage(getLocaleFromIso639_2(user.lang))
      setPhone(formatPhone(user.phone))
      setCountry(user.country)
      setCompany(user.company)
      setAccount(user.account?.id)
      setRole(user.role?.id)
      setHasSiteAccess(user.dealerTechAccess)
      setStatus(user.status)
      setHasDealerTechPermissions(user.role?.name?.toLowerCase() === "dealer technician")
      setIsUnconfirmedUser(user.confirmationStatus?.toLowerCase() === "unconfirmed")

      setInitialLanguage(getLocaleFromIso639_2(user.lang))
      setInitialPhone(formatPhone(user.phone))
      setInitialCountry(user.country)
      setInitialCompany(user.company)
      setInitialAccount(user.account?.id)
      setInitialRole(user.role?.id)
      setInitialHasSiteAccess(user.dealerTechAccess)
      setInitialStatus(user.status)

      props.actions.loadUserPermissions(user.uuid)
    }
  },[props.users])

  useEffect(async () => {
    if (isEditEnabled) {
      const currentAccountsWithRoles = accountsWithRoles

      showSpinner()

      try {
        const latestRoles = await getRoles()

        latestRoles?.forEach((role) => {
          const account = currentAccountsWithRoles.find((accountWithRole) => accountWithRole.id === role.accountId)

          if (account && !account.children.some((child) => child.id === role.id)) {
            account.children.push({id: role.id, name: role.name, isEnabled: role.isEnabled })
          }
        })

        hideSpinner()
      } catch {
        hideSpinner()
      }

      setLatestAccountsWithRoles(currentAccountsWithRoles)
    }
  }, [isEditEnabled])

  useEffect(() => {
    if (props.userDevices.length > 0) {
      setUserDevices(props.userDevices)
    }
  },[props.userDevices])

  useEffect(() => {
    if (props.userSubscriptions.length > 0) {
      setUserSubscriptions(props.userSubscriptions)
    }
  },[props.userSubscriptions])

  useEffect(() => {
    if (props.endUserPermissions.length > 0) {
      const includesDealerTechPermissions = hasDealerSiteAccess(props.endUserPermissions)
      //TODO: Use permissions once implemented on BE
      //setHasDealerTechPermissions(includesDealerTechPermissions)

      const includesEndUserSitePermissions = hasEndUserSiteAccess(props.endUserPermissions)
      setHasEndUserSitePermissions(includesEndUserSitePermissions)
    }
  },[props.endUserPermissions])

  useEffect(() => {
    if (hasEndUserSitePermissions) {
      props.actions.loadUserDevices(selectedUser.uuid).then(() => setIsFirstDevicesLoad(false))
      props.actions.loadUserSubscriptions(selectedUser.uuid).then(() => setIsFirstSubscriptionsLoad(false))
    }
  }, [hasEndUserSitePermissions])

  useEffect(() => {
    setIsEditEnabled(isEditMode)
  },[isEditMode])

  const userDetailsHeaderContent = () => {
    return (
      <div className="status-wrapper">
        <p className="input-option">{t("users-management.status")}</p>
        {renderUserStatus(status)}
      </div>
    )
  }

  const userDetailsFooterContent = () => {
    return (
      <>
        {isEditEnabled
          ? (
            <>
              <SimpleButton 
                className="cancel-button is-edit-button" 
                onClick={() => handleOnClose()}
              >
                {t('buttons.cancel')}
              </SimpleButton>
              <SimpleButton 
                className="submit-button is-edit-button"
                disabled={isDisabledSaveButton() || isLoading}
                onClick={() => handleUpdateUser()}
                requiredPermission={usersWritePermission}
              >
                {t('labels.save')}
              </SimpleButton>
            </>)
          : (
            <>
              <SimpleButton
                className="action-delete-button"
                onClick={() => deleteUser(selectedUser.uuid)}
                disabled={status !== statuses[2] && !isUnconfirmedUser}
                requiredPermission={usersDeletePermission}
              >
                {t("labels.delete")}
              </SimpleButton>
              {isUnconfirmedUser ? 
                <>
                  <SimpleButton
                    className="cancel-button"
                    onClick={() => setShowVerifyUserModal(true)}
                    requiredPermission={usersWritePermission}
                  >
                    {t("users-management.verify-user")}
                  </SimpleButton>
                  
                  <SimpleButton
                    className="cancel-button"
                    onClick={() => setShowPasswordResetConfirmModal(true)}
                    requiredPermission={usersWritePermission}
                  >
                    {t("users-management.send-reset-password")}
                  </SimpleButton>
                  
                  <SimpleButton
                    className="submit-button"
                    onClick={() => confirmUser(selectedUser.uuid)}
                    disabled={isLoading}
                    requiredPermission={usersWritePermission}
                  >
                    {t("users-management.confirm")}
                  </SimpleButton>
                </>
                
              :
                <>
                  <SimpleButton
                    className="cancel-button"
                    onClick={() => setShowPasswordResetConfirmModal(true)}
                    requiredPermission={usersWritePermission}
                  >
                    {t("users-management.send-reset-password")}
                  </SimpleButton>
                  <SimpleButton
                    className="action-button"
                    onClick={() => { setIsEditEnabled(true); setAreDevicesExpanded(false); setAreSubscriptionsExpanded(false) }}
                    requiredPermission={usersWritePermission}
                  >
                    {t("labels.edit")}
                  </SimpleButton>
                </>
              }
            </>
          )
        }
      </>
    )
  }

  const isDisabledSaveButton = () => {
    return (
      (language === initialLanguage) &&
      (phone === initialPhone) &&
      (country === initialCountry) &&
      (company === initialCompany) &&
      (account === initialAccount) &&
      (role === initialRole) &&
      (hasSiteAccess === initialHasSiteAccess) &&
      (status === initialStatus)
    )
  }

  const onSelectInput = (isComponentVisible, toggleVisibility) => {
    if (toggleVisibility) {
      toggleVisibility(!isComponentVisible)
    }    
  }

  const selectCountry = (country, toggleVisibility) => {
    setCountry(country)

    if (toggleVisibility) {
      toggleVisibility(false)
    }
  }

  const selectCountryInput = ({toggleVisibility, isComponentVisible}) => (
    <div className={"select-input"}>
      <div 
        className="height d-flex flex-align-center flex-justify-between" 
        onClick={() => onSelectInput(isComponentVisible, toggleVisibility)}
      >
        <div className="d-flex flex-align-center h-100">
          {country ? countries?.find((c) => c.code === country)?.name : t("users-management.select-country")}
        </div>
        <CaretDownIcon className={"mr-15 caret-dropdown-icon" + (isComponentVisible ? " icon-dropdown-open" : "")}/>
      </div>
    </div>
  )

  const selectCountryOptions = ({toggleVisibility}) => (
    <div className="options-wrapper d-flex flex-align-center flex-column">
      {countries?.map((countryOption, index) => (
        <div 
          className={"option cursor-pointer no-wrap" + (countryOption["code"] === country ? " selected-option" : "")} 
          key={index}
          onClick={() => selectCountry(countryOption["code"], toggleVisibility)}
        >
          {countryOption["name"]}
        </div>
      ))
      }
    </div>
  )

  const selectLanguage = (language, toggleVisibility) => {
    setLanguage(language)

    if (toggleVisibility) {
      toggleVisibility(false)
    }
  }

  const selectLanguageInput = ({toggleVisibility, isComponentVisible}) => (
    <div className={"select-input"}>
      <div 
        className="height d-flex flex-align-center flex-justify-between" 
        onClick={() => onSelectInput(isComponentVisible, toggleVisibility)}
      >
        <div className="d-flex flex-align-center h-100">
          {language ? t(`language.${getLanguageFromLocale(language)}`) : t("users-management.select-language")}
        </div>
        <CaretDownIcon className={"mr-15 caret-dropdown-icon" + (isComponentVisible ? " icon-dropdown-open" : "")}/>
      </div>
    </div>
  )

  const selectLanguageOptions = ({toggleVisibility}) => (
    <div className="options-wrapper d-flex flex-align-center flex-column">
      {Object.keys(languages)?.map((languageOption, index) => (
        <div 
          className={"option cursor-pointer no-wrap" + (languageOption === language ? " selected-option" : "")} 
          key={index}
          onClick={() => selectLanguage(languageOption, toggleVisibility)}
        >
          {t([`language.${getLanguageFromLocale(languageOption)}`,""])}
        </div>
      ))
      }
    </div>
  )

  const selectAccount = (accountId, toggleVisibility) => {
    if (account !== accountId) {
      setRole(null)
    }

    setAccount(accountId)

    if (toggleVisibility) {
      toggleVisibility(false)
    }
  }

  const selectAccountInput = ({toggleVisibility, isComponentVisible}) => (
    <div className={"select-input"}>
      <div 
        className="height d-flex flex-align-center flex-justify-between" 
        onClick={() => onSelectInput(isComponentVisible, toggleVisibility)}
      >
        <div className="d-flex flex-align-center h-100">
          {account ? latestAccountsWithRoles?.find((a) => a.id === account)?.name : t("users-management.select-account")}
        </div>
        <CaretDownIcon className={"mr-15 caret-dropdown-icon" + (isComponentVisible ? " icon-dropdown-open" : "")}/>
      </div>
    </div>
  )

  const selectAccountOptions = ({toggleVisibility}) => (
    <div className="options-wrapper d-flex flex-align-center flex-column">
      {latestAccountsWithRoles?.map((accountOption, index) => (
        <div 
          className={"option cursor-pointer no-wrap" + (accountOption.id === account ? " selected-option" : "")} 
          key={index}
          onClick={() => selectAccount(accountOption.id, toggleVisibility)}
        >
          {accountOption.name}
        </div>
      ))
      }
    </div>
  )

  const selectRole = (roleId, roleName, toggleVisibility) => {
    setRole(roleId)
    
    setHasDealerTechPermissions(roleName?.toLowerCase() === "dealer technician")

    if (toggleVisibility) {
      toggleVisibility(false)
    }
  }

  const selectRoleInput = ({toggleVisibility, isComponentVisible}) => (
    <div className={"select-input"}>
      <div 
        className="height d-flex flex-align-center flex-justify-between" 
        onClick={() => onSelectInput(isComponentVisible, toggleVisibility)}
      >
        <div className="d-flex flex-align-center h-100">
          {role ? latestAccountsWithRoles?.find((a) => a.id === account)?.children?.find((r) => r.id === role)?.name : t("users-management.select-role")}
        </div>
        <CaretDownIcon className={"mr-15 caret-dropdown-icon" + (isComponentVisible ? " icon-dropdown-open" : "")}/>
      </div>
    </div>
  )

  const selectRoleOptions = ({toggleVisibility}) => (
    <div className="options-wrapper d-flex flex-align-center flex-column">
      {latestAccountsWithRoles?.find((a) => a.id === account)?.children?.map((roleOption, index) => (
        <div 
          className={"option cursor-pointer no-wrap" + (roleOption.id === role ? " selected-option" : "")} 
          key={index}
          onClick={() => selectRole(roleOption.id, roleOption.name, toggleVisibility)}
        >
          {roleOption.name}
        </div>
      ))
      }
    </div>
  )

  const isValidInput = () => {
    setHaveError({})

    if (!role) {
      toast.error(t("error.role"))
      setHaveError({ ...haveError, role: true })
      return false
    }

    const phonePattern = new RegExp(/^\d*$/)
    if (!phonePattern.test(formatPhone(phone).trim().replaceAll("-",""))) {
      toast.error(t("error.phone"))
      setHaveError({ ...haveError, phone: true })
      return false
    }

    return true
  }

  const handleUpdateUser = () => {
    if(!isValidInput()) {
      return
    }   
    
    let bodyParams = {
      locale: language,
      phone: formatPhone(phone).replaceAll("-",""),
      company: company.trim(),
      country: country,
      roleId: role
    }

    if (hasDealerTechPermissions) {
      bodyParams = { ...bodyParams, dealerTechAccess: hasSiteAccess }
    }

    props.actions.partiallyUpdateUser(selectedUser.uuid, bodyParams)

    handleOnClose()
  }

  const handleChangeStatusUser = () => {
    if (isLoading || !checkPermission(props.userPermissions, usersWritePermission)) {
      return
    }
    
    let newStatus = statuses[0]
    if (status === newStatus) {
      newStatus = statuses[2]
    }

    props.actions.partiallyUpdateUser(selectedUser.uuid, { status : newStatus })
  }

  const renderUserStatus = (status) => {
    switch (status) {
      case statuses[0]:
        return (
          <div className="status">
            <div onClick={() => handleChangeStatusUser()}>
              <ToggleSwitch checked={true} />
            </div> 
            <p className="active">{t("filters.enabled")}</p>
          </div>
        )
      case statuses[1]:
        return (
          <div className="status">
            <div onClick={() => handleChangeStatusUser()}>
              <ToggleSwitch checked={false} />
            </div> 
            <p className="pending">{t("filters.pending")}</p>
          </div>
        )
      case statuses[2]:
        return (
          <div className="status">
            <div onClick={() => handleChangeStatusUser()}>
              <ToggleSwitch checked={false} />
            </div> 
            <p className="inactive">{t("filters.disabled")}</p>
          </div>
        )
      default:
        return null
    }
  }

  const handleUserResetPassword = () => {
    setShowPasswordResetConfirmModal(false)
    showSpinner()

    postSendResetPasswordEmail(selectedUser.uuid)
      .then(() => {
        hideSpinner()
        toast.success(t("users-management.email-sent-succesfully"))
      })
      .catch(() => {
        hideSpinner()
        toast.error(t("error.something_wrong"))
      })
  }
  
  const handleVerifyUser = () => {
    setShowVerifyUserModal(false)
    showSpinner()

    postVerifyUser(selectedUser.uuid, selectedUser.email)
      .then(() => {
        toast.dismiss()
        hideSpinner()
        toast.success(t("users-management.verified-success"))
      })
      .catch(() => {
        toast.dismiss()
        hideSpinner()
        toast.error(t("error.something_wrong"))
      })
  }

  const handleChangeAssociation = (serialNumber, targetUserEmail, associate) => {
    if (isLoading) {
      return
    }

    showSpinner()

    const changeAssociationRequestBody = {
      serial_number: serialNumber,
      email: targetUserEmail,
      action_by: props.adminEmail,
    }
    
    let changeAssociationPromise

    if (associate) {
      changeAssociationPromise = putOnlineAccessAssociation(changeAssociationRequestBody)
    } else {
      changeAssociationPromise = deleteOnlineAccessAssociation(changeAssociationRequestBody)
    }

    changeAssociationPromise
      .then((result) => {
        if (result[0]?.status === 1) {
          const updatedDevices = userDevices.map((device) => {
            if (device.serialNumber === serialNumber) {
              return { ...device, registered: associate }
            }
            return device
          })

          setUserDevices(updatedDevices)
        }

        if (result[0]?.status === 0) {
          toast.dismiss()
          toast.error(t([`online-access.${result[0].error?.code}`, "error.something_wrong"]))
        }

        hideSpinner()
      })
      .catch(() => {
        toast.dismiss()
        toast.error(t("error.failure_msg"))

        hideSpinner()
      })
  }

  const navigateToProductPage = (serialNumber) => {
    handleNavigationWithState(selectedUser.uuid)

    history.push({
      pathname:`/products/${serialNumber}`, 
      state: {
        withBackNavigation: true,
        activeTab: 3
      } 
    })
  }

  const handleOnClose = () => {
    props.actions.clearUserDevices()
    props.actions.clearUserSubscriptions()
    props.actions.clearUserPermissions()

    if (closeModal) {
      closeModal()
    }
  }

  return (
    <Fragment>
      <PageModal
          toggle
          onToggle={() => handleOnClose()}
          title={t("users-management.details")}
          headerContent={!isUnconfirmedUser && userDetailsHeaderContent()}
          footerContent={userDetailsFooterContent()}
        >
          <div className="user-details-modal-content">
            {isUnconfirmedUser &&
              <div className="unconfirmed-user-disclaimer">
                {t("users-management.user_not_confirmed")}
              </div>
            }
            <div className="user-details-item-wrapper">
              <div className="user-details-item">
                <div className="title">
                  <p>{t("users-management.first-name")}</p>
                </div>
                {isEditEnabled ? (
                  <input
                    disabled
                    type="text" 
                    className="input-item"
                    value={firstName ?? ""}
                    onChange={(e) => setFirstName(e.target.value)}>
                  </input>)
                : (
                  <div className="value">
                    <p>{firstName}</p>
                  </div>)
                }
              </div>
              <div className="user-details-item">
                <div className="title">
                  <p>{t("users-management.last-name")}</p>
                </div>
                {isEditEnabled ? (
                  <input
                    disabled
                    type="text" 
                    className="input-item"
                    value={lastName ?? ""}
                    onChange={(e) => setLastName(e.target.value)}>
                  </input>)
                : (
                  <div className="value">
                    <p>{lastName}</p>
                  </div>)
                }                
              </div>
            </div>
            <div className="user-details-item-wrapper">
              <div className="user-details-item">
                <div className="title">
                  <p>{t("users-management.email")}</p>
                </div>
                {isEditEnabled ? (
                  <input
                    disabled
                    type="text" 
                    className="input-item"
                    value={email ?? ""}
                    onChange={(e) => setEmail(e.target.value)}>
                  </input>)
                : (
                  <div className="value">
                    <p>{email}</p>
                  </div>)
                }
              </div>
              <div className="user-details-item">
                <div className="title">
                  <p>{t("users-management.language")}</p>
                </div>
                {isEditEnabled ? (
                  <ClickOutside 
                    itemRef="click-outside-wrapper"
                    eventItem={selectLanguageInput}
                    toDisplayItem={selectLanguageOptions}
                  />)
                : (
                  <div className="value">
                    <p>{t([`language.${getLanguageFromLocale(language)}`,""])}</p>
                  </div>)
                }
              </div>
            </div>
            <div className="user-details-item-wrapper">
              <div className="user-details-item">
                <div className="title">
                  <p>{t("users-management.phone")}</p>
                </div>
                {isEditEnabled ? (
                  <input 
                    type="text" 
                    className={"input-item" + (haveError["phone"] ? " has-error" : "")}
                    value={formatPhone(phone) ?? ""}
                    onChange={(e) => setPhone(formatPhone(e.target.value))}>
                  </input>)
                : (
                  <div className="value">
                    <p>{formatPhone(phone)}</p>
                  </div>)
                }
              </div>
              <div className="user-details-item">
                <div className="title">
                  <p>{t("users-management.company")}</p>
                </div>
                {isEditEnabled ? (
                  <input 
                    type="text" 
                    className="input-item"
                    value={company ?? ""}
                    onChange={(e) => setCompany(e.target.value)}>
                  </input>)
                : (
                  <div className="value">
                    <p>{company}</p>
                  </div>)
                }
              </div>
            </div>
            <div className="user-details-item-wrapper single-item">
              <div className="user-details-item">
                <div className="title">
                  <p>{t("users-management.country")}</p>
                </div>
                {isEditEnabled ? (
                  <ClickOutside 
                    itemRef="click-outside-wrapper"
                    eventItem={selectCountryInput}
                    toDisplayItem={selectCountryOptions}
                  />)
                : (
                  <div className="value">
                    <p>{country}</p>
                  </div>)
                }
              </div>
              <div className="user-details-item mobile-hidden">
              </div>
            </div>
            <div className="user-details-item-wrapper with-big-margin-top">
              <div className="user-details-item">
                <div className="title">
                  <p>{t("users-management.account")}</p>
                </div>
                {isEditEnabled ? (
                  <ClickOutside 
                    itemRef="click-outside-wrapper"
                    eventItem={selectAccountInput}
                    toDisplayItem={selectAccountOptions}
                  />)
                : (
                  <div className="value">
                    <p>{selectedUser.account?.name}</p>
                  </div>)
                }
              </div>
              <div className="user-details-item">
                <div className="title">
                  <p>{t("users-management.role")}</p>
                </div>
                {isEditEnabled ? (
                  <ClickOutside 
                    itemRef="click-outside-wrapper"
                    className={haveError["role"] ? " has-error" : ""}
                    eventItem={selectRoleInput}
                    toDisplayItem={selectRoleOptions}
                  />)
                : (
                  <div className="value">
                    <p>{selectedUser.role?.name}</p>
                  </div>)
                }
              </div>
            </div>
            {hasDealerTechPermissions && (
              <div className="user-details-item-wrapper">
                <div className="user-details-item">
                  <div className="title">
                    <p>{t("users-management.dealer-full-access")}</p>
                  </div>
                  {isEditEnabled ? (
                    <div className="d-flex flex-align-center">
                      <ToggleSwitch checked={hasSiteAccess} onChange={() => setHasSiteAccess((prevValue) => !prevValue)} />
                      <p className="with-margin-left">
                      {
                        hasSiteAccess ? t('labels.yes') : t('labels.no')
                      }
                      </p>
                    </div>)
                  : (
                    <div className="value">
                      <p>{hasSiteAccess ? t("labels.yes") : t("labels.no")}</p>
                    </div>)
                  }                
                </div>
              </div>)
            }
            {!isEditEnabled && hasEndUserSitePermissions &&
              <div className="units-subscriptions-wrapper with-big-margin-top">
                <div className="separator"/>
                <div className="units-header" onClick={() => setAreDevicesExpanded(prev => !prev)}>
                  <div className="header-title">
                    <div className="title">
                      {t("users-management.coltene-products")}
                    </div>
                    <div className="subtitle">
                      {!isFirstDevicesLoad && !isFirstSubscriptionsLoad &&
                        <>
                          <span>{`${t("users-management.total-products")}: `}</span>
                          <span className="highlight">{userDevices.length}</span>
                          <span>{` ${t("users-management.registered-products")}: `}</span>
                          <span className="highlight">{userDevices.filter((d) => d.registered)?.length}</span>
                        </>
                      }
                    </div>
                  </div>
                  <div className="expand-button">
                    {areDevicesExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </div>
                </div>
                {areDevicesExpanded && userDevices.map((userDevice, index) => 
                  <div className={"item-wrapper" + (index === 0 ? " first-item-wrapper" : "") + (index === (userDevices.length - 1) ? " last-item-wrapper": "")} key={index}>
                    <div className="item-image-wrapper">
                      <img
                        src={getImageByModel(userDevice.modelName?.replace(/-/g," "))}
                        className="item-image"
                        alt="product"
                      />
                    </div>
                    <div className="item-title-wrapper">
                      <p className="title">{userDevice.modelName?.replace(/-/g," ")}</p>
                      <SimpleButton
                        onClick={() => navigateToProductPage(userDevice.serialNumber)}
                        isLabel
                        requiredPermission={productsWritePermission}
                      >
                        {userDevice.serialNumber}
                      </SimpleButton>
                    </div>
                    <div className="device-status-wrapper">
                      <div className="item-status-wrapper">
                        <p className={userDevice.registered ? "is-registered" : "not-registered"}>
                          {userDevice.registered ? t("labels.online_access_active") : t("labels.online_access_inactive")}
                        </p>
                      </div>
                      <div className="item-button-wrapper">
                        <SimpleButton 
                          className={(userDevice.registered ? "cancel-button" : "action-button" )}
                          onClick={() => handleChangeAssociation(userDevice.serialNumber, userDevice.userEmail, !userDevice.registered)}
                          requiredPermission={productsWritePermission}
                        >
                          {userDevice.registered ? t("product.unit.dissociate") : t("product.unit.associate")}
                        </SimpleButton>
                      </div>
                    </div>
                  </div>)
                }
                <div className="separator"/>
                <div className="subscriptions-header" onClick={() => setAreSubscriptionsExpanded(prev => !prev)}>
                  <div className="header-title">
                    <div className="title">
                      {t("users-management.subscriptions")}
                    </div>
                    <div className="subtitle">
                      {!isFirstSubscriptionsLoad && !isFirstDevicesLoad &&
                        <span className="highlight">{userSubscriptions.length}</span>
                      }
                    </div>
                  </div>
                  <div className="expand-button">
                    {areSubscriptionsExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </div>
                </div>
                {areSubscriptionsExpanded && userSubscriptions.map((userSubscription, index) => 
                  <div className={"item-wrapper" + (index === 0 ? " first-item-wrapper" : "") + (index === (userSubscriptions.length - 1) ? " last-item-wrapper": "")} key={index}>
                    <div className="item-image-wrapper">
                      <img
                        src={userSubscription.product?.images[0]?.url || no_image_200x200}
                        className="item-image"
                        alt="product"
                      />
                    </div>
                    <div className="item-title-wrapper">
                      <p className="title">{userSubscription.product?.title}</p>
                      <p className="subtitle">{userSubscription.product?.subtitle}</p>
                    </div>
                    <div className="item-status-wrapper">
                      <p className={`without-margins ${userSubscription.status}`}>
                        {t([`coltene_store.subscription_history.statuses.${userSubscription.status}`, userSubscription.status])}
                      </p>
                    </div>
                  </div>)
                }
                <div className="separator"/>
              </div>
            }
          </div>
        </PageModal>
        {showPasswordResetConfirmModal && (
          <ConfirmationModal
            onToggle={() => setShowPasswordResetConfirmModal(false)}
            onAccept={() => handleUserResetPassword()}
            onCancel={() => setShowPasswordResetConfirmModal(false)}
            message={t("users-management.reset-password-confirm")}
            requiredPermission={usersWritePermission}
          >
            <div className="reset-password-tooltip">
              <p>{t("users-management.reset-password-extra")}</p>
            </div>
          </ConfirmationModal>)
        }
        {showVerifyUserModal && (
          <ConfirmationModal
            onToggle={() => setShowVerifyUserModal(false)}
            onAccept={() => handleVerifyUser()}
            onCancel={() => setShowVerifyUserModal(false)}
            message={t("users-management.verify-user-confirm")}
            requiredPermission={usersWritePermission}
          >
          </ConfirmationModal>)
        }
    </Fragment>
  )
}

function stateToProps({ users, userPermissions, authedUser }) {
  return {
    userDevices: users?.userDevices ?? [],
    userSubscriptions: users?.userSubscriptions ?? [],
    users: users?.users ?? [],
    endUserPermissions: users?.endUserPermissions ?? [],
    userPermissions,
    adminEmail: authedUser?.email
  }
}

function dispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        loadUserDevices,
        loadUserSubscriptions,
        loadUserPermissions,
        partiallyUpdateUser,
        clearUserDevices,
        clearUserSubscriptions,
        clearUserPermissions,
      },
      dispatch
    ),
  }
}

export default connect(stateToProps,dispatchToProps)(ViewEditUser)
