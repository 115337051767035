import React, { useState } from 'react'
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import SearchWithFilter from '../common/SearchWithFilter'
import SimpleButton from '../common/SimpleButton'
import {
  auditRemoteSessionLogin,
  startRemoteSession,
  trackRemoteSessionLogin
} from "../../utils/requests/remoteAccess";
import { REMOTE_SESSION_TIME, REMOTE_SESSION_DATA } from "../../actions/root"
import { formatDate, formatTime } from "../../utils/filters/date"
import { useAppContext } from "../../libs/contextLib"
import { toast } from "react-toastify";
import './remote-access.scss'

const RemoteAccessLogin = (props) => {
  const { t } = useTranslation()
  const { setRemoteSession, setCurrentRemoteSession } = props
  const [formData, updateFormData] = useState({})
  const [errorsMsg, setErrors] = useState([])
  const { showFeatureDev } = useAppContext()
  const maxSessionDurationMinutes = (Number(process.env.REACT_APP_REMOTE_SESSION_DURATION_MINUTES) || 120)
 
  const handleSearch = (value) => {
    updateFormData({
      ...formData,
      token: value.trim(),
      country: props.authedUser.country
    })
    
    setErrors([])
  }

  const displayError = (param) => {
    if (errorsMsg && errorsMsg.length) {
      const error = errorsMsg.find(it => it.param === param)
      if (error) {
        return error.msg
      }
    }
  }

  const checkRemoteAccess = () => {
    const handler = (data) => {
      localStorage.setItem(REMOTE_SESSION_DATA, JSON.stringify(data))

      const time = new Date()
      time.setMinutes(time.getMinutes() + maxSessionDurationMinutes)
      localStorage.setItem(REMOTE_SESSION_TIME, time.getTime())
      localStorage.setItem("activeSessionToken", JSON.stringify(formData))
      
      setRemoteSession(localStorage.getItem(REMOTE_SESSION_DATA))

      if (setCurrentRemoteSession) {
        setCurrentRemoteSession(data)
      }
    }

    if (formData.token) {
        var response;
        startRemoteSession(props.authedUser?.cognitoSub, formData)
        .then(data => {
          response = data
          if (!data.data.guid) {
            setErrors([{ msg: t("remote_access.invalid_token_try_again"), param: "token" }])
            return false
          }
          
          if (!data.data.is_g4_plus && !showFeatureDev) {
            setErrors([{ msg: t("remote_access.nonG4_token_try_again"), param: "token" }])
            return false
          }

          handler(data.data)
        })
        .then(() => {
          const date = `${formatDate(new Date())} ${formatTime(new Date())}`
          const resData = JSON.parse(localStorage.getItem(REMOTE_SESSION_DATA))
          let body = {
            "serial_number": resData.serial_number,
            "country": props.authedUser.country,
            "email": props.authedUser.email,
            "datetime": date,
            "ip": resData.ip ? resData.ip : '127.0.0.1'
          }
          trackRemoteSessionLogin(body)
            .then(() => {
              if (!showFeatureDev) {
                updateFormData({})
              }
            })
            .catch(error => {
              if (error.response && error.response.status && error.response.status !== 200) {
                const errors = error.response.data.errors
                alert(errors)
              }
            })
          body = {
            "serial_number": resData.serial_number,
            "token": formData.token,
            "guid": resData.guid,
            "email": props.authedUser.email,
            "datetime": date
          }
          auditRemoteSessionLogin(body)
            .catch(error => {
              if (error.response && error.response.status && error.response.status !== 200) {
                const errors = error.response.data.errors
                alert(errors)
              }
            })
        })
        .catch(error => {
          toast.dismiss();
          if (error.response && error.response.status && error.response.status !== 200) {
            const errors = error.response.data.errors
            setErrors(errors)
            toast.error(t(`error.${error.response.data.code}`))
          }
          else if(response === undefined) {
            toast.error(t("error.something_wrong"))
          }
        })
    } else {
      setErrors([{ msg: t("remote_access.must_enter_valid_token"), param: "token" }])
    }
  }
  
  // React.useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     if (formData.token) {
  //       startRemoteSession(props.authedUser?.cognitoSub, formData)
  //       .then(data => {
  //         if (!data.data.guid) {
  //           closeSession()
  //         }
  //       })
  //     }
  //   }, 60000 * remoteAccessPollingInterval)

  //   return () => {
  //     clearInterval(intervalId)
  //   }
  // }, []) 
  
  React.useEffect(() => {
    toast.dismiss();
  }, []);
  
  return (
    <div className={props.currentRemoteSession ? "remote-access-login remote-session-active" : "remote-access-login"}>
      <div className="input-wrapper-token">
        <SearchWithFilter
          onSearch={(e) => handleSearch(e)}
          disabled={props.currentRemoteSession}
          placeholder={t("remote_access.labels.token")}
        />
        <SimpleButton
          className="submit-button"
          disabled={formData?.token?.length < 6 || props.currentRemoteSession}
          onClick={() => checkRemoteAccess()}
        >
          {t('remote_access.submit_button')}
        </SimpleButton>
      </div>
      <p className="error">{displayError('token')}</p>
      <div className="message">
        {props.currentRemoteSession ? t('remote_access.active_message') : t('remote_access.message')}
      </div>
    </div>
  )
}

function mapStateToProps({ authedUser }) {
  return {
    authedUser,
  };
}

export default connect(mapStateToProps)(RemoteAccessLogin)