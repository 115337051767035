import {
  getAccounts,
  getRolePolicies,
  postAccount,
  deleteAccount,
  putRole,
  patchAccount,
  putRolePolicies,
  postRole,
  deleteRole,
  patchRole,
  getRoles,
} from "../utils/requests/accountManagementAPI"

export const LOAD_ACCOUNTS = "LOAD_ACCOUNTS"
export const LOAD_ACCOUNTS_PENDING = "LOAD_ACCOUNTS_PENDING"
export const LOAD_ACCOUNTS_FULFILLED = "LOAD_ACCOUNTS_FULFILLED"
export const LOAD_ACCOUNTS_REJECTED = "LOAD_ACCOUNTS_REJECTED"

export const LOAD_ROLES = "LOAD_ROLES"
export const LOAD_ROLES_PENDING = "LOAD_ROLES_PENDING"
export const LOAD_ROLES_FULFILLED = "LOAD_ROLES_FULFILLED"
export const LOAD_ROLES_REJECTED = "LOAD_ROLES_REJECTED"

export const LOAD_NEW_ROLES = "LOAD_NEW_ROLES"
export const LOAD_NEW_ROLES_PENDING = "LOAD_NEW_ROLES_PENDING"
export const LOAD_NEW_ROLES_FULFILLED = "LOAD_NEW_ROLES_FULFILLED"
export const LOAD_NEW_ROLES_REJECTED = "LOAD_NEW_ROLES_REJECTED"

export const LOAD_ROLE_POLICIES = "LOAD_ROLE_POLICIES"
export const LOAD_ROLE_POLICIES_PENDING = "LOAD_ROLE_POLICIES_PENDING"
export const LOAD_ROLE_POLICIES_FULFILLED = "LOAD_ROLE_POLICIES_FULFILLED"
export const LOAD_ROLE_POLICIES_REJECTED = "LOAD_ROLE_POLICIES_REJECTED"

export const PUT_ROLE = "PUT_ROLE"
export const PUT_ROLE_PENDING = "PUT_ROLE_PENDING"
export const PUT_ROLE_FULFILLED = "PUT_ROLE_FULFILLED"
export const PUT_ROLE_REJECTED = "PUT_ROLE_REJECTED"

export const PATCH_ROLE = "PATCH_ROLE"
export const PATCH_ROLE_PENDING = "PATCH_ROLE_PENDING"
export const PATCH_ROLE_FULFILLED = "PATCH_ROLE_FULFILLED"
export const PATCH_ROLE_REJECTED = "PATCH_ROLE_REJECTED"

export const PUT_ROLE_POLICIES = "PUT_ROLE_POLICIES"
export const PUT_ROLE_POLICIES_PENDING = "PUT_ROLE_POLICIES_PENDING"
export const PUT_ROLE_POLICIES_FULFILLED = "PUT_ROLE_POLICIES_FULFILLED"
export const PUT_ROLE_POLICIES_REJECTED = "PUT_ROLE_POLICIES_REJECTED"

export const DELETE_ROLE = "DELETE_ROLE"
export const DELETE_ROLE_PENDING = "DELETE_ROLE_PENDING"
export const DELETE_ROLE_FULFILLED = "DELETE_ROLE_FULFILLED"
export const DELETE_ROLE_REJECTED = "DELETE_ROLE_REJECTED"

export const POST_ROLE = "POST_ROLE"
export const POST_ROLE_PENDING = "POST_ROLE_PENDING"
export const POST_ROLE_FULFILLED = "POST_ROLE_FULFILLED"
export const POST_ROLE_REJECTED = "POST_ROLE_REJECTED"

export const DELETE_ACCOUNT = "DELETE_ACCOUNT"
export const DELETE_ACCOUNT_PENDING = "DELETE_ACCOUNT_PENDING"
export const DELETE_ACCOUNT_FULFILLED = "DELETE_ACCOUNT_FULFILLED"
export const DELETE_ACCOUNT_REJECTED = "DELETE_ACCOUNT_REJECTED"

export const POST_ACCOUNT = "POST_ACCOUNT"
export const POST_ACCOUNT_PENDING = "POST_ACCOUNT_PENDING"
export const POST_ACCOUNT_FULFILLED = "POST_ACCOUNT_FULFILLED"
export const POST_ACCOUNT_REJECTED = "POST_ACCOUNT_REJECTED"

export const PATCH_ACCOUNT = "PATCH_ACCOUNT"
export const PATCH_ACCOUNT_PENDING = "PATCH_ACCOUNT_PENDING"
export const PATCH_ACCOUNT_FULFILLED = "PATCH_ACCOUNT_FULFILLED"
export const PATCH_ACCOUNT_REJECTED = "PATCH_ACCOUNT_REJECTED"

export const CLEAR_ACCOUNTS_MESSAGES = "CLEAR_ACCOUNTS_MESSAGES"

export function loadAccounts() {
  return {
    type: LOAD_ACCOUNTS,
    payload: getAccounts(),
  }
}

export function loadRoles(queryParams,  refetch = false) {
  return {
    type: refetch ? LOAD_NEW_ROLES : LOAD_ROLES,
    payload: getRoles(queryParams),
  }
}

export function updateAccount(id, bodyParams) {
  return {
    type: PATCH_ACCOUNT,
    payload: patchAccount(id, bodyParams),
  }
}

export function createAccount(bodyParams) {
  return {
    type: POST_ACCOUNT,
    payload: postAccount(bodyParams),
  }
}

export function delAccount(id) {
  return {
    type: DELETE_ACCOUNT,
    payload: deleteAccount(id),
    meta: { id: id },
  }
}

export function createRole(bodyParams, refetch) {
  return {
    type: POST_ROLE,
    payload: postRole(bodyParams),
    meta: { refetch: refetch },
  }
}

export function updateRole(id, bodyParams, refetch, userCount) {
  return {
    type: PUT_ROLE,
    payload: putRole(id, bodyParams),
    meta: { refetch: refetch, userCount: userCount },
  }
}

export function partiallyUpdateRole(id, bodyParams, refetch) {
  return {
    type: PATCH_ROLE,
    payload: patchRole(id, bodyParams),
    meta: { refetch: refetch },
  }
}

export function updateRolePolicies(id, bodyParams) {
  return {
    type: PUT_ROLE_POLICIES,
    payload: putRolePolicies(id, bodyParams),
  }
}

export function delRole(id) {
  return {
    type: DELETE_ROLE,
    payload: deleteRole(id),
    meta: { id: id },
  }
}

export function loadRolePolicies(roleId) {
  return {
    type: LOAD_ROLE_POLICIES,
    payload: getRolePolicies(roleId),
  }
}

export function clearAccountsMessages() {
  return {
    type: CLEAR_ACCOUNTS_MESSAGES,
  }
}
