
import React, { useCallback, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./banner.scss";
import Language from "../Language";
import { useAppContext } from "../../../libs/contextLib";
import { Storage } from "aws-amplify";
import { CloseSimpleWhiteIcon, CartIcon, NotificationIcon, MenuLinesIcon, ColteneLogoMobile, ColteneModernLogo } from "../../../icons";
import useOnClickOutside from "./useOnClickOutside";
import AccountMenu from "../../AccountMenu";
import StoreBasket from "../../ColteneStore/StoreBasket";
import NotificationsTimeline from '../../MyAccount/Notifications/NotificationsTimeline';
import { loadCurrentUserNotifications } from "../../../actions/notifications"
import { bellNotificationsPermissions, checkPermission, hasEndUserSiteAccess, hasStorePermission } from "../../../utils/permissionValidation";
import { flatMap,sortBy } from 'lodash'

const Banner = (props) => {
  const { basket } = props;
  const [src, setSrc] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const { isAuthenticated, supportsColteneStore } = useAppContext();
  const [hideAccountMenu, setHideAccountMenu] = useState(true);
  const [showStoreBasket, setShowStoreBasket] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [isUnreadNotifications, setIsUnreadNotifications] = useState(false)
  const [showNotificationsTimeline, setShowNotificationsTimeline] = useState(false);
  const [notificationsLoading, setNotificationsLoading] = useState(false);
  const [pulse, setPulse] = useState(0);
  const [basketQuantity, setBasketQuantity] = useState(0);
  const [userPermissions, setUserPermissions] = useState([]);
  const notificationsPollingIntervalMinutes = (process.env.REACT_APP_NOTIFICATION_FREQUENCY_SECONDS || 120) / 60;

  useEffect(() => {
    let isMounted = true;
    setSrc("");
    if (props.authedUser == null) {
      return;
    }
    Storage.list("", {
      level: "protected",
      pageSize: 1000,
    }).then((files) => {
      if (isMounted) {
        const avatar = files?.results?.filter((file) => file.key.match(/my_avatar.*/i));
        if (avatar.length > 0) {
          Storage.get(avatar[0].key, {
            level: "protected",
          }).then((response) => {
            if (isMounted) {
              setSrc(response);
              setIsLoading(false);
            }
          });
        } else {
          setIsLoading(false);
        }
      }
    });

    return () => {
      isMounted = false;
    };
  }, [props.authedUser]);

  useEffect(() => {
    let intervalId;
    
    const loadNotifications = () => {
      if (props?.authedUser?.cognitoSub && checkPermission(props.userPermissions, bellNotificationsPermissions)) {
        setNotificationsLoading(true);
        props.actions
          .loadCurrentUserNotifications(props.authedUser.cognitoSub, isUnreadNotifications)
          .finally(() => setNotificationsLoading(false))
      }      
    }
    
    loadNotifications();

    intervalId = setInterval(() => {
      loadNotifications();
    }, (60000 * notificationsPollingIntervalMinutes));

    return () => {
      if (intervalId) {
        clearInterval(intervalId)
      }
    }
  }, [props.authedUser?.cognitoSub, props.userPermissions, isUnreadNotifications])

  useEffect(() => {
    const userProductSerialNumbers = Object.entries(props.products).filter((item) => item[1].association_active).map((item) => item[1].serial_number)

    const userNotifications = transNotifications(props.notificationsTimeline).filter(
      (item) => item.type === 'device' && userProductSerialNumbers.includes(item.device_sn) || item.type !== 'device'
    )

    setNotifications(userNotifications)
  }, [props.products, props.notificationsTimeline])

  useEffect(() => {
    const permissionArr = props.userPermissions ? (Array.isArray(props.userPermissions) ? props.userPermissions : Object.values(props.userPermissions)) : []
    setUserPermissions(permissionArr)
  }, [props.userPermissions])

  const onRefChange = useCallback(
    (value) => {
      setPulse(1);
    },
    [basket]
  );

  useEffect(() => {
    if (basket) {
      const total = Object.values(basket).reduce(function (acc, product) {
        return acc + product.quantity;
      }, 0);
      setBasketQuantity(total);
    }
  }, [basket]);

  const timelineRef = React.useRef(null);

  const handleAccountMenu = () => {
    setHideAccountMenu((prevState) => !prevState);   
  };

  useOnClickOutside(timelineRef, () => setShowNotificationsTimeline(false));

  const handleIsUnreadNotificationsChange = () => setIsUnreadNotifications(!isUnreadNotifications)

  const transNotifications = (notifications) => {
    const res = flatMap(notifications)
    const data = sortBy(res, e => -new Date(e.notification_date))
    return data
  }
  return (
    <>
      <header className={!isAuthenticated ? "banner u-header" : "banner"}>
        {!isAuthenticated ? (
          <div className="u-banner">
            <div className="banner-r">
              <div className="banner-language-wrapper">
                <Language />
              </div>
            </div>
          </div>
        ) : (
          <div className="a-banner">
            <div className="banner-l">
              <nav className="top-nav">
                {props.toggleNavDisplay && (
                  <div className="toggle-nav-menu" onClick={props.toggleNavDisplay}>
                    {props.navDisplay ? <CloseSimpleWhiteIcon className="icon"/> : <MenuLinesIcon className="icon"/>}
                  </div>)
                }
                <NavLink to="/" activeClassName="active">
                  <ColteneModernLogo alt="Scican Logo" className="logo"/>
                  <ColteneLogoMobile alt="Scican Logo" className="logo-mobile" />
                </NavLink>
              </nav>
            </div>
            <div className="banner-r">
              <div className="banner-language-wrapper">
                <Language />
              </div>
              
              {checkPermission(props.userPermissions, bellNotificationsPermissions) && 
                <div 
                  className={"banner-notifications-wrapper notification-element" + (showNotificationsTimeline ? " element-active notification-timeline-active" : "")}
                  onClick={() => {
                    setShowNotificationsTimeline((prevState) => !prevState);
                  }}
                >
                  <NotificationIcon/>
                  {(notifications?.filter((item) => !item.is_read)?.length > 0) && (
                    <div className="notifications-counter">
                      <div className="counter-badge">
                        {
                          notifications?.filter((item) => !item.is_read)?.length > 9 ?
                          '9+' : notifications?.filter((item) => !item.is_read)?.length
                        }
                      </div>
                    </div>)
                  }
                </div>
              }
              
              {hasEndUserSiteAccess(userPermissions) && (
                <>
                  {supportsColteneStore && hasStorePermission(userPermissions) &&
                    <div
                      className="banner-cart-wrapper"
                      onClick={() => {
                        setShowStoreBasket(true);
                      }}
                    >
                      <CartIcon/>

                      {basketQuantity > 0 && (
                        <>
                          <div ref={onRefChange} className="cart-product-number" onAnimationEnd={() => setPulse(0)} pulse={pulse}>
                            <div className="product-number">{basketQuantity > 9 ? "9+" : basketQuantity}</div>
                          </div>
                        </>
                      )}
                    </div>
                  }
                </>)
              }
              <div 
                className={"banner-profile-wrapper profile-element" + (!hideAccountMenu ? " element-active" : "")}
                onClick={handleAccountMenu}
              >
                <div className={"avatar-title" + (!hideAccountMenu ? " avatar-selected" : "")}>
                  {src ? (
                    <img className="banner-img" src={src} alt="avatar"/>
                  ) : isLoading ? (
                    <div className="banner-img" />
                  ) : (
                    <div className="avatar-initials-small">
                      <span>{`${props.authedUser?.firstname?.charAt(0) || ''}${props.authedUser?.lastname?.charAt(0) || ''}`.trim()}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </header>
      {!hideAccountMenu && (
        <AccountMenu
          toggleShowOnlineAccess={props.toggleShowOnlineAccess}
          disableRemoteSession={props.disableRemoteSession}
          setHideAccountMenu={setHideAccountMenu}
          closeNavDisplay={props.closeNavDisplay}
          userAvatar={src}
        />
      )}

      <StoreBasket toggle={showStoreBasket} onToggle={() => setShowStoreBasket(false)} />
      <div ref={timelineRef}>
        {showNotificationsTimeline && (
          <NotificationsTimeline
            notifications={notifications}
            products={props.products}
            role={props.authedUser.userGroup}
            closeTimeline={() => setShowNotificationsTimeline(false)} 
            isUnreadNotifications={isUnreadNotifications}
            handleIsUnreadNotificationsChange = {handleIsUnreadNotificationsChange}
            notificationsLoading={notificationsLoading}
          />
        )}
      </div>
    </>
  );
};

function mapStateToProps({ authedUser, colteneStore, notifications, userPermissions, userProducts }) {
  return {
    authedUser,
    basket: colteneStore?.basket,
    notificationsTimeline : notifications?.notificationsTimeline || [],
    userPermissions,
    products: userProducts?.products || {}
  };
}

function dispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        loadCurrentUserNotifications
      },
      dispatch
    ),
  }
}

export default connect(mapStateToProps, dispatchToProps)(Banner);