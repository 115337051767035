import React from "react"
import { useTranslation } from "react-i18next"
import { DownloadModernIcon, PdfModernIcon } from "../../icons"

const DocumentCard = (props) => {
  const { t } = useTranslation()
  return (
    <div
      className={"document-card d-flex flex-justify-between" + (props.isLast ? " last-document" : "") }
      onClick={() => props.handleDownload(props.cardContent?.file_path, props.cardContent?.file_name)}
    >
      <div className="svg-wrapper without-background">
        <DownloadModernIcon width="24px" height="24px" />
      </div>
      <div className="svg-wrapper">
        <PdfModernIcon width="16px" height="16px" />
      </div>
      <div className="document-details ">
        <div className="date d-flex">
          <div>{`${t("documents.doc-date")}:  ${props.cardContent?.document_year || "-"}`}</div>
          <div>{`${t("documents.tsb")} ${props.cardContent?.document_number || "-"}`}</div>
        </div>
        <div className="document-title">{props.cardContent.document_title}</div>
      </div>
    </div>
  )
}

export default DocumentCard
