import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import SearchWithFilter from "../common/SearchWithFilter"
import GenericTable from "../common/GenericTable"
import SimpleButton from "../common/SimpleButton"
import {
  approveRejectUser,
  deleteUserFromCompany,
  getCurrentCompany,
  getMembersByCompanyId,
  getMembersFilterOptions,
  getRequestsByCompanyId,
  updateMemberStatus
} from "../../utils/requests/company"
import LoadingSpinner from "../common/LoadingSpinner"
import EmptyPageResults from "../common/EmptyPageResults"
import { getRequestListHeaders, requestListRenderer, requestListTableKeys } from "./requests-table-config"
import { getMembersListHeaders, membersListRenderer, membersTableKeys } from "./members-table-config"
import { AddUserModal } from "./AddUserModal"
import { InviteUserModal } from "./InviteUserModal"
import { useTable } from "../../hooks/useTable"
import FiltersModal, { FilterType } from "../common/FiltersModal"
import { getRoles } from "../../utils/requests/accountManagementAPI"
import { toast } from "react-toastify"
import { debounce } from "lodash"

function TabTable({
  tabType,
  showRequestMissingItemModal,
  showRequestReviewModal,
  setShowRequestMissingItemModal,
  setShowRequestReviewModal,
  showRequestReviewRowModal,
  setShowRequestReviewRowModal,
  onRowSelectionChange,
  onSelectAll,
  activeModal,
  setActiveModal,
  ...props
}) {
  const { t } = useTranslation()

  const rowIdentifier = "id"

  const isRequestListTable = tabType === "Requests"
  const { tableData, setTableData, orderBy, setOrderBy, handleRowSelect, handleSelectAll, hasSelectedAll, selectedRowIds } = useTable({
    initialData: [],
    initialOrderBy: {},
    rowIdentifier,
  })

  const [loadingTableData, setLoadingTableData] = useState(true)
  const [canLoadMore, setCanLoadMore] = useState(false)
  const [page, setPage] = useState(1)
  const [selectedRow, setSelectedRow] = useState(null)
  const [query, setQuery] = useState("")
  const [showFiltersModal, setShowFiltersModal] = useState(false)
  const [showActionsMenu, setShowActionsMenu] = useState(null)
  const [companyId, setCompanyId] = useState()
  const [branches, setBranches] = useState([])

  const [branchFilters, setBranchFilters] = useState([])
  const [rolesFilters, setRolesFilters] = useState([])
  const [selectedBranchFilters, setSelectedBranchFilters] = useState([])
  const [selectedRolesFilters, setSelectedRolesFilters] = useState([])
  const [statusFilters, setStatusFilters] = useState([t("filters.pending"), t("status.approved"), t("status.accepted"), t("status.rejected"), t("status.invited"), t("status.requested")])
  const [membersTabStatusFilters, setMembersTabStatusFilters] = useState([t("filters.enabled"), t("filters.pending"), t("filters.disabled")])
  const [selectedStatusFilters, setSelectedStatusFilters] = useState([])
  const [selectedMembersStatusFilters, setSelectedMembersStatusFilters] = useState([])
  const [initialBranchName, setInitialBranchName] = useState("")
  const [editModalRow, setEditModalRow] = useState(null)
  const [roles, setRoles] = useState([])

  const getHeaders = isRequestListTable ? getRequestListHeaders : getMembersListHeaders
  const renderer = isRequestListTable ? requestListRenderer : membersListRenderer
  const tableKeys = isRequestListTable ? requestListTableKeys : membersTableKeys

  useEffect(() => {
    toast.dismiss()
  }, [])

  useEffect(() => {
    let mounted = true
    async function fetchTableData() {
      setLoadingTableData(true)
      const response = await fetchMembersListsByTabType({
        tabType,
        page: 1,
        companyId,
      })
      if (mounted) {
        setInitialBranchName(response?.branchName)
        setTableData(response.data)
        setPage(response.currentPage)
        setCanLoadMore(response.canLoadMore)
      }
      setLoadingTableData(false)
    }

    if (companyId) {
      fetchTableData()
    }
  }, [isRequestListTable, setTableData, tabType])

  useEffect(() => {
    async function startFetching() {
      const response = await getCurrentCompany()
      setCompanyId(response.data[0].id)
      setBranches(response.data[0].branches)
    }
    startFetching()
  }, [])

  useEffect(() => {
    async function startFetching() {
      const response = await getRoles()
      setRoles(response)
    }
    startFetching()
  }, [])

  useEffect(() => {
    async function startFetching() {
      const response = await getMembersFilterOptions(companyId)
      setRolesFilters(response.roles.map((r) => r.name))
      setBranchFilters(response.branches.map((b) => b.name))
    }

    if (companyId) {
      startFetching()
    }
  }, [companyId])

  useEffect(() => {
    let mounted = true

    async function startFetchingOnFiltersChange() {
      const response = await fetchMembersListsByTabType({
        companyId,
        tabType,
        query,
        orderBy,
        page: 1,
        selectedBranchFilters,
        selectedRolesFilters,
        selectedMembersStatusFilters,
        selectedStatusFilters,
      })
      if (mounted) {
        setTableData(response.data)
        setPage(response.currentPage)
        setCanLoadMore(response.canLoadMore)
      }
      setLoadingTableData(false)
    }

    if (companyId) {
      startFetchingOnFiltersChange()
    }
  }, [tabType, query, orderBy, setTableData, isRequestListTable, companyId, selectedBranchFilters, selectedRolesFilters, selectedMembersStatusFilters, selectedStatusFilters])

  const handleLoadMore = async () => {
    setLoadingTableData(true)
    const response = await fetchMembersListsByTabType({
      companyId,
      tabType,
      query,
      orderBy,
      page: page + 1,
    })
    setPage(page + 1)
    setTableData([...tableData, ...response.data])
    setCanLoadMore(response.canLoadMore)
    setLoadingTableData(false)
  }

  function handleRowSelection(row) {
    const newSelectedRowIds = handleRowSelect(row)
    if (onRowSelectionChange) {
      onRowSelectionChange(row, newSelectedRowIds.length > 0)
    }

    setSelectedRow(row)
  }

  async function handleApprove(row) {
    toast.dismiss()
    try {
      setLoadingTableData(true)
      await approveRejectUser(companyId, row.id, {
        approve: true,
      })
      let allData = []
      for (let currentPage = 1; currentPage <= page; currentPage++) {
        const response = await fetchMembersListsByTabType({
          tabType,
          query,
          orderBy,
          page: currentPage,
          companyId,
        })
        if (response && response.data) {
          allData = allData.concat(response.data)
        }
      }
      setTableData(allData)
      toast.success(t("dealer-portal.member_approved"))
      setLoadingTableData(false)
    } catch (error) {
      setLoadingTableData(false)
      toast.error(t("error.something_wrong"))
    }
  }

  async function handleReject(row) {
    toast.dismiss()
    try {
      setLoadingTableData(true)
      await approveRejectUser(companyId, row.id, {
        approve: false,
      })
      let allData = []
      for (let currentPage = 1; currentPage <= page; currentPage++) {
        const response = await fetchMembersListsByTabType({
          tabType,
          query,
          orderBy,
          page: currentPage, // Change to currentPage in the loop
          companyId,
        })
        if (response && response.data) {
          allData = allData.concat(response.data)
        }
      }
      setTableData(allData)
      toast.success(t("dealer-portal.member_rejected"))
      setLoadingTableData(false)
    } catch (error) {
      setLoadingTableData(false)
      toast.error(t("error.something_wrong"))
    }
  }

  function handleEdit(row) {
    setEditModalRow(row)
    setActiveModal("edit-user")
  }

  async function handleRemove(row) {
    try {
      setLoadingTableData(true)
      await deleteUserFromCompany(companyId, row.userUuid)
      toast.success(t("dealer-portal.user_removed"))
      setLoadingTableData(false)
    } catch (error) {
      setLoadingTableData(false)
      toast.error(t("error.something_wrong"))
    }
  }

  async function updateStatus(row) {
    try {
      setLoadingTableData(true)
      const payload = {
        email: row.email,
        status: row.status === 'disabled' ? 'enabled' : 'disabled'
      }
      await updateMemberStatus(companyId, row.userUuid, payload)
      const response = await fetchMembersListsByTabType({
        tabType,
        page: 1,
        companyId,
      })
      setTableData(response.data)
      toast.success(t("dealer-portal.user_status_updated"))
      setLoadingTableData(false)
    } catch (error) {
      setLoadingTableData(false)
      toast.error(t("error.something_wrong"))
    }
  }

  const handleResetFilters = () => {
    setSelectedBranchFilters([])
    setSelectedMembersStatusFilters([])
    setSelectedRolesFilters([])
    setSelectedStatusFilters([])
  }

  const table = (
    <GenericTable
      data={tableData}
      headers={getHeaders(t)}
      keys={tableKeys}
      keyRenderer={renderer({
        t,
        rowIdentifier,
        selectedRowIds,
        handleRowCheck: handleRowSelection,
        showActionsMenu,
        setShowActionsMenu,
        toggleShowActionsMenu: setShowActionsMenu,
        handleApprove,
        handleReject,
        handleEdit,
        handleRemove,
        updateStatus,
        branchName: initialBranchName,
      })}
      activeSort={orderBy}
      onRowClick={handleRowSelection}
      onSort={setOrderBy}
      hasSelectedAll={hasSelectedAll}
      onSelectAll={(e) => {
        handleSelectAll()
        if (onRowSelectionChange) {
          onSelectAll(selectedRowIds.length !== tableData.length)
        }
      }}
    />
  )

  const cards = (
    <div className="cards-container">
      {tableData.map((row) => {
        return (
          <div
            className="card"
            onClick={() => {
              // if (isRequestListTable) {
              //   setSelectedRow(row)
              //   if (row.requestType === "Request missing item") {
              //     set(true)
              //   } else if (row.requestType === "Request review") {
              //     setShowRequestReviewRowModal(true)
              //   }
              // }
            }}
          >
            {(isRequestListTable ? getRequestListHeaders : getHeaders)(t).map((header) => {
              const key = header.orderKey
              const value = row[key]

              return (
                <div className="row">
                  <div className="key">{header.title}</div>
                  <div className="value">{value}</div>
                </div>
              )
            })}
          </div>
        )
      })}
    </div>
  )

  const filters = isRequestListTable
    ? [
        {
          name: "Status",
          type: FilterType.multiSelect,
          dataset: statusFilters,
          input: selectedStatusFilters,
          output: (selectedStatusFilters) => {
            setSelectedStatusFilters(selectedStatusFilters)
          },
        },
      ]
    : [
        {
          name: "Status",
          type: FilterType.multiSelect,
          dataset: membersTabStatusFilters,
          input: selectedMembersStatusFilters.map(filter => (filter === "Activated" ? "Enabled" : filter)),
          output: (selectedMembersStatusFilters) => {
            const modifiedFilters = selectedMembersStatusFilters.map(filter => (filter === "Enabled" ? "Activated" : filter));
            setSelectedMembersStatusFilters(modifiedFilters)
          },
        },
        {
          name: "Branch",
          type: FilterType.multiSelect,
          dataset: branchFilters,
          input: selectedBranchFilters,
          output: (selectedBranchFilters) => {
            setSelectedBranchFilters(selectedBranchFilters)
          },
        },
        {
          name: "Role",
          type: FilterType.multiSelect,
          dataset: rolesFilters,
          input: selectedRolesFilters,
          output: (selectedRolesFilters) => {
            setSelectedRolesFilters(selectedRolesFilters)
          },
        },
      ]

  return (
    <div>
      <div className="filters-wrapper">
        <SearchWithFilter
          onSearch={debounce((value) => {
            if (value?.length > 2) {
              setQuery(value)
            } else if (value?.length === 0 && query?.length > 0) {
              setQuery(null)
            }
          }, 500)}
          showFiltersModal={() => setShowFiltersModal(true)}
          onResetFilters={handleResetFilters}
          showCounterBadge={selectedBranchFilters.length > 0 || selectedRolesFilters.length > 0 || selectedStatusFilters.length > 0 || selectedMembersStatusFilters.length > 0 }
          counterBadge={selectedBranchFilters.length + selectedRolesFilters.length + selectedStatusFilters.length + selectedMembersStatusFilters.length}
        />
      </div>
      {showFiltersModal && <FiltersModal filters={filters} resetFilters={() => handleResetFilters()} closeFilters={() => setShowFiltersModal(false)} />}
      {loadingTableData && (
        <div className="spinner-wrapper">
          <LoadingSpinner />
        </div>
      )}
      {!loadingTableData &&
        (tableData.length === 0 ? (
          isRequestListTable ? (
            <EmptyPageResults title={t("dealer-portal.no_requests_found")} subtitle={t("dealer-portal.no_requests_found_subtitle")} />
          ) : (
            <EmptyPageResults title={t("dealer-portal.no_members_found")} subtitle={t("dealer-portal.no_members_found_subtitle")} />
          )
        ) : (
          <>
            {tableData.length > 0 && (
              <>
                {table}
                {cards}
              </>
            )}
          </>
        ))}
      {canLoadMore && (
        <div className="loadmore-wrapper">
          <SimpleButton className="load-more-button" onClick={() => handleLoadMore()}>
            {t("buttons.load_more")}
          </SimpleButton>
        </div>
      )}
      {activeModal === "invite-user" && (
        <InviteUserModal
          companyId={companyId}
          branches={branches}
          isRequestListTable={isRequestListTable}
          row={selectedRow}
          handleClose={() => {
            setSelectedRow(null)
            setActiveModal(null)
          }}
        />
      )}
      {activeModal === "edit-user" && (
        <AddUserModal
          initialBranchName={initialBranchName}
          companyId={companyId}
          row={editModalRow}
          branches={branches}
          roles={roles}
          handleClose={() => {
            setSelectedRow(null)
            setActiveModal(null)
          }}
          isEdit={true}
        />
      )}
    </div>
  )
}

export async function fetchMembersListsByTabType({
  tabType,
  query,
  orderBy,
  page,
  companyId,
  selectedBranchFilters = [],
  selectedRolesFilters = [],
  selectedMembersStatusFilters = [],
  selectedStatusFilters = [],
}) {
  let response
  let data

  const PAGE_LIMIT = 10
  let commonParams = {
    ...(query ? { query } : {}),
    ...(selectedBranchFilters.length > 0 ? { branches: selectedBranchFilters } : {}),
    ...(selectedRolesFilters.length > 0 ? { roles: selectedRolesFilters } : {}),
    ...(selectedMembersStatusFilters.length > 0 ? { status: selectedMembersStatusFilters } : {}),
    ...(selectedStatusFilters.length > 0 ? { status: selectedStatusFilters } : {}),
    ...(orderBy?.field ? {orderBy: orderBy?.field} : {}),
    ...(orderBy?.order ? {order: orderBy?.order} : {}),
    offset: (page - 1) * PAGE_LIMIT,
    limit: PAGE_LIMIT,
  }

  if (tabType === "Requests") {
    response = await getRequestsByCompanyId(companyId, {
      ...commonParams,
    })

    data = response.data
  } else {
    response = await getMembersByCompanyId(companyId, { ...commonParams })

    data = response.data[0].users
  }

  return {
    canLoadMore: response.currentPage < response.totalPages,
    data,
    currentPage: response.currentPage,
    branchName: response.data[0]?.branchName,
  }
}

export default TabTable