export const LOGOUT = "LOGOUT"
export const REMOTE_SESSION_DATA = 'REMOTE_SESSION_DATA'
export const REMOTE_SESSION_TIME = 'REMOTE_SESSION_TIME'

export function handleLogout() {
  return {
    type: LOGOUT,
  }
}

export function handleUserLogout () {
  return (dispatch) => {
    dispatch(handleLogout())
    localStorage.removeItem(REMOTE_SESSION_DATA)
    localStorage.removeItem(REMOTE_SESSION_TIME)
  }
}