import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./report-problem.scss";
import Breadcrumb from "../common/Breadcrumb";
import Search from "../common/Search";
import Modal from "../common/Modal";
import { useWindowSize } from "../../libs/hooks";
import { CaretDownIcon } from "../../icons";
import countries from "../../utils/countries";
import ClickOutside from "../common/ClickOutside";
import CalendarInput from "../common/CalendarInput";
import { getReports, getReportsBySn, getUsersEmail, postReport } from "../../utils/requests/reportsAPI";
import { connect } from "react-redux";
import { sendMail } from "../../utils/requests/mqttPublishAPI";
import { handleReportTicket } from "../../actions/reportTicket";
import { uniqBy } from "../../utils/filters/filters";
import config from "../../utils/config";
import { toast } from "react-toastify";
import { formatPhone } from "../../utils/functions";
import { useLocation } from 'react-router-dom'

const ReportProblem = (props) => {
  const { t } = useTranslation()
  const location = useLocation();
  const isMobile = useWindowSize()[0] <= 768;
  const parentItem = props?.history?.location?.state;
  const canGoBack = location.state && location.state.withBackNavigation;
  const [search, setSearch] = useState(null);
  const [selectedPage, setSelectedPage] = useState(1);
  const [selectedReport, setSelectedReport] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const tabs = [t("report-problem.report"), t("product.archive")];
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [elementsPerPage, setElementsPerPage] = useState(25);
  const [displayLoadMore, setDisplayLoadMore] = useState(true);
  const [errorsMsg, setErrors] = useState([]);
  const [isRequired, setIsRequired] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [totalResults, setTotalResults] = useState(null);
  const [reports, setReports] = useState(null);
  const [isComplaint, setIsComplaint] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(parentItem ? parentItem?.model : t("placeholders.select-product"));
  const [selectedComplaintType, setSelectedComplaintType] = useState(t("placeholders.select-complaint-type"));
  const [selectedOobf, setSelectedOobf] = useState(t("placeholders.select-oobf"));
  const [selectedTitle, setSelectedTitle] = useState(t("placeholders.select-title"));
  const [selectedOccurredLocation, setSelectedOccurredLocation] = useState(t("placeholders.select-location"));
  const [selectedCountry, setSelectedCountry] = useState(t("placeholders.select-country"));
  const [selectedReportDate, setSelectedReportDate] = useState(null);
  const [selectedOccurrenceDate, setSelectedOccurrenceDate] = useState(null);
  const [formData, updateFormData] = useState(parentItem ? parentItem : null);
  const [productOptions, setProductOptions] = useState(null);
  const [reportTypes] = useState([`${t("report-problem.complaint")}`, `${t("report-problem.problem")}`]);
  const [selectedReportType, setSelectedReportType] = useState(reportTypes[1]);
  const [complaintTypes] = useState(["Electrical", "Fluids", "Hardware", "Other", "Software"]);
  const [oobfOptions] = useState(["Yes", "No"]);
  const [titleOptions] = useState(["Dr.", "Miss.", "Mr.", "Mrs."]);
  const [locationOccurredOptions] = useState(["Clinic", "Hospital", "Institution", "Practice", "N/A"]);
  const [countriesOptions] = useState(countries);
  const [queryParams] = useState({
    page: selectedPage,
    limit: elementsPerPage,
  });
  const [error, setError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false)
  const locale = localStorage.getItem("i18nextLng").replace(/-/g, "_");

  const requiredReportField = [
    "first_name",
    "last_name",
    "phone",
    // 'fax',
    "issue_description",
    "cycle_fault",
  ];

  const requiredComplaintField = [
    ...requiredReportField,
    "scican_technician",
    "complaint_origin",
    "occupation",
    "patient_involvement",
    "practice",
    "occurred_location",
    "doctor_name",
    "doctor_address",
    "doctor_state",
    "doctor_city",
    "doctor_zip",
    "report_date",
    "occurrence_date",
    "product_number",
    "cycle_count",
    "sample_enclosed",
  ];

  const handleSearch = () => {
    getReportsList();
  };

  const resetPage = () => {
    setSelectedPage(1);
  };

  const resetForm = () => {
    setSelectedReportType(`${t("report-problem.problem")}`);
    setSelectedProduct(parentItem ? parentItem?.model : t("placeholders.select-product"));
    setSelectedComplaintType(t("placeholders.select-complaint-type"));
    setSelectedOobf(t("placeholders.select-oobf"));
    setSelectedTitle(t("placeholders.select-title"));
    setSelectedOccurredLocation(t("placeholders.select-location"));
    setSelectedCountry(t("placeholders.select-country"));
    setSelectedReportDate(null);
    setSelectedOccurrenceDate(null);
    updateFormData(parentItem ? parentItem : null);
    setProductOptions(null);
    updateFormData({});
  };

  useEffect(() => {
    if (error) {
      toast.error(t("report-problem.report_not_found"));
    }
  }, [error, t]);

  useEffect(() => {
    if (props?.ticket?.ticket) {
      window.history.pushState("object or string", "Title", "/" + window.location.href.substring(window.location.href.lastIndexOf("/") + 1).split("?")[0]);
      setActiveTab(tabs[1]);
      if (reports && reports.length && !showModal) {
        const item = reports.find((it) => it.id === Number(props?.ticket?.ticket));
        if (item) {
          openModal(item);
        } else {
          setError(true);
          const { dispatch } = props;
          dispatch(handleReportTicket({ ticket: null }));
        }
      }
    }

    if (selectedReportType === "Complaint") {
      setIsComplaint(true);
    } else {
      setIsComplaint(false);
    }

    if (!productOptions && props && props.productTypes) {
      let arr = [];
      for (const index in props.productTypes) {
        const deviceModels = props.productTypes[index].models;
        deviceModels.map((model) => {
          arr.push(model.name);
        });
      }
      setProductOptions(arr.sort());
    }

    if (!totalResults && activeTab === t("product.archive")) {
      getReportsList();
    }
  }, [selectedPage, totalResults, selectedReportType, props?.ticket, props.productTypes, activeTab, reports]);

  const openModal = (item) => {
    setSelectedReport(item);
    setShowModal(true);
  };

  const closeModal = () => {
    if (props?.ticket?.ticket) {
      const { dispatch } = props;
      dispatch(handleReportTicket({ ticket: null }));
    }
    setShowModal(false);
  };

  const getReportsList = () => {
    let data = queryParams;
    if (search) {
      data = {
        ...data,
        page: selectedPage,
      };
      return getReportsBySn(search, data).then((data) => {
        if (data.data && data.data.length) {
          setReports(data.data);
          setTotalResults(data.data.length);
        }
        if (!data.data.length || data.data.length < elementsPerPage) {
          setSearch(search ? search : null);
          setDisplayLoadMore(false);
        } else {
          setDisplayLoadMore(true);
        }
      });
    }
    getReports(data).then((data) => {
      if (data.data && data.data.length) {
        setReports(data.data);
        setTotalResults(data.data.length);
      }
      setDisplayLoadMore(!(!data.data.length || data.data.length < elementsPerPage));
    });
  };

  const changeTab = (item) => {
    setActiveTab(item);
  };

  const handleClick = (option, toggleVisibility, setter) => {
    setter(option);
    toggleVisibility(false);
  };

  const input = ({ toggleVisibility, isComponentVisible }, value, hasKeys) => (
    <div className="select-input cursor-pointer w-100">
      <div
        className="height d-flex flex-align-center flex-justify-between"
        onClick={() => (isComponentVisible ? toggleVisibility(false) : toggleVisibility(true))}
      >
        <div className="d-flex flex-align-center h-100">{!hasKeys ? value : value.model || value.name ? value.model || value.name : value}</div>
        <CaretDownIcon className="mr-10 caret-dropdown-icon" />
      </div>
    </div>
  );

  const options = ({ toggleVisibility }, arr, setter, hasKeys) => (
    <div className="options-wrapper d-flex flex-align-center flex-column">
      {arr?.map((option, index) => (
        <div className="option cursor-pointer no-wrap" key={index} onClick={(e) => handleClick(option, toggleVisibility, setter)}>
          {!hasKeys ? option : option.model || option.name}
        </div>
      ))}
    </div>
  );

  const handleChangeCalendarDate = (e, setter) => {
    setter(e);
    if (errorsMsg) {
      const obj = errorsMsg.find((it) => it.param === "report_date" || it.param === "occurrence_date");
      if (obj !== undefined) {
        const index = errorsMsg.indexOf(obj);
        errorsMsg.splice(index, 1);
      }
    }
  };

  const handleInputChange = (e) => {
    if (isRequired) {
      setIsRequired(false);
    }
    if (errorsMsg) {
      const obj = errorsMsg.find((it) => it.param === e.target.name);
      if (obj !== undefined) {
        const index = errorsMsg.indexOf(obj);
        errorsMsg.splice(index, 1);
      }
    }
    if (e.target.name === ("patient_involvement" || "sample_enclosed")) {
      return updateFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }

    if (e.target.name === "complaint_logged") {
      if (formData[e.target.name]) {
        return delete formData[e.target.name];
      } else {
        return updateFormData({
          ...formData,
          [e.target.name]: true,
        });
      }
    }

    updateFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const displayError = (param, errorClass = null) => {
    if (errorsMsg && errorsMsg.length) {
      const error = errorsMsg.find((it) => it.param === param);
      if (error && errorClass) {
        return " required";
      }
      if (error) {
        return error.msg;
      }
    }
  };

  const modelId = (selectedProduct) => {
    return Object.values(props.productTypes).map(productType => productType.models).flat().find(model => model.name === selectedProduct).id
  }
  const submitReport = () => {
    setIsSubmitting(true)
    toast.dismiss()
    const complaintData = {
      ...formData,
      complaint_logged: false,
      status: "open",
      model: selectedProduct !== t("placeholders.select-product") ? selectedProduct : "",
      complaint_type: selectedComplaintType !== t("placeholders.select-oobf") ? selectedComplaintType : "",
      oobf: selectedOobf !== t("placeholders.select-oobf") ? selectedOobf : "",
      title: selectedTitle !== t("placeholders.select-title") ? selectedTitle : "",
      occurred_location: selectedOccurredLocation !== t("placeholders.select-location") ? selectedOccurredLocation : "",
      report_date: selectedReportDate !== t("placeholders.select-country") ? selectedReportDate : "",
      occurrence_date: selectedOccurrenceDate,
      updated_by: props?.authedUser?.firstname + " " + props?.authedUser?.lastname,
      report_by_email: props.authedUser.email,
    };

    let arr = [];
    for (const key in complaintData) {
      (selectedReportType === "Problem" ? requiredReportField : requiredComplaintField).map((it) => {
        if (!requiredReportField.includes(key) && !complaintData[it]) {
          arr.push({ msg: `${(it.charAt(0).toUpperCase() + it.slice(1)).replace(/_/g, " ")} is required`, param: it });
        }
        arr = uniqBy(arr, "param");
        setErrors(arr);
      });
    }

    if (arr.length !== 0) {
      setIsSubmitting(false)
      toast.error(t("warranty.missing-fields"));
      return setIsRequired(true);
    }

    let ticket = "";

    postReport(complaintData)
      .then((r) => {
        ticket = r?.data?.insertId;
        resetForm();
        setIsSuccess(true);
        toast.success(t("report-problem.success"));

        
        const email = `${config.distribution_email}`;
        let emailTemplate = `ocp_report_problem_${locale}`;
        const firstname = formData.first_name ? formData.first_name : "";
        const lastname = formData.last_name ? formData.last_name : "";
        let emailTemplateVars = {
          // country: formData.country ? formData.country : "",
          firstname: "SciCan Admin",
          model: selectedProduct,
          serialNumber: formData.serial_number ? formData.serial_number : "",
          ticketNumber: ticket,
          linkUrl: `${config.host}/report-problem?ticket=${ticket}`,
          name: firstname + " " + lastname,
          phone: formData.phone ? formData.phone : "",
          email: props.authedUser.email ? props.authedUser.email : "",
          subject: formData.cycle_fault ? formData.cycle_fault : "",
          description: formData.issue_description ? formData.issue_description : "",
          resolution: formData.resolution ? formData.resolution : "",
          followUp: formData.follow_up ? formData.follow_up : "",
        };

        // getUsersEmail()
        //   .then(r => {
        //     if (r && r.data) {
        //       r.data.map(userData => {
        //         sendMail(userData.email, emailTemplate, emailTemplateVars).then(r => console.log('sent'))
        //       })
        //     }
        //   })
        sendMail(email, emailTemplate, emailTemplateVars).then((r) => console.log("sent"));
        setIsSubmitting(false)

        getReportsList();
        setTimeout(() => setActiveTab(t("product.archive")), 1000);
      })
      .catch((e) => {
        setIsSubmitting(false)
        toast.error(t("error.failure_msg"));
      });
  };

  const loadMore = () => {
    if (search) {
      return getReportsBySn(search, {
        ...queryParams,
        page: selectedPage + 1,
      }).then((data) => {
        if (data.data && data.data.length) {
          let difference = reports.filter((x) => !data.data.includes(x)).concat(data.data.filter((x) => !reports.includes(x)));
          setReports(difference);
          setTotalResults(data.data.length);
        }

        if (!data.data.length || data.data.length < elementsPerPage) {
          setSearch(search ? search : null);
          setDisplayLoadMore(false);
        } else {
          setDisplayLoadMore(true);
        }
      });
    }
    setSelectedPage(selectedPage + 1);
    getReports({ ...queryParams, page: selectedPage + 1 }).then((data) => {
      if (data.data && data.data.length) {
        let difference = reports.filter((x) => !data.data.includes(x)).concat(data.data.filter((x) => !reports.includes(x)));
        setReports(difference);
        setTotalResults(data.data.length);
        setDisplayLoadMore(!(!data.data.length || data.data.length < elementsPerPage));
      }
    });
  };

  return (
    <div className={`report-${activeTab === t("product.archive") ? "problem" : "form"}-wrapper`}>
      <Breadcrumb path={window.location.pathname} withBackNavigation={canGoBack} />
      <div className="header d-flex flex-justify-between">
        <h1 className="mb-0">{t("nav.report_problem")}</h1>
      </div>
      <div className="tabs-report d-flex">
        {tabs &&
          tabs.length &&
          tabs.map((tab, index) => (
            <div
              className={"tab d-flex flex-justify-center flex-align-center" + (activeTab === tab ? " active" : "")}
              onClick={() => changeTab(tab)}
              key={index}
            >
              {tab}
            </div>
          ))}
        {!isMobile && activeTab !== t("product.archive") && (
          <div className=" report-type d-flex flex-align-center">
            <p>
              {t("report-form.report-type")}
              <span>*</span>
            </p>
            <ClickOutside
              itemRef="report-type-select"
              eventItem={(e) => input(e, selectedReportType)}
              toDisplayItem={(e) => options(e, reportTypes, setSelectedReportType)}
            />
          </div>
        )}
      </div>
      {activeTab === t("product.archive") ? (
        <React.Fragment>
          {!isMobile ? (
            <div className="table d-flex flex-column">
              <div className="search-wrapper d-flex flex-align-center">
                <Search
                  className="search-input"
                  placeholder={t("placeholders.select-serial-number")}
                  onSearch={(e) => setSearch(e)}
                  resetPage={() => resetPage()}
                />
                <button className="search-button" onClick={handleSearch}>
                  {t("search.search")}
                </button>
              </div>
              <div className="table-content">
                <div className="table-header">{t("report-problem.report")}</div>
                <div className="table-header">{t("report-problem.ticket-number")}</div>
                <div className="table-header">{t("report-problem.serial-number")}</div>
                <div className="table-header">{t("report-problem.status")}</div>
                <div className="table-header">{t("report-problem.products")}</div>
                <div className="table-header">{t("report-problem.cycle-faults")}</div>
                <div className="table-header">{t("report-problem.subject-of-report")}</div>
                <div className="table-header">{t("report-problem.report-by-email")}</div>

                {reports &&
                  reports.length &&
                  reports.map((report, index) => (
                    <React.Fragment key={index}>
                      <div className="table-body">
                        <button className="table-body button" onClick={() => openModal(report)}>
                          View
                        </button>
                      </div>
                      <div className="table-body">{report.id}</div>
                      <div className="table-body">{report.serial_number}</div>
                      <div className="table-body">{report.status}</div>
                      <div className="table-body">{report.model}</div>
                      <div className="table-body">{report.cycle_fault}</div>
                      <div className="table-body">{report.issue_description}</div>
                      <div className="table-body">{report.report_by_email}</div>
                    </React.Fragment>
                  ))}
              </div>
              {!isMobile && totalResults && elementsPerPage && displayLoadMore && (
                <div className="buttons-wrapper">
                  <button className="load-more-button" onClick={loadMore}>
                    {t("buttons.load_more")}
                  </button>
                </div>
              )}
            </div>
          ) : (
            <div className="mobile-content">
              <div className="search-wrapper d-flex flex-align-center">
                <Search className="search-input" placeholder={t("search.search")} onSearch={(e) => setSearch(e)} resetPage={() => resetPage()} />
                <button className="search-button" onClick={handleSearch}>
                  {t("search.search")}
                </button>
              </div>
              {reports &&
                reports.length &&
                reports.map((report, index) => (
                  <React.Fragment key={index}>
                    <div className="report-header d-flex flex-justify-between flex-align-center">
                      <div className="table-header">
                        {t("report-problem.ticket-number")}
                        {report.id}
                      </div>
                      <button className="table-body button" onClick={() => openModal(report)}>
                        {t("report-problem.view")}
                      </button>
                    </div>
                    <div className="report-body">
                      <div className="table-header">{t("report-problem.report")}</div>
                      <div className="table-header">#{report.id}</div>
                      <div className="table-header">{t("report-problem.serial-number")}</div>
                      <div className="table-body">{report.serial_number}</div>
                      <div className="table-header">{t("report-problem.status")}</div>
                      <div className="table-body">{report.status}</div>
                      <div className="table-header">{t("report-problem.products")}</div>
                      <div className="table-body">{report.model}</div>
                      <div className="table-header">{t("report-problem.cycle-faults")}</div>
                      <div className="table-body">{report.cycle_fault}</div>
                      <div className="table-header">{t("report-problem.subject-of-report")}</div>
                      <div className="table-body">{report.issue_description}</div>
                      <div className="table-header">{t("report-problem.report-by-email")}</div>
                      <div className="table-body">{report.report_by_email}</div>
                    </div>
                  </React.Fragment>
                ))}
            </div>
          )}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className="form-body">
            {isMobile && (
              <div className={"search-wrapper d-flex flex-justify-between" + (isMobile ? " flex-column" : "")}>
                <p>
                  {t("report-form.report-type")} <span>*</span>
                </p>
                <ClickOutside
                  itemRef="report-type-select"
                  eventItem={(e) => input(e, selectedReportType)}
                  toDisplayItem={(e) => options(e, reportTypes, setSelectedReportType)}
                />
              </div>
            )}
            <div className="general-information">
              <h2 className="mb-30 mt-0">{t("report-form.general-info")}</h2>
              <div className="select-product-serial-number d-flex flex-justify-start">
                <ClickOutside
                  itemRef="product-select"
                  eventItem={(e) => input(e, selectedProduct)}
                  toDisplayItem={(e) => options(e, productOptions, setSelectedProduct)}
                />
                <input
                  type="text"
                  placeholder={t("summary.serial_number")}
                  onChange={handleInputChange}
                  value={formData?.serial_number || ""}
                  name="serial_number"
                />
              </div>
              <div className={"wrapper d-flex flex-justify-start" + (isMobile ? " flex-column" : "")}>
                <div className="complaint-type d-flex flex-column">
                  <h3>{t("report-form.complaint-type")}</h3>
                  <ClickOutside
                    itemRef="complaint-type-select"
                    eventItem={(e) => input(e, selectedComplaintType)}
                    toDisplayItem={(e) => options(e, complaintTypes, setSelectedComplaintType)}
                  />
                </div>
                <div className="oobf d-flex flex-column">
                  <h3>{t("report-form.oobf")}</h3>
                  <ClickOutside
                    itemRef="oobf-select"
                    eventItem={(e) => input(e, selectedOobf)}
                    toDisplayItem={(e) => options(e, oobfOptions, setSelectedOobf)}
                  />
                </div>
                <div className="rga-type d-flex flex-column">
                  <h3>{t("report-form.rga")}</h3>
                  <input type="text" onChange={handleInputChange} value={formData?.rga || ""} name="rga" />
                </div>
              </div>
              {/* {!isMobile &&
              <div className="d-flex flex-align-center mt-25">
                <input type="checkbox" name="complaint_logged" onChange={handleInputChange}/>
                <h3 className="ml-15">{t('report-form.already-exists')}<span>*</span></h3>
              </div>
              } */}
            </div>
            <div className="issue-reported-by">
              <h2 className="mb-30 mt-0">{t("report-form.reported-by")}</h2>
              <div className="reported-by-form-data d-flex">
                <div className="input d-flex flex-column">
                  <h3>{t("report-form.title")}</h3>
                  <ClickOutside
                    itemRef="title-select"
                    eventItem={(e) => input(e, selectedTitle)}
                    toDisplayItem={(e) => options(e, titleOptions, setSelectedTitle)}
                  />
                </div>
                <div className={"input d-flex flex-column " + displayError("first_name", true)}>
                  <h3>
                    {t("report-form.first-name")}
                    <span>*</span>
                  </h3>
                  <input type="text" onChange={handleInputChange} value={formData?.first_name || ""} name="first_name" />
                  <p className="error">{displayError("first_name")}</p>
                </div>
                <div className={"input d-flex flex-column " + displayError("last_name", true)}>
                  <h3>
                    {t("report-form.last-name")}
                    <span>*</span>
                  </h3>
                  <input type="text" onChange={handleInputChange} value={formData?.last_name || ""} name="last_name" />
                  <p className="error">{displayError("last_name")}</p>
                </div>
                <div className="input d-flex flex-column">
                  <h3>{t("report-form.country")}</h3>
                  <input type="text" onChange={handleInputChange} value={formData?.country || ""} name="country" />
                </div>
                <div className={"input d-flex flex-column " + displayError("phone", true)}>
                  <h3>
                    {t("report-form.phone")}
                    <span>*</span>
                  </h3>
                  <input type="text" onChange={handleInputChange} value={formatPhone(formData?.phone || "")} name="phone" />
                  <p className="error">{displayError("phone")}</p>
                </div>
                <div className="input d-flex flex-column ">
                  <h3>{t("report-form.fax")}</h3>
                  <input type="text" onChange={handleInputChange} value={formData?.fax || ""} name="fax" />
                </div>
              </div>
            </div>
            {isComplaint && (
              <React.Fragment>
                <h3 className="complaint">{t("report-form.complaint-form")}</h3>

                <div className="event-information">
                  <h2 className="mb-15 mt-0">{t("report-form.event-info")}</h2>

                  <div className="technician">
                    <h3 className="form-title">{t("report-form.technician")}</h3>
                    <div className="technician-inputs d-flex">
                      <div className={"input d-flex flex-column " + displayError("scican_technician", true)}>
                        <h3>{t("report-form.technician-name")}</h3>
                        <input type="text" onChange={handleInputChange} value={formData?.scican_technician || ""} name="scican_technician" />
                        <p className="error">{displayError("scican_technician")}</p>
                      </div>
                      <div className="input d-flex flex-column">
                        <h3>{t("report-form.customer-id")}</h3>
                        <input type="text" onChange={handleInputChange} value={formData?.customer_id || ""} name="customer_id" />
                      </div>
                      <div className={"input d-flex flex-column " + displayError("occupation", true)}>
                        <h3>
                          {t("report-form.occupation")}
                          <span>*</span>
                        </h3>
                        <input type="text" onChange={handleInputChange} value={formData?.occupation || ""} name="occupation" />
                        <p className="error">{displayError("occupation")}</p>
                      </div>
                      <div className={"input d-flex flex-column " + displayError("practice", true)}>
                        <h3>
                          {t("report-form.practice")}
                          <span>*</span>
                        </h3>
                        <input type="text" onChange={handleInputChange} value={formData?.practice || ""} name="practice" />
                        <p className="error">{displayError("practice")}</p>
                      </div>
                      <div className={"input d-flex flex-column " + displayError("complaint_origin", true)}>
                        <h3>
                          {t("report-form.complaint-origin")}
                          <span>*</span>
                        </h3>
                        <input type="text" onChange={handleInputChange} value={formData?.complaint_origin || ""} name="complaint_origin" />
                        <p className="error">{displayError("complaint_origin")}</p>
                      </div>
                      <div className={"input d-flex flex-column " + displayError("occurred_location", true)}>
                        <h3>
                          {t("report-form.location")}
                          <span>*</span>
                        </h3>
                        <ClickOutside
                          itemRef="location-select"
                          eventItem={(e) => input(e, selectedOccurredLocation)}
                          toDisplayItem={(e) => options(e, locationOccurredOptions, setSelectedOccurredLocation)}
                        />
                        <p className="error">{displayError("occurred_location")}</p>
                      </div>
                      <div className="input d-flex flex-column">
                        <h3>&nbsp;</h3>
                        <input
                          type="text"
                          placeholder="Other"
                          onChange={handleInputChange}
                          value={formData?.occurred_location_other || ""}
                          name="occurred_location_other"
                        />
                      </div>
                      <div className={"input d-flex flex-column " + displayError("patient_involvement", true)}>
                        <h3>
                          {t("report-form.involvement")}
                          <span>*</span>
                        </h3>
                        <div className="radio-select d-flex flex-align-center" onChange={handleInputChange}>
                          <input type="radio" name="patient_involvement" value={"simple"} />
                          <p>{t("report-form.simple")}</p>
                          <input type="radio" name="patient_involvement" value={"advanced"} />
                          <p>{t("report-form.advanced")}</p>
                        </div>
                        <p className="error">{displayError("patient_involvement")}</p>
                      </div>
                    </div>
                  </div>

                  <div className="doctor">
                    <h3 className="form-title">{t("report-form.doctor-info")}</h3>
                    <div className="doctor-inputs d-flex">
                      <div className={"input d-flex flex-column " + displayError("doctor_name", true)}>
                        <h3>
                          {t("report-form.doctor")}
                          <span>*</span>
                        </h3>
                        <input type="text" onChange={handleInputChange} value={formData?.doctor_name || ""} name="doctor_name" />
                        <p className="error">{displayError("doctor_name")}</p>
                      </div>
                      <div className="input d-flex flex-column">
                        <h3>{t("report-form.scican-rep")}</h3>
                        <input type="text" onChange={handleInputChange} value={formData?.scican_rep || ""} name="scican_rep" />
                      </div>
                      <div className={"input d-flex flex-column " + displayError("doctor_address", true)}>
                        <h3>
                          {t("report-form.address")}
                          <span>*</span>
                        </h3>
                        <input type="text" onChange={handleInputChange} value={formData?.doctor_address || ""} name="doctor_address" />
                        <p className="error">{displayError("doctor_address")}</p>
                      </div>
                      <div className={"input d-flex flex-column " + displayError("doctor_city", true)}>
                        <h3>
                          {t("report-form.city")}
                          <span>*</span>
                        </h3>
                        <input type="text" onChange={handleInputChange} value={formData?.doctor_city || ""} name="doctor_city" />
                        <p className="error">{displayError("doctor_city")}</p>
                      </div>
                      <div className={"input d-flex flex-column " + displayError("doctor_state", true)}>
                        <h3>
                          {t("report-form.state")}
                          <span>*</span>
                        </h3>
                        <input type="text" onChange={handleInputChange} value={formData?.doctor_state || ""} name="doctor_state" />
                        <p className="error">{displayError("doctor_state")}</p>
                      </div>
                      <div className={"input d-flex flex-column " + displayError("doctor_zip", true)}>
                        <h3>
                          {t("report-form.zip")}
                          <span>*</span>
                        </h3>
                        <input type="text" onChange={handleInputChange} value={formData?.doctor_zip || ""} name="doctor_zip" />
                        <p className="error">{displayError("doctor_zip")}</p>
                      </div>
                      <div className="input d-flex flex-column">
                        <h3>{t("report-form.country")}</h3>
                        <ClickOutside
                          itemRef="country-select"
                          eventItem={(e) => input(e, selectedCountry, true)}
                          toDisplayItem={(e) => options(e, countriesOptions, setSelectedCountry, true)}
                        />
                      </div>
                      <div className={"input d-flex flex-column " + displayError("report_date", true)}>
                        <h3>
                          {t("report-form.report-date")}
                          <span>*</span>
                        </h3>
                        <CalendarInput
                          onChangeDay={(e) => handleChangeCalendarDate(e, setSelectedReportDate)}
                          value={selectedReportDate}
                          placeholder={t("placeholders.occurrence-date")}
                        />
                        <p className="error">{displayError("report_date")}</p>
                      </div>
                      <div className={"input d-flex flex-column " + displayError("occurrence_date", true)}>
                        <h3>
                          {t("report-form.occurrence-date")}
                          <span>*</span>
                        </h3>
                        <CalendarInput
                          onChangeDay={(e) => handleChangeCalendarDate(e, setSelectedOccurrenceDate)}
                          value={selectedOccurrenceDate}
                          placeholder={t("placeholders.occurrence-date")}
                        />
                        <p className="error">{displayError("occurrence_date")}</p>
                      </div>
                      <div className="input d-flex flex-column">
                        <h3>{t("profile-labels.technician")}</h3>
                        <input type="text" onChange={handleInputChange} value={formData?.technician || ""} name="technician" />
                      </div>
                      <div className="input d-flex flex-column">
                        <h3>{t("report-form.dealer-branch")}</h3>
                        <input type="text" onChange={handleInputChange} value={formData?.dealer_branch || ""} name="dealer_branch" />
                      </div>
                    </div>
                  </div>

                  <div className="product">
                    <h3 className="form-title">{t("report-form.product-info")}</h3>
                    <div className="product-inputs d-flex">
                      <div className={"input d-flex flex-column " + displayError("product_number", true)}>
                        <h3>
                          {t("report-form.product-number")}
                          <span>*</span>
                        </h3>
                        <input type="text" onChange={handleInputChange} value={formData?.product_number || ""} name="product_number" />
                        <p className="error">{displayError("product_number")}</p>
                      </div>
                      <div className={"input d-flex flex-column " + displayError("cycle_count", true)}>
                        <h3>
                          {t("report-form.cycle-count")}
                          <span>*</span>
                        </h3>
                        <input type="text" onChange={handleInputChange} value={formData?.cycle_count || ""} name="cycle_count" />
                        <p className="error">{displayError("cycle_count")}</p>
                      </div>
                      <div className="input d-flex flex-column">
                        <h3>{t("report-form.expiry-date")}</h3>
                        <input type="text" onChange={handleInputChange} value={formData?.expiry_date || ""} name="expiry_date" />
                      </div>
                      <div className="input d-flex flex-column">
                        <h3>{t("report-form.product-name")}</h3>
                        <input type="text" onChange={handleInputChange} value={formData?.model || ""} name="model" />
                      </div>
                      <div className="input d-flex flex-column">
                        <h3>{t("report-form.other-info")}</h3>
                        <input type="text" onChange={handleInputChange} value={formData?.other_info || ""} name="other_info" />
                      </div>
                      <div className={"input d-flex flex-column " + displayError("sample_enclosed", true)}>
                        <h3>
                          {t("report-form.sample-enclosed")}
                          <span>*</span>
                        </h3>
                        <div className="radio-select d-flex flex-align-center" onChange={handleInputChange}>
                          <input type="radio" name="sample_enclosed" value={"simple"} />
                          <p>{t("report-form.simple")}</p>
                          <input type="radio" name="sample_enclosed" value={"advanced"} />
                          <p>{t("report-form.advanced")}</p>
                        </div>
                        <p className="error">{displayError("sample_enclosed")}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )}
            <div className="report-information">
              <h2 className="mb-30 mt-0">{t("report-form.report-info")}</h2>
              <div className="report-info-inputs d-flex flex-column">
                <div className={"d-flex" + (isMobile ? " flex-column w-100" : " flex-wrap")}>
                  <div className={"input d-flex flex-column " + displayError("cycle_fault", true)}>
                    <h3>
                      {t("report-form.cycle-fault")}
                      <span>*</span>
                    </h3>
                    <input type="text" onChange={handleInputChange} value={formData?.cycle_fault || ""} name="cycle_fault" />
                    <p className="error">{displayError("cycle_fault")}</p>
                  </div>
                  <div className="input d-flex flex-column">
                    <h3>{t("report-form.other")}</h3>
                    <input type="text" onChange={handleInputChange} value={formData?.cycle_fault_other || ""} name="cycle_fault_other" />
                  </div>
                  <div className="input d-flex flex-column">
                    <h3>{t("report-form.job-number")}</h3>
                    <input type="text" onChange={handleInputChange} value={formData?.job_number || ""} name="job_number" />
                  </div>
                </div>
                <div className="d-flex flex-column">
                  <div className={"d-flex" + (isMobile ? " flex-column" : "")}>
                    <div className={"input area d-flex flex-column " + displayError("issue_description", true)}>
                      <h3>
                        {t("report-form.description-issue")}
                        <span>*</span>
                      </h3>
                      <textarea onChange={handleInputChange} value={formData?.issue_description || ""} name="issue_description" />
                      <p className="error">{displayError("issue_description")}</p>
                    </div>
                    <div className="input area d-flex flex-column">
                      <h3>{t("report-form.resolution")}</h3>
                      <textarea onChange={handleInputChange} value={formData?.resolution || ""} name="resolution" />
                    </div>
                  </div>
                  <div className={"d-flex" + (isMobile ? " flex-column" : "")}>
                    <div className="input area d-flex flex-column">
                      <h3>{t("report-form.findings")}</h3>
                      <textarea onChange={handleInputChange} value={formData?.findings || ""} name="findings" />
                    </div>
                    <div className="input area d-flex flex-column">
                      <h3>{t("report-form.follow-up")}</h3>
                      <textarea onChange={handleInputChange} value={formData?.follow_up || ""} name="follow_up" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={"d-flex flex-column " + (isMobile ? " flex-justify-center" : "")}>
            <button disabled={isSubmitting} className={"submit cursor-pointer" + (isMobile ? " unset" : "")} onClick={submitReport}>
              {t("report-form.submit")}
            </button>
          </div>
        </React.Fragment>
      )}
      {showModal && (
        <Modal onToggle={closeModal} toggle={showModal} className="report-modal">
          <h1>{t("report-form.general-info")}</h1>
          <div className="wrapper">
            <div className="d-flex flex-column">
              <h5>{t("report-form.product")}</h5>
              <h4>{selectedReport.model}</h4>
            </div>
            <div className="d-flex flex-column">
              <h5>{t("report-form.serial-number")}</h5>
              <h4>{selectedReport.serial_number}</h4>
            </div>
            <div className="d-flex flex-column">
              <h5>{t("report-form.complaint-type")}</h5>
              <h4>{selectedReport.complaint_type}</h4>
            </div>
            <div className="d-flex flex-column">
              <h5>{t("report-form.oobf")}</h5>
              <h4>{selectedReport.oobf}</h4>
            </div>
            <div className="d-flex flex-column">
              <h5>{t("report-form.rga")}</h5>
              <h4>{selectedReport.rga}</h4>
            </div>
          </div>
          <h1>{t("report-form.reported-by")}</h1>
          <div className="wrapper">
            <div className="d-flex flex-column">
              <h5>{t("report-form.title")}</h5>
              <h4>{selectedReport.title}</h4>
            </div>
            <div className="d-flex flex-column">
              <h5>{t("report-form.first-name")}</h5>
              <h4>{selectedReport.first_name}</h4>
            </div>
            <div className="d-flex flex-column">
              <h5>{t("report-form.last-name")}</h5>
              <h4>{selectedReport.last_name}</h4>
            </div>
            <div className="d-flex flex-column">
              <h5>{t("report-form.country")}</h5>
              <h4>{selectedReport.country}</h4>
            </div>
            <div className="d-flex flex-column">
              <h5>{t("report-form.phone")}</h5>
              <h4>{formatPhone(selectedReport.phone)}</h4>
            </div>
            <div className="d-flex flex-column">
              <h5>{t("report-form.fax")}</h5>
              <h4>{selectedReport.fax}</h4>
            </div>
          </div>
          <h1>{t("report-form.event-info")}</h1>
          <h3>{t("report-form.technician")}</h3>
          <div className="wrapper technician">
            <div className="d-flex flex-column">
              <h5>{t("report-form.technician-name")}</h5>
              <h4>{selectedReport.scican_technician}</h4>
            </div>
            <div className="d-flex flex-column">
              <h5>{t("report-form.customer-id")}</h5>
              <h4>{selectedReport.customer_id}</h4>
            </div>
            <div className="d-flex flex-column">
              <h5>{t("report-form.occupation")}</h5>
              <h4>{selectedReport.occupation}</h4>
            </div>
            <div className="d-flex flex-column">
              <h5>{t("report-form.practice")}</h5>
              <h4>{selectedReport.practice}</h4>
            </div>
            <div className="d-flex flex-column">
              <h5>{t("report-form.complaint-origin")}</h5>
              <h4>{selectedReport.complaint_origin}</h4>
            </div>
            <div className="d-flex flex-column">
              <h5>{t("report-form.location")}</h5>
              <h4>{selectedReport.location}</h4>
            </div>
            <div className="d-flex flex-column">
              <h5>{t("report-form.involvement")}</h5>
              <h4>{selectedReport.involvement}</h4>
            </div>
          </div>
          <h3 className="doctor">{t("report-form.doctor-info")}</h3>
          <div className="wrapper doctor">
            <div className="d-flex flex-column">
              <h5>{t("report-form.doctor")}</h5>
              <h4>{selectedReport.doctor}</h4>
            </div>
            <div className="d-flex flex-column">
              <h5>{t("report-form.scican-rep")}</h5>
              <h4>{selectedReport.scican_rep}</h4>
            </div>
            <div className="d-flex flex-column">
              <h5>{t("report-form.address")}</h5>
              <h4>{selectedReport.doctor_address}</h4>
            </div>
            <div className="d-flex flex-column">
              <h5>{t("report-form.city")}</h5>
              <h4>{selectedReport.doctor_city}</h4>
            </div>
            <div className="d-flex flex-column">
              <h5>{t("report-form.state")}</h5>
              <h4>{selectedReport.doctor_state}</h4>
            </div>
            <div className="d-flex flex-column">
              <h5>{t("report-form.country")}</h5>
              <h4>{selectedReport.doctor_country}</h4>
            </div>
            <div className="d-flex flex-column">
              <h5>{t("report-form.report-date")}</h5>
              <h4>{selectedReport.report_date}</h4>
            </div>
            <div className="d-flex flex-column">
              <h5>{t("report-form.occurrence-date")}</h5>
              <h4>{selectedReport.occurrence_date}</h4>
            </div>
            <div className="d-flex flex-column">
              <h5>{t("profile-labels.technician")}</h5>
              <h4>{selectedReport.technician}</h4>
            </div>
            <div className="d-flex flex-column">
              <h5>{t("report-form.dealer-branch")}</h5>
              <h4>{selectedReport.dealer_branch}</h4>
            </div>
          </div>
          <h3 className="product-info">{t("report-form.product-info")}</h3>
          <div className="wrapper product-info">
            <div className="d-flex flex-column">
              <h5>{t("report-form.product-number")}</h5>
              <h4>{selectedReport.product_number}</h4>
            </div>
            <div className="d-flex flex-column">
              <h5>{t("report-form.cycle-count")}</h5>
              <h4>{selectedReport.cycle_count}</h4>
            </div>
            <div className="d-flex flex-column">
              <h5>{t("report-form.expiry-date")}</h5>
              <h4>{selectedReport.expiry_date}</h4>
            </div>
            <div className="d-flex flex-column">
              <h5>{t("report-form.product-name")}</h5>
              <h4>{selectedReport.model}</h4>
            </div>
            <div className="d-flex flex-column">
              <h5>{t("report-form.other-info")}</h5>
              <h4>{selectedReport.other_info}</h4>
            </div>
            <div className="d-flex flex-column">
              <h5>{t("report-form.sample-enclosed")}</h5>
              <h4>{selectedReport.sample_enclosed}</h4>
            </div>
          </div>
          <h1>{t("report-form.report-info")}</h1>
          <div className="wrapper area">
            <div className="d-flex flex-column">
              <h5>{t("report-form.cycle-fault")}</h5>
              <h4>{selectedReport.cycle_fault}</h4>
            </div>
            <div className="d-flex flex-column">
              <h5>{t("report-form.other")}</h5>
              <h4>{selectedReport.other}</h4>
            </div>
            <div className="d-flex flex-column">
              <h5>{t("report-form.job-number")}</h5>
              <h4>{selectedReport.job_number}</h4>
            </div>
          </div>
          <div className="wrapper area">
            <div className="d-flex flex-column">
              <h5>{t("report-form.description-issue")}</h5>
              <h4>{selectedReport.issue_description}</h4>
            </div>
            {!isMobile && <div>&nbsp;</div>}
            <div className="d-flex flex-column">
              <h5>{t("report-form.resolution")}</h5>
              <h4>{selectedReport.resolution}</h4>
            </div>
          </div>
          <div className="wrapper area">
            <div className="d-flex flex-column">
              <h5>{t("report-form.findings")}</h5>
              <h4>{selectedReport.findings}</h4>
            </div>
            {!isMobile && <div>&nbsp;</div>}
            <div className="d-flex flex-column">
              <h5>{t("report-form.follow-up")}</h5>
              <h4>{selectedReport.follow_up}</h4>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

function mapStateToProps({ productTypes, authedUser, ticket }) {
  return {
    productTypes,
    authedUser,
    ticket,
  };
}

export default connect(mapStateToProps)(ReportProblem);
