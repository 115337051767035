import { useState } from "react"

import "./price-list.scss"
import SimpleButton from "../common/SimpleButton"
import PageModal from "../common/PageModal"
import { useTranslation } from "react-i18next"
import { postRequestReview } from "../../utils/requests/dealersAPI"
import LoadingSpinner from "../common/LoadingSpinner"
import { toast } from "react-toastify"

export function RequestReviewModal({ parts, options, handleClose }) {
  const { t } = useTranslation()

  const [selectedParts, setSelectedParts] = useState(parts)
  const [isFormSubmission, setIsFormSubmission] = useState(false)
  const [formState, setFormState] = useState(() => {
    return parts.map((part) => {
      return {
        partNo: part.partNo,
        options: [],
        comments: "",
      }
    })
  })

  function handlePartSelection(event) {
    const targetPart = parts.find((part) => part.id === Number(event.target.value))
    if (event.target.checked) {
      setSelectedParts([...selectedParts, targetPart])

      setFormState([
        ...formState,
        {
          partNo: targetPart.partNo,
          options: [],
          comments: "",
        },
      ])
    } else {
      setSelectedParts(selectedParts.filter((selectedPart) => selectedPart !== targetPart))
      setFormState(formState.filter((state) => state.partNo !== targetPart.partNo))
    }
  }
  
  function handleAllOptionSelection(type, partNo) {
    const newFormState = formState.map((state) => {
      if (state.partNo === partNo) {
        return {
          ...state,
          options: type === "all" ? options.map((option) => option.id) : [],
        }
      }
      return state
    })
    setFormState(newFormState)
  }

  function handleOptionSelection(event, partNo, id) {
    const isChecked = event.target.checked
    const newFormState = formState.map((state) => {
      if (state.partNo === partNo) {
        return {
          ...state,
          options: isChecked ? [...state.options, id] : state.options.filter((option) => option !== id),
        }
      }
      return state
    })

    setFormState(newFormState)
  }

  function handleCommentChange(event, partNo) {
    const newFormState = formState.map((state) => {
      if (state.partNo === partNo) {
        return {
          ...state,
          comments: event.target.value,
        }
      }
      return state
    })

    setFormState(newFormState)
  }

  async function handleSubmit() {
    toast.dismiss()
    try {
      setIsFormSubmission(true)
      await postRequestReview(formState)
      setIsFormSubmission(false)
      toast.success(t("dealer-portal.review_success"))
    } catch (error) {
      setIsFormSubmission(false)
      toast.error(t("error.something_wrong"))
    }
    handleClose()
  }

  function getFooter() {
    return (
      <>
        <SimpleButton className="cancel-button" onClick={handleClose}>
          {t("labels.cancel")}
        </SimpleButton>
        <SimpleButton className="submit-button" onClick={handleSubmit}>
          {t("labels.submit")}
        </SimpleButton>
      </>
    )
  }

  return (
    <PageModal toggle onToggle={handleClose} title={t("dealer-portal.request-review")} headerContent={<div></div>} footerContent={getFooter()}>
      {isFormSubmission && (
        <div className="spinner-wrapper">
          <LoadingSpinner />
        </div>
      )}
      <div className="request-review-modal">
        <div className="header-info">Here is the text, or short advice for user what he/she need to to on this modal for a better results.</div>

        <div className="parts-selection-container">
          <div className="header">{t("dealer-portal.selected-part-number")}</div>
          <div className="select-part-form">
            {parts.map(({ id, partNo }) => {
              return (
                <div key={partNo} className="form-group">
                  <input onChange={handlePartSelection} type="checkbox" value={id} id={id} checked={selectedParts.find((p) => p.id === id)} />
                  <label htmlFor={id}>{partNo}</label>
                </div>
              )
            })}
          </div>
        </div>

        <form onSubmit={(e) => e.preventDefault()}>
          {selectedParts.map(({ partNo, description }) => {
            const currentSelectedPartFormState = formState.find((state) => state.partNo === partNo)

            return (
              <div className="request-form" key={partNo}>
                <div className="header">
                  <div className="left">
                    <span className="title">{partNo}</span>
                    <span className="subtitle">{description}</span>
                  </div>
                  <div className="right">
                    <button onClick={() => handleAllOptionSelection("all", partNo)}>{t("dealer-portal.select-all")}</button>
                    <button onClick={() => handleAllOptionSelection("reset", partNo)}>{t("dealer-portal.clear-all")}</button>
                  </div>
                </div>
                <div className="selected-part-forms">
                  <div className="options">
                    {options.map(({ id, reviewOptions }) => {
                      return (
                        <div className="form-group" key={id}>
                          <input
                            type="checkbox"
                            name="option"
                            id={id}
                            checked={currentSelectedPartFormState.options.includes(id)}
                            onChange={(event) => handleOptionSelection(event, partNo, id)}
                          />
                          <label htmlFor={id}>{reviewOptions}</label>
                        </div>
                      )
                    })}
                  </div>
                  <div className="comments">
                    <label>{t("dealer-portal.comments")}</label>
                    <textarea
                      name="comments"
                      id=""
                      rows="3"
                      value={currentSelectedPartFormState.comments}
                      onChange={(event) => handleCommentChange(event, partNo)}
                    ></textarea>
                  </div>
                </div>
              </div>
            )
          })}
        </form>
      </div>
    </PageModal>
  )
}