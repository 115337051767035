import { convertTimeWithTAndZ } from "../../utils/filters/date"
import ExpandableText from "../common/ExpandableText"

export function getRequestListHeaders(t) {
  return [
    {
      title: t("dealer-portal.request-type"),
      orderKey: "type",
    },
    {
      title: t("dealer-portal.partNo"),
      orderKey: "partNo",
    },
    {
      title: t("dealer-portal.description"),
      orderKey: "description",
    },
    {
      title: t("dealer-portal.comments"),
      orderKey: "comments",
    },
    {
      title: t("dealer-portal.date"),
      orderKey: "date",
    },
  ]
}

export const requestListTableKeys = ["type", "partNo", "description", "comments", "date"]

export const requestListRenderer = ({t}) => {
  return {
    type: (item) => {
      return (
        <div className="product-type-wrapper">
          <ExpandableText text={t(`dealer-portal.${item.requestType}`)} />
        </div>
      )
    },
    partNo: (item) => {
      return (
        <div className="part-no-wrapper">
          <ExpandableText text={item.partNo} />
        </div>
      )
    },
    description: (item) => {
      return (
        <div className="description-wrapper">
          <ExpandableText text={item.description} />
        </div>
      )
    },
    comments: (item) => {
      return (
        <div className="date-wrapper">
          <ExpandableText text={item.comments} />
        </div>
      )
    },
    date: (item) => {
      return (
        <div className="date-wrapper">
          <ExpandableText text={convertTimeWithTAndZ(item.requestDate)} />
        </div>
      )
    },
  }
}
