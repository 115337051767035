import React, { useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import './select-user-group.scss'
import PublicPageHeading from "../common/PublicPageHeading";
import {
  DentistIcon,
  DealerTechnicianIcon,
  PeopleIcon
} from '../../icons'
import { getUserAccountTypes } from '../../utils/requests/usersAPI'
import LoadingSpinner from '../common/LoadingSpinner';

const SelectUserGroup = (props) => {
  const [accountType, setAccountTypes] = React.useState([])
  const history = useHistory()
  const { t } = useTranslation()

  const urlRedirect = (name, userGroups) => {
    switch (name) {
      case "Coltene":
        return RedirectToForm("scican-employee", userGroups)
      case "End User":
        return RedirectToForm("dentist", userGroups)
      case "Dealer":
        return RedirectToForm("dealer-technician", userGroups)
      default:
        break
    }
  }
  
  const RedirectToForm = (type, userGroups) => {
    history.push(`${"/register/create-account/" + type.replace(' ', '-')}`, {
      userGroups,
    });
  }
  
  const getIcon = (key) => {
    switch (key) {
      case "End User":
        return <DentistIcon className="icon" />
      case "Dealer":
        return <DealerTechnicianIcon className="icon" />
      case "Coltene":
        return <PeopleIcon className="icon" />
      default:
        break
    }
  }

  const getSubHeading = () => (
    <>
      {t("register.already_have_account")} <Link to="/" className="login-link">  {t("register.login_account")} </Link>
    </>
  )
  
  useEffect(() => { 
    getUserAccountTypes().then((res) => { 
      setAccountTypes(res)
    })
  },[])
  
  function accountTypeSorter(a, b) {
    const sortOrder = ['End User', 'Dealer', 'Coltene']
    const indexA = sortOrder.indexOf(a.name)
    const indexB = sortOrder.indexOf(b.name)
    return indexA - indexB
  }
  
  return (
    <div className="center register-container public-page">
    {accountType.length > 0 ? (
      <div className="center type-container">
        <PublicPageHeading heading={t("register.select_account_type")} subheading={getSubHeading()} />
        {/* <h1 >{t("register.select_account_type")}</h1>
        <div>{t("register.already_have_account")} <Link to="/" className="login-link"> 
          {t("register.login_account")}
          </Link></div>
        <div className="divider" ></div> */}
        <div className="select-account-type">
          {
            Object.values(accountType).sort(accountTypeSorter).map(k => {
              return (<div key={k.id} 
                className={`account-type ${k.name}`} 
                onClick={() => urlRedirect(k.name, k.userGroups)} 
              >
                <div className="type-icon"> <span>{ getIcon(k.name) }</span> </div>
                <span className="type-name">{t(`register.${k.name}`)}</span>
              </div>)
            })
          }
        </div>
      </div>
    ) : <LoadingSpinner /> } 
    </div>
  )
}

export default SelectUserGroup