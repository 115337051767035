import React, { Fragment, useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import {toast} from "react-toastify"
import PageModal from "../common/PageModal"
import ClickOutside from "../common/ClickOutside"
import ToggleSwitch from "../common/ToggleSwitch"
import SimpleButton from '../common/SimpleButton'
import { CaretDownIcon } from "../../icons"
import {
  loadEmailTemplates,
  createNotificationSetting,
  updateNotificationSetting
} from "../../actions/notifications"
import { getEmailTemplateContent } from "../../utils/requests/notificationsAPI";
import { acceptedTemplates } from './notificationEmailTemplates'

const AddEditNotificationSetting = (props) => {
  const { t } = useTranslation()
  const { selectedNotificationSetting, methods, groupEvents, closeModal, isLoading, showSpinner, hideSpinner, UnusedGroupEvents } = props
  const [emailTemplates, setEmailTemplates] = useState([])
  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")
  const [isUserEditable, setIsUserEditable] = useState(false)
  const [isEnabled, setIsEnabled] = useState(false)
  const [selectedEmailTemplate, setSelectedEmailTemplate] = useState(null)
  const [selectedType, setSelectedType] = useState(null)
  const [selectedGroupEvents, setSelectedGroupEvents] = useState(null)
  const [emailTemplatePreview, setEmailTemplatePreview] = useState(null)
  const [haveError, setHaveError] = useState({})

  useEffect(() => {
    if (props.emailTemplates.length === 0) {
      props.actions.loadEmailTemplates()
    }
  }, [props.actions])

  useEffect(() => {
    setEmailTemplates(props.emailTemplates)
  }, [props.emailTemplates])

  useEffect(() => {
    if (selectedNotificationSetting) {
      setTitle(selectedNotificationSetting.name)
      setDescription(selectedNotificationSetting.description)
      setIsUserEditable(selectedNotificationSetting.allowSuppress)
      setIsEnabled(selectedNotificationSetting.isEnabled)
      setSelectedEmailTemplate(selectedNotificationSetting.emailTemplateName)
      setSelectedType(selectedNotificationSetting.type)
      setSelectedGroupEvents(groupEvents.find((e) => e.id === selectedNotificationSetting.eventGroupId))
    }
  },[selectedNotificationSetting])

  const notificationHeaderContent = () => {
    return (
      <div className="status-wrapper">
        <p className="input-option">{t('events.status')}</p>
        <div className="status-option">
          <ToggleSwitch checked={isEnabled} onChange={() => setIsEnabled((prevValue) => !prevValue)} />
          <p className="text-status-option">
          {
            isEnabled ? t('events.active') : t('events.inactive')
          }
          </p>
        </div>
      </div>
    )
  }

  const notificationFooterContent = () => {
    return (
      <>
        <SimpleButton 
          className="cancel-button" 
          onClick={() => closeModal()}
        >
          {t('buttons.cancel')}
        </SimpleButton>
        <SimpleButton 
          className="submit-button" 
          onClick={() => handleAddEditDeviceNotification()}
          disabled={isLoading}
        >
          {t('labels.save')}
        </SimpleButton>
      </>
    )
  }

  const previewTemplateFooterContent = () => {
    return (
      <>
        <SimpleButton className="cancel-button" onClick={() => setEmailTemplatePreview(null)}>
          {t('buttons.cancel')}
        </SimpleButton>
      </>
    )
  }

  const onSelectInput = (isComponentVisible, toggleVisibility) => {
    if (toggleVisibility) {
      toggleVisibility(!isComponentVisible)
    }    
  }

  const selectEmailTemplate = (emailTemplate, toggleVisibility) => {
    setSelectedEmailTemplate(emailTemplate)

    if (toggleVisibility) {
      toggleVisibility(false)
    }
  }

  const getEmailTemplateKey = (template) => {
    if(template === 'cycle_fault'){
      return 'cycle_fault_notification'
    }
    return template
  }

  const getNotificationEmailTemplates = (templates, acceptedArr) => {
    if(!templates) return []
    return templates.filter(e => {
      if(acceptedArr.includes(e)) return true
      return false
    })
  }

  const selectEmailTemplateInput = ({toggleVisibility, isComponentVisible}) => (
    <div className={"select-input"}>
      <div 
        className="height d-flex flex-align-center flex-justify-between" 
        onClick={() => onSelectInput(isComponentVisible, toggleVisibility)}
      >
        <div className="d-flex flex-align-center h-100">
          {selectedEmailTemplate ? t([`filters.${getEmailTemplateKey(selectedEmailTemplate)}`, getEmailTemplateKey(selectedEmailTemplate)]) : t("events.select_email_template")}
        </div>
        <CaretDownIcon className={"mr-15 caret-dropdown-icon" + (isComponentVisible ? " icon-dropdown-open" : "")}/>
      </div>
    </div>
  )

  const selectEmailTemplateOptions = ({toggleVisibility}) => (
    <div className="options-wrapper d-flex flex-align-center flex-column">
      {getNotificationEmailTemplates(emailTemplates,acceptedTemplates).map((emailTemplate, index) => (
        <div 
          className={"option cursor-pointer no-wrap" + (emailTemplate === selectedEmailTemplate ? " selected-option" : "")} 
          key={index}
          onClick={() => selectEmailTemplate(emailTemplate, toggleVisibility)}
        >
          {t([`filters.${getEmailTemplateKey(emailTemplate)}`, getEmailTemplateKey(emailTemplate)])}
        </div>
      ))
      }
    </div>
  )

  const selectType = (type, toggleVisibility) => {
    setSelectedType(type)

    if (toggleVisibility) {
      toggleVisibility(false)
    }
  }

  const selectTypeInput = ({toggleVisibility, isComponentVisible}) => (
    <div className={"select-input"}>
      <div 
        className="height d-flex flex-align-center flex-justify-between" 
        onClick={() => onSelectInput(isComponentVisible, toggleVisibility)}
      >
        <div className="d-flex flex-align-center h-100">
          {selectedType ? t([`filters.${selectedType}`, selectedType]) : t("events.select_method")}
        </div>
        <CaretDownIcon className={"mr-15 caret-dropdown-icon" + (isComponentVisible ? " icon-dropdown-open" : "")}/>
      </div>
    </div>
  )

  const selectTypeOptions = ({toggleVisibility}) => (
    <div className="options-wrapper d-flex flex-align-center flex-column">
      {methods?.map((type, index) => (
        <div 
          className={"option cursor-pointer no-wrap" + (type === selectedType ? " selected-option" : "")} 
          key={index}
          onClick={() => selectType(type, toggleVisibility)}
        >
          {t([`filters.${type}`, type])}
        </div>
      ))
      }
    </div>
  )

  const selectGroupEvents = (groupEvent, toggleVisibility) => {
    const keyName = groupEvent.key.split('_').join(' ')
    const title = keyName.charAt(0).toUpperCase() + keyName.slice(1);
    setTitle(title)
    setSelectedGroupEvents(groupEvent)

    if (toggleVisibility) {
      toggleVisibility(false)
    }
  }

  const selectGroupEventsInput = ({toggleVisibility, isComponentVisible}) => (
    <div className={"select-input"}>
      <div 
        className="height d-flex flex-align-center flex-justify-between" 
        onClick={() => onSelectInput(isComponentVisible, toggleVisibility)}
      >
        <div className="d-flex flex-align-center h-100">
          {selectedGroupEvents ? t([`filters.${selectedGroupEvents.key}`, selectedGroupEvents.key]) : t("events.select_event_group")}
        </div>
        <CaretDownIcon className={"mr-15 caret-dropdown-icon" + (isComponentVisible ? " icon-dropdown-open" : "")}/>
      </div>
    </div>
  )

  const selectGroupEventsOptions = ({toggleVisibility}) => (
    <div className="options-wrapper d-flex flex-align-center flex-column">
      {UnusedGroupEvents?.map((groupEvent, index) => (
        <div 
          className={"option cursor-pointer no-wrap" + (groupEvent === selectedGroupEvents ? " selected-option" : "")} 
          key={index}
          onClick={() => selectGroupEvents(groupEvent, toggleVisibility)}
        >
          {t([`filters.${groupEvent.key}`, groupEvent.key])}
        </div>
      ))
      }
    </div>
  )

  const handlePreviewEmailTemplate = () => {
    if (selectedEmailTemplate && !isLoading) {
      showSpinner()
      getEmailTemplateContent(selectedEmailTemplate).then((data) => {
        setEmailTemplatePreview(data.HtmlPart)
        hideSpinner()
      })
      .catch(() => {
        toast.error(t("error.something_wrong"))
        hideSpinner()
      })      
    }    
  }

  const isValidInput = () => {
    let errors = {}
    let isValid = true

    if (!title.trim()) {
      errors = { ...errors, title: true }
      isValid = false
    }

    if (!selectedType) {
      errors = { ...errors, selectedType: true }
      isValid = false
    }

    if (!selectedGroupEvents) {
      errors = { ...errors, selectedGroupEvents: true }
      isValid = false
    }

    if (!selectedEmailTemplate) {
      errors = { ...errors, selectedEmailTemplate: true }
      isValid = false
    }

    setHaveError(errors)
    return isValid
  }

  const handleAddEditDeviceNotification = () => {
    if (!isValidInput()) {
      toast.dismiss()
      toast.error(t("error.fill_all_fields"))
      return
    }

    let bodyParams = {
      name: title.trim(),
      description: description.trim(),
      type: selectedType,
      allowSuppress: isUserEditable,
      emailTemplateName: selectedEmailTemplate,
      eventGroupId: selectedGroupEvents.id,
      isEnabled: isEnabled
    }
    if (selectedNotificationSetting) {
      props.actions.updateNotificationSetting(selectedNotificationSetting.id, bodyParams)
    } else {
      props.actions.createNotificationSetting(bodyParams)
    }

    closeModal()
  }

  return (
    <Fragment>
      <PageModal
          toggle
          onToggle={() => closeModal()}
          title={selectedNotificationSetting ? t("events.edit-notification-setting-title") : t("events.add-notification-setting")}
          headerContent={notificationHeaderContent()}
          footerContent={notificationFooterContent()}
        >
          <div className="notification-modal-content">
            <div className="dropdown-wrapper">
              <div className="device-event-wrapper">
                <p className="input-option">{t('events.notification-type')}*</p>
                {
                  !selectedNotificationSetting ? 
                  <ClickOutside 
                    itemRef="click-outside-wrapper"
                    className={haveError["selectedGroupEvents"] ? "has-error" : ""}
                    eventItem={selectGroupEventsInput}
                    toDisplayItem={selectGroupEventsOptions}
                  /> :
                  <input 
                    type="text" 
                    className="name-input name-input-plus"
                    value={title}
                    disabled
                  >
                  </input> 
                }
                
              </div>
            </div>
            <p className="input-option">{t('events.description')}</p>
            <textarea 
              className="description-input"
              value={description ?? ""}
              onChange={(e) => setDescription(e.target.value)}>            
            </textarea>
            <div className="edit-option-wrapper">
              <p className="input-edit-option">{t('events.user-editable')}</p>
              <ToggleSwitch checked={isUserEditable} onChange={() => setIsUserEditable((prevValue) => !prevValue)} />
              <p className="text-edit-option">
              {
                isUserEditable ? t('labels.yes') : t('labels.no')
              }
              </p>
            </div>
            <p className="edit-option-tooltip">{t("events.user-editable-message")}</p>
            <div className="dropdown-wrapper">
              <div className="type-wrapper">
                <p className="input-option">{t('events.delivery-method')}*</p>
                <ClickOutside 
                  itemRef="click-outside-wrapper"
                  className={haveError["selectedType"] ? "has-error" : ""}
                  eventItem={selectTypeInput}
                  toDisplayItem={selectTypeOptions}
                />
              </div>
            </div>
            <div className="dropdown-wrapper">
              <div className="email-template-wrapper">
                <p className="input-option">{t('events.email-template')}*</p>
                <ClickOutside 
                  itemRef="click-outside-wrapper"
                  className={haveError["selectedEmailTemplate"] ? "has-error" : ""}
                  eventItem={selectEmailTemplateInput}
                  toDisplayItem={selectEmailTemplateOptions}
                />
              </div>
              <div className="email-template-preview-button" onClick={() => handlePreviewEmailTemplate()}>
                <p className={"template-preview-tooltip" + (!selectedEmailTemplate ? " disabled" : "")}>
                  {t('events.preview-template')}
                </p>
              </div>
            </div>
          </div>
        </PageModal>
        {emailTemplatePreview && (
          <PageModal
            toggle
            onToggle={() => setEmailTemplatePreview(null)}
            title={t([`filters.${getEmailTemplateKey(selectedEmailTemplate)}`, getEmailTemplateKey(selectedEmailTemplate)])}
            footerContent={previewTemplateFooterContent()}
          >
            <div className="template-preview-modal-content">
              <div className="template-preview-content">
                <div dangerouslySetInnerHTML={{__html: emailTemplatePreview}}/>
              </div>
            </div>
          </PageModal>)
        }
    </Fragment>
  )
}

function stateToProps({ notifications }) {
  return {
    emailTemplates: notifications?.emailTemplates || [],
  }
}

function dispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        loadEmailTemplates,
        createNotificationSetting,
        updateNotificationSetting,
      },
      dispatch
    ),
  }
}

export default connect(stateToProps,dispatchToProps)(AddEditNotificationSetting)
