import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { useHistory, useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { formatStoreProductPrice } from "../../utils/filters/filters"
import { ColteneAddToCart, ColtenePlaceholderProduct } from "../../icons"
import { setBackNavigationState } from "../../actions/backNavigationState"
import { setInBasket } from "../../actions/colteneStore"

const ProductCard = (props) => {
  const history = useHistory()
  const location = useLocation()
  const { t } = useTranslation()
  const { product, price, setShowStoreBasket, isPreview, basket } = props

  const handleNavigation = (e) => {
    if (isPreview) {
      return
    }

    e.preventDefault()

    props.actions.setBackNavigationState({
      backNavPage: location.pathname,
      activeIndex: product.isDigital ? 1 : 0,
    })

    if (product.isDigital) {
      history.push({
        pathname: `/coltene-store/${product.id}`, 
        search: `?subscriptionId=${price?.id}`,
        state: {
          withBackNavigation: true,
        }
      })
    } else {
      history.push({
        pathname: `/coltene-store/${product.id}`,
        state: {
          withBackNavigation: true,
        }
      })
    }
  }

  const handleBuy = (e) => {
    e.stopPropagation()
    setShowStoreBasket()

    if (product.isDigital) {
      Object.values(basket).forEach((item) => {
        if (item.isDigital && item.id !== product.id) {
          props.actions.setInBasket(item, 0)
        }
      })

      const digitalProduct = {...product, prices: product.prices.map((p) => p.id === price.id ? {...p, isSelectedPlan: true} : {...p, isSelectedPlan: false})}
      props.actions.setInBasket(digitalProduct, 1)

      return
    }

    props.actions.setInBasket(product, basket[product.id]?.quantity ? basket[product.id].quantity + 1 : 1)
  }

  return (
    <div
      className="wrapper-item"
      onClick={(e) => handleNavigation(e)}
    >
      <div className="product-image-wrapper">
        {product.images?.length > 0 ? 
          <img alt="complex" src={product.images[0].url} /> 
        : 
          <ColtenePlaceholderProduct />
        }
      </div>
      <div className="product-info-wrapper">
        <div className="product-type">
          {!product.isDigital ? 
            t("coltene_store.product_details.physical_product") 
          : 
            t("coltene_store.product_details.subscription")
          }
        </div>
        <div className="product-name">
          {product.title} {price?.recurrenceInterval && <span>({price.recurrenceInterval})</span>}
        </div>
        {product.subtitle && 
          <div className="product-description">
            {product.subtitle}
          </div>
        }
        <div className="product-price-wrapper">
          <div className="prices-wrapper">
              <div
                key={"price-item" + price.id} 
                className={`product-price`}
              >
                {formatStoreProductPrice(price.amount, price.currencyCode, price.recurrenceInterval, price.recurrenceIntervalCount)}
              </div>
          </div>
          {!product.isSoldOut && !isPreview && product.prices &&
            <div
              className="btn-add-to-cart"
              onClick={(e) => handleBuy(e)}
            >
              <ColteneAddToCart />
            </div>
          }
        </div>
      </div>
    </div>
  )
}

function stateToProps({ colteneStore }) {
  return {
    basket: colteneStore?.basket || {},
  }
}

function dispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        setInBasket,
        setBackNavigationState,
      },
      dispatch
    ),
  }
}

export default connect(stateToProps, dispatchToProps)(ProductCard)

ProductCard.propTypes = {
  product: PropTypes.object.isRequired,
  numInBasket: PropTypes.object,
}
