import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { toast } from "react-toastify"
import BusinessRuleModal from "./BusinessRuleModal"
import LoadingSpinner from "../common/LoadingSpinner"
import FiltersModal, { FilterType } from "../common/FiltersModal"
import Breadcrumb from "../common/Breadcrumb"
import SearchWithFilter from "../common/SearchWithFilter"
import ConfirmationModal from "../common/ConfirmationModal"
import SimpleButton from "../common/SimpleButton"
import EmptyPageResults from "../common/EmptyPageResults"
import DashboardHeader from "../common/DashboardHeader/DashboardHeader"
import GenericTable from "../common/GenericTable"
import ExpandableText from "../common/ExpandableText"
import ActionsMenu from "../common/ActionsMenu"
import { useWindowSize } from "../../libs/hooks"
import { capitalizeFirstLetter } from "../../utils/functions"
import { useDeepCompareEffect } from '../../hooks/useDeepCompareEffect'
import { EnabledIcon, DisabledIcon, MenuKebabVerticalIcon, MenuKebabVerticalBackgroundIcon, CaretDownIcon } from "../../icons"
import { loadUsers } from "../../actions/users"
import { loadBusinessRules, loadBusinessRuleTypes, clearBusinessRulesMessages, changeBusinessRuleStatus, delBusinessRule } from "../../actions/businessRules"
import { loadAdminStoreProducts } from "../../actions/adminStore"
import { businessRulesDeletePermission, businessRulesWritePermission } from "../../utils/permissionValidation"
import ClickOutside from "../common/ClickOutside"
import "./business-rules.scss"

const orderingFields = [
  {
    field: "name",
    order: "asc",
    key: "name_asc",
  },
  {
    field: "name",
    order: "desc",
    key: "name_desc",
  },
  {
    field: "type",
    order: "asc",
    key: "type_asc",
  },
  {
    field: "type",
    order: "desc",
    key: "type_desc",
  },
  {
    field: "status",
    order: "asc",
    key: "status_asc",
  },
  {
    field: "status",
    order: "desc",
    key: "status_desc",
  },
]

const BusinessRules = (props) => {
  const { t } = useTranslation()
  const isMobile = useWindowSize()[0] <= 768
  const [businessRulesTableData, setBusinessRulesTableData] = useState([])
  const [filterQuery, setFilterQuery] = useState(null)
  const [selectedFilterTypes, setSelectedFilterTypes] = useState([])
  const [selectedFilterStatuses, setSelectedFilterStatuses] = useState([])
  const [shouldApplyFilters, setShouldApplyFilters] = useState(true)
  const [orderBy, setOrderBy] = useState({ field: "name", order: "desc", key: "name_desc" })
  const [canLoadMore, setCanLoadMore] = useState(false)
  const [selectedPage, setSelectedPage] = useState(1)
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const [showSpinner, setShowSpinner] = useState(false)
  const [showFiltersModal, setShowFiltersModal] = useState(false)
  const [showBusinessRuleModal, setShowBusinessRuleModal] = useState(false)
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false)
  const [businessRuleIdToDelete, setBusinessRuleIdToDelete] = useState(null)
  const [expandedBusinessRule, setExpandedBusinessRule] = useState(0)
  const [usersSearchParams, setUsersSearchParams] = useState("")
  const [usersOffset, setUsersOffset] = useState(0)
  const [productsSearchParams, setProductsSearchParams] = useState(null)
  const [productsSelectedPage, setProductsSelectedPage] = useState(1)
  const [editBusinessRule, setEditBusinessRule] = useState(null)
  const [showActionsMenu, setShowActionsMenu] = useState(null)
  
  useEffect(() => {
    if (props.businessRuleTypes.length === 0) {
      props.actions.loadBusinessRuleTypes()
    }
  }, [props.actions])

  useEffect(() => {
    const tableData = props.businessRules.map((businessRule) => {
      return {
        identifier: businessRule.id,
        name: businessRule.name,
        description: businessRule.description,
        type: capitalizeFirstLetter(businessRule.type).replace("_", " "),
        status: businessRule.isEnabled,
        actions: [
          {
            label: t("labels.edit"),
            onActionClick: (e) => { e.stopPropagation(); handleEditBusinessRule(businessRule.id) },
          },
          {
            label: businessRule.isEnabled ? t("labels.deactivate") : t("labels.activate"),
            onActionClick: (e) => { e.stopPropagation(); handleChangeStatusBusinessRule(businessRule.id, businessRule.isEnabled) },
            requiredPermission: businessRulesWritePermission,
            isDisabled: businessRule.isEnabled && (businessRule.products?.length > 0 || businessRule.value.users?.length > 0)
          },
          {
            label: t("labels.delete"),
            onActionClick: (e) => { e.stopPropagation(); showConfirmationModal(businessRule.id) },
            isDisabled: businessRule.isEnabled,
            requiredPermission: businessRulesDeletePermission
          },
        ]
      }
    })

    setBusinessRulesTableData(tableData)
  }, [props.businessRules])

  useEffect(() => {
    setCanLoadMore(props.canLoadMore)
  }, [props.canLoadMore])

  useEffect(() => {
    setShowSpinner(props.isLoading)
  }, [props.isLoading])

  useEffect(() => {
    let changeValueTimeout = window.setTimeout(
      () => {
        if (props.errorMessage) {
          toast.dismiss()
          toast.error(props.errorMessage)
    
          props.actions.clearBusinessRulesMessages()
        }
      },
      props.errorMessage ? 300 : 0
    )
    return () => {
      clearTimeout(changeValueTimeout)
    }
  }, [props.errorMessage])

  useEffect(() => {
    let changeValueTimeout = window.setTimeout(
      () => {
        if (props.successMessage) {
          toast.dismiss()
          toast.success(props.successMessage)
    
          props.actions.clearBusinessRulesMessages()
        }
      },
      props.successMessage ? 300 : 0
    )
    return () => {
      clearTimeout(changeValueTimeout)
    }
  }, [props.successMessage])

  useEffect(() => {
    if (selectedPage && selectedPage > 1) {
      handleLoadBusinessRules(false)
    }
  }, [selectedPage])

  useDeepCompareEffect(() => {
    let changeValueTimeout = window.setTimeout(
      () => {
        if (shouldApplyFilters) {
          setSelectedPage(1)
          handleLoadBusinessRules(true)
        }

        setShouldApplyFilters(true)
      },
      filterQuery ? 1000 : 0
    )

    return () => {
      clearTimeout(changeValueTimeout)
    }
  }, [filterQuery, selectedFilterStatuses, selectedFilterTypes, orderBy])

  const handleLoadBusinessRules = (withReset) => {
    let queryParams = {}

    if (filterQuery) {
      queryParams = { ...queryParams, query: filterQuery}
    }

    if (selectedFilterTypes.length === 1) {
      queryParams = { ...queryParams, type: selectedFilterTypes }
    }

    if (selectedFilterStatuses.length === 1) {
      const isActiveStatus = true

      queryParams = { ...queryParams, isEnabled: selectedFilterStatuses.indexOf("Inactive") >= 0 ? false : isActiveStatus }
    }
    
    if (orderBy?.field && orderBy?.order) {
      queryParams = { ...queryParams, orderBy: orderBy.field, order: orderBy.order }
    }

    queryParams = {
      ...queryParams,
      startPage: withReset ? 1 : selectedPage
    }

    props.actions.loadBusinessRules(queryParams, withReset)
      .then(() => setIsFirstLoad(false))
  }

  const handleSearch = (value) => {
    if (value?.length > 2) {
      setShowSpinner(true)
      setFilterQuery(value)
    } else if (value?.length === 0 && filterQuery?.length > 0) {
      setShowSpinner(true)
      setFilterQuery(null)
    }
  }

  const handleLoadMore = () => {
    setSelectedPage((prevValue) => (prevValue ? prevValue + 1 : 2))
  }

  const handleSort = (orderingField) => {
    setOrderBy(orderingField)
  }

  useEffect(() => {
    let queryParams = {
      offset: usersOffset
    }

    if (usersSearchParams.length > 0) {
      queryParams = { ...queryParams, query: usersSearchParams}
    }

    let changeValueTimeout = window.setTimeout(
      () => {
        props.actions.loadUsers(queryParams, true)
        setUsersOffset(0)
      },
      usersSearchParams ? 1000 : 0
    )

    return () => {
      clearTimeout(changeValueTimeout)
    }
  }, [usersSearchParams])

  useEffect(() => {
    if (usersOffset && usersOffset > 10) {
      let queryParams = {
        offset: usersOffset,
      }

      if (usersSearchParams.length > 0) {
        queryParams = { ...queryParams, query: usersSearchParams}
      }

      props.actions.loadUsers(queryParams)
    }
  }, [usersOffset])

  const handleLoadMoreUsers = () => {
    setUsersOffset((prevVal) => (prevVal ? prevVal + 10 : 20))
  }

  const handleSearchUsers = (searchString) => {
    setUsersSearchParams(searchString)
  }

  useDeepCompareEffect(() => {
    let changeValueTimeout = window.setTimeout(
      () => {
        setProductsSelectedPage(1)
        handleLoadStoreProducts(true)
      },
      productsSearchParams ? 1000 : 0
    )
    return () => {
      clearTimeout(changeValueTimeout)
    }
  }, [productsSearchParams])

  useEffect(() => {
    if (productsSelectedPage > 1) {
      handleLoadStoreProducts(false)
    }
  }, [productsSelectedPage])

  const handleSearchProducts = (value) => {
    if (value?.length > 2) {
      setShowSpinner(true)
      setProductsSearchParams(value)
    } else if (value?.length === 0 && productsSearchParams?.length > 0) {
      setShowSpinner(true)
      setProductsSearchParams(null)
    }
  }

  const handleLoadMoreProducts = () => {
    setProductsSelectedPage((prevValue) => (prevValue ? prevValue + 1 : 2))
  }

  const handleLoadStoreProducts = (withReset) => {
    let queryParams = { 
      startPage: withReset ? 1 : productsSelectedPage,
      digital: true
    }

    if (productsSearchParams) {
      queryParams = { ...queryParams, title: productsSearchParams }
    }

    props.actions.loadAdminStoreProducts(queryParams, withReset)
  }

  const handleChangeStatusBusinessRule = (id, isEnabled) => {
    props.actions.changeBusinessRuleStatus(id, isEnabled ? "disable" : "enable", true)
  }

  const showConfirmationModal = (id) => {
    setBusinessRuleIdToDelete(id)
    setShowDeleteConfirmationModal(true)
  }

  const handleDeleteBusinessRule = (id) => {
    setShowDeleteConfirmationModal(false)
    props.actions.delBusinessRule(id)
  }

  const handleEditBusinessRule = (businessRuleId) => {
    const businessRule = props.businessRules.find((rule) => rule.id === businessRuleId)
    
    if (businessRule) {
      setEditBusinessRule(businessRule)
      setShowBusinessRuleModal(true)
    }
  }

  const handleCloseModal = () => {
    setShowBusinessRuleModal((prevVal) => !prevVal)
    setEditBusinessRule(null)
    setUsersOffset(0)
    setUsersSearchParams("")
  }

  const handleClearFilters = () => {
    setSelectedFilterStatuses([])
    setSelectedFilterTypes([])
  }

  const onSelectInput = (isComponentVisible, toggleVisibility) => {
    if (toggleVisibility) {
      toggleVisibility(!isComponentVisible)
    }
  }

  const selectOrderByOptions = (orderingField, toggleVisibility) => {
    handleSort(orderingField)

    if (toggleVisibility) {
      toggleVisibility(false)
    }
  }

  const selectOrderByOptionsInput = ({ toggleVisibility, isComponentVisible }) => (
    <div className={"select-input"}>
      <div className="height d-flex flex-align-center flex-justify-between" onClick={() => onSelectInput(isComponentVisible, toggleVisibility)}>
        <div className="d-flex flex-align-center h-100">{orderBy ? t(`business-rules.${orderBy.key}`) : t("business-rules.select_ordering")}</div>
        <CaretDownIcon className={"mr-15 caret-dropdown-icon" + (isComponentVisible ? " icon-dropdown-open" : "")} />
      </div>
    </div>
  )

  const selectOrderByDropdownOptions = ({ toggleVisibility }) => (
    <div className="options-wrapper d-flex flex-align-center flex-column">
      {orderingFields?.map((orderingField, index) => (
        <div
          className={"option cursor-pointer no-wrap" + (orderingField === orderBy ? " selected-option" : "")}
          key={index}
          onClick={() => selectOrderByOptions(orderingField, toggleVisibility)}
        >
          {t(`business-rules.${orderingField.key}`)}
        </div>
      ))}
    </div>
  )

  const toggleShowActionsMenu = (identifier) => {
    setShowActionsMenu(showActionsMenu === identifier ? null : identifier)
  }

  return (
    <div className="business-rules-wrapper">
      <Breadcrumb path={window.location.pathname} />
      <DashboardHeader
        headerText={t("business-rules.business_rules")}
      >
        <SimpleButton 
          className="submit-button" 
          onClick={() => setShowBusinessRuleModal((prevVal) => !prevVal)}
        >
          {t("business-rules.add_new_rule")}
        </SimpleButton>
      </DashboardHeader>
      <div className="separator" />
      <div className="actions-wrapper">
        <SearchWithFilter
          onSearch={handleSearch}
          showFiltersModal={() => setShowFiltersModal((prevVal) => !prevVal)}
          onResetFilters={handleClearFilters}
          showCounterBadge={selectedFilterStatuses.length > 0 || selectedFilterTypes.length > 0}
          counterBadge={selectedFilterStatuses.length + selectedFilterTypes.length} 
        />
        {isMobile && 
          <div className="order-by-mobile-wrapper">
            <ClickOutside 
              itemRef="click-outside-wrapper"
              eventItem={selectOrderByOptionsInput}
              toDisplayItem={selectOrderByDropdownOptions}
            />
          </div>
        }
      </div>
      {!isMobile && businessRulesTableData.length > 0 &&
        <GenericTable
          data={businessRulesTableData}
          headers={[
            {
              title: t('business-rules.name'),
              orderKey: 'name'
            },
            {
              title: t('business-rules.description')
            },
            {
              title: t('business-rules.type'),
              orderKey: 'type'
            },
            {
              title: t('business-rules.status'),
              orderKey: 'status'
            },
            {
              title: ''
            },
          ]}
          keys={[
            'name',
            'description',
            'type',
            'status',
            'action',
          ]}
          keyRenderer={{
            status: (item) => {
              return (
                <div className="status-wrapper">
                  {item.status ?
                    <div className="status-active">
                      <EnabledIcon /> 
                      <p>{t("business-rules.active")}</p>
                    </div>
                  : 
                    <div className="status-inactive">
                      <DisabledIcon /> 
                      <p>{t("business-rules.inactive")}</p>
                    </div>
                  }
                </div>
              )
            },
            action: (item) => {
              return (
                <div className="table-actions-wrapper" onClick={(e) => { e.stopPropagation(); toggleShowActionsMenu(item["identifier"]) }}>
                  {showActionsMenu === item["identifier"] ? <MenuKebabVerticalBackgroundIcon /> : <MenuKebabVerticalIcon />}
                  {showActionsMenu === item["identifier"] &&
                    <ActionsMenu
                      setHideMenu={() => setShowActionsMenu(null)}
                      actions={item.actions}
                      isLoading={showSpinner}
                    />
                  }
                </div>
              )
            }
          }}
          onRowClick={(businessRule) => handleEditBusinessRule(businessRule.identifier)}
          activeSort={orderBy}
          onSort={(orderingField) => handleSort(orderingField)}
          isLoading={showSpinner}
        />
      }
      {isMobile && businessRulesTableData.map((businessRule, index) =>
        <div 
          key={index}
          className="card"
          onClick={() => setExpandedBusinessRule(index)}
        >
          <div className={"card-item" + (expandedBusinessRule !== index ? " align-center" : "")}>
            <div className="card-item-title">{t('business-rules.name')}</div>
            <div className={"card-item-body" + (expandedBusinessRule !== index ? " align-center" : "")}>
              <div>{businessRule.name}</div>
              <div 
                className="card-actions"
                onClick={(e) => { e.stopPropagation(); setShowActionsMenu(index) }}
              >
                {showActionsMenu === index ? <MenuKebabVerticalBackgroundIcon /> : <MenuKebabVerticalIcon />}
                {showActionsMenu === index &&
                  <ActionsMenu
                    setHideMenu={() => setShowActionsMenu(null)}
                    actions={businessRule.actions}
                    isLoading={showSpinner}
                  />
                }
              </div>
            </div>
          </div>
          {expandedBusinessRule === index && (
            <>
              <div className="card-item">
                <div className="card-item-title">{t('business-rules.description')}</div>
                <div className="card-item-body">{businessRule.description}</div>
              </div>
              <div className="card-item">
                <div className="card-item-title">{t('business-rules.type')}</div>
                <div className="card-item-body">{businessRule.type}</div>
              </div>
              <div className="card-item">
                <div className="card-item-title">{t('business-rules.status')}</div>
                <div className="card-item-body">
                  <div className="card-item-body-status">
                    {businessRule.status ? 
                      <div className="status-active">
                        <EnabledIcon /> 
                        <p>{t("business-rules.active")}</p>
                      </div>
                    : 
                      <div className="status-inactive">
                        <DisabledIcon /> 
                        <p>{t("business-rules.inactive")}</p>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </>
          )}
        </div>)
      }
      {!showSpinner && !isFirstLoad && businessRulesTableData.length === 0 &&  (
        <EmptyPageResults 
          title={t("business-rules.no_business_rules")} 
          subtitle={t("business-rules.no_business_rules_tips")} 
        />
      )}
      {canLoadMore && (
        <div className="buttons-wrapper">
          <SimpleButton className="load-more-button" onClick={() => handleLoadMore()}>
            {t('buttons.load_more')}
          </SimpleButton>
        </div>)
      }
      {showBusinessRuleModal && (
        <BusinessRuleModal
          handleClose={() => handleCloseModal()}
          businessRule={editBusinessRule}
          searchUsers={handleSearchUsers}
          loadMoreUsers={handleLoadMoreUsers}
          searchProducts={handleSearchProducts}
          loadMoreProducts={handleLoadMoreProducts}
        />
      )}
      {showFiltersModal && (
        <FiltersModal
          filters={[
            {
              name: t("business-rules.type"),
              type: FilterType.multiSelect,
              dataset: props.businessRuleTypes,
              input: selectedFilterTypes,
              output: (filterBusinessRulesTypes) => {
                setSelectedFilterTypes(filterBusinessRulesTypes)
              },
            },
            {
              name: t("business-rules.status"),
              type: FilterType.multiSelect,
              dataset: ["Active", "Inactive"],
              input: selectedFilterStatuses,
              output: (filterBusinessRulesStatuses) => {
                setSelectedFilterStatuses(filterBusinessRulesStatuses)
              },
            },
          ]}
          shouldFilter={(shouldApply) => setShouldApplyFilters(shouldApply)}
          closeFilters={() => setShowFiltersModal(false)}
          resetFilters={() => {
            setSelectedFilterStatuses([])
            setSelectedFilterTypes([])
          }}
        />
      )}
      {showDeleteConfirmationModal && (
        <ConfirmationModal
          onToggle={() => setShowDeleteConfirmationModal(false)}
          message={t("business-rules.delete_confirmation")}
          acceptButtonText={t("labels.delete")}
          onCancel={() => setShowDeleteConfirmationModal(false)}
          onAccept={() => handleDeleteBusinessRule(businessRuleIdToDelete)}
          isWarning
          requiredPermission={businessRulesDeletePermission}
        />
      )}
      {showSpinner && (
        <div className={"spinner-wrapper"}>
          <LoadingSpinner />
        </div>
      )}
    </div>
  )
}

function stateToProps({ businessRules, users, adminStore }) {
  return {
    businessRules: businessRules?.busRules || [],
    businessRuleTypes: businessRules?.busRuleTypes || [],
    isLoading: businessRules?.busRulesLoading || businessRules?.busRuleTypeLoading || businessRules?.busRuleLoading || users?.usersLoading || adminStore?.productsLoading,
    canLoadMore: businessRules?.canLoadMore,
    errorMessage: businessRules?.errorMessage,
    successMessage: businessRules?.successMessage,
  }
}

function dispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        loadBusinessRules,
        loadUsers,
        loadBusinessRuleTypes,
        loadAdminStoreProducts,
        changeBusinessRuleStatus,
        delBusinessRule,
        clearBusinessRulesMessages,
      },
      dispatch
    ),
  }
}

export default connect(stateToProps, dispatchToProps)(BusinessRules)
