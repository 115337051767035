import { DownloadModernIcon, PdfModernIcon } from "../../icons"
import { convertTimeWithTAndZ } from "../../utils/filters/date"
import ExpandableText from "../common/ExpandableText"

export function getHeaders(t) {
  return [
    {
      title: "",
    },
    {
      title: t("dealer-portal.title"),
      orderKey: "documentTitle",
    },
    {
      title: t("dealer-portal.description"),
      orderKey: "bulletinType",
    },
    {
      title: t("dealer-portal.updated"),
      orderKey: "createdAt",
    }
  ]
}

export const dealerBulletinsTableKeys = [
  "download",
  "documentTitle",
  "bulletinType",
  "createdAt",
]

export const dealerBulletinsRenderer = ({ selectedItems, handleSelectReport, handleDownload }) => {
  return {
    download: (item) => {
      return (
        <button className="download" onClick={(e) => {
          e.stopPropagation()
          handleDownload(item.filePath, item.fileName)
        }}>
          <DownloadModernIcon />
        </button>
      )
    },
    documentTitle: (item) => {
      return (
        <div className="title-wrapper">
          <PdfModernIcon />
          <ExpandableText text={item.documentTitle} />
        </div>
      )
    },
    bulletinType: (item) => {
      return (
        <div className="description-wrapper">
          <ExpandableText text={item.bulletinType} />
        </div>
      )
    },
    createdAt: (item) => {
      return (
        <div className="updated-wrapper">
          <ExpandableText text={convertTimeWithTAndZ(item.createdAt)} />
        </div>
      )
    },
  }
}