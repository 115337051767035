import React, { useEffect, useState, Fragment } from "react"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import { useHistory } from "react-router-dom"
import LinkCard from "./LinkCard"
import Breadcrumb from "../common/Breadcrumb"
import SimpleButton from "../common/SimpleButton"
import SearchWithFilter from '../common/SearchWithFilter'
import LoadingSpinner from "../common/LoadingSpinner"
import VideoModal from '../common/VideoModal'
import { getVideosList } from '../../utils/requests/videosAPI'
import { currentDay } from "../../utils/filters/date"
import { VideoPlayIcon } from "../../icons"
import { useWindowSize } from "../../libs/hooks"
import { toast } from "react-toastify"
import { alphanumericValidationSchema } from "../../utils/formValidation"

function ColteneUserDashboard(props) {
  const { t } = useTranslation()
  const isMobile = useWindowSize()[0] <= 768
  const history = useHistory()
  const { linkCards } = props
  const [userPermissions, setUserPermissions] = useState([])
  const [searchedSerialNumber, setSearchedSerialNumber] = useState('')
  const [videos, setVideos] = useState([])
  const [selectedVideo, setSelectedVideo] = useState(null)
  const [showSpinner, setShowSpinner] = useState(null) 
  
  useEffect(() => { 
    toast.dismiss()
  },[])

  useEffect(() => {
    const permissionArr = props.userPermissions ? (Array.isArray(props.userPermissions) ? props.userPermissions : Object.values(props.userPermissions)) : []
    setUserPermissions(permissionArr)
  }, [props.userPermissions])

  useEffect(() => {
    const getVideos = async () => {
      setShowSpinner(true)

      const videosQueryParams = {
        page: 1,
        limit: 4
      }

      try {
        const videosData = await getVideosList(videosQueryParams)

        if (videosData.data) {
          setVideos(videosData.data)
        }

        setShowSpinner(false)
      } catch {
        setShowSpinner(false)
      }
    }

    getVideos()
  },[props.authedUser])

  const handleSearch = (value) => {
    setSearchedSerialNumber(value)
  }
  
  const handleViewProduct = async() => {
    toast.dismiss()
    const alphanumericSchema = alphanumericValidationSchema()
    
    try {
      await alphanumericSchema.validate(searchedSerialNumber)
      history.push(`/products/${searchedSerialNumber.toUpperCase()}`)
    } catch {
      toast.error(t("error.invalid_serial_number"));
    }
  }

  const openVideoModal = (item) => {
    setSelectedVideo(item)
  }

  const closeVideoModal = () => {
    setSelectedVideo(null)
  }

  return (
    <div className="coltene-user-dashboard-wrapper">
      <Breadcrumb path={window.location.pathname} />
      <div className={`dashboard-title d-flex ${isMobile ? "flex-column" : "flex-row"} flex-justify-between`}>
        <h1>{t("dashboard.welcome", { firstName: props.authedUser?.firstname, lastName: props.authedUser?.lastname })}</h1>
        <div>{currentDay()}</div>
      </div>
      <div className="search-product-wrapper">
        <SearchWithFilter
          onSearch={(e) => handleSearch(e)}
          placeholder={t("dashboard.serial_number")}
          onKeyDown={(e) => e.key === "Enter" && handleViewProduct()}
        />
        <SimpleButton
          className="submit-button"
          disabled={searchedSerialNumber.length < 6}
          onClick={() => handleViewProduct()}
        >
          {t("dashboard.view_product")}
        </SimpleButton>
      </div>
      <div className={`dashboard-content d-flex ${isMobile ? "flex-column" : "flex-row"} flex-justify-between`}>
        <div className={`links-wrapper d-flex ${isMobile ? "flex-column" : "flex-row"}`}>
          {linkCards.map((card, index) => 
            <Fragment key={index}>
              {userPermissions.includes(card.permission) &&
                <LinkCard cardContent={card} userPermissions={userPermissions} />
              }
            </Fragment>)
          }
        </div>
      </div>
      <div className="videos-content d-flex flex-column">
        <div className="section-title">{t("products.videos")}</div>
        <div className="videos-wrapper">
          {videos.map((video, index) => (
            <div
              key={index}
              className="video-item-wrapper"
              onClick = {() => openVideoModal(video)}
            >
              <div className="thumbnail-wrapper">
                <div className="thumbnail-icon">
                  <VideoPlayIcon />
                </div>
                <img
                  src={video.thumbnail_url}
                  alt="video"
                />
              </div>                
              <p className="title">{video.title}</p>
            </div>))
          }
        </div>               
      </div>
      {selectedVideo &&
        <VideoModal
          title={selectedVideo.title}
          videoId={selectedVideo.video_id}
          onCloseVideo={closeVideoModal}
        />
      }
      {showSpinner &&
        <div className="spinner-wrapper">
          <LoadingSpinner />
        </div>
      }
    </div>
  )
}

function mapStateToProps({ authedUser, userPermissions }) {
  return {
    authedUser,
    userPermissions
  }
}

export default connect(mapStateToProps)(ColteneUserDashboard)
