import { useEffect, useRef, useState } from "react"
import { CaretDownIcon } from "../../../icons"
import "./select-menu.scss"
import { useOnClickOutside } from "../../../hooks/useOnClickOutside"

export function SelectMenu({ options, initialSelectedKey, onChange }) {
  const [isSelectMenuOpen, setIsSelectMenuOpen] = useState(false)
  const [selectedKey, setSelectedKey] = useState(initialSelectedKey)
  const [optionsState, setOptionsState] = useState(options.sort((a,b) => a.value - b.value))
  const ref = useRef()
  
  useOnClickOutside(ref, () => { 
    setIsSelectMenuOpen(false)
  })

  return (
    <div className="select-menu" ref={ref}>
      <button className="selected-option" onClick={() => setIsSelectMenuOpen(!isSelectMenuOpen)}>
        <div>{optionsState.find((o) => o.key === selectedKey)?.value}</div>
        <CaretDownIcon className={"mr-15 caret-dropdown-icon" + (isSelectMenuOpen ? " icon-dropdown-open" : "")} />
      </button>
      {isSelectMenuOpen && (
        <div className="options">
          {optionsState.map((option) => {
            return (
              <button
                key={option.key}
                className="option"
                onClick={() => {
                  setSelectedKey(option.key)
                  setIsSelectMenuOpen(false)
                  if (onChange) {
                    onChange(option)
                  }
                }}
              >
                {option.value}
              </button>
            )
          })}
        </div>
      )}
    </div>
  )
}
