import React, { Fragment, useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import {toast} from "react-toastify"
import AddEditNotificationMessage from './AddEditNotificationMessage'
import LoadingSpinner from "../common/LoadingSpinner"
import SearchWithFilter from "../common/SearchWithFilter"
import FiltersModal, { FilterType } from '../common/FiltersModal'
import ConfirmationModal from '../common/ConfirmationModal'
import ClickOutside from "../common/ClickOutside"
import SimpleButton from '../common/SimpleButton'
import EmptyPageResults from '../common/EmptyPageResults'
import GenericTable from "../common/GenericTable"
import ActionsMenu from "../common/ActionsMenu"
import ExpandableText from "../common/ExpandableText"
import {useWindowSize} from "../../libs/hooks"
import {
  DisabledIcon,
  EnabledIcon,
  CaretDownIcon,
  MenuKebabVerticalIcon,
  MenuKebabVerticalBackgroundIcon 
} from "../../icons"
import {
  loadNotificationMessages,
  loadNotificationSettings,
  loadEventGroups,
  loadNotificationDeliveryMethods,
  loadNotificationRegions,
  partiallyUpdateNotificationMsg,
  removeNotificationMsg,
  clearNotificationsMessages 
} from "../../actions/notifications"

const orderingFields = [
  {
    field: "name",
    order: "asc",
    key: "name_asc"
  },
  {
    field: "name",
    order: "desc",
    key: "name_desc"
  },
  {
    field: "type",
    order: "asc",
    key: "type_asc"
  },
  {
    field: "type",
    order: "desc",
    key: "type_desc"
  },
  {
    field: "eventGroup",
    order: "asc",
    key: "eventGroup_asc"
  },
  {
    field: "eventGroup",
    order: "desc",
    key: "eventGroup_desc"
  },
  {
    field: "status",
    order: "asc",
    key: "status_asc"
  },
  {
    field: "status",
    order: "desc",
    key: "status_desc"
  },
]

const NotificationMessages = (props) => {
  const { t } = useTranslation();
  const isMobile = useWindowSize()[0] <= 768
  const statuses = [ "active", "inactive" ]
  const [msgsTableData, setMsgsTableData] = useState([])
  const [selectedMsg, setSelectedMsg] = useState(null)
  const [groupEvents, setGroupEvents] = useState([])
  const [notificationSettings, setNotificationSettings] = useState([])
  const [selectedFilterTypes, setSelectedFilterTypes] = useState([])
  const [selectedFilterGroupEvents, setSelectedFilterGroupEvents] = useState([])
  const [selectedFilterStatus, setSelectedFilterStatus] = useState(null)
  const [filterQuery, setFilterQuery] = useState(null)
  const [orderBy, setOrderBy] = useState(null)
  const [showSpinner, setShowSpinner] = useState(null)
  const [showFiltersModal, setShowFiltersModal] = useState(false)
  const [showAddEditNotificationModal, setShowAddEditNotificationModal] = useState(false)
  const [canLoadMore, setCanLoadMore] = useState(false)
  const [selectedPage, setSelectedPage] = useState(1)
  const [showDeleteNotificationConfirmModal, setShowDeleteNotificationConfirmModal] = useState(false)
  const [msgIdToDelete, setMsgIdToDelete] = useState(null)
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const [expandedDeviceNotification, setExpandedDeviceNotification] = useState(0)
  const [showActionsMenu, setShowActionsMenu] = useState(null)

  useEffect(() => {
    if (props.eventGroups.length === 0) {
      props.actions.loadEventGroups()
    }
    
    if (props.deliveryMethods.length === 0) {
      props.actions.loadNotificationDeliveryMethods()
    }
    
    if (props.notificationSettings.length === 0) {
      props.actions.loadNotificationSettings()
    }
    
    if (props.notificationRegions.length === 0) {
      props.actions.loadNotificationRegions()
    }
  }, [props.actions])

  useEffect(() => {
    const tableData = props.notificationMessages.map((msg) => {
      return {
        identifier: msg.id,
        title: msg.title,
        type: t([`filters.${getNotificationSettingNameById(msg.notificationId)}`, getNotificationSettingNameById(msg.notificationId)]),
        deliveryMethod: t([`filters.${msg.deliveryMethod}`, msg.deliveryMethod]),
        status: msg.isEnabled,
        actions: [
          {
            label: t("labels.edit"),
            onActionClick: (e) => { e.stopPropagation(); handleOpenEditMsg(msg.id) },
          },
          {
            label: msg.isEnabled ? t("labels.deactivate") : t("labels.activate"),
            onActionClick: (e) => { e.stopPropagation(); handleChangeMsgStatus(msg.id, msg.isEnabled) },
          },
          {
            label: t("labels.delete"),
            onActionClick: (e) => { e.stopPropagation(); showConfirmationModal(msg.id) },
            isDisabled: msg.isEnabled,
          },
        ]
      }
    })

    setMsgsTableData(tableData)
  }, [props.notificationMessages, props.eventGroups])

  useEffect(() => {
    setGroupEvents(props.eventGroups)
  }, [props.eventGroups])

  useEffect(() => {
    setNotificationSettings(props.notificationSettings)
  }, [props.notificationSettings])

  useEffect(() => {
    setCanLoadMore(props.canLoadMore)
  }, [props.canLoadMore])

  useEffect(() => {
    if (props.errorMessage) {
      toast.dismiss()
      toast.error(props.errorMessage)

      props.actions.clearNotificationsMessages()
    }    
  }, [props.errorMessage])

  useEffect(() => {
    if (props.successMessage) {
      toast.dismiss()
      toast.success(props.successMessage)

      props.actions.clearNotificationsMessages()
    }    
  }, [props.successMessage])

  useEffect(() => {
    if (selectedPage && selectedPage > 1) {
      handleLoadNotificationMessages(false)
    }
  }, [selectedPage])

  useEffect(() => {
    let changeValueTimeout = window.setTimeout(
      () => {
        setSelectedPage(1)
        handleLoadNotificationMessages(true)
      },
      filterQuery ? 1000 : 0
    )
    return () => {
      clearTimeout(changeValueTimeout)
    }    
  },[filterQuery, selectedFilterTypes, selectedFilterGroupEvents, selectedFilterStatus, orderBy])

  useEffect(() => {
    setShowSpinner(props.isLoading)
  }, [props.isLoading])

  const handleLoadNotificationMessages = (withReset) => {
    let queryParams = {
      startPage: withReset ? 1 : selectedPage
    }

    if (filterQuery) {
      queryParams = { ...queryParams, query: filterQuery}
    }

    if (selectedFilterTypes.length > 0) { 
      queryParams = { ...queryParams, type: selectedFilterTypes}
    }

     if (selectedFilterGroupEvents.length > 0) { 
      const filterGroupEvents = groupEvents.filter((e) => selectedFilterGroupEvents.includes(e.key))
      queryParams = { ...queryParams, eventGroupId: filterGroupEvents.map((deviceEvent) => deviceEvent.id)}
    }

     if (selectedFilterStatus) { 
      queryParams = { ...queryParams, isEnabled: selectedFilterStatus === "active"}
    }

    if (orderBy?.field && orderBy?.order) {
      queryParams = { ...queryParams, orderBy: orderBy.field, order: orderBy.order}
    }

    props.actions.loadNotificationMessages(queryParams, withReset)
      .then(() => setIsFirstLoad(false))
  }

  const handleSearch = (value) => {
    if (value?.length > 2) {
      setShowSpinner(true)
      setFilterQuery(value)
    } else if (value?.length === 0 && filterQuery?.length > 0) {
      setShowSpinner(true)
      setFilterQuery(null)
    }
  }

  const handleSort = (orderingField) => {
    setOrderBy(orderingField)
  }

  const handleLoadMore = () => {
    setSelectedPage((prevValue) => (prevValue ? prevValue + 1 : 2))
  }

  const handleResetFilters = () => {
    setSelectedFilterTypes([])
    setSelectedFilterGroupEvents([])
    setSelectedFilterStatus(null)
  }

  const onSelectInput = (isComponentVisible, toggleVisibility) => {
    if (toggleVisibility) {
      toggleVisibility(!isComponentVisible)
    }    
  }

  const selectOrderByOptions = (orderingField, toggleVisibility) => {
    handleSort(orderingField)

    if (toggleVisibility) {
      toggleVisibility(false)
    }
  }

  const selectOrderByOptionsInput = ({toggleVisibility, isComponentVisible}) => (
    <div className={"select-input"}>
      <div 
        className="height d-flex flex-align-center flex-justify-between" 
        onClick={() => onSelectInput(isComponentVisible, toggleVisibility)}
      >
        <div className="d-flex flex-align-center h-100">
          {orderBy ? t(`events.${orderBy.key}`) : t("events.select_ordering")}
        </div>
        <CaretDownIcon className={"mr-15 caret-dropdown-icon" + (isComponentVisible ? " icon-dropdown-open" : "")}/>
      </div>
    </div>
  )

  const selectOrderByDropdownOptions = ({toggleVisibility}) => (
    <div className="options-wrapper d-flex flex-align-center flex-column">
      {orderingFields?.map((orderingField, index) => (
        <div 
          className={"option cursor-pointer no-wrap" + (orderingField === orderBy ? " selected-option" : "")} 
          key={index}
          onClick={() => selectOrderByOptions(orderingField, toggleVisibility)}
        >
          {t(`events.${orderingField.key}`)}
        </div>
      ))
      }
    </div>
  )

  const getNotificationSettingNameById = (id) => {
    const ns = notificationSettings?.find(e => e.id === id)
    return ns?.name ?? ""
  }

  const handleOpenEditMsg = (id) => {
    const msg = props.notificationMessages.find((e) => e.id === id)
    if (msg) {
      setSelectedMsg(msg)
      setShowAddEditNotificationModal(true)
    }
  }

  const handleChangeMsgStatus = (id, isEnabled) => {
    props.actions.partiallyUpdateNotificationMsg(id, { isEnabled :!isEnabled })
  }

  const showConfirmationModal = (id) => {
    setMsgIdToDelete(id)
    setShowDeleteNotificationConfirmModal(true)
  }

  const handleDeleteDeviceNotification = () => {
    setShowDeleteNotificationConfirmModal(false)
    props.actions.removeNotificationMsg(msgIdToDelete)
  }

  const toggleShowActionsMenu = (identifier) => {
    setShowActionsMenu(showActionsMenu === identifier ? null : identifier)
  }

  return (
    <Fragment>
      <div className="device-notifications-content-wrapper">
        {/*Searching and filtering functionality remains hidden until further notice */}
        {/* <div className="actions-wrapper d-flex flex-align-center">
          <SearchWithFilter
            onSearch={(e) => handleSearch(e)}
            showFiltersModal={() => setShowFiltersModal(true)}
            onResetFilters={() => handleResetFilters()}
            showCounterBadge={selectedFilterTypes.length > 0 || selectedFilterGroupEvents.length > 0 || selectedFilterStatus}
            counterBadge={selectedFilterTypes.length + selectedFilterGroupEvents.length + (selectedFilterStatus && 1)}
          />
          *Ordering functionality remains hidden until further notice
          <div className="order-by-mobile-wrapper">
            <ClickOutside 
              itemRef="click-outside-wrapper"
              eventItem={selectOrderByOptionsInput}
              toDisplayItem={selectOrderByDropdownOptions}
            />
          </div>
        </div> */}
        <div className="add-notification-button">
          <SimpleButton className="submit-button" onClick={() => setShowAddEditNotificationModal(true)}>
            {t('events.add-notification-message')}
          </SimpleButton>
        </div>
        {!isMobile && msgsTableData.length > 0 &&
          <GenericTable
            className="device-notifications-table"
            data={msgsTableData}
            headers={[
              {
                title: t('events.title')
              },
              {
                title: t('events.type')
              },
              {
                title: t('events.delivery-method')
              },
              {
                title: t('events.status')
              },
              {
                title: ''
              },
            ]}
            keys={[
              'title',
              'type',
              'deliveryMethod',
              'status',
              'action',
            ]}
            keyRenderer={{
              description: (item) => {
                return (
                  <div className="description-wrapper">
                    <ExpandableText text={item.description} />
                  </div>
                )
              },
              status: (item) => {
                return (
                  <div className="status-wrapper">
                    {item.status ?
                      <div className="status-active">
                        <EnabledIcon /> 
                        <p>{t("events.active")}</p>
                      </div>
                    : 
                      <div className="status-inactive">
                        <DisabledIcon /> 
                        <p>{t("events.inactive")}</p>
                      </div>
                    }
                  </div>
                )
              },
              action: (item) => {
                return (
                  <div className="table-actions-wrapper" onClick={(e) => { e.stopPropagation(); toggleShowActionsMenu(item["identifier"]) }}>
                    {showActionsMenu === item["identifier"] ? <MenuKebabVerticalBackgroundIcon /> : <MenuKebabVerticalIcon />}
                    {showActionsMenu === item["identifier"] &&
                      <ActionsMenu
                        setHideMenu={() => setShowActionsMenu(null)}
                        actions={item.actions}
                        isLoading={showSpinner}
                      />
                    }
                  </div>
                )
              }
            }}
            onRowClick={(msgsTableData) => handleOpenEditMsg(msgsTableData.identifier)}
          />
        }
        {isMobile && msgsTableData.map((e, index) =>
          <div 
            key={index}
            className={"card" + (index === 0 ? " with-margin-top" : "")} 
            onClick={() => setExpandedDeviceNotification(index)}
          >
            <div className={"card-item" + (expandedDeviceNotification !== index ? " align-center" : "")}>
              <div className="card-item-title">{t('events.title')}</div>
              <div className={"card-item-body" + (expandedDeviceNotification !== index ? " align-center" : "")}>
                <div>{e.title}</div>
                <div 
                  className="card-actions"
                  onClick={(e) => { e.stopPropagation(); setShowActionsMenu(index) }}
                >
                  {showActionsMenu === index ? <MenuKebabVerticalBackgroundIcon /> : <MenuKebabVerticalIcon />}
                  {showActionsMenu === index &&
                    <ActionsMenu
                      setHideMenu={() => setShowActionsMenu(null)}
                      actions={e.actions}
                      isLoading={showSpinner}
                    />
                  }
                </div>
              </div>
            </div>
            {expandedDeviceNotification === index && (
              <>
                <div className="card-item">
                  <div className="card-item-title">{t('events.type')}</div>
                  <div className="card-item-body">{e.type}</div>
                </div>
                <div className="card-item">
                  <div className="card-item-title">{t('events.delivery-method')}</div>
                  <div className="card-item-body">{e.deliveryMethod}</div>
                </div>
                <div className="card-item">
                  <div className="card-item-title">{t('events.status')}</div>
                  <div className="card-item-body">
                    <div className="card-item-body-status">
                      {e.status ? 
                        <div className="status-active">
                          <EnabledIcon /> 
                          <p>{t("events.active")}</p>
                        </div>
                      : 
                        <div className="status-inactive">
                          <DisabledIcon /> 
                          <p>{t("events.inactive")}</p>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>)
        }
        {!showSpinner && !isFirstLoad && msgsTableData.length === 0 && (
          <EmptyPageResults
            className="with-margin-top"
            title={t("events.no-notification-messages-found")}
            subtitle={t("events.no-device-notifications-found-extra")}
          />)
        }
        {canLoadMore && (
          <div className="buttons-wrapper">
            <SimpleButton className="load-more-button" onClick={() => handleLoadMore()}>
              {t('buttons.load_more')}
            </SimpleButton>
          </div>)
        }
      </div>
      {showAddEditNotificationModal && (
        <AddEditNotificationMessage
          groupEvents={groupEvents}
          selectedMessage={selectedMsg}
          closeModal={() => { 
            setShowAddEditNotificationModal(false); 
            setSelectedMsg(null) 
          }}
          showSpinner={() => setShowSpinner(true)}
          hideSpinner={() => setShowSpinner(false)}
          isLoading={showSpinner}
        />)
      }
      {showFiltersModal && (
        <FiltersModal 
          filters = {[
            {
              name: t('events.type'),
              type: FilterType.multiSelect,
              dataset: props.deliveryMethod,
              input: selectedFilterTypes,
              output: (filterTypes) => { setSelectedFilterTypes(filterTypes) }
            },
            {
              name: t('events.device-event'),
              type: FilterType.multiSelect,
              dataset: groupEvents.map((deviceEvent) => deviceEvent.key),
              input: selectedFilterGroupEvents,
              output: (filterGroupEvents) => { setSelectedFilterGroupEvents(filterGroupEvents) }
            },
            {
              name: t('events.status'),
              type: FilterType.singleSelect,
              dataset: statuses,
              input: selectedFilterStatus,
              output: (filterStatus) => { setSelectedFilterStatus(filterStatus) }
            }
          ]}
          resetFilters={() => handleResetFilters()}
          closeFilters={() => setShowFiltersModal(false)}
        />)
      }
      {showDeleteNotificationConfirmModal && (
        <ConfirmationModal
          onToggle={() => setShowDeleteNotificationConfirmModal(false)}
          onAccept={() => handleDeleteDeviceNotification()}
          onCancel={() => setShowDeleteNotificationConfirmModal(false)}
          message={t("events.confirm-delete-notification-message")}
          acceptButtonText={t("labels.delete")}
          isWarning
        />)
      }
      {showSpinner &&
        <div className={"spinner-wrapper"}>
          <LoadingSpinner/>
        </div>
      }
    </Fragment>
  )
}

function stateToProps({ notifications }) {
  return {
    notificationMessages: notifications?.notificationMessages || [],
    isLoading: notifications?.isLoading,
    canLoadMore: notifications?.canLoadMore,
    eventGroups: notifications?.eventGroups || [],
    deliveryMethods: notifications?.deliveryMethods || [],
    errorMessage: notifications?.errorMessage,
    successMessage: notifications?.successMessage,
    notificationSettings: notifications?.notificationSettings || [],
    notificationRegions: notifications?.notificationRegions || [],
  }
}

function dispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        loadNotificationMessages,
        loadNotificationSettings,
        loadEventGroups,
        loadNotificationRegions,
        loadNotificationDeliveryMethods,
        partiallyUpdateNotificationMsg,
        removeNotificationMsg,
        clearNotificationsMessages
      },
      dispatch
    ),
  }
}

export default connect(stateToProps, dispatchToProps)(NotificationMessages)