import React from "react"
import { useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { checkPermission } from "../../utils/permissionValidation"
import { Pages } from "../../utils/pages"

const LinkCard = (props) => {
  const history = useHistory()
  const { t } = useTranslation()

  const handleNavigation = () => {
    if (props.cardContent.path === "/request-help") {
      props.openRequestHelpModal()
      return
    }

    let navigationPath = props.cardContent.path

    if (props.cardContent.modulePages) {
      navigationPath = getAvailablePage(props.cardContent.modulePages)
    }

    history.push({ pathname: navigationPath })
  }

  const getAvailablePage = (sectionRoutes) => {
    const firstAvailableRoute = sectionRoutes?.find((route) => checkPermission(props.userPermissions, route.permission))
    
    if (firstAvailableRoute) {
      return firstAvailableRoute.route
    }

    return Pages.dashbord.route
  }

  return (
    <div className="link-card" onClick={() => handleNavigation()}>
      <div className="card-background">{props.cardContent.image}</div>
      <div className="card-image">{props.cardContent.image}</div>
      <div className="card-details">
        <div>{t(props.cardContent.titleKey)}</div>
        <div>{t(props.cardContent.descriptionKey)}</div>
      </div>
    </div>
  )
}

export default LinkCard
