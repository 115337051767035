import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { useTranslation } from "react-i18next"
import { useHistory, useLocation } from 'react-router-dom'
import {toast} from "react-toastify"
import ViewFaultDetails from './ViewFaultDetails'
import LoadingSpinner from "../common/LoadingSpinner"
import EmptyPageResults from '../common/EmptyPageResults'
import SimpleButton from '../common/SimpleButton'
import GenericTable from '../common/GenericTable'
import ExpandableText from '../common/ExpandableText'
import SearchWithFilter from "../common/SearchWithFilter"
import FiltersModal, { FilterType } from "../common/FiltersModal"
import ActionsMenu from '../common/ActionsMenu'
import { getCycleFaultProducts, getCyclesSummary } from "../../utils/requests/cyclesAPI"
import { useWindowSize } from "../../libs/hooks"
import { useDeepCompareEffect } from '../../hooks/useDeepCompareEffect'
import { MenuKebabVerticalBackgroundIcon, MenuKebabVerticalIcon, PendingIcon, FlagAUIcon, FlagCAIcon, FlagCNIcon, FlagDEIcon, FlagUSIcon, FlagJPIcon, NotesIcon, InProgressIcon, ClosedIcon } from '../../icons'
import { currentDay, formatVal, convertToSimpleDateWithFullTime } from "../../utils/filters/date"
import { getImageByModel } from '../../utils/functions'
import { setBackNavigationState, clearBackNavigationState } from "../../actions/backNavigationState"
import './cycle-faults.scss'

const CycleFaults = (props) => {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()
  const isMobile = useWindowSize()[0] <= 768
  const [cycleFaults, setCycleFaults] = useState([])
  const [cycleFaultsTableData, setCycleFaultsTableData] = useState([])
  const [cyclesSummary, setCyclesSummary] = useState({})
  const [selectedCycleFault, setSelectedCycleFault] = useState(null)
  const [shouldApplyFilters, setShouldApplyFilters] = useState(true)
  const [selectedFilterUnits, setSelectedFilterUnits] = useState([])
  const [selectedFilterCountries, setSelectedFilterCountries] = useState([])
  const [selectedFilterStatus, setSelectedFilterStatus] = useState([])
  const [selectedFilterOfficeIp, setSelectedFilterOfficeIp] = useState([])
  const [filterQuery, setFilterQuery] = useState(null)
  const [showSpinner, setShowSpinner] = useState(null)
  const [showFiltersModal, setShowFiltersModal] = useState(false)
  const [canLoadMore, setCanLoadMore] = useState(false)
  const [selectedPage, setSelectedPage] = useState(1)
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const [expandedCycleFault, setExpandedCycleFault] = useState(0)
  const [showActionsMenu, setShowActionsMenu] = useState(null)
  const [units, setUnits] = useState([])
  const statuses = ["pending", "closed", "in_progress"]
  const countries = ["AU", "CA", "CN", "DE", "JP", "US"]
  const officeIpFilters = ["scican_office"]
  const cycleFaultsLimit = 20

  useEffect(() => {
    if (props.productTypes && Object.values(props.productTypes).length > 0) {
      const products = Object.values(props.productTypes).map((product) => product.name)
      setUnits(products)
    }
  }, [props.productTypes])

  useEffect(() => {
    if (selectedPage && selectedPage > 1 && !props.backNavigationState) {
      handleLoadCycleFaults(false)
    }
  }, [selectedPage])

  useDeepCompareEffect(() => {
    let changeValueTimeout = window.setTimeout(
      () => {
        if (shouldApplyFilters && !props.backNavigationState ) {
          setSelectedPage(1)
          handleLoadCycleFaults(true)
        }

        setShouldApplyFilters(true)
      },
      filterQuery ? 1000 : 0
    )
    return () => {
      clearTimeout(changeValueTimeout)
    }    
  },[filterQuery, selectedFilterUnits, selectedFilterCountries, selectedFilterStatus, selectedFilterOfficeIp])

  useEffect(() => {
    setShowSpinner(props.isLoading)
  }, [props.isLoading])

  useEffect(() => {
    mapTableData(cycleFaults)

    if (selectedCycleFault && !props.backNavigationState) {
      const selectedFault = cycleFaults?.find((fault) => fault.cycle_fault === selectedCycleFault.cycle_fault && fault.serial_number === selectedCycleFault.serial_number)
      setSelectedCycleFault(selectedFault)
    }
  }, [cycleFaults])

  useEffect(async () => {
    if (props.backNavigationState) {
      setShowSpinner(true)

      const backNavigationFilterQuery = props.backNavigationState.filterQuery
      const backNavigationSelectedFilterUnits = props.backNavigationState.selectedFilterUnits
      const backNavigationSelectedFilterCountries = props.backNavigationState.selectedFilterCountries
      const backNavigationSelectedFilterStatus = props.backNavigationState.selectedFilterStatus
      const backNavigationSelectedFilterOfficeIp = props.backNavigationState.selectedFilterOfficeIp
      const backNavigationSelectedPage = props.backNavigationState.selectedPage
      const selectionLimit = cycleFaultsLimit * backNavigationSelectedPage

      setFilterQuery(backNavigationFilterQuery)
      setSelectedFilterUnits(backNavigationSelectedFilterUnits)
      setSelectedFilterCountries(backNavigationSelectedFilterCountries)
      setSelectedFilterStatus(backNavigationSelectedFilterStatus)
      setSelectedFilterOfficeIp(backNavigationSelectedFilterOfficeIp)
      setSelectedPage(backNavigationSelectedPage)

      let queryParams = {
        queue: 1,
        page: 1,
        limit: selectionLimit,
      }

      let cyclesSummaryQueryParams = {}

      if (backNavigationFilterQuery) {
        queryParams = { ...queryParams, sn: backNavigationFilterQuery}
        cyclesSummaryQueryParams = { ...cyclesSummaryQueryParams, sn: backNavigationFilterQuery}
      } else {
        queryParams = {
          ...queryParams,
          start_date: `${new Date().getFullYear()}-${formatVal(new Date().getMonth())}-${formatVal(new Date().getDate() + 1)}`,
          end_date: `${new Date().getFullYear()}-${formatVal(new Date().getMonth() + 1)}-${formatVal(new Date().getDate())}`,
        }
      }
  
      if (backNavigationSelectedFilterCountries.length > 0) {
        queryParams = { ...queryParams, country: backNavigationSelectedFilterCountries.join(",")}
        cyclesSummaryQueryParams = { ...cyclesSummaryQueryParams, country: backNavigationSelectedFilterCountries.join(",")}
      }
  
      if (backNavigationSelectedFilterUnits.length > 0) {
        queryParams = { ...queryParams, product_type: backNavigationSelectedFilterUnits.join(",")}
        cyclesSummaryQueryParams = { ...cyclesSummaryQueryParams, product_type: backNavigationSelectedFilterUnits.join(",")}
      }

      if (backNavigationSelectedFilterStatus.length > 0) {
        queryParams = { ...queryParams, status: backNavigationSelectedFilterStatus.join(",")}
        cyclesSummaryQueryParams = { ...cyclesSummaryQueryParams, status: backNavigationSelectedFilterStatus.join(",")}
      }

      if (backNavigationSelectedFilterOfficeIp.length > 0) {
        queryParams = { ...queryParams, office_ip_only: true}
        cyclesSummaryQueryParams = { ...cyclesSummaryQueryParams, office_ip_only: true}
      }

      let requestsCompleted = 0
      let requestsErrored = 0
      const totalRequests = 2

      getCyclesSummary(cyclesSummaryQueryParams)
        .then((response) => {
          setCyclesSummary(response.data)

          requestsCompleted++

          if (requestsCompleted === totalRequests) {
            setShowSpinner(false)
            setIsFirstLoad(false)
          }
        })
        .catch((error) => {
          console.log("Error fetching cycle summary", error)
          requestsCompleted++
          requestsErrored++

          if (requestsCompleted === totalRequests) {
            setShowSpinner(false)
            setIsFirstLoad(false)
          }

          if (requestsErrored === totalRequests) {
            toast.dismiss()
            toast.error(t('error.error_loading_data'))
          }
        })

      getCycleFaultProducts(queryParams)
        .then((response) => {
          const faults = response.data
          setCycleFaults(faults)

          setCanLoadMore(response.currentPage !== response.totalPages)

          if (props.backNavigationState.cycleFault && props.backNavigationState.serialNumber) {
            const selectedFault = faults.find((fault) => fault.cycle_fault === props.backNavigationState.cycleFault && fault.serial_number === props.backNavigationState.serialNumber)
            setSelectedCycleFault(selectedFault)
          }

          requestsCompleted++

          if (requestsCompleted === totalRequests) {
            setShowSpinner(false)
            setIsFirstLoad(false)
          }
        })
        .catch((error) => {
          console.log("Error fetching cycle faults", error)
          requestsCompleted++
          requestsErrored++
          
          if (requestsCompleted === totalRequests) {
            setShowSpinner(false)
            setIsFirstLoad(false)
          }

          if (requestsErrored === totalRequests) {
            toast.dismiss()
            toast.error(t('error.error_loading_data'))
          }
        })

      props.actions.clearBackNavigationState()
    }
  }, [props.backNavigationState])

  const handleLoadCycleFaults = async (withReset) => {
    let queryParams = {
      queue: 1,
      page: withReset ? 1 : selectedPage,
      limit: cycleFaultsLimit
    }

    let cyclesSummaryQueryParams = {}

    if (filterQuery) {
      queryParams = { ...queryParams, sn: filterQuery}
      cyclesSummaryQueryParams = { ...cyclesSummaryQueryParams, sn: filterQuery}
    } else {
      queryParams = {
        ...queryParams,
        start_date: `${new Date().getFullYear()}-${formatVal(new Date().getMonth())}-${formatVal(new Date().getDate() + 1)}`,
        end_date: `${new Date().getFullYear()}-${formatVal(new Date().getMonth() + 1)}-${formatVal(new Date().getDate())}`,
      }
    }

    if (selectedFilterCountries.length > 0) {
      queryParams = { ...queryParams, country: selectedFilterCountries.join(",")}
      cyclesSummaryQueryParams = { ...cyclesSummaryQueryParams, country: selectedFilterCountries.join(",")}
    }

    if (selectedFilterUnits.length > 0) {
      queryParams = { ...queryParams, product_type: selectedFilterUnits.join(",")}
      cyclesSummaryQueryParams = { ...cyclesSummaryQueryParams, product_type: selectedFilterUnits.join(",")}
    }

    if (selectedFilterStatus.length > 0) {
      queryParams = { ...queryParams, status: selectedFilterStatus.join(",")}
      cyclesSummaryQueryParams = { ...cyclesSummaryQueryParams, status: selectedFilterStatus.join(",")}
    }

    if (selectedFilterOfficeIp.length > 0) {
      queryParams = { ...queryParams, office_ip_only: true}
      cyclesSummaryQueryParams = { ...cyclesSummaryQueryParams, office_ip_only: true}
    }

    setShowSpinner(true)

    let requestsCompleted = 0
    let requestsErrored = 0
    const totalRequests = 2

    getCyclesSummary(cyclesSummaryQueryParams)
      .then((response) => {
        setCyclesSummary(response.data)

        requestsCompleted++

        if (requestsCompleted === totalRequests) {
          setShowSpinner(false)
          setIsFirstLoad(false)
        }
      })
      .catch((error) => {
        console.log("Error fetching cycle summary", error)
        requestsCompleted++
        requestsErrored++

        if (requestsCompleted === totalRequests) {
          setShowSpinner(false)
          setIsFirstLoad(false)
        }

        if (requestsErrored === totalRequests) {
          toast.dismiss()
          toast.error(t('error.error_loading_data'))
        }
      })

    getCycleFaultProducts(queryParams)
      .then((response) => {
        const faults = withReset ? response.data : [...cycleFaults, ...response.data]
        setCycleFaults(faults)

        setCanLoadMore(response.currentPage !== response.totalPages)

        requestsCompleted++

        if (requestsCompleted === totalRequests) {
          setShowSpinner(false)
          setIsFirstLoad(false)
        }
      })
      .catch((error) => {
        console.log("Error fetching cycle faults", error)
        requestsCompleted++
        requestsErrored++
        
        if (requestsCompleted === totalRequests) {
          setShowSpinner(false)
          setIsFirstLoad(false)
        }

        if (requestsErrored === totalRequests) {
          toast.dismiss()
          toast.error(t('error.error_loading_data'))
        }
      })
  }

  const mapTableData = (data) => {
    const tableData = data?.map((cycleFault, index) => {
      return {
        identifier: `${cycleFault.cycle_fault}-${cycleFault.serial_number}`,
        product: cycleFault.model,
        sn: cycleFault.serial_number,
        queue: index + 1,
        country: cycleFault.country,
        cf: `CF ${cycleFault.cycle_fault}`,
        cf_date: convertToSimpleDateWithFullTime(cycleFault.date_originated),
        note: cycleFault.notes?.length > 0 || false,
        status: cycleFault.action_in_progress ? "in_progress" : cycleFault.pending_for_action ? "pending" : "closed",
        actions: [
          {
            label: t("labels.view"),
            onActionClick: (e) => { e.stopPropagation(); openCycleFaultDetails(`${cycleFault.cycle_fault}-${cycleFault.serial_number}`, data) },
          },
          {
            label: t("monitoring.product_page"),
            onActionClick: (e) => { e.stopPropagation(); navigateToProductPage(cycleFault.serial_number, cycleFault.model_id) },
          },
          {
            label: t("monitoring.view_all_notes"),
            onActionClick: (e) => { e.stopPropagation(); navigateToAllNotes(cycleFault.serial_number, cycleFault.model_id) },
          },
        ]
      }
    })

    setCycleFaultsTableData(tableData)
  }

  const handleSearch = (value) => {
    if (value?.length > 5) {
      setShowSpinner(true)
      setFilterQuery(value)
    } else if (value?.length === 0 && filterQuery?.length > 0) {
      setShowSpinner(true)
      setFilterQuery(null)
    }
  }

  const handleLoadMore = () => {
    setSelectedPage((prevValue) => (prevValue ? prevValue + 1 : 2))
  }

  const handleResetFilters = () => {
    setSelectedFilterUnits([])
    setSelectedFilterCountries([])
    setSelectedFilterStatus([])
    setSelectedFilterOfficeIp([])
  }

  const openCycleFaultDetails = (identifier, data) => {
    const [cycleFault, serialNumber] = identifier.split("-")

    const selectedFault = data?.find((fault) => fault.cycle_fault === cycleFault && fault.serial_number === serialNumber)

    setSelectedCycleFault(selectedFault)
  }

  const navigateToProductPage = (serialNumber, modelId) => {
    handleTableNavigationWithState()

    history.push({
      pathname:`/products/${serialNumber}`, 
      state: {
        model_id: modelId,
        withBackNavigation: true
      } 
    })
  }

  const navigateToAllNotes = (serialNumber, modelId) => {
    handleTableNavigationWithState()

    const items = [{ model_id: modelId, serial_number: serialNumber }]

    history.push({
      pathname: `/products/${serialNumber}/notes`,
      state: {
        items,
        withBackNavigation: true
      }
    })
  }

  const renderCycleFaultCountry = (country) => {
    switch (country) {
      case "AU":
        return (
          <div className="country-wrapper">
            <FlagAUIcon />
            <p>{t("filters.AU")}</p>
          </div>
        )
      case "CA":
        return (
          <div className="country-wrapper">
            <FlagCAIcon />
            <p>{t("filters.CA")}</p>
          </div>
        )
      case "CN":
        return (
          <div className="country-wrapper">
            <FlagCNIcon />
            <p>{t("filters.CN")}</p>
          </div>
        )
      case "DE":
        return (
          <div className="country-wrapper">
            <FlagDEIcon />
            <p>{t("filters.DE")}</p>
          </div>
        )
      case "JP":
        return (
          <div className="country-wrapper">
            <FlagJPIcon />
            <p>{t("filters.JP")}</p>
          </div>
        )
      case "US":
        return (
          <div className="country-wrapper">
            <FlagUSIcon />
            <p>{t("filters.US")}</p>
          </div>
        )
      default:
        return null
    }
  }

  const renderCycleFaultStatus = (status) => {
    switch (status) {
      case "in_progress":
        return (
          <div className="status-in-progress">
            <InProgressIcon />
            <p>{t("filters.in_progress")}</p>
          </div>
        )
      case "pending":
        return (
          <div className="status-pending">
            <PendingIcon />
            <p>{t("filters.pending")}</p>
          </div>
        )
      case "closed":
        return (
          <div className="status-inactive">
            <ClosedIcon />
            <p>{t("filters.closed")}</p>
          </div>
        )
      default:
        return null
    }
  }

  const handleUpdateCycleFault = (updatedCycleFault) => {
    const updatedCycleFaultStatus = updatedCycleFault.pending_for_action ? "pending" : updatedCycleFault.action_in_progress ? "in_progress" : "closed"

    const currentStatusNotMatchingFilters = selectedFilterStatus.length > 0 && !selectedFilterStatus.includes(updatedCycleFaultStatus)
    const currentStatusClosedAndNoSNSearch = updatedCycleFaultStatus === "closed" && !filterQuery && selectedFilterStatus.length === 0

    if (currentStatusNotMatchingFilters || currentStatusClosedAndNoSNSearch) {
      const updatedCycleFaults = cycleFaults.filter((cycleFault) => cycleFault.id !== updatedCycleFault.id)
      setCycleFaults(updatedCycleFaults)
      return
    }

    const updatedCycleFaults = cycleFaults.map((cycleFault) => {
      if (cycleFault.id === updatedCycleFault.id) {
        return updatedCycleFault
      }

      return cycleFault
    })

    setCycleFaults(updatedCycleFaults)
  }

  const handleDetailsNavigationWithState = () => {
    props.actions.setBackNavigationState({
      backNavPage: location.pathname,
      serialNumber: selectedCycleFault?.serial_number,
      cycleFault: selectedCycleFault?.cycle_fault,
      filterQuery: filterQuery,
      selectedFilterUnits: selectedFilterUnits,
      selectedFilterCountries: selectedFilterCountries,
      selectedFilterStatus: selectedFilterStatus,
      selectedFilterOfficeIp: selectedFilterOfficeIp,
      selectedPage: selectedPage
    })
  }

  const handleTableNavigationWithState = () => {
    props.actions.setBackNavigationState({
      backNavPage: location.pathname,
      filterQuery: filterQuery,
      selectedFilterUnits: selectedFilterUnits,
      selectedFilterCountries: selectedFilterCountries,
      selectedFilterStatus: selectedFilterStatus,
      selectedFilterOfficeIp: selectedFilterOfficeIp,
      selectedPage: selectedPage,
    })
  }

  const toggleShowActionsMenu = (identifier) => {
    setShowActionsMenu(showActionsMenu === identifier ? null : identifier)
  }

  return (
    <div className="cycle-faults-content-wrapper">
      <div className="actions-wrapper">
        <SearchWithFilter
          searchText={filterQuery}
          placeholder={t("search.search_by_serial_number")}
          onSearch={(e) => handleSearch(e)}
          showFiltersModal={() => setShowFiltersModal(true)}
          onResetFilters={() => handleResetFilters()}
          showCounterBadge={selectedFilterUnits.length > 0 || selectedFilterCountries.length > 0 || selectedFilterStatus.length > 0 || selectedFilterOfficeIp.length > 0}
          counterBadge={selectedFilterUnits.length + selectedFilterCountries.length + selectedFilterStatus.length + selectedFilterOfficeIp.length}
        />
        <p className="current-date">
          <span>{currentDay().split(",")[0]},</span>
          <span className="medium">{currentDay().split(",")[1]},{currentDay().split(",")[2]}</span>
        </p>
      </div>
      <div className="total-cycles-wrapper">
        <div className="cycles-card">
          <div className="title">{t("cycles.labels.total-cycles")}</div>
          <div className="data-wrapper">
            <div className="data-item">
              <div className="value">{cyclesSummary["total_cycles"]?.today || 0}</div>
              <div className="label">{t('cycles.card.today')}</div>
            </div>
            <div className="data-item">
              <div className="value">{cyclesSummary["total_cycles"]?.last_seven_days || 0}</div>
              <div className="label">{t('cycles.card.last_week')}</div>
            </div>
            <div className="data-item">
              <div className="value">{cyclesSummary["total_cycles"]?.last_thirty_days || 0}</div>
              <div className="label">{t('cycles.card.last_month')}</div>
            </div>
          </div>
        </div>
        <div className="cycles-card">
          <div className="title">{t("cycles.labels.total-cf")}</div>
          <div className="data-wrapper">
            <div className="data-item">
              <div className="value">{cyclesSummary["total_cf"]?.today || 0}</div>
              <div className="label">{t('cycles.card.today')}</div>
            </div>
            <div className="data-item">
              <div className="value">{cyclesSummary["total_cf"]?.last_seven_days || 0}</div>
              <div className="label">{t('cycles.card.last_week')}</div>
            </div>
            <div className="data-item">
              <div className="value">{cyclesSummary["total_cf"]?.last_thirty_days || 0}</div>
              <div className="label">{t('cycles.card.last_month')}</div>
            </div>
          </div>
        </div>
        <div className="cycles-card">
          <div className="title">{t("cycles.labels.total-cf-pending")}</div>
          <div className="data-wrapper">
            <div className="data-item">
              <div className="value">{cyclesSummary["total_cf_pending"]?.today || 0}</div>
              <div className="label">{t('cycles.card.today')}</div>
            </div>
            <div className="data-item">
              <div className="value">{cyclesSummary["total_cf_pending"]?.last_seven_days || 0}</div>
              <div className="label">{t('cycles.card.last_week')}</div>
            </div>
            <div className="data-item">
              <div className="value">{cyclesSummary["total_cf_pending"]?.last_thirty_days || 0}</div>
              <div className="label">{t('cycles.card.last_month')}</div>
            </div>
          </div>
        </div>
      </div>
      {!isMobile && cycleFaultsTableData.length > 0 && 
        <GenericTable
          data={cycleFaultsTableData}
          headers={[
            {
              title: t('monitoring.product')
            },
            {
              title: t('monitoring.sn')
            },
            {
              title: t('monitoring.queue')
            },
            {
              title: t('monitoring.country')
            },
            {
              title: t('monitoring.cf')
            },
            {
              title: t('monitoring.cf_date')
            },
            {
              title: t('monitoring.note')
            },
            {
              title: t('monitoring.status')
            },
            {
              title: ''
            },
          ]}
          keys={[
            'product',
            'sn',
            'queue',
            'country',
            'cf',
            'cf_date',
            'note',
            'status',
            'action',
          ]}
          keyRenderer={{
            product: (item) => {
              return (
                <div className="product-wrapper">
                  <div className="product-image">
                    <img 
                      src={getImageByModel(item.product)}
                      alt={item.product}
                    />
                  </div>
                  <ExpandableText text={item.product} />
                </div>
              )
            },
            sn: (item) => {
              return (
                <div className="serial-number-wrapper">
                  <ExpandableText text={item.sn} />
                </div>
              )
            },
            queue: (item, index) => {
              return (
                <>
                  {`${index + 1}`}
                </>
              )
            },
            country: (item) => {
              return (
                <>
                  {renderCycleFaultCountry(item.country)}
                </>
              )
            },
            cf: (item) => {
              return (
                <div className="cf-wrapper">
                  {item.cf}
                </div>
              )
            },
            cf_date: (item) => {
              return (
                <div className="cf-date-wrapper">
                  <ExpandableText text={item.cf_date} />
                </div>
              )
            },
            note: (item) => {
              return (
                <>
                  {item.note &&
                    <NotesIcon />
                  }
                </>
              )
            },
            status: (item) => {
              return (
                <div className="status-wrapper">
                  {renderCycleFaultStatus(item.status)}
                </div>
              )
            },
            action: (item) => {
              return (
                <div className="table-actions-wrapper" onClick={(e) => { e.stopPropagation(); toggleShowActionsMenu(item["identifier"]) }}>
                  {showActionsMenu === item["identifier"] ? <MenuKebabVerticalBackgroundIcon /> : <MenuKebabVerticalIcon />}
                  {showActionsMenu === item["identifier"] &&
                    <ActionsMenu
                      setHideMenu={() => setShowActionsMenu(null)}
                      actions={item.actions}
                      isLoading={showSpinner}
                    />
                  }
                </div>
              )
            }
          }}
          onRowClick={(cycleFault) => openCycleFaultDetails(cycleFault.identifier, cycleFaults)}
        />
      }
      {isMobile && cycleFaultsTableData.map((cycleFault, index) =>
        <div 
          key={index}
          className="card" 
          onClick={() => setExpandedCycleFault(index)}
        >
          <div className={"card-item" + (expandedCycleFault !== index ? " align-center" : "")}>
            <div className="card-item-title">{t("monitoring.product")}</div>
            <div className={"card-item-body" + (expandedCycleFault !== index ? " align-center" : "")}>
              <div className="card-description">
                <div className="image-wrapper">
                  <img
                    src={getImageByModel(cycleFault.product)} 
                    alt="product"
                  />
                </div>
                {cycleFault.product}
              </div>
              <div 
                className="card-actions"
                onClick={(e) => { e.stopPropagation(); setShowActionsMenu(index) }}
              >
                {showActionsMenu === index ? <MenuKebabVerticalBackgroundIcon /> : <MenuKebabVerticalIcon />}
                {showActionsMenu === index &&
                  <ActionsMenu
                    setHideMenu={() => setShowActionsMenu(null)}
                    actions={cycleFault.actions}
                    isLoading={showSpinner}
                  />
                }
              </div>
            </div>
          </div>
          {expandedCycleFault === index && (
            <>
              <div className="card-item">
                <div className="card-item-title">{t("monitoring.sn")}</div>
                <div className="card-item-body">{cycleFault.sn}</div>
              </div>
              <div className="card-item">
                <div className="card-item-title">{t("monitoring.queue")}</div>
                <div className="card-item-body">{cycleFault.queue}</div>
              </div>
              <div className="card-item">
                <div className="card-item-title">{t("monitoring.country")}</div>
                <div className="card-item-body">{renderCycleFaultCountry(cycleFault.country)}</div>
              </div>
              <div className="card-item">
                <div className="card-item-title">{t("monitoring.cf")}</div>
                <div className="card-item-body color-blue">{cycleFault.cf}</div>
              </div>
              <div className="card-item">
                <div className="card-item-title">{t("monitoring.cf_date_short")}</div>
                <div className="card-item-body">{cycleFault.cf_date}</div>
              </div>
              <div className="card-item">
                <div className="card-item-title">{t("monitoring.note")}</div>
                <div className="card-item-body">{cycleFault.note && <NotesIcon />}</div>
              </div>
              <div className="card-item">
                <div className="card-item-title">{t("monitoring.status")}</div>
                <div className="card-item-body">
                  <div className="card-item-body-status">{renderCycleFaultStatus(cycleFault.status)}</div>
                </div>
              </div>
            </>
          )}
        </div>)
      }
      {!showSpinner && !isFirstLoad && cycleFaultsTableData.length === 0 && (
        <EmptyPageResults 
          title={t("monitoring.no-cycle-faults-found")}
          subtitle={t("monitoring.no-cycle-faults-found-extra")}
        />)
      }
      {canLoadMore && (
        <div className="buttons-wrapper">
          <SimpleButton className="load-more-button" onClick={() => handleLoadMore()}>
            {t('buttons.load_more')}
          </SimpleButton>
        </div>)
      }
      {selectedCycleFault &&
        <ViewFaultDetails
          selectedCycleFault={selectedCycleFault}
          handleUpdateCycleFault={(updatedCycleFault) => handleUpdateCycleFault(updatedCycleFault) }
          closeModal={() => { setSelectedCycleFault(null)}}
          showSpinner={() => setShowSpinner(true)}
          hideSpinner={() => setShowSpinner(false)}
          handleNavigationWithState={() => handleDetailsNavigationWithState()}
        />
      }
      {showFiltersModal &&
        <FiltersModal
          filters={[
            {
              name: t("filters.status"),
              type: FilterType.multiSelect,
              dataset: statuses,
              input: selectedFilterStatus,
              output: (filterStatuses) => {
                setSelectedFilterStatus(filterStatuses)
              },
            },
            {
              name: t("monitoring.unit"),
              type: FilterType.multiSelect,
              dataset: units,
              input: selectedFilterUnits,
              output: (filterUnits) => {
                setSelectedFilterUnits(filterUnits)
              },
            },
            {
              name: t("monitoring.country"),
              type: FilterType.multiSelect,
              dataset: countries,
              input: selectedFilterCountries,
              output: (filterCountries) => {
                setSelectedFilterCountries(filterCountries)
              },
            },
            {
              name: t("monitoring.ip"),
              type: FilterType.multiSelect,
              dataset: officeIpFilters,
              input: selectedFilterOfficeIp,
              output: (filterOfficeIp) => {
                setSelectedFilterOfficeIp(filterOfficeIp)
              },
            },
          ]}
          shouldFilter={(shouldApply) => setShouldApplyFilters(shouldApply)}
          resetFilters={() => handleResetFilters()}
          closeFilters={() => setShowFiltersModal(false)}
        />
      }
      {showSpinner &&
        <div className="spinner-wrapper">
          <LoadingSpinner/>
        </div>
      }
    </div>
  )
}

function stateToProps({ productTypes, backNavigationState }) {
  return {
    productTypes,
    backNavigationState
  }
}

function dispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        setBackNavigationState,
        clearBackNavigationState
      },
      dispatch
    ),
  }
}

export default connect(stateToProps, dispatchToProps)(CycleFaults)
