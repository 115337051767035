import React, { Fragment, useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { toast } from 'react-toastify'
import PageModal from '../../common/PageModal'
import ToggleSwitch from "../../common/ToggleSwitch"
import SimpleButton from "../../common/SimpleButton"
import ConfirmationModal from '../../common/ConfirmationModal'
import LoadingSpinner from '../../common/LoadingSpinner'
import RadioInput from '../../common/RadioInput'
import { useWindowSize } from "../../../libs/hooks"
import {
  getProductNotificationsConfiguration,
  postProductNotificationConfiguration,
  updateProductNotificationConfiguration,
  deleteProductNotificationConfiguration
} from "../../../utils/requests/productsAPI"
import {
  loadCurrentUserNotificationPreferences,
  loadProductNotificationsConfiguration,
  createNotificationConfiguration,
  removeNotificationConfiguration,
  clearNotificationsMessages,
  clearNotificationsConfiguration
} from '../../../actions/notifications'
import { SortingArrow, TrashCan } from './../../../icons/index'
import { checkPermission, userNotificationDeletePermission, userNotificationWritePermission } from '../../../utils/permissionValidation'
import './notification-settings.scss'
import { useAppContext } from '../../../libs/contextLib'

const NotificationSettings = (props) => {
  const { isG4Plus, serialNumber, model, modelImage, closeModal } = props
  const { t } = useTranslation()
  const { showFeatureDev } = useAppContext()
  const isMobile = useWindowSize()[0] <= 1054
  const [notificationCategories, setNotificationCategories] = useState([])
  const [newEmailConfiguration, setNewEmailConfiguration] = useState({})
  const [currentUserConfiguration, setCurrentUserConfiguration] = useState({})
  const [emailConfigurations, setEmailConfigurations] = useState([])
  const [sort, setSort] = useState(false)
  const [expandedConfiguration, setExpandedConfiguration] = useState(null)
  const [showDeleteConfigurationConfirmModal, setShowDeleteConfigurationConfirmModal] = useState(false)
  const [showApplyToAllConfirmModal, setShowApplyToAllConfirmModal] = useState(false)
  const [showSpinner, setShowSpinner] = useState(null)
  const [emailConfigurationToDelete, setEmailConfigurationToDelete] = useState(null)

  useEffect(() => {
    props.actions.loadCurrentUserNotificationPreferences(props.authedUser?.cognitoSub)
    props.actions.loadProductNotificationsConfiguration(serialNumber)
  }, [props.actions])

  useEffect(() => {
    const filteredNotifications = props.notificationPreferences.notifications?.filter(
      (notification) => notification.allow_suppress && notification.is_enabled && notification.category !== null
    )

    let categories = filteredNotifications?.reduce((acc, notification) => {
      const category = notification.category
      const areDisplayedCategorized = category === "cycles"

      const categoryIndex = acc.findIndex((item) => item.category === category)
      const categoryFound = categoryIndex !== -1

      if(!categoryFound) {
        acc.push ({
          category,
          notifications: [{ 
            notification_id: notification.notification_id, 
            is_enabled: notification.is_enabled,
            group: notification.group,
            email_notifications_enabled: notification.email_notifications_enabled
          }],
          areDisplayedCategorized
        })
      } else {
        acc[categoryIndex].notifications.push({ 
            notification_id: notification.notification_id, 
            is_enabled: notification.is_enabled,
            group: notification.group,
            email_notifications_enabled: notification.email_notifications_enabled
          })
      }

      return acc
    }, [])
    categories?.sort((a,b) => { 
      return a.category?.toLowerCase()?.localeCompare(b.category?.toLowerCase())
    })

    setNotificationCategories(categories)
  },[props.notificationPreferences])

  useEffect(() => {
    if (notificationCategories?.length > 0 && props.authedUser) {

      if (!props.notificationsConfiguration) {
        return
      }

      const addWithoutMessages = true;

      const allNotifications = notificationCategories.reduce((acc, category) => {
        return acc.concat(category.notifications)
      }, [])

      const allActiveNotificationIds = allNotifications.map((notification) => notification.notification_id)

      if (props.notificationsConfiguration.length === 0) {
        handleAddConfiguration(props.authedUser.email, allNotifications, addWithoutMessages)
        return
      }

      let mappedEmailConfigurations = []
      let mappedCurrentUserConfiguration = {}

      const groupedNotificationsConfiguration = props.notificationsConfiguration.reduce((acc, notification) => {
        if (allActiveNotificationIds.includes(notification.notification_id)) {
          const email = notification.email
          const emailIndex = acc.findIndex((item) => item.email === email)
          const emailFound = emailIndex !== -1
          
          if(!emailFound) {
            acc.push ({
              email,
              notifications: [notification]
            })
          } else {
            acc[emailIndex].notifications.push(notification)
          }
        }

        return acc
      }, [])

      if (!groupedNotificationsConfiguration.some((groupedConfiguration) => groupedConfiguration.email === props.authedUser.email)) {
        handleAddConfiguration(props.authedUser.email, allNotifications, addWithoutMessages)
        return
      }

      for (let idx = 0; idx < groupedNotificationsConfiguration.length; idx++) {
        const groupedConfiguration = groupedNotificationsConfiguration[idx]
        const email = groupedConfiguration.email

        const missingNotifications = allNotifications.filter((notification) => {
          return !groupedConfiguration.notifications.some((notificationConfig) => notificationConfig.notification_id === notification.notification_id)
        })

        if (missingNotifications.length > 0) {
          handleAddConfiguration(email, missingNotifications, addWithoutMessages)
          return 
        }

        const mappedConfiguration = {
          email,
          categoriesWithNotifications: JSON.parse(JSON.stringify(notificationCategories))
        }

        for (let i = 0; i < groupedConfiguration.notifications.length; i++) {
          const currentNotification = groupedConfiguration.notifications[i]

          const categoryWithNotificationsIndex = mappedConfiguration.categoriesWithNotifications
            .findIndex((category) => category.notifications.some((notification) => notification.notification_id === currentNotification.notification_id))
          
          const notificationIndex = mappedConfiguration.categoriesWithNotifications[categoryWithNotificationsIndex].notifications
            .findIndex((notification) => notification.notification_id === currentNotification.notification_id)
          
          mappedConfiguration.categoriesWithNotifications[categoryWithNotificationsIndex].notifications[notificationIndex].is_enabled = currentNotification.is_enabled
        }

        if (email === props.authedUser.email) {
          mappedCurrentUserConfiguration = mappedConfiguration
        } else {
          mappedEmailConfigurations.push(mappedConfiguration)
        }
      }

      setCurrentUserConfiguration(mappedCurrentUserConfiguration)
      setEmailConfigurations(mappedEmailConfigurations)
    }
  },[props.notificationsConfiguration, props.authedUser, notificationCategories])

  useEffect(() => {
    if (notificationCategories?.length > 0) {
      setNewEmailConfiguration({
        email: "",
        categoriesWithNotifications: JSON.parse(JSON.stringify(notificationCategories))
      })
    }
  },[notificationCategories])

  useEffect(() => {
    setShowSpinner(props.isLoading)
  },[props.isLoading])

  useEffect(() => {
    let changeValueTimeout = window.setTimeout(
      () => {
        if (props.errorMessageNotificationSettings) {
          toast.dismiss()
          toast.error(props.errorMessageNotificationSettings)

          props.actions.clearNotificationsMessages()
        } 
      },
      props.errorMessageNotificationSettings ? 300 : 0
    )
    return () => {
      clearTimeout(changeValueTimeout)
    }
  }, [props.errorMessageNotificationSettings])

  useEffect(() => {
    if (props.successMessageNotificationSettings) {
      toast.dismiss()
      toast.success(props.successMessageNotificationSettings)

      props.actions.clearNotificationsMessages()
    }
  }, [props.successMessageNotificationSettings])

  const handleChangeSort = () => {
    setSort(!sort)
    const arrangedEmailConfigurations = emailConfigurations.sort((a, b) => a && a.email && b && b.email ? a.email.localeCompare(b.email) : false)
    sort
    ? setEmailConfigurations(arrangedEmailConfigurations) 
    : setEmailConfigurations(arrangedEmailConfigurations.reverse())
  }
  
  const handleApplyConfigToAllDevices = () => {
    setShowApplyToAllConfirmModal(false)
    setShowSpinner(true)
    
    if (!props.products || Object.entries(props.products).length === 0) {
      setShowSpinner(false)
      toast.dismiss()
      toast.error(t("dashboard.no-products-found"))
      return
    }

    const filteredProductsSerialNumber = Object.entries(props.products)
      .filter((item) => item[1].association_active && !item[1].model?.toLowerCase().includes("bravo") && item[1].serial_number !== serialNumber)
      .map((product) => product[1].serial_number)

    const retrieveNotificationConfigurationsPromises = [];

    filteredProductsSerialNumber.forEach((filteredSerialNumber) => {
      retrieveNotificationConfigurationsPromises.push(getProductNotificationsConfiguration(filteredSerialNumber))
    })
    
    Promise.allSettled(retrieveNotificationConfigurationsPromises)
      .then((results) => {
        const deleteNotificationConfigurationsPromises = [];

        results.forEach((result) => {
          if (result.status === "fulfilled") {
            const notificationsConfiguration = result.value

            const groupedNotificationsConfiguration = notificationsConfiguration.reduce((acc, notification) => {
              const email = notification.email
              const deviceSn = notification.device_sn
              const emailIndex = acc.findIndex((item) => item.email === email)
              const emailFound = emailIndex !== -1

              if(!emailFound) {
                acc.push ({ email, deviceSn })
              }

              return acc
            }, [])

            groupedNotificationsConfiguration.forEach((groupedConfiguration) => {
              deleteNotificationConfigurationsPromises.push(deleteProductNotificationConfiguration(groupedConfiguration.deviceSn, groupedConfiguration.email))
            })
          }
        })

        Promise.allSettled(deleteNotificationConfigurationsPromises)
          .then(() => {
            const createNotificationConfigurationsPromises = [];

            const configurationsToApply = emailConfigurations.map((emailConfiguration) => {
              return {
                email: emailConfiguration.email,
                notifications: emailConfiguration.categoriesWithNotifications.reduce((acc, category) => {
                  return acc.concat(category.notifications)
                }, [])
              }
            })

            configurationsToApply.push({
              email: currentUserConfiguration.email,
              notifications: currentUserConfiguration.categoriesWithNotifications.reduce((acc, category) => {
                return acc.concat(category.notifications)
              }, [])
            })

            filteredProductsSerialNumber.forEach((filteredSerialNumber) => {
              const bodyParams = []

              configurationsToApply.forEach((configurationToApply) => {
                configurationToApply.notifications.forEach((notification) => {
                  bodyParams.push({
                    email: configurationToApply.email,
                    device_sn: filteredSerialNumber,
                    notification_id: notification.notification_id,
                    is_enabled: notification.is_enabled
                  })
                })
              })

              createNotificationConfigurationsPromises.push(postProductNotificationConfiguration(filteredSerialNumber, bodyParams))
            })

            Promise.allSettled(createNotificationConfigurationsPromises)
              .then(() => {
                toast.dismiss()
                toast.success(t("notifications.succesfully-applied-to-all"))
                setShowSpinner(false)
              })
              .catch(() => {
                toast.dismiss()
                toast.error(t("error.something_wrong"))
                setShowSpinner(false)
              })            
          })
          .catch(() => {
            toast.dismiss()
            toast.error(t("error.something_wrong"))
            setShowSpinner(false)
          })
      })
      .catch(() => {
        toast.dismiss()
        toast.error(t("error.something_wrong"))
        setShowSpinner(false)
      })
  }

  const handleAddConfiguration = (emailAddress, notifications, withoutMessage = false) => {
    const bodyParams = []

    notifications?.forEach((notification) => {
      bodyParams.push({
        device_sn: serialNumber,
        email: emailAddress,
        notification_id: notification.notification_id,
        is_enabled: notification.is_enabled && notification.email_notifications_enabled
      })
    })

    props.actions.createNotificationConfiguration(serialNumber, bodyParams, withoutMessage)
  }

  const isValidConfigurationEmail = (email) => {
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const isValidFormat =  emailRegex.test(String(email).toLowerCase())
    if(!isValidFormat) {
      toast.error(t("error.email"))
      return 
    }
    const doesNotExist = emailConfigurations.find((config) => config.email === email) === undefined
    const currentUserExist = currentUserConfiguration?.email === email
    
    if(!doesNotExist || currentUserExist) {
      toast.error(t("error.email_exists"))
      return 
    }
    return true
  }

  const handleAddNewEmailConfiguration = () => {
    toast.dismiss()
    
    if (!checkPermission(props.userPermissions, userNotificationWritePermission)) {
      return
    }

    if (!isValidConfigurationEmail(newEmailConfiguration.email)) {
      return
    }

    const notifications = newEmailConfiguration.categoriesWithNotifications.reduce((acc, category) => {
        return acc.concat(category.notifications)
      }, [])
      
    handleAddConfiguration(newEmailConfiguration.email, notifications)

    setNewEmailConfiguration({ ...newEmailConfiguration, email: "" })
  }

  const handleUpdateIndividualNewEmailConfiguration = (category, notificationId, enabled) => {
    const newNotifications = newEmailConfiguration.categoriesWithNotifications.find(e => e.category === category)?.notifications.map((notification) => {
      if(notification.notification_id === notificationId) {
        return {
          ...notification,
          is_enabled: !enabled
        }
      }
      return notification
    })
    setNewEmailConfiguration({
      ...newEmailConfiguration,
      categoriesWithNotifications: newEmailConfiguration.categoriesWithNotifications.map((e, index) => {
        if (e.category === category) {
          return {
            ...e,
            notifications: newNotifications
          }
        }
        return e
      }
    )})
  }

  const handleUpdateCategorizedNewEmailConfiguration = (categoryIndex, notificationId) => {
    const categoryWithNotifications = newEmailConfiguration.categoriesWithNotifications[categoryIndex]

    if (!notificationId && !categoryWithNotifications?.notifications.some((n) => n.is_enabled)) {
      return
    }

    if (notificationId && categoryWithNotifications?.notifications.some((n) => n.notification_id === notificationId && n.is_enabled)) {
      return
    }

    const newNotifications = categoryWithNotifications?.notifications.map((notification) => {
      if(notification.notification_id === notificationId) {
        return {
          ...notification,
          is_enabled: true
        }
      }

      return {
        ...notification,
        is_enabled: false
      }
    })

    setNewEmailConfiguration({
      ...newEmailConfiguration,
      categoriesWithNotifications: newEmailConfiguration?.categoriesWithNotifications.map((category, index) => {
        if (index === categoryIndex) {
          return {
            ...category,
            notifications: newNotifications
          }
        }
        return category
      }
    )})
  }

  const handleEditConfiguration = (email, notifications) => {
    setShowSpinner(true)
    const notificationsUpdatePromises = []

    notifications.forEach((notification) => {
      const bodyParams = { is_enabled: notification.is_enabled }
      notificationsUpdatePromises.push(updateProductNotificationConfiguration(serialNumber, notification.notificationId, email, bodyParams))
    })

    Promise.allSettled(notificationsUpdatePromises)
      .then((results) => {
        if (results?.some((result) => result.status === "rejected")) {
          toast.dismiss()
          toast.error(t("error.something_wrong"))
          return
        }

        props.actions.loadProductNotificationsConfiguration(serialNumber)
          .then(() => {
            toast.dismiss()
            toast.success(t("notifications.success-edit-notification-settings"))
          })
      })
      .catch(() => {
        toast.dismiss()
        toast.error(t("error.something_wrong"))
        setShowSpinner(false)
      })
  }

  const handleUpdateCategorizedEmailConfiguration = (notificationId, email, categoryIndex, configIndex) => {
    if (showSpinner || !checkPermission(props.userPermissions, userNotificationWritePermission)) {
      return
    }

    const categoryWithNotifications = configIndex === -1 
      ? currentUserConfiguration.categoriesWithNotifications[categoryIndex]
      : emailConfigurations[configIndex]?.categoriesWithNotifications[categoryIndex]

    if (!notificationId && !categoryWithNotifications?.notifications.some((n) => n.is_enabled)) {
      return
    }

    if (notificationId && categoryWithNotifications?.notifications.some((n) => n.notification_id === notificationId && n.is_enabled)) {
      return
    }

    const notifications = categoryWithNotifications?.notifications.map((notification) => {
      return {
        notificationId: notification.notification_id,
        is_enabled: notificationId === notification.notification_id
      }
    })

    handleEditConfiguration(email, notifications)
  }

  const handleUpdateIndividualEmailConfiguration = (notificationId, email, enabled) => {
    if (showSpinner || !checkPermission(props.userPermissions, userNotificationWritePermission)) {
      return
    }
    
    handleEditConfiguration(email, [{ notificationId, is_enabled: !enabled }])
  }

  const showConfirmationModal = (email) => {
    setEmailConfigurationToDelete(email)
    setShowDeleteConfigurationConfirmModal(true)
  }
  
  function filterNonG4PlusCategories(c) {
    if ((!isG4Plus || !showFeatureDev) && ["device_alarm", "maintenance"].includes(c.category)) {
      return false
    }
    return true;
  }  

  const handleDeleteEmailConfiguration = () => {
    setShowDeleteConfigurationConfirmModal(false)
    props.actions.removeNotificationConfiguration(serialNumber, emailConfigurationToDelete)
  }

  const handleCloseModal = () => {
    props.actions.clearNotificationsConfiguration()

    if (closeModal) {
      closeModal()
    }
  }

  const toggleExpandConfiguration = (configEmail) => {
    setExpandedConfiguration(configEmail !== expandedConfiguration ? configEmail : null)
  }

  return (
    <div className="notification-settings-wrapper">
      {!isMobile ? 
        <PageModal
          toggle
          onToggle={() => handleCloseModal()}
          title={t("account.setup_email_notifications")}
          subtitle={t("account.my_devices_disclaimer")}
        >
          <div className="notification-settings-modal-content">
            <div className="device-data-wrapper d-flex flex-align-center">
              <div className="data">
                <div className="image-wrapper d-flex flex-align-center">
                  <img
                    src={modelImage}
                    className="img"
                    alt={model}
                  />
                </div>
                <div className="product-wrapper d-flex flex-column">
                  <p className="product-title">{model}</p>
                  <p className="product-number">{`${t("sn")}: ${serialNumber}`}</p>
                </div>
              </div>
              <div className="action">
                <SimpleButton 
                  className="action-button" 
                  onClick={() => setShowApplyToAllConfirmModal(true)}
                  disabled={emailConfigurations.length === 0 && Object.keys(currentUserConfiguration).length === 0}
                  requiredPermission={userNotificationWritePermission}
                >
                  {t("account.apply_to_all_devices")}
                </SimpleButton>
                <p className="tooltip">{t('account.apply_to_all_devices_tooltip')}</p>
              </div>
            </div>
            {newEmailConfiguration.categoriesWithNotifications?.length > 0 &&
              <div className="notification-settings-table">
                <div className="table-info-header">
                  <div className="table-info-header-item send-to-item" onClick={() => handleChangeSort()}>
                    <p className="with-margin-right">{t("notifications.send-to")}</p>
                    <div className="header-sort">
                      <SortingArrow
                        className={sort ? "active-sort" : "inactive-sort"}
                      />
                      <SortingArrow
                        className={!sort ? "active-sort" : "inactive-sort"}
                      />
                    </div>
                  </div>
                  {newEmailConfiguration.categoriesWithNotifications.filter(c => {
                    if (!isG4Plus || !showFeatureDev) {
                      return !["device_alarm", "maintenance"].includes(c.category)
                    }
                    return true
                  }).map((categoryWithNotification, index) => 
                    {
                      return <Fragment key={index}>
                        {categoryWithNotification.areDisplayedCategorized ?
                          <div className="table-info-header-item category-item">
                            <p>{t([`notifications.category-${categoryWithNotification.category}`, "notifications.category-undefined"])}</p>
                          </div>
                          :
                          <Fragment>
                            {categoryWithNotification.notifications.map((notification, idx) => <div className="table-info-header-item" key={idx}>
                              <p>{t([`notifications.group-${notification.group}`, "notifications.group-undefined"])}</p>
                            </div>)}
                          </Fragment>}
                      </Fragment>
                    })
                  }
                  <div className="table-info-header-item action-item">
                    <p>{t("notifications.actions")}</p>
                  </div>
                </div>

                <div className="table-action-header">
                  <div className="table-action-header-item send-to-item">
                    <input 
                      type="email"
                      className="email-input"
                      placeholder={t('notifications.enter_email')} 
                      onChange={(e) => setNewEmailConfiguration({ ...newEmailConfiguration, email: e.target.value })}
                      value={newEmailConfiguration.email || ''}
                    />
                  </div>
                  {newEmailConfiguration.categoriesWithNotifications?./* filter(c => {
                    if (!isG4Plus || !showFeatureDev) {
                      return !["device_alarm", "maintenance"].includes(c.category)
                    }
                    return true
                    }). */filter(filterNonG4PlusCategories).map(filterAllCyclesGroup).map((categoryWithNotifications, categoryIndex) => 
                    <Fragment key={categoryIndex}>
                      {categoryWithNotifications.areDisplayedCategorized ?
                        <div className="table-action-header-item category-item">
                          {/* <div className="radio-wrapper" onClick={() => handleUpdateCategorizedNewEmailConfiguration(categoryIndex, undefined)}>
                            <ToggleSwitch
                              checked={!(categoryWithNotifications.notifications.some((n) => n.is_enabled))}
                            />
                          </div> */}
                          {categoryWithNotifications.notifications.map((notification, categorizedNotificationIndex) => 
                            {
                              return <div
                                key={`${categorizedNotificationIndex}-${notification.notification_id}`}
                                className="radio-wrapper"
                                onClick={() => notification.email_notifications_enabled && handleUpdateIndividualNewEmailConfiguration(categoryWithNotifications.category, notification.notification_id, notification.is_enabled)}
                              >
                                <ToggleSwitch
                                  checked={notification.is_enabled && notification.email_notifications_enabled} />
                                <p className={(notification.is_enabled && notification.email_notifications_enabled ? "" : " status-disabled")}>
                                  {t('notifications.group-cycle_fault-short')}
                                </p>
                              </div>
                            })
                          }
                        </div>
                      : 
                        <Fragment>
                          {categoryWithNotifications.notifications.map((notification, individualNotificationIndex) => 
                            <div
                              key={individualNotificationIndex}
                              className="table-action-header-item"
                              onClick={() => notification.email_notifications_enabled && handleUpdateIndividualNewEmailConfiguration(categoryWithNotifications.category, notification.notification_id, notification.is_enabled)}
                            >
                              <ToggleSwitch 
                                checked={notification.is_enabled && notification.email_notifications_enabled}
                              />
                              <p className={"status" + (notification.is_enabled && notification.email_notifications_enabled ? "" : " status-disabled")}>
                                {notification.is_enabled && notification.email_notifications_enabled ? t('labels.enabled') : t('labels.disabled')}
                              </p>
                            </div>)
                          }
                        </Fragment>
                      }
                    </Fragment>)
                  }
                  <div className="table-action-header-item action-item">
                    <SimpleButton
                      className="submit-button"
                      onClick={()=> handleAddNewEmailConfiguration()}
                      disabled={newEmailConfiguration.email?.length === 0}
                      requiredPermission={userNotificationWritePermission}
                    >
                      {t("labels.add")}
                    </SimpleButton>
                  </div>
                </div>
                
                {Object.keys(currentUserConfiguration).length > 0 &&
                  <div className="table-body fixed-item">
                    <div className="table-body-item send-to-item">
                      <p>{currentUserConfiguration.email}</p>
                    </div>
                    {currentUserConfiguration.categoriesWithNotifications.filter(c => {
                        if (!isG4Plus || !showFeatureDev) {
                          return !["device_alarm", "maintenance"].includes(c.category)
                        }
                        return true
                      }).filter(filterNonG4PlusCategories).map(filterAllCyclesGroup).map((categoryWithNotifications, categoryIndex) =>
                      <Fragment key={categoryIndex}>
                        {categoryWithNotifications.areDisplayedCategorized ?
                          <div className="table-body-item category-item">
                            {/* <div className="radio-wrapper" onClick={() => handleUpdateCategorizedEmailConfiguration(undefined, currentUserConfiguration.email, categoryIndex, -1)}>
                              <ToggleSwitch 
                                checked={!(categoryWithNotifications.notifications.some((n) => n.is_enabled))}
                              />
                            </div> */}
                            {categoryWithNotifications.notifications.map((notification, categorizedNotificationIndex) => 
                              {
                                return <div
                                  key={`${categorizedNotificationIndex}-${notification.notification_id}`}
                                  className="radio-wrapper"
                                  onClick={() => notification.email_notifications_enabled && handleUpdateIndividualEmailConfiguration(notification.notification_id, currentUserConfiguration.email, notification.is_enabled)}
                                >
                                  <ToggleSwitch
                                    checked={notification.is_enabled && notification.email_notifications_enabled} />
                                  <p className={(notification.is_enabled && notification.email_notifications_enabled ? "" : " status-disabled")}>
                                    {t('notifications.group-cycle_fault-short')}
                                  </p>
                                </div>
                              })
                            }
                          </div>
                        : 
                          <Fragment>
                            {categoryWithNotifications.notifications.filter(c => {
                                if (!isG4Plus || !showFeatureDev) {
                                  return !["device_alarm", "maintenance"].includes(c.category)
                                }
                                return true
                              }).map((notification, individualNotificationIndex) => 
                              <div
                                key={individualNotificationIndex}
                                className="table-body-item"
                                onClick={() => notification.email_notifications_enabled && handleUpdateIndividualEmailConfiguration(notification.notification_id, currentUserConfiguration.email, notification.is_enabled)}
                              >
                                <ToggleSwitch 
                                  checked={notification.is_enabled && notification.email_notifications_enabled} 
                                />
                                <p className={"status" + (notification.is_enabled && notification.email_notifications_enabled ? "" : " status-disabled")}>
                                  {notification.is_enabled && notification.email_notifications_enabled ? t('labels.enabled') : t('labels.disabled')}</p>
                              </div>)
                            }
                          </Fragment>
                        }
                      </Fragment>)
                    }
                    <div className="table-body-item action-item">
                    </div>
                  </div>
                }

                {emailConfigurations.map((config, configIndex) => 
                  <div className="table-body" key={configIndex}>
                    <div className="table-body-item send-to-item">
                      <p>{config.email}</p>
                    </div>
                    {config.categoriesWithNotifications.filter(filterNonG4PlusCategories).map(filterAllCyclesGroup).map((categoryWithNotifications, categoryIndex) =>
                      {
                        return <Fragment key={categoryIndex}>
                          {categoryWithNotifications.areDisplayedCategorized ?
                            <div className="table-body-item category-item">
                              {/* <div className="radio-wrapper" onClick={() => handleUpdateCategorizedEmailConfiguration(undefined, config.email, categoryIndex, configIndex)}>
                  <ToggleSwitch
                    checked={!(categoryWithNotifications.notifications.some((n) => n.is_enabled))}
                  />
                </div> */}
                              {categoryWithNotifications.notifications.map((notification, categorizedNotificationIndex) => {
                                return <div
                                  key={`${categorizedNotificationIndex}-${notification.notification_id}`}
                                  className="radio-wrapper"
                                  onClick={() => notification.email_notifications_enabled && handleUpdateIndividualEmailConfiguration(notification.notification_id, config.email, notification.is_enabled)}
                                >
                                  <ToggleSwitch
                                    checked={notification.is_enabled && notification.email_notifications_enabled} />
                                  <p className={(notification.is_enabled && notification.email_notifications_enabled ? "" : " status-disabled")}>
                                    {t('notifications.group-cycle_fault-short')}
                                  </p>
                                </div>
                              })}
                            </div>
                            :
                            <Fragment>
                              {categoryWithNotifications.notifications.map((notification, individualNotificationIndex) => {
                                return <div
                                  key={individualNotificationIndex}
                                  className="table-body-item"
                                  onClick={() => notification.email_notifications_enabled && handleUpdateIndividualEmailConfiguration(notification.notification_id, config.email, notification.is_enabled)}
                                >
                                  <ToggleSwitch
                                    checked={notification.is_enabled && notification.email_notifications_enabled} />
                                  <p className={"status" + (notification.is_enabled && notification.email_notifications_enabled ? "" : " status-disabled")}>
                                    {notification.is_enabled && notification.email_notifications_enabled ? t('labels.enabled') : t('labels.disabled')}
                                  </p>
                                </div>
                              })}
                            </Fragment>}
                        </Fragment>
                      })
                    }
                    <div className="table-body-item action-item">
                      <TrashCan onClick={() => {
                          if (!checkPermission(props.userPermissions, userNotificationWritePermission)) {
                            return
                          }

                          showConfirmationModal(config.email)
                        }}
                      />
                    </div>
                  </div>)
                }
              </div>
            }
          </div>
        </PageModal>
      :
        (<PageModal
          toggle
          onToggle={() => handleCloseModal()}
          title={t("account.setup_email_notifications")}
          subtitle={t("account.my_devices_disclaimer")}
        >
          <div className="notification-settings-modal-content">
            <div className="device-data-wrapper d-flex flex-align-center">
              <div className="data">
                <div className="image-wrapper d-flex flex-align-center">
                  <img
                    src={modelImage}
                    className="img"
                    alt={model}
                  />
                </div>
                <div className="product-wrapper d-flex flex-column">
                  <p className="product-title">{model}</p>
                  <p className="product-number">{`${t("sn")}: ${serialNumber}`}</p>
                </div>
              </div>
              <div className="action">
                <SimpleButton 
                  className="action-button" 
                  onClick={() => setShowApplyToAllConfirmModal(true)}
                  disabled={emailConfigurations.length === 0 && Object.keys(currentUserConfiguration).length === 0}
                >
                  {t("account.apply_to_all_devices")}
                </SimpleButton>
                <p className="tooltip">{t('account.apply_to_all_devices_tooltip')}</p>
              </div>
            </div>
            {newEmailConfiguration.categoriesWithNotifications?.length > 0 && 
              <div className="notification-settings-table">
                <div className="card-add-header">
                  <div className="card-add-header-send-to">
                    <p className="with-margin-left">{t("notifications.send-to")}</p>
                  </div>
                  <input 
                    type="email"
                    className="email-input"
                    placeholder={t('notifications.enter_email')} 
                    onChange={(e) => setNewEmailConfiguration({ ...newEmailConfiguration, email: e.target.value })}
                    value={newEmailConfiguration.email || ''}
                  />
                  {newEmailConfiguration.categoriesWithNotifications?.filter(c => {
                      if (!isG4Plus || !showFeatureDev) {
                        return !["device_alarm", "maintenance"].includes(c.category)
                      }
                      return true
                    }).filter(filterNonG4PlusCategories).map(filterAllCyclesGroup).map((categoryWithNotifications, categoryIndex) => 
                    <Fragment key={categoryIndex}>
                      {categoryWithNotifications.areDisplayedCategorized ?
                        <div className="card-add-header-notification">
                          <p className="category-title">{t([`notifications.category-${categoryWithNotifications.category}`, "notifications.category-undefined"])}</p>
                          <div className="card-add-header-notification-status-wrapper category-item">
                            {/* <div className="radio-wrapper" onClick={() => handleUpdateCategorizedNewEmailConfiguration(categoryIndex, undefined)}>
                              <ToggleSwitch
                                checked={!(categoryWithNotifications.notifications.some((n) => n.is_enabled))}
                              />
                            </div> */}
                            {categoryWithNotifications.notifications.map((notification, categorizedNotificationIndex) => 
                              {
                                return <div
                                  key={`${categorizedNotificationIndex}-${notification.notification_id}`}
                                  className="radio-wrapper"
                                  onClick={() => handleUpdateIndividualNewEmailConfiguration(categoryIndex, notification.notification_id, notification.is_enabled)}
                                >
                                  <p>{t('notifications.group-cycle_fault-short')}</p>
                                  <ToggleSwitch
                                    checked={notification.is_enabled} />
                                </div>
                              })
                            }
                          </div>
                        </div>
                      : 
                        <Fragment>
                          {categoryWithNotifications.notifications.map((notification, individualNotificationIndex) =>
                            <div
                              key={individualNotificationIndex}
                              className="card-add-header-notification"
                              onClick={() => handleUpdateIndividualNewEmailConfiguration(categoryIndex, notification.notification_id, notification.is_enabled)}
                            >
                              <p className="category-title">{t([`notifications.group-${notification.group}`, "notifications.group-undefined"])}</p>
                              <div className="card-add-header-notification-status-wrapper">
                                <p className="status">{notification.is_enabled ? t('labels.enabled') : t('labels.disabled')}</p>
                                <ToggleSwitch
                                  checked={notification.is_enabled}
                                />
                              </div>
                            </div>)
                          }
                        </Fragment>
                      }
                    </Fragment>)
                  }
                  <div className="card-add-header-button">
                    <SimpleButton
                      className="submit-button"
                      onClick={()=> handleAddNewEmailConfiguration()}
                      disabled={newEmailConfiguration.email?.length === 0}
                      requiredPermission={userNotificationWritePermission}
                    >
                      {t("labels.add")}
                    </SimpleButton>
                  </div>
                </div>

                {Object.keys(currentUserConfiguration).length > 0 &&
                  <div className={"card-configuration fixed-item"+ (expandedConfiguration === currentUserConfiguration.email ? " selected": "")}>
                    <div className="card-configuration-header" onClick={() => toggleExpandConfiguration(currentUserConfiguration.email)}>
                      <p className="with-margin-left">{currentUserConfiguration.email}</p>
                    </div>
                    {expandedConfiguration === currentUserConfiguration.email &&
                      <div className="separator"/>
                    }
                    {expandedConfiguration === currentUserConfiguration.email && currentUserConfiguration.categoriesWithNotifications.filter(filterNonG4PlusCategories).map(filterAllCyclesGroup).map((categoryWithNotifications, categoryIndex) =>
                      <Fragment key={categoryIndex}>
                        {categoryWithNotifications.areDisplayedCategorized ?
                          <div className="card-configuration-notification">
                            <p className="category-title">{t([`notifications.category-${categoryWithNotifications.category}`, "notifications.category-undefined"])}</p>
                            <div className="card-configuration-notification-status-wrapper category-item">
                              {/* <div className="radio-wrapper" onClick={() => handleUpdateCategorizedEmailConfiguration(undefined, currentUserConfiguration.email, categoryIndex, -1)}>
                                <ToggleSwitch 
                                  checked={!(categoryWithNotifications.notifications.some((n) => n.is_enabled))}
                                />
                              </div> */}
                              {categoryWithNotifications.notifications.map((notification, categorizedNotificationIndex) =>
                                {
                                  return <div
                                    key={`${categorizedNotificationIndex}-${notification.notification_id}`}
                                    className="radio-wrapper"
                                    onClick={() => handleUpdateCategorizedEmailConfiguration(notification.notification_id, currentUserConfiguration.email, categoryIndex, -1)}
                                  >
                                    <ToggleSwitch
                                      checked={notification.is_enabled} />
                                  <p>{t('notifications.group-cycle_fault-short')}</p>
                                  </div>
                                })
                              }
                            </div>
                          </div>
                        : 
                          <Fragment>
                            {categoryWithNotifications.notifications.map((notification, individualNotificationIndex) =>
                              <div
                                key={individualNotificationIndex}
                                className="card-configuration-notification"
                                onClick={() => handleUpdateIndividualEmailConfiguration(notification.notification_id, currentUserConfiguration.email, notification.is_enabled)}
                              >
                                <p className="category-title">{t([`notifications.group-${notification.group}`, "notifications.group-undefined"])}</p>
                                <div className="card-configuration-notification-status-wrapper">
                                  <p className="status">{notification.is_enabled ? t('labels.enabled') : t('labels.disabled')}</p>
                                  <ToggleSwitch 
                                    checked={notification.is_enabled} 
                                  />
                                </div>
                              </div>)
                            }
                          </Fragment>
                        }
                      </Fragment>)
                    }
                  </div>
                }
                
                {emailConfigurations.map((config, configIndex) =>
                  <div
                    key={configIndex}
                    className={"card-configuration"+ (expandedConfiguration === config.email ? " selected": "")}
                  >
                    <div className="card-configuration-header" onClick={() => toggleExpandConfiguration(config.email)}>
                      <p className="with-margin-left">{config.email}</p>
                      <div className="card-configuration-actions">
                        <TrashCan onClick={(e) => {
                            if (!checkPermission(props.userPermissions, userNotificationWritePermission)) {
                              return
                            }

                            e.stopPropagation()
                            showConfirmationModal(config.email)
                          }}
                        />
                      </div>
                    </div>
                    {expandedConfiguration === config.email && config.categoriesWithNotifications.filter(filterNonG4PlusCategories).map(filterAllCyclesGroup).map((categoryWithNotifications, categoryIndex) =>
                      <Fragment key={categoryIndex}>
                        {categoryWithNotifications.areDisplayedCategorized ?
                          <div className="card-configuration-notification">
                            <p className="category-title">{t([`notifications.category-${categoryWithNotifications.category}`, "notifications.category-undefined"])}</p>
                            <div className="card-configuration-notification-status-wrapper category-item">
                              {/* <div className="radio-wrapper" onClick={() => handleUpdateCategorizedEmailConfiguration(undefined, config.email, categoryIndex, configIndex)}>
                                <ToggleSwitch 
                                  checked={!(categoryWithNotifications.notifications.some((n) => n.is_enabled))}
                                />
                              </div> */}
                              {categoryWithNotifications.notifications.map((notification, categorizedNotificationIndex) =>
                                {
                                  return <div
                                    key={`${categorizedNotificationIndex}-${notification.notification_id}`}
                                    className="radio-wrapper"
                                    onClick={() => handleUpdateCategorizedEmailConfiguration(notification.notification_id, config.email, categoryIndex, configIndex)}
                                  >
                                    <ToggleSwitch
                                      checked={notification.is_enabled} />
                                  <p>{t('notifications.group-cycle_fault-short')}</p>
                                  </div>
                                })
                              }
                            </div>
                          </div>
                        : 
                          <Fragment>
                            {categoryWithNotifications.notifications.map((notification, individualNotificationIndex) =>
                              <div
                                key={individualNotificationIndex}
                                className="card-configuration-notification"
                                onClick={() => handleUpdateIndividualEmailConfiguration(notification.notification_id, config.email, notification.is_enabled)}
                              >
                                <p className="category-title">{t([`notifications.group-${notification.group}`, "notifications.group-undefined"])}</p>
                                <div className="card-configuration-notification-status-wrapper">
                                  <p className="status">{notification.is_enabled ? t('labels.enabled') : t('labels.disabled')}</p>
                                  <ToggleSwitch 
                                    checked={notification.is_enabled} 
                                  />
                                </div>
                              </div>)
                            }
                          </Fragment>
                        }
                      </Fragment>)
                    }
                  </div>)
                }
              </div>
            }
          </div>
        </PageModal>)
      }
      {showDeleteConfigurationConfirmModal && (
        <ConfirmationModal
          onToggle={() => setShowDeleteConfigurationConfirmModal(false)}
          onAccept={() => handleDeleteEmailConfiguration()}
          onCancel={() => setShowDeleteConfigurationConfirmModal(false)}
          message={t("notifications.confirm-delete-email-configuration")}
          acceptButtonText={t("labels.delete")}
          isWarning
          requiredPermission={userNotificationDeletePermission}
        />)
      }
      {showApplyToAllConfirmModal && (
        <ConfirmationModal
          onToggle={() => setShowApplyToAllConfirmModal(false)}
          onAccept={() => handleApplyConfigToAllDevices()}
          onCancel={() => setShowApplyToAllConfirmModal(false)}
          message={t("notifications.confirm-apply-to-all-configuration")}
          requiredPermission={userNotificationWritePermission}
        >
          <div className="apply-to-all-tooltip">
            <p>{t("notifications.apply-to-all-configuration-warning")}</p>
          </div>
        </ConfirmationModal>)
      }
      {showSpinner &&
        <div className={"spinner-wrapper"}>
          <LoadingSpinner/>
        </div>
      }
    </div>
  )
}

function filterAllCyclesGroup(c) {  
  return {
    ...c,
    notifications: c.notifications.filter((n) => n.group !== "all_cycles"),
  }
}

function stateToProps({ authedUser, notifications, userProducts, userPermissions }) {
  return {
    authedUser: authedUser,
    products: userProducts?.products || {},
    notificationsConfiguration: notifications?.notificationsConfiguration,
    isLoading: notifications?.isLoading,
    notificationPreferences: notifications?.notificationPreferences || {},
    errorMessageNotificationSettings: notifications?.errorMessageNotificationSettings,
    successMessageNotificationSettings: notifications?.successMessageNotificationSettings,
    userPermissions
  }
}

function dispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        loadCurrentUserNotificationPreferences,
        loadProductNotificationsConfiguration,
        createNotificationConfiguration,
        removeNotificationConfiguration,
        clearNotificationsMessages,
        clearNotificationsConfiguration
      },
      dispatch
    ),
  }
}

export default connect(stateToProps, dispatchToProps)(NotificationSettings)