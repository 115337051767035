import React, { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DigitalTwin from "./DigitalTwin";
import EventLog from './EventLog';
import RemoteAccessLogin from "../RemoteAccessLogin";
import RemoteAccessSession from "../RemoteAccessSession";
import Breadcrumb from "../common/Breadcrumb";
import DashboardHeader from "../common/DashboardHeader/DashboardHeader";
import WrappedTabs from "../common/WrappedTabs";
import SimpleButton from "../common/SimpleButton";
import TooltipedIcon from "../common/TooltipedIcon";
import { fetchLatest } from "../../utils/requests/telemetryAPI";
import { endRemoteSession, startRemoteSession } from "../../utils/requests/remoteAccess";
import { REMOTE_SESSION_TIME, REMOTE_SESSION_DATA } from "../../actions/root";
import { hasAdvancedTroubleshootingPermission, hasEnhancedEventLogPermission } from "../../utils/permissionValidation"
import "./remoteAccess.scss"
import { useAppContext } from "../../libs/contextLib";
import { connect } from "react-redux";
import { publishClearRemoteSession } from "../../utils/requests/mqttPublishAPI";
import { toast } from "react-toastify";
import { InformationIcon } from "../../icons";

// how often to refreshing the data through API call, default is 10 seconds
const refreshInterval = 10 * 1000

function RemoteAccess(props) {
  const { t } = useTranslation()
  const { setRemoteSession } = props
  const [currentRemoteSession, setCurrentRemoteSession] = useState(JSON.parse(localStorage.getItem(REMOTE_SESSION_DATA)))
  const [remoteSessionTime, setRemoteSessionTime] = useState(localStorage.getItem(REMOTE_SESSION_TIME))
  const [rawData, setRawData] = useState([])
  const [rawHistoricInput, setRawHistoricInput] = useState([])
  const [rawHistoricOutput, setRawHistoricOutput] = useState([])
  const [chartEndTime, setChartEndTime] = useState(null)
  const [serNum, setSerNum] = useState(null)
  const [productIsG4Plus, setProductIsG4Plus] = useState(false)
  const [elapsedTime, setElapsedTime] = useState('-- : --')
  const [tabs, setTabs] = useState([])
  const [activeTab, setActiveTab] = useState(null)
  const [isBackwards, setIsBackwards] = useState(false) 
  const [isLatest, setIsLatest] = useState(true) 
  const [isForwards, setIsForwards] = useState(false) 
  const { showFeatureDev } = useAppContext()
  
  useEffect(() => { 
    toast.dismiss()
  },[])
  
  const handleCloseSession = useCallback(() => {
    toast.dismiss()
    if (currentRemoteSession?.guid && serNum) {
      const data = {
        guid: currentRemoteSession.guid,
        ip: currentRemoteSession.ip || "127.0.0.1",
      }

      endRemoteSession(data)
        .then(() => {
          setRemoteSession(false)
          setCurrentRemoteSession(null)
          localStorage.removeItem(REMOTE_SESSION_TIME)
          localStorage.removeItem(REMOTE_SESSION_DATA)
          toast.success(t("remote_access.session_closed"))
          setSerNum(null)
          setProductIsG4Plus(false)
          setRawHistoricInput([])
          setRawHistoricOutput([])
          setRawData([])
        })
        .catch((error) => {
          setRemoteSession(false)
          setCurrentRemoteSession(null)
          localStorage.removeItem(REMOTE_SESSION_TIME)
          localStorage.removeItem(REMOTE_SESSION_DATA)
          toast.error(t("remote_access.session_closed"))

          toast.error(t("remote_access.session_closed"))
          setSerNum(null)
          setProductIsG4Plus(false)
          setRawHistoricInput([])
          setRawHistoricOutput([])
          setRawData([])
          console.log(error.message)
        })
        
      const payload = {
        serial_number: serNum,
        session_status: "complete",
        date: new Date().toISOString() // iso datetime
      }

      publishClearRemoteSession(serNum, payload)
    }
  }, [currentRemoteSession?.guid, currentRemoteSession?.ip, serNum, setRemoteSession])

  React.useEffect(() => {
    const remoteAccessPollingInterval = (process.env.REACT_APP_REMOTE_ACCESS_FREQUENCY_SECONDS || 30) / 60
    const intervalId = setInterval(() => {
      const activeSessionToken = JSON.parse(localStorage.getItem("activeSessionToken"))
      if (activeSessionToken) {
        startRemoteSession(props.authedUser?.cognitoSub, activeSessionToken).then((data) => {
          if (!data.data.guid) {
            handleCloseSession()
          }
        })
      }
    }, 60000 * remoteAccessPollingInterval)

    return () => {
      clearInterval(intervalId)
    }
  }, [handleCloseSession, props.authedUser?.cognitoSub]) 
  
  useLayoutEffect(() => {
    if (currentRemoteSession && Object.keys(currentRemoteSession).length > 0) {
      setRemoteSessionTime(localStorage.getItem(REMOTE_SESSION_TIME) || 0)
      setSerNum(currentRemoteSession.serial_number)
      setProductIsG4Plus(currentRemoteSession.is_g4_plus)
    }
  }, [currentRemoteSession])
  
  useLayoutEffect(() => {
    const tabData = [
      {
        key: 0,
        title: t("remote_access.title"),
        content: <RemoteAccessLogin setRemoteSession={setRemoteSession} currentRemoteSession={currentRemoteSession} setCurrentRemoteSession={setCurrentRemoteSession} disableRemoteSession={() => handleCloseSession()} />,
      },
      showFeatureDev && {
        key: 1,
        title: t("remote_access.session-title"),
        content: <RemoteAccessSession disableRemoteSession={() => handleCloseSession()} />,
      },
      hasAdvancedTroubleshootingPermission(props.userPermissions) && {
        key: 2,
        title: t("advance_troubleshooting.title"),
        content: <DigitalTwin
          rawData={rawData}
          rawHistoricInput={rawHistoricInput}
          rawHistoricOutput={rawHistoricOutput}
          endTime={chartEndTime}
          serNum={serNum}
          setEndTime={setChartEndTime}
          setRawHistoricInput={setRawHistoricInput}
          setRawHistoricOutput={setRawHistoricOutput}
          isBackwards={isBackwards}
          setIsBackwards={setIsBackwards}
          isLatest={isLatest}
          setIsLatest={setIsLatest}
          isForwards={isForwards}
          setIsForwards={setIsForwards}
        />,
      },
      hasEnhancedEventLogPermission(props.userPermissions) && {
        key: 3,
        title: t("enhanced_event_log.title"),
        content: <EventLog serialNumber={serNum} />,
      },      
    ]

    let filteredTabs = [] 

    if (!serNum) {
      filteredTabs = tabData.filter((tab) => tab.key === 0)
    } else {
       if(showFeatureDev) {
        filteredTabs = productIsG4Plus ? tabData.filter((tab) => tab.key !== 0) : tabData.filter((tab) => tab.key === 1)
      }else {
        filteredTabs = tabData
      }
    }

    setTabs(filteredTabs)

    setActiveTab(serNum && activeTab && activeTab.key !== 0 ? tabData[activeTab.key] : filteredTabs[0])
  }, [setRemoteSession, serNum, rawHistoricInput, rawHistoricOutput, rawData, chartEndTime, productIsG4Plus])

  useEffect(() => {
    const timer = setInterval(() => {
      if (currentRemoteSession && Object.keys(currentRemoteSession).length > 0 && remoteSessionTime && remoteSessionTime > 0) {
        const currentTime = (new Date()).getTime()
        let diff = Math.floor((Number(remoteSessionTime) - currentTime) / 1000)

        if (diff < 0) { 
          diff = 0
        }

        let h = Math.floor(diff / 3600)
        diff -= h * 3600
        let m = Math.floor(diff / 60)
        diff -= m * 60
        let s = diff
        setElapsedTime(`${h ? h + ' : ' : ''}${ (m < 10 ? '0' : '') + m } : ${ (s < 10 ? '0' : '') + s }`)
      }
    }, 500)

    return () => {
      clearInterval(timer)
    }
  }, [currentRemoteSession, remoteSessionTime])

  const getTelemetryData = useCallback((serNum) => {
    fetchLatest(serNum)
      .then((data) => {
        setRawData(data)
        setRawHistoricInput((prevRawHistoricInput) => [...prevRawHistoricInput, data])
        setRawHistoricOutput((prevRawHistoricOutput) => [...prevRawHistoricOutput, data])
      })
      .catch((e) => {
        console.log("ERROR", e.message)
      })
  }, [])

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (serNum && productIsG4Plus && isLatest) {
        getTelemetryData(serNum)
      }
    }, refreshInterval)

    return () => clearInterval(intervalId)
  }, [getTelemetryData, serNum, productIsG4Plus, isLatest])

  useEffect(() => {
    if (serNum) {
      const endTime = new Date()
      setChartEndTime(endTime)
    }
  },[serNum])

  const handleChangeTab = (tabKey) => {
    const tab = tabs.find((tab) => tab.key === tabKey)
    setActiveTab(tab)
  }

  const getMaxSessionDuration = () => {
    const sessionDurationMinutes = Number(process.env.REACT_APP_REMOTE_SESSION_DURATION_MINUTES || 120)

    if (sessionDurationMinutes > 59) {
      const hours = Math.floor(sessionDurationMinutes / 60)
      const minutes = sessionDurationMinutes % 60

      let durationText = `${hours} ${t('remote_access.hours')} `

      if (minutes > 0) {
        durationText += `${minutes} ${t('remote_access.minutes')}`
      }

      return durationText.trimEnd()
    }

    return `${sessionDurationMinutes} ${t('remote_access.minutes')}`
  }

  return (
    <div className="remote-access-wrapper">
      <Breadcrumb path={window.location.pathname} />
      <DashboardHeader headerText={serNum || t("nav.remote-access")} />
      {serNum && 
        <div className="active-remote-session-wrapper">
          <div className="timer-wrapper">
            <div className="timer-title">
              {t('remote_access.session_countdown')}
              <div className="timer-note">
                <TooltipedIcon
                  icon={<InformationIcon />}
                  tooltipText={`${t('remote_access.duration_info')}: ${getMaxSessionDuration()}`}
                />
              </div>
            </div>
            <div className="timer-elapsed">
              { elapsedTime }
            </div>
          </div>
          <SimpleButton className="submit-button" onClick={() => handleCloseSession()}>
            {t('remote_access.close-session')}
          </SimpleButton>
        </div>
      }
      <WrappedTabs tabs={tabs.map((tab) => ({ id: tab.key, name: tab.title }))} activeTab={activeTab?.key} changeTab={handleChangeTab} />
      {activeTab?.content && activeTab.content}
    </div>
  )
}

function mapStateToProps({ authedUser }) {
  return {
    authedUser,
  };
}

export default connect(mapStateToProps)(RemoteAccess);