import React, { useEffect, useState, useRef } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import LoadingSpinner from "../common/LoadingSpinner"
import Calendar from "../common/Calendar"
import CycleDetailsModal from "../common/CycleDetailsModal"
import { months, formatVal, convertToSimpleDateWithFullTime } from "../../utils/filters/date"
import { getProductCycleDetails, getProductCycles } from "../../utils/requests/productsAPI"
import { getCycleDetails, getMQTTCycles } from '../../utils/requests/trackingAPI'
import { useAppContext } from "../../libs/contextLib"
import "./cycle-history.scss"

const CycleHistory = (props) => {
  const { t } = useTranslation()
  const { showFeatureDev } = useAppContext()
  const { serialNumber, model, modelId, isG4Plus } = props
  const [printouts, setPrintouts] = useState([])
  const [cycles, setCycles] = useState([])
  const [cycleDetails, setCycleDetails] = useState({})
  const [cycleDetailsDeviceData, setCycleDetailsDeviceData] = useState({})
  const [selectedDay, setSelectedDay] = useState(`${new Date().getFullYear()}-${formatVal(new Date().getMonth() + 1)}-${formatVal(new Date().getDate())}`)
  const [showCycleDetails, setShowCycleDetails] = useState(false)
  const [showSpinner, setShowSpinner] = useState(null)
  const localMonths = months()
  const calendarRef = useRef(null)
  const cyclesRef = useRef(null)
  const isG4PlusAPIEnabled = process.env.REACT_APP_G4PLUS_API_TOGGLE === "on"

  useEffect(() => {
    if (calendarRef?.current && cyclesRef?.current) {
      const calendarHeight = calendarRef.current.offsetHeight;
      cyclesRef.current.style.height = `${calendarHeight}px`;
    }
  },[calendarRef?.current?.offsetHeight])

  useEffect(() => {
    getPrintouts(selectedDay)
  },[])

  useEffect(() => {
    setShowSpinner(true)

    if (isG4Plus && showFeatureDev && isG4PlusAPIEnabled) {
      getMQTTCycles(serialNumber, {
        "cycleStartDate": selectedDay.replace(/-/g, ""),
        "cycleEndDate": selectedDay.replace(/-/g, ""),
      })
        .then((cycles) => {
          let eventData = []
          if(cycles.length > 0) {
            eventData = cycles.sort((a, b) => new Date(a.cycleStartDateTime) - new Date(b.cycleStartDateTime) || a.cycleNumber - b.cycleNumber).filter((c) => c.cycleStartDateTime?.substr(0, 10) === selectedDay).map((cycle) => {
              return {
                cycle_event: cycle.cycleEvent,
                start: convertToSimpleDateWithFullTime(cycle.cycleStartDateTime),
                end: convertToSimpleDateWithFullTime(cycle.cycleEndDateTime),
                cycleDate: cycle.cycleStartDateTime,
                serial_number: cycle.deviceSerialNumber,
                cycle_number: cycle.cycleNumber,
                cycle_fault: cycle.cycleFault,
                recordsUuid: cycle.recordsUuid,
              }
            })
          }

          setCycles(eventData)
          setShowSpinner(false)
        })
        .catch(() => {
          setShowSpinner(false)
          setCycles([])
        })
    } else {
      getProductCycles(serialNumber, {
        start_date: selectedDay,
        end_date: selectedDay,
      })
        .then((data) => {
          if (data?.data?.length > 0) {
            const cyclesForSelectedDay = []
            data.data.sort((a, b) => new Date(a.start) - new Date(b.start) || a.cycle_number - b.cycle_number).forEach((object) => {
              const startDate = object.start?.substr(0, 10)
              if (startDate === selectedDay) {
                cyclesForSelectedDay.push({ ...object, cycleDate: object.start })
              }
            })

            setCycles(cyclesForSelectedDay)
          } else {
            setCycles([])
          }

          setShowSpinner(false)
        })
        .catch(() => {
          setShowSpinner(false)
          setCycles([])
        })
    }
  }, [isG4Plus, selectedDay, serialNumber, showFeatureDev])

  const getPrintouts = (date) => {
    const firstDay = `${new Date(date).getFullYear()}-${formatVal(new Date(date).getMonth() + 1)}-01`
    const lastDay = `${new Date(date).getFullYear()}-${formatVal(new Date(date).getMonth() + 2)}-01`

    setShowSpinner(true)

    if (isG4Plus && showFeatureDev && isG4PlusAPIEnabled) {
      getMQTTCycles(serialNumber, {
        "cycleStartDate": firstDay.replace(/-/g, ""),
        "cycleEndDate": lastDay.replace(/-/g, ""),
      })
        .then((cycles) => {
          let eventData = []
          if(cycles.length > 0) {
            eventData = cycles.sort((a, b) => new Date(a.cycleStartDateTime) - new Date(b.cycleStartDateTime) || a.cycleNumber - b.cycleNumber).map((cycle) => {
              return {
                cycle_event: cycle.cycleEvent,
                start: convertToSimpleDateWithFullTime(cycle.cycleStartDateTime),
                end: convertToSimpleDateWithFullTime(cycle.cycleEndDateTime),
                cycleDate: cycle.cycleStartDateTime,
                serial_number: cycle.deviceSerialNumber,
                cycle_number: cycle.cycleNumber,
                cycle_fault: cycle.cycleFault,
                recordsUuid: cycle.recordsUuid,
                date: cycle.cycleStartDateTime ? convertToSimpleDateWithFullTime(cycle.cycleStartDateTime).substring(0, 10) : null,
              }
            })
          }

          setPrintouts(eventData)
          setShowSpinner(false)
        })
        .catch(() => {
          setShowSpinner(false)
          setPrintouts([])
        })
    } else {
      getProductCycles(serialNumber, {
        start_date: firstDay,
        end_date: lastDay,
      })
        .then((data) => {
          if (data?.data?.length > 0) {
            const printouts = data.data.sort((a, b) => new Date(a.start) - new Date(b.start) || a.cycle_number - b.cycle_number).map((cycle) => ({
              ...cycle,
              date: cycle.start ? cycle.start.substring(0, 10) : null,
              cycleDate: cycle.start,
            }))

            setPrintouts(printouts)
          } else {
            setPrintouts([])
          }

          setShowSpinner(false)
        })
        .catch(() => {
          setShowSpinner(false)
          setPrintouts([])
        })
    }
  }

  const handleDaySelected = (date) => {
    setSelectedDay(date)    
  }

  const handleMonthSelected = (date) => {
    getPrintouts(date)
  }

  const formatSelectedDay = (date) => {
    const day = date.substring(8, 10)
    return `${day} ${localMonths[new Date(date).getMonth()]}, ${new Date(date).getFullYear()}`
  }

  const extractMinutesAndSecondsWithDayPeriod = (date) => {
    if (!date?.length > 0) {
      return ""
    }

    const time = date.split(" ")[1]
    const hour = time.split(":")[0]
    const minutes = time.split(":")[1]
    const dayPeriod = Number(hour) >= 12 ? 'pm' : 'am'
    const hours12 = Number(hour) % 12 || 12

    return `${hours12 < 10 ? "0": ""}${hours12}:${minutes}${dayPeriod}`
  }

  const handleOpenCycleDetails = (cycle) => {
    setShowSpinner(true)

    setCycleDetailsDeviceData({
      model: model,
      cycleNumber: cycle.cycle_number,
      cycleStartTime: cycle.cycleDate,
      deviceSerialNumber: cycle.serial_number,
    })

    let fetchCycleDetailsPromise

    if (showFeatureDev && isG4Plus && isG4PlusAPIEnabled) {
      fetchCycleDetailsPromise = getCycleDetails(cycle.recordsUuid, serialNumber)
    } else {
      fetchCycleDetailsPromise = getProductCycleDetails(serialNumber, cycle.cid, { model_id: modelId})
    }

    fetchCycleDetailsPromise
      .then((res) => {
        setShowSpinner(false)

        setCycleDetails(res.data || res)

        setShowCycleDetails(true)
      })
      .catch((error) => {
        setShowSpinner(false)

        toast.dismiss()
        toast.error(t([`error.${error?.response?.data?.code}`, "error.something_wrong"]))

        setShowCycleDetails(true)
      })
  }

  return (
    <div className="cycle-history-wrapper">
      <div className="cycles-calendar-wrapper" ref={calendarRef}>
        <Calendar 
          onChangeDay={handleDaySelected} 
          onChangeMonth={handleMonthSelected} 
          printouts={printouts} 
          selectedDay={selectedDay}
          hideYearPicker
          hasFixedMonth
        />
      </div>
      <div className="cycles-data-wrapper" ref={cyclesRef}>
        <div className="label">
          {t("printouts.selected-day")}
        </div>
        <div className="selected-date">
          {formatSelectedDay(selectedDay)}
        </div>
        <div className="cycles-wrapper">
          {cycles.map((cycle, index) =>
            <div
              key={index}
              className={"cycle-item-wrapper " + `${cycle.cycle_event ? cycle.cycle_event.toLowerCase()?.replace(/_/g, "-") : "cycle-complete"}` } 
              onClick = {() => handleOpenCycleDetails(cycle)}
            >
              <div className="time">{`${extractMinutesAndSecondsWithDayPeriod(cycle.start)}`}</div>
              <div className="title">{`C#${cycle.cycle_number}`}</div>
            </div>)
          }
        </div>
        {!showSpinner && cycles.length === 0 && 
          <div className="no-cycles-wrapper">
            <p className="disclaimer">{t("products.no_cycles_available")}</p>
          </div>
        }
      </div>
      {showCycleDetails &&
        <CycleDetailsModal
          deviceData={cycleDetailsDeviceData}
          cycleDetails={cycleDetails}
          closeModal={() => {
            setShowCycleDetails(false)
            setCycleDetails({})
            setCycleDetailsDeviceData({})
          }}
        />
      }
      {showSpinner && (
        <div className="spinner-wrapper">
          <LoadingSpinner />
        </div>
      )}
    </div>
  )
}

export default CycleHistory
