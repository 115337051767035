import { useTranslation } from "react-i18next"
import { useState } from "react"
import { usersRequestToJoinCompany } from "../../../utils/requests/companyAPI"
import SimpleButton from "../../common/SimpleButton"
import PageModal from "../../common/PageModal"
import LoadingSpinner from "../../common/LoadingSpinner"
import { toast } from "react-toastify"

export function InviteUserModal({ handleClose }) {
  const { t } = useTranslation()
  const [email, setEmail] = useState("")
  const [showSpinner, setShowSpinner] = useState(false)

  async function handleSubmit() {
    toast.dismiss()
    try {
      setShowSpinner(true)
      await usersRequestToJoinCompany({
        email,
      })
      toast.success(t("account.request_sent"))
      setShowSpinner(false)
    } catch (error) {
      toast.error(t("error.something_wrong"))
      setShowSpinner(false)
    }
    handleClose()
  }

  function getFooter() {
    return (
      <>
        <SimpleButton className="cancel-button" onClick={handleClose}>
          {t("labels.cancel")}
        </SimpleButton>
        <SimpleButton className="submit-button" disabled={!email} onClick={handleSubmit}>
          Add
        </SimpleButton>
      </>
    )
  }

  return (
    <PageModal
      toggle
      onToggle={handleClose}
      title={
        <div>
          <span>{t("account.request_to_join")}</span>
        </div>
      }
      headerContent={<div></div>}
      footerContent={getFooter()}
    >
      {showSpinner && (
        <div className="spinner-wrapper">
          <LoadingSpinner />
        </div>
      )}
      <div className="request-review-modal">
        <div className="form-group">
          <label htmlFor="user-email">User email*</label>
          <input type="text" id="user-email" value={email} onChange={(e) => setEmail(e.target.value)} />
        </div>
      </div>
    </PageModal>
  )
}
