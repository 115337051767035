import { pending_approval } from "../../images";
import { Person } from "../../icons";
import "./pending_approval.scss";
import { useTranslation } from "react-i18next";
import config from "../../utils/config";
import { toast } from "react-toastify"
import { sendMail } from "../../utils/requests/mqttPublishAPI";

const PendingApproval = (props) => {
  const { t } = useTranslation();
  const locale = localStorage.getItem("i18nextLng").replace(/-/g, "_");
  
  const sendEmail = () => { 
    const emailTemplateVars = {
      name: `${props?.authedUser?.firstname}`,
      firstname: `${props?.authedUser?.firstname}`,
      lastname: `${props?.authedUser?.lastname}`,
      phone: `${props?.authedUser?.phone}`,
      company: `${props?.authedUser?.company}`,
      country: `${props?.authedUser?.country}`,
      language: `${props?.authedUser?.language}`,
      email: `${props?.authedUser?.email}`,
    }
    const templateName = `ocp_dealer_technician_whitelist_registrations_${locale}`
    const email = config.dealerTechEmailTemplate.email
    try {
      sendMail(
        email,
        templateName,
        emailTemplateVars
      ).then(() => {
        toast.dismiss()
        toast.success(t("register.email_sent"))
      })
    } catch (error) {
      toast.dismiss()
      toast.error(t("error.something_wrong"))
    }
  }

  return (
    <div className="approval-wrapper">
      <img src={pending_approval} alt="stericenter" />
      <div className="message">
        <div className="icon-text">
          <Person />
          <div>
            <div>{t("register.account_pending_approval")}</div>
            <div className="email-text" onClick={sendEmail}>{t("register.email_customer_service")}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PendingApproval;
