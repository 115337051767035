import React, { Fragment, useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import {toast} from "react-toastify"
import { Storage } from "aws-amplify"
import LoadingSpinner from "../common/LoadingSpinner"
import HighlightedText from "../common/HighlightedText"
import EmptyPageResults from '../common/EmptyPageResults'
import SearchWithFilter from '../common/SearchWithFilter'
import FiltersModal, { FilterType } from "../common/FiltersModal"
import SimpleButton from '../common/SimpleButton'
import { useDeepCompareEffect } from '../../hooks/useDeepCompareEffect'
import { DownloadModernIcon, PdfModernIcon } from "../../icons"
import { loadDocuments, loadDocumentModels, loadDocumentTypes, clearDocumentsMessages, loadDocumentLanguages } from "../../actions/documents"

const Download = (props) => {
  const { t } = useTranslation();
  const [documents, setDocuments] = useState([])
  const [models, setModels] = useState([])
  const [types, setTypes] = useState([])
  const [languages, setLanguages] = useState([])
  const [shouldApplyFilters, setShouldApplyFilters] = useState(true)
  const [selectedFilterModel, setSelectedFilterModel] = useState(props.model || null)
  const [selectedFilterType, setSelectedFilterType] = useState(null)
  const [selectedFilterLanguage, setSelectedFilterLanguage] = useState(null)
  const [filterQuery, setFilterQuery] = useState(null)
  const [showFiltersModal, setShowFiltersModal] = useState(false)
  const [showSpinner, setShowSpinner] = useState(null)
  const [canLoadMore, setCanLoadMore] = useState(false)
  const [selectedPage, setSelectedPage] = useState(1)
  const [isFirstLoad, setIsFirstLoad] = useState(true)

  useEffect(() => {
    setDocuments(props.documents)
  }, [props.documents])

  useEffect(() => {
    setModels(props.documentModels)
  }, [props.documentModels])

  useEffect(() => {
    setTypes(props.documentTypes)
  }, [props.documentTypes])

  useEffect(() => {
    if (props.documentModels.length === 0) {
      props.actions.loadDocumentModels()
    }
    
    if (props.documentTypes.length === 0) {
      props.actions.loadDocumentTypes()
    }
  }, [props.actions])
  
  useEffect(() => {    
    if (props.documentLanguages.length === 0) {
      props.actions.loadDocumentLanguages()
    }
  }, [props.actions])
  
  useEffect(() => {
    const sortedLanguageCodes = props.documentLanguages
      .map((lang) => ({
        code: `${lang}-lang`,
        name: t(`filters.${lang}-lang`),
      }))
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((item) => (item.code))

    setLanguages(sortedLanguageCodes)
  }, [props.documentLanguages])

  useEffect(() => {
    setCanLoadMore(props.canLoadMore)
  }, [props.canLoadMore])

   useEffect(() => {
    if (props.errorMessage) {
      toast.dismiss()
      toast.error(props.errorMessage)

      props.actions.clearDocumentsMessages()
    }
  }, [props.errorMessage])

  useEffect(() => {
    if (selectedPage && selectedPage > 1) {
      handleLoadDocuments(false)
    }
  }, [selectedPage])

  useDeepCompareEffect(() => {
    let changeValueTimeout = window.setTimeout(
      () => {
        if (shouldApplyFilters) {
          setSelectedPage(1)
          handleLoadDocuments(true)
        }

        setShouldApplyFilters(true)
      },
      filterQuery ? 1000 : 0
    )
    return () => {
      clearTimeout(changeValueTimeout)
    }    
  },[filterQuery, selectedFilterModel, selectedFilterType, selectedFilterLanguage])

  useEffect(() => {
    setShowSpinner(props.isLoading)
  }, [props.isLoading])

  const handleLoadDocuments = (withReset) => {
    let queryParams = {
      page: withReset ? 1 : selectedPage,
      exclude: "category:technical service bulletins"
    }

    if (filterQuery) {
      queryParams = { ...queryParams, query: filterQuery}
    }

    if (selectedFilterModel) {
      queryParams = { ...queryParams, model: selectedFilterModel}
    }

    if (selectedFilterType) {
      queryParams = { ...queryParams, document_type: selectedFilterType}
    }
    
    if (selectedFilterLanguage) {
      queryParams = { ...queryParams, lang: selectedFilterLanguage}
    }

    props.actions.loadDocuments(queryParams, withReset)
      .then(() => setIsFirstLoad(false))
  }

  const handleLoadMore = () => {
    setSelectedPage((prevValue) => (prevValue ? prevValue + 1 : 2))
  }

  const handleSearch = (value) => {
    if (value?.length > 2) {
      setShowSpinner(true)
      setFilterQuery(value)
    } else if (value?.length === 0 && filterQuery?.length > 0) {
      setShowSpinner(true)
      setFilterQuery(null)
    }
  }

  const handleResetFilters = () => {
    setSelectedFilterModel(null)
    setSelectedFilterType(null)
    setSelectedFilterLanguage(null)
  }

  async function handleDownload(filePath, fileName) {
    Storage.configure({
      customPrefix: {
        public: "",
        protected: "",
        private: "",
      },
    })

    const result = await Storage.get(`${filePath}${fileName}`)
    window.open(result, "_blank")
  }
  
  return (
    <Fragment>
      <div className="documents-content-wrapper">
        {!props.hideFiltering && (
          <div className="actions-wrapper d-flex flex-align-center">
            <SearchWithFilter
              onSearch={(e) => handleSearch(e)}
              showFiltersModal={() => setShowFiltersModal(true)}
              onResetFilters={() => handleResetFilters()}
              showCounterBadge={selectedFilterModel || selectedFilterType || selectedFilterLanguage}
              counterBadge={(selectedFilterModel && 1 ) + (selectedFilterType && 1) + (selectedFilterLanguage && 1)}
            />
          </div>
        )}
        {documents.length > 0 && (
          <div className="list-wrapper">
            {documents.map((item, index) => {
              return (
                <div
                  key={index}
                  className={"item-wrapper" + (index === documents.length - 1 ? " no-border" : "")}
                  onClick = {() => handleDownload(item?.file_path, item?.file_name)}
                >
                  <div className="image-wrapper">
                    <DownloadModernIcon />
                  </div>
                  <div className="image-wrapper rounded-image">
                    <PdfModernIcon />
                  </div>
                  <div className="name-wrapper">
                    <HighlightedText
                      defaultTextClassName="title"
                      matchingTextClassName="title matching-text"
                      nonMatchingTextClassName="title non-matching-text"
                      text={item?.document_title ? item?.document_title : item?.file_name} 
                      highlight={filterQuery}
                    />
                    <p className="section">{item?.section_name}</p>
                  </div>
                </div>
              )})
            }
          </div>)
        }
        {!showSpinner && !isFirstLoad && documents.length === 0 && (
          <EmptyPageResults
            className="with-margin-top"
            title={t("documents.no-documents-found")}
            subtitle={t("documents.no-documents-found-extra")}
          />)
        }
        {canLoadMore && documents.length > 0 && (
          <div className="buttons-wrapper">
            <SimpleButton className="load-more-button" onClick={() => handleLoadMore()}>
              {t('buttons.load_more')}
            </SimpleButton>
          </div>)
        }
      </div>
      {showFiltersModal &&
        <FiltersModal
          filters={[
            {
              name: t("model"),
              type: FilterType.singleSelect,
              dataset: models,
              input: selectedFilterModel,
              output: (filterModel) => {
                setSelectedFilterModel(filterModel)
              },
            },
            {
              name: t("type"),
              type: FilterType.singleSelect,
              dataset: types,
              input: selectedFilterType,
              output: (filterType) => {
                setSelectedFilterType(filterType)
              },
            },
            {
              name: t("language_option"),
              type: FilterType.singleSelect,
              dataset: languages,
              input: selectedFilterLanguage ? `${selectedFilterLanguage}-lang` : null,
              output: (filterLanguage) => {
                const language = filterLanguage?.split("-")[0] || null
                setSelectedFilterLanguage(language)
              },
            },
          ]}
          shouldFilter={(shouldApply) => setShouldApplyFilters(shouldApply)}
          resetFilters={() => handleResetFilters()}
          closeFilters={() => setShowFiltersModal(false)}
        />
      }
      {showSpinner &&
        <div className={"spinner-wrapper"}>
          <LoadingSpinner/>
        </div>
      }
    </Fragment>
  )
}

function stateToProps({ userPermissions, documents }) {
  return {
    userPermissions, 
    documents: documents?.documents || [],
    documentModels: documents?.documentModels || [],
    documentTypes: documents?.documentTypes || [],
    documentLanguages: documents?.documentLanguages || [],
    isLoading: documents?.isLoading,
    canLoadMore: documents?.canLoadMore,
    errorMessage: documents?.errorMessage,
  }
}

function dispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        loadDocuments,
        loadDocumentModels,
        loadDocumentTypes,
        loadDocumentLanguages,
        clearDocumentsMessages
      },
      dispatch
    ),
  }
}

export default connect(stateToProps, dispatchToProps)(Download)
