import {_baseGetFetch, ApiEnum } from "./baseFetch"

const path = "/subscriptions"

export const getSubscriptionsPayments = (queryParams) => {
  return _baseGetFetch(`${path}/payments`, { ...queryParams }, ApiEnum.admin)
}

export const getSubscriptionsPaymentsCsv = (queryParams) => {
  return _baseGetFetch(`${path}/payments/download`, { ...queryParams }, ApiEnum.admin)
}