import React, { useState, useEffect } from "react"
import "./tabs.scss"

const Tabs = ({ defaultIndex = 0, className = "", onTabClick, children, ...props }) => {
  // const [activeTab, setActiveTab] = React.useState(children[0].props.label);
  const [activeTab, setActiveTab] = useState(defaultIndex)

  useEffect(() => {
    setActiveTab(defaultIndex)
  }, [defaultIndex])

  let items
  if (Array.isArray(children)) {
    items = children //children.filter(item => item.type.name === 'Tab')
  } else {
    items = [children]
  }

  const handleClick = (e, newActiveTab) => {
    e.preventDefault()
    setActiveTab(newActiveTab)
    if (onTabClick) {
      onTabClick(newActiveTab)
    }
  }

  return (
    <div className="tabs-wrapper">
      <ul className={`tabs ${className}`}>
        {items.map((child) => {
          const label = child.props.label
          const index = child.props.index

          return (
            <li
              key={child.props.label}
              className={index === activeTab ? "current" : "tab"}
              onClick={function (e) {
                return handleClick(e, index)
              }}
            >
              <div className="tab-item">
                {index === activeTab ? child.props.activeIcon : child.props.inactiveIcon}
                <div className="tab-title">{label}</div>
              </div>
            </li>
          )
        })}
      </ul>
      {items.map(function (child) {
        if (child.props.index === activeTab)
          return (
            <div key={child.props.label} className={`content ${className}-content`}>
              {child.props.children}
            </div>
          )

        return null
      })}
    </div>
  )
}

const Tab = (props) => <div {...props} />

const BoxTabs = ({ defaultIndex = 0, className="", onTabClick, children, ...props }) => {
  return (
    <Tabs className={`box-tab ${className}`} onTabClick={onTabClick} defaultIndex={defaultIndex} {...props}>
      {children}
    </Tabs>
  )
}

const UnderlineTabs = ({ defaultIndex = 0, onTabClick, children }) => {
  return (
    <Tabs className="underline-tab" onTabClick={onTabClick} defaultIndex={defaultIndex}>
      {children}
    </Tabs>
  )
}

export { Tabs, BoxTabs, UnderlineTabs, Tab }
