import {
  LOAD_ACCOUNTS_FULFILLED,
  LOAD_ACCOUNTS_PENDING,
  LOAD_ACCOUNTS_REJECTED,
  LOAD_ROLES_FULFILLED,
  LOAD_ROLES_PENDING,
  LOAD_ROLES_REJECTED,
  LOAD_NEW_ROLES_FULFILLED,
  LOAD_NEW_ROLES_PENDING,
  LOAD_NEW_ROLES_REJECTED,
  LOAD_ROLE_POLICIES_FULFILLED,
  LOAD_ROLE_POLICIES_PENDING,
  LOAD_ROLE_POLICIES_REJECTED,
  POST_ACCOUNT_PENDING,
  POST_ACCOUNT_FULFILLED,
  POST_ACCOUNT_REJECTED,
  POST_ROLE_PENDING,
  POST_ROLE_FULFILLED,
  POST_ROLE_REJECTED,
  PUT_ROLE_POLICIES_PENDING,
  PUT_ROLE_POLICIES_FULFILLED,
  PUT_ROLE_POLICIES_REJECTED,
  PUT_ROLE_PENDING,
  PUT_ROLE_FULFILLED,
  PUT_ROLE_REJECTED,
  PATCH_ACCOUNT_PENDING,
  PATCH_ACCOUNT_FULFILLED,
  PATCH_ACCOUNT_REJECTED,
  PATCH_ROLE_PENDING,
  PATCH_ROLE_FULFILLED,
  PATCH_ROLE_REJECTED,
  DELETE_ACCOUNT_FULFILLED,
  DELETE_ACCOUNT_PENDING,
  DELETE_ACCOUNT_REJECTED,
  DELETE_ROLE_PENDING,
  DELETE_ROLE_FULFILLED,
  DELETE_ROLE_REJECTED,
  CLEAR_ACCOUNTS_MESSAGES,
} from "../actions/accountsManagement"
import i18n from "i18next"

export default function accounts(state = {}, action) {
  switch (action.type) {
    case LOAD_ACCOUNTS_PENDING:
      return {
        ...state,
        accountsLoading: true,
        errorMessage: null,
        successMessage: null,
      }
    case LOAD_ROLES_PENDING:
    case LOAD_NEW_ROLES_PENDING:
      return {
        ...state,
        rolesLoading: true,
        errorMessage: null,
        successMessage: null,
      }
    case LOAD_ROLE_POLICIES_PENDING:
      return {
        ...state,
        rolePoliciesLoading: true,
        errorMessage: null,
        successMessage: null,
      }
    case POST_ACCOUNT_PENDING:
    case PATCH_ACCOUNT_PENDING:
    case DELETE_ACCOUNT_PENDING:
      return {
        ...state,
        errorMessage: null,
        successMessage: null,
        accountsLoading: true,
      }
    case POST_ROLE_PENDING:
    case PUT_ROLE_PENDING:
    case PUT_ROLE_POLICIES_PENDING:
      return {
        ...state,
        errorMessage: null,
        successMessage: null,
        roleLoading: true,
      }
    case PATCH_ROLE_PENDING:
      return { 
        ...state, 
        errorMessage: null, 
        successMessage: null, 
        ...(action.meta.refetch ? { rolesLoading: true } : { roleLoading: true }) 
      }
    case DELETE_ROLE_PENDING:
      return {
        ...state,
        errorMessage: null,
        successMessage: null,
        rolesLoading: true,
      }
    case LOAD_ACCOUNTS_REJECTED:
      return {
        ...state,
        accountsLoading: false,
        errorMessage: i18n.t([`error.${action.payload.response?.data?.code}`, "error.something_wrong"]),
      }
    case LOAD_ROLES_REJECTED:
    case LOAD_NEW_ROLES_REJECTED:
      return {
        ...state,
        rolesLoading: false,
        errorMessage: i18n.t([`error.${action.payload.response?.data?.code}`, "error.something_wrong"]),
      }
    case LOAD_ROLE_POLICIES_REJECTED:
      return {
        ...state,
        rolePoliciesLoading: false,
        errorMessage: i18n.t([`error.${action.payload.response?.data?.code}`, "error.something_wrong"]),
      }
    case PATCH_ACCOUNT_REJECTED:
    case POST_ACCOUNT_REJECTED:
    case DELETE_ACCOUNT_REJECTED:
      return {
        ...state,
        accountsLoading: false,
        errorMessage: i18n.t([`error.${action.payload.response?.data?.code}`, "error.something_wrong"]),
      }
    case POST_ROLE_REJECTED:
    case PUT_ROLE_REJECTED:
    case PUT_ROLE_POLICIES_REJECTED:
      return {
        ...state,
        roleLoading: false,
        rolesLoading: false,
        errorMessage: i18n.t([`error.${action.payload.response?.data?.code}`, "error.something_wrong"]),
      }
    case PATCH_ROLE_REJECTED:
      return {
        ...state,
        ...(action.meta.refetch ? { rolesLoading: false } : { roleLoading: false }),
        errorMessage: i18n.t([`error.${action.payload.response?.data?.code}`, "error.something_wrong"]),
      }
    case DELETE_ROLE_REJECTED:
      return {
        ...state,
        rolesLoading: false,
        errorMessage: i18n.t([`error.${action.payload.response?.data?.code}`, "error.something_wrong"]),
      }
    case LOAD_ACCOUNTS_FULFILLED:
      return {
        ...state,
        accounts: action.payload,
        accountsLoading: false,
      }
    case POST_ROLE_FULFILLED:
      return {
        ...state,
        ...(action.meta.refetch && { roles: [...state?.roles, { ...action.payload, userCount: 0 }] }),
        roleLoading: false,
        successMessage: i18n.t("account_management.account.role_created"),
      }
    case PUT_ROLE_FULFILLED:
      return {
        ...state,
        ...(action.meta.refetch && {
          roles: state?.roles.map((item) => (item.id === action.payload.id ? { ...action.payload, userCount: action.meta.userCount } : item)),
          roleLoading: false,
          successMessage: i18n.t("account_management.account.role_updated"),
        }),
      }
    case PUT_ROLE_POLICIES_FULFILLED:
      return {
        ...state,
        roleLoading: false,
      }
    case PATCH_ROLE_FULFILLED:
      const modified_roles = state?.roles.map((item) => (item.id === action.payload.id ? { ...item, isEnabled: action.payload.isEnabled } : item))
      return {
        ...state,
        roles: modified_roles,
        ...(action.meta.refetch ? { rolesLoading: false } : { roleLoading: false }),
        successMessage: action.payload.isEnabled ? i18n.t("account_management.account.role_enabled") : i18n.t("account_management.account.role_disabled"),
      }
    case DELETE_ROLE_FULFILLED:
      return {
        ...state,
        roles: state?.roles.filter((role) => role.id !== action.meta.id),
        rolesLoading: false,
        successMessage: i18n.t("account_management.account.role_deleted"),
      }
    case POST_ACCOUNT_FULFILLED:
      const initialAccounts = state?.accounts || []
      return {
        ...state,
        accounts: [...initialAccounts, action.payload],
        accountsLoading: false,
        successMessage: i18n.t("account_management.account.account_created"),
      }
    case PATCH_ACCOUNT_FULFILLED:
      const updatedAccounts = state?.accounts.map((item) => (item.id === action.payload.id ? { ...action.payload } : item))
      return {
        ...state,
        accounts: updatedAccounts,
        accountsLoading: false,
        successMessage: i18n.t("account_management.account.account_updated"),
      }
    case DELETE_ACCOUNT_FULFILLED:
      var deleted_accounts = state?.accounts.filter((account) => account.id !== action.meta.id)
      return {
        ...state,
        accounts: deleted_accounts,
        accountsLoading: false,
        successMessage: i18n.t("account_management.account.account_deleted"),
      }
    case LOAD_ROLES_FULFILLED:
      const roles = state?.roles || []
      const newRolesIds = action.payload.map((item) => item.id)
      const difference = roles.filter((x) => !newRolesIds.includes(x.id)).concat(action.payload)
      return {
        ...state,
        rolesLoading: false,
        canLoadMoreRoles: action.payload.length === Number(process.env.REACT_APP_PAGINATION_SIZE),
        roles: difference,
      }
    case LOAD_NEW_ROLES_FULFILLED:
      return {
        ...state,
        rolesLoading: false,
        canLoadMoreRoles: action.payload.length === Number(process.env.REACT_APP_PAGINATION_SIZE),
        roles: action.payload,
      }
    case LOAD_ROLE_POLICIES_FULFILLED:
      return {
        ...state,
        rolePoliciesLoading: false,
        rolePolicies: action.payload,
      }
    case CLEAR_ACCOUNTS_MESSAGES:
      return {
        ...state,
        errorMessage: null,
        successMessage: null,
      }
    default:
      return state
  }
}
