import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import SearchWithFilter from "../common/SearchWithFilter"
import GenericTable from "../common/GenericTable"
import { getPriceListHeaders, priceListRenderer, priceListTableKeys } from "./price-list-table-config"
import SimpleButton from "../common/SimpleButton"
import { RequestReviewModal } from "./RequestReviewModal"
import { RequestMissingItem } from "./RequestMissingItem"
import FiltersModal, { FilterType } from "../common/FiltersModal"
import { getRequestListHeaders, requestListRenderer, requestListTableKeys } from "./requests-table-config"
import { RequestReviewRowModal } from "./RequestReviewRowModal"
import { downloadPricelists, getRequestReviewOptions } from "../../utils/requests/dealersAPI"
import LoadingSpinner from "../common/LoadingSpinner"
import EmptyPageResults from "../common/EmptyPageResults"
import { downloadFile } from "../../utils/functions"
import { useFirstRender } from "../../hooks/useFirstRender"
import { fetchPriceListsByTabType } from "."
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { loadPriceListFilters } from "../../actions/priceLists"
import { debounce, isEmpty } from "lodash"
import { useTable } from "../../hooks/useTable"
import { RequestMissingItemRowModal } from "./RequestMissingItemRowModal"

function TabTable({
  tabType,
  showRequestReviewModal,
  showRequestMissingItemModal,
  setShowRequestMissingItemModal,
  showRequestMissingItemRowModal,
  setShowRequestMissingItemRowModal,
  setShowRequestReviewModal,
  showRequestReviewRowModal,
  setShowRequestReviewRowModal,
  onRowSelectionChange,
  onSelectAll,
  showFiltersModal,
  setShowFiltersModal,
  ...props
}) {
  const { t } = useTranslation()

  const rowIdentifier = "id"

  const isRequestListTable = tabType === "Requests"
  const initialOrderField = ""
  const { tableData, setTableData, orderBy, setOrderBy, handleRowSelect, handleSelectAll, hasSelectedAll, selectedRowIds, clearSelections } = useTable({
    initialData: [],
    initialOrderBy: {
      field: initialOrderField,
      order: "asc",
      key: `${initialOrderField}_desc`,
    },
    rowIdentifier,
  })

  const [requestReviewOptions, setRequestReviewOptions] = useState([])
  const [loadingTableData, setLoadingTableData] = useState(true)
  const [canLoadMore, setCanLoadMore] = useState(false)
  const [page, setPage] = useState(1)
  const [selectedRow, setSelectedRow] = useState(null)
  const [query, setQuery] = useState("")
  const firstRender = useFirstRender()

  const [productGroupFilters, setProductGroupFilters] = useState([])
  const [productLineFilters, setProductLineFilters] = useState([])
  const [trademarkFilters, setTrademarkFilters] = useState([])
  const [segmentFilters, setSegmentFilters] = useState([])
  const [sellingEntityFilters, setSellingEntityFilters] = useState([])
  const [requestTypeFilters, setRequestTypeFilters] = useState([t("dealer-portal.review"), t("dealer-portal.missingItem")])
  const [selectedProductGroupFilter, setSelectedProductGroupFilter] = useState("")
  const [selectedProductLineFilter, setSelectedProductLineFilter] = useState("")
  const [selectedTrademarkFilter, setSelectedTrademarkFilter] = useState("")
  const [selectedSegmentFilter, setSelectedSegmentFilter] = useState("")
  const [selectedSellingEntityFilter, setSelectedSellingEntityFilter] = useState("")
  const [selectedRequestTypeFilter, setSelectedRequestTypeFilter] = useState("")

  const getHeaders = isRequestListTable ? getRequestListHeaders : getPriceListHeaders
  const renderer = isRequestListTable ? requestListRenderer : priceListRenderer
  const tableKeys = isRequestListTable ? requestListTableKeys : priceListTableKeys

  useEffect(() => {
    let mounted = true
    async function fetchTableData() {
      // const initialOrderField = isRequestListTable ? { field: "type", order: "asc", key: "type_desc" } : { field: "segment", order: "asc", key: "segment_desc" }
      setLoadingTableData(true)

      const response = await fetchPriceListsByTabType({
        tabType,
        query: "",
        // orderBy: initialOrderField,
        page: 1,
      })
      if (mounted) {
        setTableData(response.data)
        setPage(response.currentPage)
        setCanLoadMore(response.canLoadMore)
      }
      setLoadingTableData(false)
    }
    fetchTableData()
  }, [isRequestListTable, setTableData, tabType])

  useEffect(() => {
    let mounted = true

    async function startFetchingOnFiltersChange() {
      const response = await fetchPriceListsByTabType({
        tabType,
        query,
        orderBy,
        page: 1,
        selectedProductGroupFilters: selectedProductGroupFilter,
        selectedProductLineFilters: selectedProductLineFilter,
        selectedTrademarkFilters: selectedTrademarkFilter,
        selectedSegmentFilters: selectedSegmentFilter,
        selectedSellingEntityFilters: selectedSellingEntityFilter,
        selectedRequestTypeFilters: selectedRequestTypeFilter === "Review" ? "review" : selectedRequestTypeFilter === "Missing Item" ? "missingItem" : "",
      })
      if (mounted) {
        setTableData(response.data)
        setPage(response.currentPage)
        setCanLoadMore(response.canLoadMore)
      }
      setLoadingTableData(false)
    }

    if (!firstRender || isRequestListTable) {
      startFetchingOnFiltersChange()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedProductGroupFilter,
    selectedProductLineFilter,
    selectedTrademarkFilter,
    selectedSegmentFilter,
    selectedSellingEntityFilter,
    selectedRequestTypeFilter,
    tabType,
    query,
    orderBy,
    setTableData,
    isRequestListTable,
  ])

  useEffect(() => {
    if (isEmpty(props.priceLists.filters)) {
      props.actions.loadPriceListFilters()
      return
    }

    const filters = props.priceLists.filters
    setProductGroupFilters(filters.productGroup)
    setSegmentFilters(filters.segment)
    setProductLineFilters(filters.productLine)
    setTrademarkFilters(filters.trademark)
    setSellingEntityFilters(filters.sellingEntity)
  }, [props.actions, props.priceLists.filters])
  
  useEffect(() => { 
    async function startFetching() {
      const res = await getRequestReviewOptions()
      setRequestReviewOptions(res.data)
    }
    
    startFetching()
  }, [])
  
  const handleLoadMore = async () => {
    setLoadingTableData(true)
    const response = await fetchPriceListsByTabType({
      tabType,
      query,
      orderBy,
      page: page + 1,
      selectedProductGroupFilters: selectedProductGroupFilter,
      selectedProductLineFilters: selectedProductLineFilter,
      selectedTrademarkFilters: selectedTrademarkFilter,
      selectedSegmentFilters: selectedSegmentFilter,
      selectedSellingEntityFilters: selectedSellingEntityFilter,
    })
    setPage(page + 1)
    setTableData([...tableData, ...response.data])
    setCanLoadMore(response.canLoadMore)
    setLoadingTableData(false)
  }

  function handleRowSelection(row) {
    const newSelectedRowIds = handleRowSelect(row)
    if (onRowSelectionChange) {
      onRowSelectionChange(row, newSelectedRowIds.length)
    }

    setSelectedRow(row)
  }

  const handleResetFilters = () => {
    setSelectedProductGroupFilter("")
    setSelectedProductLineFilter("")
    setSelectedSellingEntityFilter("")
    setSelectedTrademarkFilter("")
    setSelectedSegmentFilter("")
    setSelectedRequestTypeFilter("")
  }

  const handleDownload = async () => {
    const res = await downloadPricelists()
    // {
    //   ...(query ? { query } : {}),
    //   ...(selectedProductGroupFilter ? { productGroup: selectedProductGroupFilter } : {}),
    //   ...(selectedProductLineFilter ? { productLine: selectedProductLineFilter } : {}),
    //   ...(selectedTrademarkFilter ? { trademark: selectedTrademarkFilter } : {}),
    //   ...(selectedSegmentFilter ? { segment: selectedSegmentFilter } : {}),
    //   ...(selectedSellingEntityFilter ? { sellingEntity: selectedSellingEntityFilter } : {}),
    //   order: orderBy.field,
    //   orderBy: orderBy.order,
    // }
    const date = new Date().toISOString().split("T")
    const dateNow = date[0].split("-").reverse().join("_")
    const timeNow = date[1].split(".")[0].split(":").join("_")

    downloadFile({
      data: res,
      fileName: `price-lists_${dateNow}_${timeNow}.csv`,
      fileType: "text/csv",
    })
  }

  const table = (
    <GenericTable
      data={tableData}
      headers={getHeaders(t)}
      keys={tableKeys}
      keyRenderer={renderer({
        t,
        rowIdentifier,
        selectedRowIds,
        handleRowCheck: handleRowSelection,
      })}
      activeSort={orderBy}
      onRowClick={handleRowSelection}
      onSort={setOrderBy}
      hasSelectedAll={hasSelectedAll}
      onSelectAll={(e) => {
        handleSelectAll()
        if (onRowSelectionChange) {
          onSelectAll(selectedRowIds.length !== tableData.length)
        }
      }}
    />
  )

  const cards = (
    <div className="cards-container">
      {!isRequestListTable && (
        <div className="card select-all-card">
          <input
            type="checkbox"
            className="row-selector"
            name="selectable"
            id="all-row-selector"
            onChange={(e) => {
              if (e.target.checked) {
                handleSelectAll()
              } else {
                clearSelections()
              }
              if (onRowSelectionChange) {
                onSelectAll(selectedRowIds.length !== tableData.length)
              }
            }}
          />
          <label htmlFor="all-row-selector" className="key">
            {t("dealer-portal.select-all")}
          </label>
        </div>
      )}
      {tableData.map((row) => {
        return (
          <div
            key={row[rowIdentifier]}
            className="card"
            onClick={() => {
              if (isRequestListTable) {
                setSelectedRow(row)
                if (row.requestType === "missingItem") {
                  setShowRequestMissingItemRowModal(true)
                } else if (row.requestType === "review") {
                  setShowRequestReviewRowModal(true)
                }
              }
            }}
          >
            {!isRequestListTable && (
              <input
                type="checkbox"
                className="row-selector"
                name="selectable"
                id=""
                checked={selectedRowIds.includes(row.id)}
                onChange={() => handleRowSelection(row)}
              />
            )}
            {getHeaders(t).map((header) => {
              const key = header.orderKey
              const value = row[key]

              return (
                <div className="row" key={header.orderKey}>
                  <div className="key">{header.title}</div>
                  <div className="value">{value}</div>
                </div>
              )
            })}
          </div>
        )
      })}
    </div>
  )

  const requestFilters = [
    {
      name: "Request Type",
      type: FilterType.singleSelect,
      dataset: requestTypeFilters,
      input: selectedRequestTypeFilter,
      output: (selectedRequestType) => {
        setSelectedRequestTypeFilter(selectedRequestType)
      },
    },
  ]

  const filters = [
    {
      name: "Segment",
      type: FilterType.singleSelect,
      dataset: segmentFilters,
      input: selectedSegmentFilter,
      output: (selectedSegmentFilter) => {
        setSelectedSegmentFilter(selectedSegmentFilter)
      },
    },
    {
      name: "Product Group",
      type: FilterType.singleSelect,
      dataset: productGroupFilters,
      input: selectedProductGroupFilter,
      output: (selectedProductGroupFilter) => {
        setSelectedProductGroupFilter(selectedProductGroupFilter)
      },
    },
    {
      name: "Product Line",
      type: FilterType.singleSelect,
      dataset: productLineFilters,
      input: selectedProductLineFilter,
      output: (selectedProductLineFilter) => {
        setSelectedProductLineFilter(selectedProductLineFilter)
      },
    },
    {
      name: "Trademark",
      type: FilterType.singleSelect,
      dataset: trademarkFilters,
      input: selectedTrademarkFilter,
      output: (selectedTrademarkFilter) => {
        setSelectedTrademarkFilter(selectedTrademarkFilter)
      },
    },
    {
      name: "Selling Entity",
      type: FilterType.singleSelect,
      dataset: sellingEntityFilters,
      input: selectedSellingEntityFilter,
      output: (selectSellingEntityFilter) => {
        setSelectedSellingEntityFilter(selectSellingEntityFilter)
      },
    },
  ]

  return (
    <div>
      <div className="filters-wrapper">
        <SearchWithFilter
          onSearch={debounce((value) => {
            if (value?.length > 2) {
              setQuery(value)
            } else if (value?.length === 0 && query?.length > 0) {
              setQuery(null)
            }
          }, 500)}
          showFiltersModal={() => setShowFiltersModal(true)}
          onResetFilters={handleResetFilters}
          showCounterBadge={
            selectedTrademarkFilter ||
            selectedProductGroupFilter ||
            selectedProductLineFilter ||
            selectedSegmentFilter ||
            selectedSellingEntityFilter ||
            selectedRequestTypeFilter
          }
          counterBadge={
            [
              selectedTrademarkFilter,
              selectedProductGroupFilter,
              selectedProductLineFilter,
              selectedSegmentFilter,
              selectedSellingEntityFilter,
              selectedRequestTypeFilter,
            ].filter(Boolean).length
          }
        />
        {!isRequestListTable && (
          <SimpleButton className="action-button" onClick={handleDownload}>
            {t("download.download")}
          </SimpleButton>
        )}
      </div>
      {showFiltersModal && (
        <FiltersModal
          filters={isRequestListTable ? requestFilters : filters}
          resetFilters={() => handleResetFilters()}
          closeFilters={() => setShowFiltersModal(false)}
        />
      )}
      {loadingTableData && (
        <div className="spinner-wrapper">
          <LoadingSpinner />
        </div>
      )}
      {!loadingTableData &&
        (tableData.length === 0 ? (
          <EmptyPageResults
            title={t("dealer-portal.no-pricelists-found")}
            subtitle={t("coltene_store.admin_order_history.no-orders-found-extra")}
          />
        ) : (
          <>
            {tableData.length > 0 && (
              <>
                {table}
                {cards}
              </>
            )}
          </>
        ))}
      {canLoadMore && (
        <div className="loadmore-wrapper">
          <SimpleButton className="load-more-button" onClick={() => handleLoadMore()}>
            {t("buttons.load_more")}
          </SimpleButton>
        </div>
      )}

      {showRequestReviewModal && (
        <RequestReviewModal
          rowIdentifier={rowIdentifier}
          options={requestReviewOptions}
          parts={tableData.filter((row) => selectedRowIds.includes(row[rowIdentifier]))}
          handleClose={() => setShowRequestReviewModal(false)}
        />
      )}
      {showRequestMissingItemModal && (
        <RequestMissingItem
          isRequestListTable={isRequestListTable}
          row={selectedRow}
          handleClose={() => {
            setSelectedRow(null)
            setShowRequestMissingItemModal(false)
          }}
        />
      )}
      {showRequestReviewRowModal && (
        <RequestReviewRowModal
          row={selectedRow}
          options={requestReviewOptions}
          handleClose={() => {
            setSelectedRow(null)
            setShowRequestReviewRowModal(false)
          }}
        />
      )}
      {showRequestMissingItemRowModal && (
        <RequestMissingItemRowModal
          row={selectedRow}
          isRequestListTable={isRequestListTable}
          handleClose={() => {
            setSelectedRow(null)
            setShowRequestMissingItemRowModal(false)
          }}
        />
      )}
    </div>
  )
}

function mapStateToProps({ priceLists }) {
  return {
    priceLists,
  }
}

function dispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        loadPriceListFilters,
      },
      dispatch
    ),
  }
}

export default connect(mapStateToProps, dispatchToProps)(TabTable)
