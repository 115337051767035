import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import { useHistory } from 'react-router-dom'
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import DOMPurify from "dompurify"
import { snakeCase, camelCase } from 'lodash'
import SimpleButton from '../../common/SimpleButton'
import LoadingSpinner from "../../common/LoadingSpinner"
import ToggleSwitchShort from '../../common/ToggleSwitchShort'
import { markAllNotificationsRead, markNotificationRead, loadCurrentUserNotifications } from '../../../actions/notifications'
import { formatDateDayMonthYear, convertToSimpleDate, convertToSimpleDateWithTime} from '../../../utils/filters/date'
import './notifications-timeline.scss'

const isCycleStatus = (status) => ["cycle_complete", "cycle_aborted", "cycle_interrupted", "cycle_fault"].includes(status)
const isUpdateStatus = (status) => ["software_update_available", "firmware_update"].includes(status)

const NotificationsTimeline = (props) => {
  const { t } = useTranslation()
  const history = useHistory()
  const {
    notifications,
    closeTimeline,
    isUnreadNotifications,
    handleIsUnreadNotificationsChange,
    products,
    role,
    notificationsLoading
  } = props
  const [timeline, setTimeline] = useState({})
  const [isLoadingMarkAll, setIsLoadingMarkAll] = useState(false)
  const [showSpinner, setShowSpinner] = useState(null)

  useEffect(() => {
    const groupedNotifications = {}
    let unreadNotifications = 0
    notifications?.forEach((notification) => {
      const formattedDate = formatDateDayMonthYear(notification.notification_date)

      if (!groupedNotifications[formattedDate]) {
        groupedNotifications[formattedDate] = []
      }

      if(camelCase(notification.type) === 'firmwareUpdate' && notification?.models.length > 0) {
        const splittedItems = notification.models.map(e => ({
          ...notification,
          models: e
        }))
        groupedNotifications[formattedDate].push(...splittedItems)
      } else {
        groupedNotifications[formattedDate].push(notification)
      }

      if (!notification.is_read) {
        unreadNotifications++
      }
    });
    setTimeline(groupedNotifications)
  }, [notifications])

  const handleMarkAllAsRead = () => {
    setIsLoadingMarkAll(true)

    if (!isLoadingMarkAll && notifications?.filter((item) => !item.is_read)?.length > 0) {
      setShowSpinner(true)

      props.actions.markAllNotificationsRead(props.cognitoSub)
        .then(() => {
          props.actions.loadCurrentUserNotifications(props.cognitoSub, isUnreadNotifications)
        })
        .finally(() => {
          setIsLoadingMarkAll(false)
          setShowSpinner(false)
        
        })
    }
  }

  const handleMarkNotificationAsRead = (notificationId, type) => {
    setShowSpinner(true)

    props.actions.markNotificationRead(props.cognitoSub, notificationId, type)
      .then(() => {
        props.actions.loadCurrentUserNotifications(props.cognitoSub, isUnreadNotifications)
      })
      .finally(() => {
        setShowSpinner(false)
      })
  }

  const handleViewProductPage = (serialNumber, date) => {
    history.push({
      pathname: `/products/${serialNumber}`,
      state: {
        cycleFaultDate: convertToSimpleDate(date),
        timeStamp: Date.now()
      },
    })
    closeTimeline()
  }

  const getSoftwareVersionForUpdateAvailable = (eventBody) => {
    if(!eventBody) return ''
    const words = eventBody.split(' ')
    if(words.length === 1) return ''
    const index = words.findIndex(e => e.includes('available'))
    if(!index) return ''
    return words[index-1]
  }

  const dentistRoles = ['dentist', 'dentist-notifications', 'dentist-aperture']

  const getSoftwareUpdateUI = (version, model) => {
    const ui = (
        <>
          <span className="firmware-update">
              {t("notifications.firmware-update-part1")} {version}&nbsp;
          </span>
          {t("notifications.firmware-update-part2")}&nbsp;{model}.&nbsp;{t("notifications.firmware-update-part3")}.
        </>
      )
      
    return (
      <div className="notification-body">
        <div className='one-line-format'>
          {ui}
        </div>
      </div>
    )
  }

  const getInfoForMaintenanceAvailable = (msg) => {
    let value = ''
    if(!msg) return value
    const words = msg.split(' ')
    value = words[words.length - 1].replace('.', '')
    return value
  }

  const getMessageTitle = (item) => {
    switch (snakeCase(item.type)){
      case 'device':
        return (
          <>
            <p className="notification-title">{products?.[item.device_sn].model??''}</p>
            {item.device_sn && 
              <p className="notification-sn">
                &nbsp;&nbsp;&nbsp;&nbsp;{`${t("sn")}: ${item.device_sn}`}
              </p>
            }
          </>
        )
      case 'firmware_update':
        return <p className="notification-title">{ item.models }</p>
      case 'account':
        return (
          <p className="notification-title">{t("filters.account")}</p>
        )
      case 'bulletin':
        return (
          <p className="notification-title">{t("filters.bulletin")}</p>
        )
        case 'newsletter':
          return (
            <p className="notification-title">{t("filters.newsletter")}</p>
          )
      default:
        return (
          <>
            <p className="notification-sn">{`${t(`filters.${snakeCase(item?.type)}`)}`}</p>
          </>
        )
    } 
  }

  const getMessageBody = (item) => {
    if(item.type === 'device'){
      const eventType = item?.event_id?.split('-')[1]

      let message = ''

      switch(eventType){
        case 'cycle_fault':
          const cycleNumber = item.event_data?.cycle_number ? `${t("cycles.cycle.cycle")} ${item.event_data?.cycle_number || ""}` : ""

          message = (
            <div 
              className="cf-notification-body"
            >
              {`${cycleNumber} CF-${item.event_data?.cycle_fault}`}
            </div>
          )
          break
        case 'cycle_complete':
        case 'cycle_aborted':
        case 'cycle_interrupted':
          message = (
            <div 
              className="notification-body"
            >
              {item.event_body ? item.event_body: eventType.split('_').join(' ').toUpperCase()}
              {item.device_sn &&
                <SimpleButton
                  className="submit-button"
                  >
                  {t("labels.view_details")}
                </SimpleButton>
              }
            </div>
          )
          break
        case 'maintenance_notification_trigger_cycle':
          message = (
            <div 
              className="notification-body reminder-space"
            >
              {t("notifications.maintenance-available-message-part0")}&nbsp;{t("notifications.maintenance-available-message-part1")}&nbsp;{t("notifications.maintenance-available-message-part2")}&nbsp;{getInfoForMaintenanceAvailable(item.event_body)}.
            </div>
          )
          break
        case 'maintenance_notification_trigger_date':
          message = (
            <div 
              className="notification-body reminder-space"
            >
              {t("notifications.maintenance-available-message-part0")}&nbsp;{t("notifications.maintenance-available-message-part1")}&nbsp;{t("notifications.maintenance-available-message-part3")}&nbsp;{getInfoForMaintenanceAvailable(item.event_body)}.
            </div>
          )
          break
        case 'software_update_available':
          message = (getSoftwareUpdateUI(
            getSoftwareVersionForUpdateAvailable(item.event_body),
            products?.[item.device_sn].model??''
          ))
          break
        default:
          message = (
            <div 
              className="notification-body"
            >
              {item.event_body ? item.event_body: eventType.split('_').join(' ').toUpperCase()}
            </div>
          )
      }

      return message
    }

    switch (snakeCase(item.type)){
      case 'firmware_update':
        return getSoftwareUpdateUI(
          role,
          item.firmware_version,
          item.models
        )
      default:
        return (
          <div className="notification-body" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(`<p>${item.message || item.title}</p>`)}} />
        )
    }
  }
  
  const getClassName = (item) => {
    let status = item?.event_id?.split("-")[1]
    if (item.type !== "device" && !dentistRoles.includes(role)) {
      status = snakeCase(item?.type)
    }
    
    const isReadClassName = item.is_read ? "" : "unread"
    const cursorPointerClassName = isCycleStatus(status) || isUpdateStatus(status) ? "cursor-pointer" : ""

    return `notification-item ${isReadClassName} ${cursorPointerClassName}`
  }  
  
  const handleItemClick = (e, item) => {
    const status = item?.event_id?.split('-')[1];
    if (item.is_read === 0) {
      handleMarkNotificationAsRead(item.id, item.type);
    }

    if (item.type === 'device' && isCycleStatus(status)) {
      history.push({
        pathname: `/products/${item.device_sn}`,
        state: {
          cycleFaultDate: convertToSimpleDate(item.notification_date),
          timeStamp: Date.now(),
          cycleFault: item.event_data?.cycle_fault,
          cycleNumber: item.event_data?.cycle_number
        },
      })  
      closeTimeline()
    } else if (!dentistRoles.includes(role)){
      history.push({
        pathname: "/firmware",
        state: { modelInNotification: item.models },
      })
      closeTimeline()
    }
  };
  
  return (
    <div className="notifications-timeline-page">
      <div className="notifications-overflow" onClick={()=> closeTimeline()}/>
      <div className="notifications-timeline-wrapper">
        <div className="notifications-header">
          <div className="notifications-back-button" onClick={() => closeTimeline()}>
            <p className="title">{t("labels.notifications")}</p>
          </div>
          <div className="notifications-mark-as-read" onClick={() => handleMarkAllAsRead()}>{t("notifications.mark-all-read")}</div>
          <div className="notifications-show-unread">
            <p className="input-option">{t("notifications.only-show-unread")}</p>
            <div 
              className="status-option"
              onClick = {() => handleIsUnreadNotificationsChange()}
            >
              <ToggleSwitchShort checked={isUnreadNotifications} />
            </div>
          </div>
        </div>
        <div className="notifications-body">
          {Object.keys(timeline).map((date, index) => 
            <div key={date} className="notifications-date-wrapper">
              {timeline[date].map((item, index) => {
                return (
                <div
                  key={index}
                  className={getClassName(item)}
                  onClick={(e) => handleItemClick(e, item)}
                >
                  <div className="title-wrapper">
                    {getMessageTitle(item)}
                  </div>
                  {getMessageBody(item)}
                  {!item.is_read && (<div className="is-unread"/>)}
                  <p className='notification-diff'>{convertToSimpleDateWithTime(new Date(item.notification_date))}</p>
                </div>)
              })}
            </div>)
          }
          {
            Object.keys(timeline).length === 0 &&
            <div className="no-unread-notifications">
              <p>{t("notifications.no-unread-notifications")}</p>
            </div>
          }
        </div>
        {(showSpinner || notificationsLoading) && (
          <div className={"spinner-wrapper"}>
            <LoadingSpinner />
          </div>
        )}
      </div>
    </div>
  )
}

function stateToProps({ authedUser }) {
  return {
    cognitoSub: authedUser?.cognitoSub
  }
}

function dispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        markAllNotificationsRead,
        markNotificationRead,
        loadCurrentUserNotifications
      },
      dispatch
    ),
  }
}

export default connect(stateToProps, dispatchToProps)(NotificationsTimeline)