import { useCallback, useMemo, useState } from "react"

export function useTable({ rowIdentifier, initialData = [], initialOrderBy, ...props }) {
  const [tableData, setTableData] = useState(initialData)
  const [selectedRowIds, setSelectedRowIds] = useState([])
  const [orderBy, setOrderBy] = useState(initialOrderBy)
  const [selectionLimit, setSelectionLimit] = useState(props?.selectionLimit)

  const handleRowSelect = useCallback(
    (row) => {
      const id = row[rowIdentifier]
      let newSelectedRowIds;
      if (selectedRowIds.includes(id)) {
        newSelectedRowIds = selectedRowIds.filter((rowId) => rowId !== id)
      } else {
        newSelectedRowIds = [...selectedRowIds, id]
      }
      setSelectedRowIds(newSelectedRowIds)
      return newSelectedRowIds
    },
    [rowIdentifier, selectedRowIds]
  )

  const handleSelectAll = useCallback(() => {
    if (selectedRowIds.length === tableData.length) {
      setSelectedRowIds([])
    } else {
      setSelectedRowIds(tableData.map((row) => row[rowIdentifier]))
    }
  }, [rowIdentifier, selectedRowIds.length, tableData])
  
  const clearSelections = useCallback(() => {
      setSelectedRowIds([])
  }, [])
  
  const hasRowSelections = useMemo(() => { 
    return selectedRowIds.length > 0
  }, [selectedRowIds.length])

  const hasSelectedAll = useMemo(() => selectedRowIds.length === tableData.length, [selectedRowIds.length, tableData.length])

  return {
    initialData,
    tableData,
    setTableData,
    orderBy,
    setOrderBy,
    selectedRowIds,
    handleRowSelect,
    handleSelectAll,
    hasRowSelections,
    hasSelectedAll,
    clearSelections
  }
}