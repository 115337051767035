import React, { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import DOMPurify from "dompurify"
import FormProductDetails from "./components/FormProductDetails"
import FormProductImages from "./components/FormProductImages"
import FormProductDescription from "./components/FormProductDescription"
import FormProductPrices from "./components/FormProductPrices"
import PageModal from "../common/PageModal"
import SimpleButton from "../common/SimpleButton"
import LoadingSpinner from "../common/LoadingSpinner"
import WrappedTabs from "../common/WrappedTabs"
import {
  createProduct,
  updateProduct,
  updateProductPrices,
  loadAdminStorePricePlans,
  loadAdminStoreCountries,
  loadAdminStoreProductById
} from "../../actions/adminStore"
import {
  getAdminImageSignedUrl,
  putAdminProductImage,
  postAdminProductPrice,
  patchAdminProductPrice,
  deleteAdminProductPrice,
} from "../../utils/requests/productsAPI"
import { 
  validateForm, 
  getStoreProductDetailsValidationSchema, 
  getStoreProductImagesValidationSchema, 
  getStoreProductDescriptionValidationSchema,
  getStoreProductPricesValidationSchema
} from "../../utils/formValidation"
import { JSONdeepClone } from "../../utils/functions"
import { adminStoreWritePermission } from "../../utils/permissionValidation"
import "./add-product-modal.scss"

const defaultFormState = {
  productDetails: {
    partNumber: "",
    productMainTitle: "",
    productSubtitle: "",
    isDigital: false,
    categoryId: null,
    serviceId: null,
    planType: null,
  },
  images: [],
  description: {
    productDescription: "",
    specification: "",
    features: [],
    relatedProductIds: [],
  },
  prices: []
}

const AddEditProductModal = (props) => {
  const { t } = useTranslation()
  const { editedProduct, handleClose, navigateToPreview, isPreviewUpdate } = props
  const [productDetailsFormData, setProductDetailsFormData] = useState(JSONdeepClone(defaultFormState.productDetails))
  const [productImagesFormData, setProductImagesFormData] = useState([])
  const [productDescriptionFormData, setProductDescriptionFormData] = useState(JSONdeepClone(defaultFormState.description))
  const [productPricesFormData, setProductPricesFormData] = useState([])
  const [initialProductDetailsFormData, setInitialProductDetailsFormData] = useState(JSON.stringify(defaultFormState.productDetails))
  const [initialProductImagesFormData, setInitialProductImagesFormData] = useState(JSON.stringify([]))
  const [initialProductDescriptionFormData, setInitialProductDescriptionFormData] = useState(JSON.stringify(defaultFormState.description))
  const [initialProductPricesFormData, setInitialProductPricesFormData] = useState(JSON.stringify([]))
  const [errors, setErrors] = useState({})
  const [showSpinner, setShowSpinner] = useState(false)
  const tabs = useMemo(
    () => [
      {
        id: 0,
        name: "Product Details"
      },
      {
        id: 1,
        name: "Product Images"
      },
      {
        id: 2,
        name: "Product Description"
      },
    ],
    []
  )
  const [activeTab, setActiveTab] = useState(tabs[0])

  useEffect(() => {
    if (props.pricePlans.length === 0) {
      props.actions.loadAdminStorePricePlans()
    }
    
    if (props.countries.length === 0) {
      props.actions.loadAdminStoreCountries()
    }
  }, [props.actions])

  useEffect(() => {
    if (editedProduct) {
      const detailsFormData = {
        partNumber: editedProduct.partNumber || "",
        productMainTitle: editedProduct.title || "",
        productSubtitle: editedProduct.subtitle || "",
        isDigital: editedProduct.isDigital || false,
        categoryId: editedProduct.categoryId || null,
        serviceId: editedProduct.serviceId || null, 
      }

      const imagesFormData = editedProduct.images?.map((image) => { return { imageSrc: image.url }}) || []

      const descriptionFormData = {
        productDescription: editedProduct.description || "",
        specification: editedProduct.specification || "",
        features: editedProduct.features?.map((feature) => { return { title: feature.title, details: feature.details }}) || [],
        relatedProductIds: editedProduct.relatedProducts?.map((product) => product.id) || [],
      }

      if (descriptionFormData.features.length === 0) {
        descriptionFormData.features.push({ title: "", details: "" })
      }

      const pricesFormData = editedProduct.prices?.map((price) => {
        const priceFormData = {
          id: price.id,
          amount: price.amount || 0,
          currencyCode: price.currencyCode || "",
          countryCode: price.countryCode || "",
          trialBusinessRuleId: price.trialBusinessRuleId || null,
        }

        if (editedProduct.isDigital) {
          priceFormData.recurrenceInterval = price.recurrenceInterval
          priceFormData.recurrenceIntervalCount = price.recurrenceIntervalCount
          detailsFormData.planType = price.recurrenceInterval
        }

        return priceFormData
      }) || []

      setProductDetailsFormData(detailsFormData)
      setProductImagesFormData(imagesFormData)
      setProductDescriptionFormData(descriptionFormData)
      setProductPricesFormData(pricesFormData)

      setInitialProductDetailsFormData(JSON.stringify(detailsFormData))
      setInitialProductImagesFormData(JSON.stringify(imagesFormData))
      setInitialProductDescriptionFormData(JSON.stringify(descriptionFormData))
      setInitialProductPricesFormData(JSON.stringify(pricesFormData))
    }
  }, [editedProduct])

  useEffect(() => {
    setShowSpinner(props.isLoading)
  }, [props.isLoading])

  const handleChangeTab = (tabKey) => {
    const tab = tabs.find((tab) => tab.id === tabKey)
    setActiveTab(tab)
  }

  const hasProductChanges = () => {
    return JSON.stringify(productDetailsFormData) !== initialProductDetailsFormData ||
      JSON.stringify(productImagesFormData) !== initialProductImagesFormData ||
      JSON.stringify(productDescriptionFormData) !== initialProductDescriptionFormData ||
      JSON.stringify(productPricesFormData) !== initialProductPricesFormData
  }

  const addEditProductModalFooterContent = () => {
    return (
      <>
        <SimpleButton
          className="cancel-button"
          onClick={() => handleCloseModal()}
        >
          {t("labels.cancel")}
        </SimpleButton>
        <SimpleButton 
          className="submit-button"
          disabled={showSpinner || !hasProductChanges()} 
          onClick={editedProduct ? handleEditProduct : handleSaveProduct}
          requiredPermission={adminStoreWritePermission}
        >
          {t("labels.save")}
        </SimpleButton>
      </>
    )
  }

  const buildProductDetailsRequestData = () => {
    return {
      partNumber: productDetailsFormData.partNumber.trim(),
      title: productDetailsFormData.productMainTitle.trim(),
      subtitle: productDetailsFormData.productSubtitle.trim(),
      categoryId: productDetailsFormData.categoryId,
      isDigital: productDetailsFormData.isDigital,
      ...(productDetailsFormData.isDigital && { serviceId: productDetailsFormData.serviceId }),
    }
  }

  const buildProductImagesRequestData = async () => {
    const imagesToUpload = productImagesFormData.filter((image) => image.isNewImage)
    const imagesToKeep = productImagesFormData.filter((image) => !image.isNewImage)

    const signUrlsPromises = imagesToUpload.map((image, index) => {
      return getAdminImageSignedUrl(`image-${index}.${image.extension}`)
    })

    const imageSignedUrls = await Promise.all(signUrlsPromises)

    const saveImagesPromises = imageSignedUrls.map((image, index) => {
      return putAdminProductImage(image.url, imagesToUpload[index].blob, imagesToUpload[index].extension)
    })

    await Promise.all(saveImagesPromises)

    const mappedSavedImages = imageSignedUrls.map((image) => { return { imageSrc: image.path }})

    const productImages = [...imagesToKeep, ...mappedSavedImages]

    setProductImagesFormData(productImages)

    return productImages.map((image, index) => {
      return { order: index + 1, url: image.imageSrc }
    })
  }

  const buildProductDescriptionRequestData = () => {
    const vaadinRichTextHtmlEmptyState = '<p><br></p>'

    return {
      description: productDescriptionFormData.productDescription.trim(),
      specification: productDescriptionFormData.specification.trim() !== vaadinRichTextHtmlEmptyState ? DOMPurify.sanitize(productDescriptionFormData.specification.trim()) : "",
      features: productDescriptionFormData.features.filter((f) => f.title?.trim()?.length > 0 || f.details?.trim()?.length > 0).map((feature, index) => {
        return { 
          order: index + 1, 
          title: feature.title.trim(), 
          details: DOMPurify.sanitize(feature.details.trim()) 
        }
      }),
      relatedProductIds: productDescriptionFormData.relatedProductIds.filter(Number),
    }
  }

  const buildProductPricesRequestData = (isEdit) => {
    return productPricesFormData.map((price) => {
      return {
        amount: Number(parseFloat(price.amount)),
        currencyCode: price.currencyCode,
        countryCode: price.countryCode,
        ...((isEdit || price.trialBusinessRuleId) && { trialBusinessRuleId: price.trialBusinessRuleId }),
        ...(productDetailsFormData.isDigital && { recurrenceInterval: productDetailsFormData.planType }),
        ...(productDetailsFormData.isDigital && { recurrenceIntervalCount: 1 }),
      }
    })
  }

  const validateFormFields = async (detailsFormData, imagesFormData, descriptionFormData, pricesFormData) => {
    const productDetailsValidationSchema = getStoreProductDetailsValidationSchema()
    const productImagesValidationSchema = getStoreProductImagesValidationSchema()
    const productDescriptionValidationSchema = getStoreProductDescriptionValidationSchema()
    const productPricesValidationSchema = getStoreProductPricesValidationSchema(productDetailsFormData.isDigital)

    const fullDetailsFormData = {
      ...detailsFormData,
      ...(productDetailsFormData.isDigital && { planType: productDetailsFormData.planType }),
    }

    const [detailsValidation, imagesValidation, descriptionValidation, pricesValidation] = await Promise.all([
      validateForm(productDetailsValidationSchema, fullDetailsFormData),
      validateForm(productImagesValidationSchema, imagesFormData),
      validateForm(productDescriptionValidationSchema, descriptionFormData),
      validateForm(productPricesValidationSchema, pricesFormData),
    ])

    const mappedDetailsErrors = {
      ...detailsValidation,
      productMainTitle: detailsValidation.title, 
      productSubtitle: detailsValidation.subtitle
    }
  
    const mappedImagesErrors = Object.keys(imagesValidation).map((key) => {
      const index = key.split(".")[0]
      const indexWithRemovedOpeningBracket = index.slice(1, -1)
      const indexWithRemovedClosingBracket = indexWithRemovedOpeningBracket.substring(1, indexWithRemovedOpeningBracket.length - 1)
      
      return Number(indexWithRemovedClosingBracket)
    })

    const mappedDescriptionErrors = {
      description: descriptionValidation.description,
      specification: descriptionValidation.specification,
      features: Object.keys(descriptionValidation)
        .filter((key) => key.includes("features"))
        .map((key) => {
          const index = key.split(".")[0]
          const property = key.split(".")[1]

          const indexWithRemovedOpeningBracketAndFeatures = index.slice(9, -1)
          const indexWithRemovedClosingBracket = indexWithRemovedOpeningBracketAndFeatures.substring(1, indexWithRemovedOpeningBracketAndFeatures.length - 1)

          return `${indexWithRemovedClosingBracket}-${property}`
        }),
      relatedProductIds: Object.keys(descriptionValidation)
        .filter((key) => key.includes("relatedProductIds"))
        .map((key) => {
          const indexWithRemovedOpeningBracketAndRelatedProductIds = key.slice(18, -1)
          const indexWithRemovedClosingBracket = indexWithRemovedOpeningBracketAndRelatedProductIds.substring(1, indexWithRemovedOpeningBracketAndRelatedProductIds.length - 1)

          return Number(indexWithRemovedClosingBracket)
        })
    }

    const mappedPricesErrors = Object.keys(pricesValidation).map((key) => {
      const index = key.split(".")[0]
      const property = key.split(".")[1]

      const indexWithRemovedOpeningBracket = index.slice(1, -1)
      const indexWithRemovedClosingBracket = indexWithRemovedOpeningBracket.substring(1, indexWithRemovedOpeningBracket.length - 1)
      
      return `${detailsFormData.isDigital ? "digital" : "physical"}-${indexWithRemovedClosingBracket}-${property}`
    })

    const newErrors = { 
      details: mappedDetailsErrors, 
      images: mappedImagesErrors, 
      description: mappedDescriptionErrors, 
      prices: mappedPricesErrors
    }

    setErrors(newErrors)

    if (Object.keys(detailsValidation).length > 0 ||Object.keys(descriptionValidation).length > 0 || Object.keys(pricesValidation).length > 0 || Object.keys(imagesValidation).length > 0) {
      toast.dismiss()
      toast.error(t("error.fill_all_fields"))

      return false
    }

    return true
  }

  const handleSaveProduct = async () => {
    try {
      setShowSpinner(true)

      const productDetailsRequestData = buildProductDetailsRequestData()
      const productImagesRequestData = await buildProductImagesRequestData()
      const productDescriptionRequestData = buildProductDescriptionRequestData()
      const productPricesRequestData = buildProductPricesRequestData()

      const hasValidForm = await validateFormFields(productDetailsRequestData, productImagesRequestData, productDescriptionRequestData, productPricesRequestData)

      if (!hasValidForm) {
        setShowSpinner(false)

        return
      }

      props.actions.createProduct({
        ...productDetailsRequestData,
        ...productDescriptionRequestData, 
        images: productImagesRequestData,
        prices: productPricesRequestData,
      })
        .then(async (response) => {
          handleCloseModal()

          navigateToPreview(response?.value?.id || {})

          setShowSpinner(false)
        })
        .catch(() => {
          setShowSpinner(false)
        })
    } catch (error) {
      console.log("Error on save product", error)

      toast.dismiss()
      toast.error(t("error.something_wrong"))

      setShowSpinner(false)
    }
  }

  const handleEditProduct = async () => {
    try {
      setShowSpinner(true)

      const isEdit = true

      const productDetailsRequestData = buildProductDetailsRequestData()
      const productImagesRequestData = await buildProductImagesRequestData()
      const productDescriptionRequestData = buildProductDescriptionRequestData()
      const productPricesRequestData = buildProductPricesRequestData(isEdit)

      const hasValidForm = await validateFormFields(productDetailsRequestData, productImagesRequestData, productDescriptionRequestData, productPricesRequestData)

      if (!hasValidForm) {
        setShowSpinner(false)

        return
      }

      const hasPriceChanges = JSON.stringify(productPricesFormData) !== initialProductPricesFormData

      const hasProductChanges = JSON.stringify(productDetailsFormData) !== initialProductDetailsFormData ||
        JSON.stringify(productImagesFormData) !== initialProductImagesFormData ||
        JSON.stringify(productDescriptionFormData) !== initialProductDescriptionFormData
      
      const updatePromises = []
      
      if (hasProductChanges) {
        updatePromises.push(props.actions.updateProduct(editedProduct.id, {
          ...productDetailsRequestData,
          ...productDescriptionRequestData, 
          images: productImagesRequestData,
          isPublished: editedProduct.isPublished,
        }))
      }

      if (hasPriceChanges) {
        const initialPrices = JSON.parse(initialProductPricesFormData)

        const pricesToDeletePromises = initialPrices
          .filter((initialPrice) => {
            return !productPricesFormData.some((currentPrice) => currentPrice.id === initialPrice.id)
          })
          .map((price) => { return deleteAdminProductPrice(editedProduct.id, price.id)})

        await Promise.all(pricesToDeletePromises)

        const pricesToUpdate = productPricesFormData
          .map((currentPrice, originalIndex) => { return { ...currentPrice, originalIndex }})
          .filter((currentPrice) => {
            return initialPrices.some((initialPrice) => 
              (initialPrice.id === currentPrice.id) &&
              (initialPrice.amount !== currentPrice.amount || 
                initialPrice.currencyCode !== currentPrice.currencyCode || 
                initialPrice.countryCode !== currentPrice.countryCode || 
                initialPrice.trialBusinessRuleId !== currentPrice.trialBusinessRuleId
              ))
          })
          .map((price) => { return patchAdminProductPrice(productPricesRequestData[price.originalIndex], editedProduct.id, price.id)})

        const pricesToAddPromises = productPricesFormData
          .map((currentPrice, originalIndex) => { return { ...currentPrice, originalIndex }})
          .filter((currentPrice) => {
            return !initialPrices.some((initialPrice) => initialPrice.id === currentPrice.id)
          })
          .map((price) => { return postAdminProductPrice(productPricesRequestData[price.originalIndex], editedProduct.id)})

        updatePromises.push(...pricesToUpdate, ...pricesToAddPromises)
      }

      const updatedProduct = await Promise.all(updatePromises)

      props.actions.updateProductPrices(updatedProduct[0]?.value?.prices || [], editedProduct.id)

      handleCloseModal()

      if (navigateToPreview) {
        navigateToPreview(editedProduct.id)
      }

      if (isPreviewUpdate) {
        props.actions.loadAdminStoreProductById(editedProduct.id)
      }

      setShowSpinner(false)
    } catch (error) {
      console.log("Error on edit product", error)

      toast.dismiss()
      toast.error(t("error.something_wrong"))

      setShowSpinner(false)
    }
  }

  const handleCloseModal = () => {
    if (handleClose) {
      handleClose()
    }
  }

  return (
    <div className="add-product-modal">
      <PageModal
        toggle
        onToggle={() => handleCloseModal()}
        title={editedProduct ? t("coltene_store.create_product.edit-product") : t("coltene_store.create_product.add-new-product")}
        footerContent={addEditProductModalFooterContent()}
      >
        <div className="add-product-modal-content-wrapper">
          <WrappedTabs tabs={tabs} activeTab={activeTab?.id} changeTab={handleChangeTab} />
          <div className="product-modal-tab">
            <div className={activeTab?.id === 0 ? 'visible' : 'hidden'}>
              <FormProductDetails
                isEditMode={editedProduct ? true : false}
                productDetailsFormData={productDetailsFormData}
                productDetailsErrors={errors.details}
                updateProductDetails={(updatedProductDetails) => setProductDetailsFormData(updatedProductDetails)}
              />
              <div className="section-delimiter" />
              <FormProductPrices
                isEditMode={editedProduct ? true : false}
                pricesFormData={productPricesFormData}
                productPricesErrors={errors.prices}
                resetPricesErrors={() => setErrors({ ...errors, prices: [] })}
                isDigital={productDetailsFormData.isDigital}
                updatePrices={(updatedPrices) => setProductPricesFormData(updatedPrices)}
              />
            </div>
            <div className={activeTab?.id === 1 ? 'visible' : 'hidden'}>
              <FormProductImages
                productImagesFormData={productImagesFormData}
                productImagesErrors={errors.images}
                updateProductImages={(updatedProductImages) => setProductImagesFormData(updatedProductImages)}
              />
            </div>
            <div className={activeTab?.id === 2 ? 'visible' : 'hidden'}>
              <FormProductDescription
                productId={editedProduct?.id}
                descriptionFormData={productDescriptionFormData}
                productDescriptionErrors={errors.description}
                resetFeaturesErrors={() => setErrors({ ...errors, description: { ...errors.description, features: [] } })}
                updateDescription={(updatedDescription) => setProductDescriptionFormData(updatedDescription)}
              />
            </div>
          </div>
        </div>
      </PageModal>
      {showSpinner && (
        <div className="spinner-wrapper">
          <LoadingSpinner />
        </div>
      )}
    </div>
  )
}

function stateToProps({ adminStore }) {
  return {
    isLoading: adminStore?.productLoading,
    countries: adminStore?.countries || [],
    pricePlans: adminStore?.pricePlans || [],
  }
}

function dispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        createProduct,
        updateProduct,
        updateProductPrices,
        loadAdminStoreCountries,
        loadAdminStorePricePlans,
        loadAdminStoreProductById
      },
      dispatch
    ),
  }
}

export default connect(stateToProps, dispatchToProps)(AddEditProductModal)
