import {_baseDeleteFetch, _baseGetFetch, _basePatchFetch, _basePostFetch, _basePutFetch, ApiEnum} from './baseFetch'

const path = "/companies"

export const getAllBranches = () => {
  return _baseGetFetch(`${path}/branches`, {},  ApiEnum.admin)
}

export const getAllCompanies = () => {
  return _baseGetFetch(`${path}/list`, {},  ApiEnum.admin)
}

export const addCompany = (bodyParams) => {
  return _basePostFetch(`${path}`, {bodyParams},  ApiEnum.admin)
}

export const updateCompany = (companyId, bodyParams) => {
  return _basePutFetch(`${path}/${companyId}`, {bodyParams},  ApiEnum.admin)
}

export const verifyCompany = (companyId, bodyParams) => {
  return _basePatchFetch(`${path}/${companyId}/verify`, {bodyParams},  ApiEnum.admin)
}

export const deleteCompany = (companyId, bodyParams) => {
  return _baseDeleteFetch(`${path}/${companyId}`, {bodyParams},  ApiEnum.admin)
}

export const getBranchById = (companyId) => {
  return _baseGetFetch(`${path}/${companyId}/branches`, {},  ApiEnum.admin)
}

export const addBranch = (companyId, bodyParams) => {
  return _basePostFetch(`${path}/${companyId}/branches`, {bodyParams},  ApiEnum.admin)
}

export const updateBranch = (companyId, branchId, bodyParams) => {
  return _basePutFetch(`${path}/${companyId}/branches/${branchId}`, {bodyParams},  ApiEnum.admin)
}

export const deleteBranch = (companyId, branchId, bodyParams) => {
  return _baseDeleteFetch(`${path}/${companyId}/branches/${branchId}`, {bodyParams},  ApiEnum.admin)
}

// -----------------------------------------------------------
// Members API
// -----------------------------------------------------------

export const getCurrentCompany = () => {
  return _baseGetFetch("/users/current/company", {},  ApiEnum.admin)
}

export const getMembersByCompanyId = (companyId, params) => {
  return _baseGetFetch(`${path}/${companyId}/members`, params, ApiEnum.admin)
}

export const getRequestsByCompanyId = (companyId, bodyParams) => {
  return _baseGetFetch(`${path}/${companyId}/requests`, bodyParams,  ApiEnum.admin)
}

export const inviteUserToCompany = (companyId, payload) => {
  return _basePostFetch(`${path}/${companyId}/requests/invite`, payload,  ApiEnum.admin)
}

export const approveRejectUser = (companyId, id, payload) => {
  return _basePatchFetch(`${path}/${companyId}/requests/${id}`, payload,  ApiEnum.admin)
}

export const deleteUserFromCompany = (companyId, uuid) => {
  return _baseDeleteFetch(`${path}/${companyId}/members/${uuid}`, {},  ApiEnum.admin)
}

export const getMembersFilterOptions = (companyId) => {
  return _baseGetFetch(`${path}/${companyId}/members/filter-options`, {},  ApiEnum.admin)
}

export const editMember = (companyId, uuid, payload) => {
  return _basePatchFetch(`${path}/${companyId}/members/${uuid}`, payload,  ApiEnum.admin)
}

export const updateMemberStatus = (companyId, uuid, payload) => {
  return _basePatchFetch(`${path}/${companyId}/members/${uuid}`, payload,  ApiEnum.admin)
}


// export const getMembersByCompanyAndBranchId = (companyId, branchId) => {
//   return _baseGetFetch(`${path}/${companyId}/branches/${branchId}/members`, {},  ApiEnum.admin)
// }

// export const getAdminViewMember = (companyId, uuid) => {
//   return _baseGetFetch(`${path}/${companyId}/branches/members/${uuid}`, {},  ApiEnum.admin)
// }

// export const removeUserFromBranchCompany = (companyId, uuid) => {
//   return _basePatchFetch(`${path}/${companyId}/members/${uuid}`, {},  ApiEnum.admin)
// }
