import React, {useEffect, useState} from 'react'
import {useTranslation} from "react-i18next"
import {connect} from "react-redux"
import { toast } from 'react-toastify'
import {useHistory, useLocation } from 'react-router-dom'
import PropTypes from "prop-types"
import TroubleshootDetailsModal from './TroubleshootDetailsModal'
import Search from "../common/Search"
import ClickOutside from "../common/ClickOutside"
import Breadcrumb from "../common/Breadcrumb"
import LoadingSpinner from "../common/LoadingSpinner"
import DataPlaceholder from '../common/DataPlaceholder'
import DashboardHeader from "../common/DashboardHeader/DashboardHeader"
import EmptyPageResults from '../common/EmptyPageResults'
import SimpleButton from '../common/SimpleButton'
import VideoModal from '../common/VideoModal'
import {useWindowSize} from "../../libs/hooks"
import {CaretDownIcon, VideoPlayIcon} from "../../icons"
import { getImageByModel } from '../../utils/functions'
import { getModelsTrobleshoot } from "../../utils/requests/troubleshootingAPI"
import { getVideosList } from '../../utils/requests/videosAPI'
import './troubleshoot.scss'

const Troubleshoot = (props) => {
  const {t} = useTranslation()
  const history = useHistory()
  const location = useLocation()
  const hideBreadcrumb = props.hideBreadcrumb
  const stateModel = location.state && location.state.model
  const stateCycleFault = location.state && location.state.cycleFault
  const canGoBack = location.state && location.state.withBackNavigation
  const isMobile = useWindowSize()[0] <= 768
  const [products, setProducts] = useState(null)
  const [rawCycles, setRawCycles] = useState(null)
  const [cycles, setCycles] = useState(null)
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [selectedCycle, setSelectedCycle] = useState(null)
  const [videos, setVideos] = useState([])
  const [selectedVideo, setSelectedVideo] = useState(null)
  const [showSpinner, setShowSpinner] = useState(null)
  const [isTroubleshootModal, setIsTroubleshootModal] = useState(props.isTroubleshootModal)
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const model = props.model || stateModel

  useEffect(() => {
    if(!products && props && props.productTypes) {
      toggleSpinner(true)
      mapProductTypesToProducts(props.productTypes)
      toggleSpinner(false)
    }
    if (model && !selectedProduct) {
      setSelectedProduct(model)
      getProductData(model)
      setIsTroubleshootModal(props.model)
    }
  }, [props.productTypes])

  useEffect(() => {
    if (selectedCycle) {
      const state = location.state
      delete state?.model
      delete state?.cycleFault
      history.replace(`${history.location.pathname}`, { ...state })
    }
  }, [selectedCycle])

  const mapProductTypesToProducts = (productTypes) => {
    let arr = []
    for (const index in productTypes) {
      const deviceModels = productTypes[index].models
      deviceModels.map(model => {
        arr.push(model.name)
      })
    }
    setProducts(arr.sort())
    setIsFirstLoad(false)
  }

  const getProductData = async (model) => {
    if (model) {
      toggleSpinner(true)

      const modelsTroubleshootParams = {
        model: model
      }

      //TODO: Add back section once videos for troubelshooting are ready
      // const videosQueryParams = {
      //   category: "Troubleshooting",
      //   page: 1,
      //   model: model,
      //   limit: 3
      // }

      // const [cyclesData, videosData] = await Promise.all([getModelsTrobleshoot(modelsTroubleshootParams), getVideosList(videosQueryParams)])
      const [cyclesData] = await Promise.all([getModelsTrobleshoot(modelsTroubleshootParams)])
      
      if (cyclesData.data) {
        let arr = []

        cyclesData.data.map(item => {
          arr.push(item.faults)
        })

        const result = [].concat(...arr)
        setCycles(result)
        setRawCycles(result)

        if (stateCycleFault) {
          const preselectedCycleFault = result.find(cycle => cycle.fault === stateCycleFault)

          if (preselectedCycleFault) {
            setSelectedCycle(preselectedCycleFault)
          }
        }
      }

      // if (videosData.data) {
      //   setVideos(videosData.data)
      // }

      // if (!cyclesData.data && !videosData.data) {
      //   toast.dismiss()
      //   toast.error(t("error.failure_msg"))
      //   setSelectedProduct(null)
      // }

      if (!cyclesData.data) {
        toast.dismiss()
        toast.error(t("error.failure_msg"))
        setSelectedProduct(null)
      }

      toggleSpinner(false)
    }
  }  

  const searchProduct = (value) => {
    if (value) {
      let filteredProducts = []
      for (const index in props.productTypes) {
        const deviceModels = props.productTypes[index].models
        deviceModels.map(model => {
          if (model.name.toLowerCase().includes(value.toLowerCase())) {
            filteredProducts.push(model.name)
          }
        })
      }
      setProducts(filteredProducts.sort())
    } else {
      mapProductTypesToProducts(props.productTypes)
    }    
  }

  const searchCycle = (value) => {
    if (value) {
      const filteredCycles = rawCycles.filter(cycle => cycle.fault.toLowerCase().includes(value.toLowerCase()))
      setCycles(filteredCycles)
    } else {
      setCycles(rawCycles)
    }    
  }

  const selectProduct = (product, toggleVisibility) => {
    setSelectedProduct(product)

    if (toggleVisibility) {
      toggleVisibility(false)
      mapProductTypesToProducts(props.productTypes)
    }   
    
    setCycles(null)
    setSelectedCycle(null)
    getProductData(product)
  }

  const onSelectProductInput = (isComponentVisible, toggleVisibility) => {
    if (isComponentVisible) {
      toggleVisibility(false)
      mapProductTypesToProducts(props.productTypes)
    } else {
      mapProductTypesToProducts(props.productTypes)
      toggleVisibility(true)
    }
  }

  const selectProductInput = ({toggleVisibility, isComponentVisible}) => (
    <div className={"select-input"}>
      <div 
        className="height d-flex flex-align-center flex-justify-between" 
        onClick={() => onSelectProductInput(isComponentVisible, toggleVisibility)}
      >
        <div className="d-flex flex-align-center h-100">
          {selectedProduct ? selectedProduct : t('troubleshoot.no-product')}
        </div>
        <CaretDownIcon className={"mr-15 caret-dropdown-icon" + (isComponentVisible ? " icon-dropdown-open" : "")}/>
      </div>
    </div>
  )

  const selectProductOptions = ({toggleVisibility}) => (
    <div className="options-wrapper d-flex flex-align-center flex-column">
      <div className="options-filter">
        <Search
          className="search"
          onSearch={(e) => searchProduct(e)}
          placeholder={t('placeholders.search-filters')}
        />
      </div>
      {products?.map((product, index) => 
        <div
          key={index}
          className={"option cursor-pointer no-wrap" + (product === selectedProduct ? " selected-option" : "")} 
          onClick={() => selectProduct(product, toggleVisibility)}
        >
          {product}
        </div>)
      }
    </div>
  ) 

  const selectCycle = (cycle, toggleVisibility) => {
    setCycles(rawCycles)
    setSelectedCycle(cycle)
    toggleVisibility(false)
  }

  const onSelectCycleInput = (isComponentVisible, toggleVisibility) => {
    if (isComponentVisible) {
      toggleVisibility(false)
      setCycles(rawCycles)
    } else {
      setCycles(rawCycles)
      toggleVisibility(true)
    }
  }

  const selectCycleInput = ({toggleVisibility, isComponentVisible}) => (
    <div className={"select-input"}>
      <div 
        className="height d-flex flex-align-center flex-justify-between" 
        onClick={() => onSelectCycleInput(isComponentVisible, toggleVisibility)}
      >
        <div className="d-flex flex-align-center h-100">
          {selectedCycle ? selectedCycle.fault : t('troubleshoot.no-option')}
        </div>
        <CaretDownIcon className={"mr-15 caret-dropdown-icon" + (isComponentVisible ? " icon-dropdown-open" : "")}/>
      </div>
    </div>
  )

  const selectCycleOptions = ({toggleVisibility}) => (
    <div className="options-wrapper d-flex flex-align-center flex-column">
      <div className="options-filter">
        <Search
          className="search"
          onSearch={(e) => searchCycle(e)}
          placeholder={t('placeholders.search-filters')}
        />
      </div>
      {cycles?.map((cycle) => 
        <div
          key={cycle.id}
          className={"option cursor-pointer no-wrap" + (cycle === selectedCycle ? " selected-option" : "")} 
          onClick={() => selectCycle(cycle, toggleVisibility)}
        >
          {cycle.fault}
        </div>)
      }
    </div>
  ) 

  const toggleSpinner = (val) => {
    setShowSpinner(val)
  }

  const openVideoModal = (item) => {
    setSelectedVideo(item)
  }

  const closeVideoModal = () => {
    setSelectedVideo(null)
  }

  const handleNavigateToVideos = () => {
    history.push({
      pathname: `/videos`,
      state: {
        model: selectedProduct,
      }
    })
  }
  
  return (
    <div className="troubleshoot-wrapper d-flex flex-column">
      {!hideBreadcrumb && (
        <Breadcrumb path={window.location.pathname} withBackNavigation={canGoBack}/>
      )}
      <DashboardHeader headerText={t('nav.troubleshoot')} />
      <div className="separator"/>
      {!isTroubleshootModal &&
        <>
          {!isMobile ?
            <div className="content">
              {products?.length > 0 ?
                <>
                  <div className="products">
                    {products.map((item, index) =>
                      <div
                        key={index}
                        className={"card d-flex flex-align-center" + (selectedProduct === item ? " selected-card" : "")}
                        onClick={() => selectProduct(item)}
                      >
                        <div className="image-wrapper d-flex flex-align-center">
                          <img
                            src={getImageByModel(item)}
                            className="img"
                            alt={item}
                          />
                        </div>
                        <p className="product-name">{item}</p>
                      </div>)
                    }
                  </div>

                  {selectedProduct ?
                    <div className="cycles-wrapper">
                      <p className="cycle-select-title">{t('troubleshoot.select-option')}</p>
                      <ClickOutside 
                        itemRef="click-outside-wrapper"
                        eventItem={selectCycleInput}
                        toDisplayItem={selectCycleOptions}
                      />

                      {selectedCycle &&
                        <div className="cycle-details-wrapper">
                          <div className="cycle-title-wrapper">
                            <div className="cycle-title">
                              <span>{t('troubleshoot.fault')}</span>
                              <span className="cycle-title-number">{selectedCycle.fault}</span>
                            </div>
                            <div className="cycle-description">
                              <span>{selectedCycle.description}</span>
                            </div>
                          </div>
                          {selectedCycle.probable_cause?.length > 0 && 
                            <div className="cycle-category d-flex flex-column">
                              <h3 className="cycle-category-title d-flex flex-align-center">
                                {t('troubleshoot.probable-cause')}
                              </h3>
                              <ul className="list-none">
                                {selectedCycle.probable_cause.map((el, index) => (
                                  <li key={index} className="cycle-categories">
                                    <ul className="cycle-category-issue-list">
                                      {el.split("\\n").map((el, index) => <li key={index} className="cycle-category-issue">{el}</li>)}
                                    </ul>
                                  </li>
                                  ))
                                }
                              </ul>
                            </div>
                          }
                          {selectedCycle.tech_troubleshooting?.length > 0 && 
                            <div className="cycle-category d-flex flex-column">
                              <h3 className="cycle-category-title d-flex flex-align-center">
                                {t('troubleshoot.tech')}
                              </h3>
                              <ul className="list-none">
                                {selectedCycle.tech_troubleshooting.map((el, index) => (
                                  <li key={index} className="cycle-categories">
                                    <ul className="cycle-category-issue-list">
                                      {el.split("\\n").map((el, index) => <li  key={index} className="cycle-category-issue">{el}</li>)}
                                    </ul>
                                  </li>
                                  ))
                                }
                              </ul>
                            </div>
                          }
                          {selectedCycle.user_troubleshooting?.length > 0 && 
                            <div className="cycle-category d-flex flex-column">
                              <h3 className="cycle-category-title d-flex flex-align-center">
                                {t('troubleshoot.user-troubleshoot')}
                              </h3>
                              <ul className="list-none">
                                {selectedCycle.user_troubleshooting.map((el, index) => (
                                  <li key={index} className="cycle-categories">
                                    <ul className="cycle-category-issue-list">
                                      {el.split("\\n").map((el, index) => <li key={index} className="cycle-category-issue">{el}</li>)}
                                    </ul>
                                  </li>
                                  ))
                                }
                              </ul>
                            </div>
                          }
                          {/*TODO: Add back section once videos for troubelshooting are ready */}
                          {/* <div className="product-videos-wrapper">
                            <div className="section-title">{t("products.videos")}</div>
                            <div className="videos-wrapper">
                              {videos.map((video, index) => (
                                <div
                                  key={index}
                                  className="video-item-wrapper"
                                  onClick = {() => openVideoModal(video)}
                                >
                                  <div className="thumbnail-wrapper">
                                    <div className="thumbnail-icon">
                                      <VideoPlayIcon />
                                    </div>
                                    <img
                                      src={video.thumbnail_url}
                                      alt="video"
                                    />
                                  </div>                
                                  <p className="title">{video.title}</p>
                                </div>))
                              }
                              {videos.length === 0 && 
                                <div className="no-videos-wrapper">
                                  <p className="disclaimer">{t("products.no_videos_available")}</p>
                                </div>
                              }
                            </div>
                            <SimpleButton className="action-button" onClick={() => handleNavigateToVideos()}>
                              {t("products.view_all_videos")}
                            </SimpleButton>
                          </div> */}
                        </div>
                      }
                      {!selectedCycle && 
                        <EmptyPageResults
                          title={t('troubleshoot.no-cycle-selected')}
                          subtitle={t('troubleshoot.select-cycle')}
                        />
                      }
                    </div>
                  :
                    <DataPlaceholder
                      titlePlaceholder={t('troubleshoot.no-product-selected')}
                      subtitlePlaceholder={t('troubleshoot.select-product')}
                    />
                  }
                </>
              :
                <>
                  {!isFirstLoad && 
                    <EmptyPageResults 
                      title={t("troubleshoot.no-products")}
                    />
                  }
                </>
              }
            </div>
          :
            <div className="content">
              {products?.map((item, index) => 
                <div
                  key={index}
                  className="card d-flex flex-align-center"
                  onClick={() => selectProduct(item)}
                >
                  <div className="image-wrapper d-flex flex-align-center">
                    <img
                      src={getImageByModel(item)}
                      className="img"
                      alt={item}
                    />
                  </div>
                  <p className="product-name">{item}</p>
                </div>)
              }
              {!isFirstLoad && products?.length === 0 && 
                <EmptyPageResults title={t("troubleshoot.no-products")} />
              }
            </div>
          }
        </>
      }
      {isTroubleshootModal &&
        <div className="troubleshoot-modal-wrapper">
          <div className="products-wrapper">
            <p className="product-select-title">{t('troubleshoot.select-product')}</p>                
            <ClickOutside 
              itemRef="click-outside-wrapper"
              eventItem={selectProductInput}
              toDisplayItem={selectProductOptions}/>
          </div>
          <div className={"cycles-wrapper" + (!selectedProduct ? " disabled" : "")}>
            <p className="cycle-select-title">{t('troubleshoot.select-option')}</p> 
            <ClickOutside 
              itemRef="click-outside-wrapper"
              eventItem={selectCycleInput}
              toDisplayItem={selectCycleOptions}
            />

            {selectedCycle &&
              <div className="cycle-details-wrapper">
                <div className="cycle-title">
                  <span>{t('troubleshoot.fault')}</span>
                  <span className="cycle-title-number">{selectedCycle.fault}</span>
                </div>
                <div className="cycle-description">
                  <span>{selectedCycle.description}</span>
                </div>
                {selectedCycle.probable_cause?.length > 0 && 
                  <div className="cycle-category d-flex flex-column">
                    <h3 className="cycle-category-title d-flex flex-align-center">
                      {t('troubleshoot.probable-cause')}
                    </h3>
                    <ul className="cycle-category-issue-list">
                      {selectedCycle.probable_cause.map((el, index) =>
                        <li key={index} className="cycle-category-issue">
                          {el}
                        </li>)
                      }
                    </ul>
                  </div>
                }
                {selectedCycle.tech_troubleshooting?.length > 0 && 
                  <div className="cycle-category d-flex flex-column">
                    <h3 className="cycle-category-title d-flex flex-align-center">
                      {t('troubleshoot.tech')}
                    </h3>
                    <ul className="cycle-category-issue-list">
                      {selectedCycle.tech_troubleshooting.map((el, index) =>
                        <li key={index} className="cycle-category-issue">
                          {el}
                        </li>)
                      }
                    </ul>
                  </div>
                }
                {selectedCycle.user_troubleshooting?.length > 0 && 
                  <div className="cycle-category d-flex flex-column">
                    <h3 className="cycle-category-title d-flex flex-align-center">
                      {t('troubleshoot.user-troubleshoot')}
                    </h3>
                    <ul className="cycle-category-issue-list">
                      {selectedCycle.user_troubleshooting.map((el, index) => 
                        <li key={index} className="cycle-category-issue">
                          {el}
                        </li>)
                      }
                    </ul>
                  </div>
                }
              </div>
            }
          </div>
        </div>
      }
      {isMobile && selectedProduct && !isTroubleshootModal &&
        <TroubleshootDetailsModal
          selectedProduct={selectedProduct}
          rawCycles={rawCycles}
          selectedCycle={selectedCycle}
          onSelectCycle={(cycle, toggleVisibility) => selectCycle(cycle, toggleVisibility)}
          videos={videos}
          selectedVideo={selectedVideo}
          openVideoModal={(video) => openVideoModal(video)}
          closeVideoModal={() => closeVideoModal()}
          showSpinner={showSpinner}
          closeModal={() => setSelectedProduct(null)}
        />
      }
      {selectedVideo &&
        <VideoModal
          title={selectedVideo.title}
          videoId={selectedVideo.video_id}
          onCloseVideo={closeVideoModal}
        />
      }
      {showSpinner &&
        <div className="spinner-wrapper">
          <LoadingSpinner/>
        </div>
      }
    </div>
  )
}

Troubleshoot.propTypes = {
  model: PropTypes.string
}

function mapStateToProps({ productTypes }) {
  return {
    productTypes,
  }
}

export default connect(mapStateToProps)(Troubleshoot)