import { ApiEnum, _baseDeleteFetch, _baseGetFetch, _basePatchFetch, _basePostFetch, _basePutFetch } from "./baseFetch"
const baseUrl = "https://5d9996a5-ccd5-41c5-b8d2-8ab459b47898.mock.pstmn.io"
const xApiKey = "PMAK-607c5fc46c0c5a0063428897-38023b2d4d9c640b8fb7cd0b1701a15c1a"

export const getAccounts = () => {
  return _baseGetFetch(`/accounts`, undefined, ApiEnum.admin)
}

export const getRoles = (queryParams) => {
  return _baseGetFetch("/roles", queryParams, ApiEnum.admin)
}

export const getRolePolicies = (id) => {
  return _baseGetFetch(`/roles/${id}/policies`, {}, ApiEnum.admin)
}

export const postAccount = (body) => {
  return _basePostFetch(`/accounts`, body, ApiEnum.admin)
}

export const postRole = (body) => {
  return _basePostFetch(`/roles`, body, ApiEnum.admin)
}

export const putAccount = (accountId, body) => {
  return _basePutFetch(`/accounts/${accountId}`, body, ApiEnum.admin)
}

export const putRole = (roleId, body) => {
  return _basePutFetch(`/roles/${roleId}`, body, ApiEnum.admin)
}

export const putRolePolicies = (id, policies) => {
  return _basePutFetch(`/roles/${id}/policies`, policies, ApiEnum.admin)
}

export const patchAccount = (accountId, body) => {
  return _basePatchFetch(`/accounts/${accountId}`, body, ApiEnum.admin)
}

export const patchRole = (roleId, body) => {
  return _basePatchFetch(`/roles/${roleId}`, body, ApiEnum.admin)
}

export const deleteAccount = (accountId) => {
  return _baseDeleteFetch(`/accounts/${accountId}`, {}, ApiEnum.admin)
}

export const deleteRole = (roleId) => {
  return _baseDeleteFetch(`/roles/${roleId}`, {}, ApiEnum.admin)
}