import {_baseDeleteFetch, _baseGetFetch, _basePatchFetch, _basePostFetch, _basePutFetch, ApiEnum} from './baseFetch'

const path = "/companies"

export const getAllBranches = () => {
  return _baseGetFetch(`${path}/branches`, {},  ApiEnum.admin)
}

export const getAllCompanies = () => {
  return _baseGetFetch(`${path}/list`, {},  ApiEnum.admin)
}

export const addCompany = (bodyParams) => {
  return _basePostFetch(`${path}`, {bodyParams},  ApiEnum.admin)
}

export const updateCompany = (companyId, bodyParams) => {
  return _basePutFetch(`${path}/${companyId}`, {bodyParams},  ApiEnum.admin)
}

export const verifyCompany = (companyId, bodyParams) => {
  return _basePatchFetch(`${path}/${companyId}/verify`, {bodyParams},  ApiEnum.admin)
}

export const deleteCompany = (companyId, bodyParams) => {
  return _baseDeleteFetch(`${path}/${companyId}`, {bodyParams},  ApiEnum.admin)
}

export const getBranchById = (companyId) => {
  return _baseGetFetch(`${path}/${companyId}/branches`, {},  ApiEnum.admin)
}

export const addBranch = (companyId, bodyParams) => {
  return _basePostFetch(`${path}/${companyId}/branches`, {bodyParams},  ApiEnum.admin)
}

export const updateBranch = (companyId, branchId, bodyParams) => {
  return _basePutFetch(`${path}/${companyId}/branches/${branchId}`, {bodyParams},  ApiEnum.admin)
}

export const deleteBranch = (companyId, branchId, bodyParams) => {
  return _baseDeleteFetch(`${path}/${companyId}/branches/${branchId}`, {bodyParams},  ApiEnum.admin)
}


// ---------------------------------------------------
// End User
// ---------------------------------------------------

export const getUserInviteRequest = () => {
  return _baseGetFetch("/users/current/company/requests", {},  ApiEnum.admin)
}

export const userAcceptRejectCompanyInvitation = (id, bodyParams) => {
  return _basePatchFetch(`/users/current/company/requests/${id}`, bodyParams,  ApiEnum.admin)
}

export const usersRequestToJoinCompany = (bodyParams) => {
  return _basePatchFetch("/users/current/company/requests/join", bodyParams,  ApiEnum.admin)
}

export const usersLeaveCompany = () => {
  return _baseDeleteFetch("/users/current/company/leave", {},  ApiEnum.admin)
}

export const getUsersCurrentDetails = () => {
  return _baseGetFetch("/users/current/details", {},  ApiEnum.admin)
}