import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { useHistory, useLocation } from "react-router-dom"
import { toast } from "react-toastify"
import AccountModal from "./AccountModal"
import RoleModal from "./RoleModal"
import FiltersModal, { FilterType } from "../common/FiltersModal"
import LoadingSpinner from "../common/LoadingSpinner"
import Breadcrumb from "../common/Breadcrumb"
import ConfirmationModal from "../common/ConfirmationModal"
import SearchWithFilter from "../common/SearchWithFilter"
import SimpleButton from "../common/SimpleButton"
import WrappedTabs from '../common/WrappedTabs'
import DashboardHeader from "../common/DashboardHeader/DashboardHeader"
import GenericTable from "../common/GenericTable"
import ActionsMenu from "../common/ActionsMenu"
import ExpandableText from "../common/ExpandableText"
import EmptyPageResults from "../common/EmptyPageResults"
import { accountsDeletePermission, rolesDeletePermission, rolesWritePermission } from "../../utils/permissionValidation"
import { loadAccounts, loadRoles, delAccount, delRole, clearAccountsMessages, partiallyUpdateRole } from "../../actions/accountsManagement"
import { loadPolicyTypes, loadPolicies } from "../../actions/policies"
import { useWindowSize } from "../../libs/hooks"
import { useDeepCompareEffect } from '../../hooks/useDeepCompareEffect'
import { EnabledIcon, DisabledIcon, MenuKebabVerticalIcon, MenuKebabVerticalBackgroundIcon } from "../../icons"
import "./accounts.scss"

const ManageAccounts = (props) => {
  const { t } = useTranslation()
  const isMobile = useWindowSize()[0] <= 768
  const location = useLocation()
  const history = useHistory()
  const { accounts } = props
  const statuses = [t("filters.enabled"), t("filters.disabled")]
  const [activeTab, setActiveTab] = useState(0)
  const [rolesTableData, setRolesTableData] = useState([])
  const [showAccountModal, setShowAccountModal] = useState(false)
  const [accountModalType, setAccountModalType] = useState(null)
  const [roleModalType, setRoleModalType] = useState(null)
  const [editableAccount, setEditableAccount] = useState(null)
  const [roleModal, setRoleModal] = useState(null)
  const [shouldApplyFilters, setShouldApplyFilters] = useState(true)
  const [searchQuery, setSearchQuery] = useState(null)
  const [showFiltersModal, setShowFiltersModal] = useState(false)
  const [showRoleConfirmationModal, setShowRoleConfirmationModal] = useState(false)
  const [showAccountConfirmationModal, setShowAccountConfirmationModal] = useState(false)
  const [showRoleModal, setShowRoleModal] = useState(false)
  const [showSpinner, setShowSpinner] = useState(false)
  const [selectedFilterStatus, setSelectedFilterStatus] = useState([])
  const [roleIdToDelete, setRoleIdToDelete] = useState(null)
  const [accountIdToDelete, setAccountIdToDelete] = useState(null)
  const [deleteAccountInput, setDeleteAccountInput] = useState(null)
  const [canDeleteAccount, setCanDeleteAccount] = useState(false)
  const [showActionsMenu, setShowActionsMenu] = useState(null)
  const [expandedRole, setExpandedRole] = useState(0)
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const [canLoadMore, setCanLoadMore] = useState(false)
  const [selectedPage, setSelectedPage] = useState(1)

  useEffect(() => {
    if (accounts.length === 0 && !props.accountsLoading) {
      props.actions.loadAccounts()
    }
  }, [props.actions])

  useEffect(() => {
    setShowSpinner(props.isLoading)
  }, [props.isLoading])
  
  useEffect(() => {
    setCanLoadMore(props.canLoadMoreRoles)
  }, [props.canLoadMoreRoles])

  useEffect(() => {
    if (accounts.length > 0) {
      const queryParams = new URLSearchParams(location.search)
      const accountId = queryParams.get("accountId")

      if (accountId) {
        const tab = accounts.findIndex((account) => account.id === Number(accountId))

        if (tab >= 0) {
          handleChangeTab(Number(accountId))
        } else {
          toast.dismiss()
          toast.error(t("account_management.account.no_account", { id: accountId }))
        }

        return
      }

      handleChangeTab(accounts[0].id)
    }
  }, [accounts])

  useEffect(() => {
    const allowDeleteAccount = accounts && deleteAccountInput && deleteAccountInput === accounts.find((acc) => acc.id === activeTab)?.name

    setCanDeleteAccount(allowDeleteAccount)
  }, [deleteAccountInput, accounts, accountIdToDelete])

  useEffect(() => {
    if (props.errorMessage) {
      toast.dismiss()
      toast.error(props.errorMessage)

      props.actions.clearAccountsMessages()
    }
  }, [props.errorMessage])

  useEffect(() => {
    if (props.successMessage) {
      toast.dismiss()
      toast.success(props.successMessage)

      props.actions.clearAccountsMessages()
    }
  }, [props.successMessage])

  useEffect(() => {
    let filteredRoles = props.roles

    if (searchQuery) {
      filteredRoles = props.roles.filter((item) => item.name.toLowerCase().includes(searchQuery.toLowerCase()))
    }

    const tableData = filteredRoles.map((role) => {
      return {
        identifier: role.id,
        name: role.name,
        description: role.description,
        userCount: role.userCount,
        status: role.isEnabled,
        actions: [
          {
            label: t("labels.view"),
            onActionClick: (e) => { e.stopPropagation(); openViewRoleModal(role.id) },
          },
          {
            label: t("labels.edit"),
            onActionClick: (e) => { e.stopPropagation(); openEditRoleModal(role.id) },
          },
          {
            label: role.isEnabled ? t("labels.disable") : t("labels.enable"),
            onActionClick: (e) => { e.stopPropagation(); handleChangeRoleStatus(role.id, !role.isEnabled) },
            requiredPermission: rolesWritePermission
          },
          {
            label: t("labels.delete"),
            onActionClick: (e) => { e.stopPropagation(); openRoleConfirmationModal(role.id) },
            isDisabled: role.isEnabled || role.userCount > 0,
            requiredPermission: rolesDeletePermission
          },
        ]
      }
    })

    setRolesTableData(tableData)
  }, [searchQuery, props.roles])

  useDeepCompareEffect(() => {
    let params = new URLSearchParams(location.search)
    let queryParams = {
      maxResults: process.env.REACT_APP_PAGINATION_SIZE,
      accountId: params.get("accountId"),
    }

    if (selectedFilterStatus.length === 1) {
      queryParams = { ...queryParams, isEnabled: selectedFilterStatus[0] === statuses[0] }
    }

    if (queryParams.accountId && shouldApplyFilters) {
      props.actions.loadRoles(queryParams, true)
        .then(() => setIsFirstLoad(false))
    }

    setSelectedPage(1)
    setShouldApplyFilters(true)
  }, [selectedFilterStatus, location.search])

  const isAccountEnabled = () => {
    return accounts?.find((acc) => acc.id === activeTab)?.isEnabled || false
  }

  const handleAccountInputChange = (e) => {
    setDeleteAccountInput(e.target.value)
  }

  const handleResetFilters = () => {
    setSelectedFilterStatus([])
    setSelectedPage(1)
  }

  const openViewRoleModal = (roleId) => {
    const role = props.roles.find((role) => role.id === roleId)

    if (role) {
      setRoleModalType("view")
      setShowRoleModal(true)
      setRoleModal(role)
    }    
  }

  const openEditRoleModal = (roleId) => {
    const role = props.roles.find((role) => role.id === roleId)

    if (role) {
      setRoleModalType("edit")
      setShowRoleModal(true)
      setRoleModal(role)
    }    
  }

  const openCreateRoleModal = () => {
    setRoleModalType("create")
    setShowRoleModal(true)
    setRoleModal(null)
  } 

  const openEditAccountModal = (account) => {
    setAccountModalType("edit")
    setShowAccountModal(true)
    setEditableAccount(account)
  }

  const openCreateAccountModal = () => {
    setAccountModalType("create")
    setShowAccountModal(true)
  }

  const openRoleConfirmationModal = (id) => {
    setRoleIdToDelete(id)
    setShowRoleConfirmationModal(true)
  }

  const openAccountConfirmationModal = (id) => {
    setAccountIdToDelete(id)
    setShowAccountConfirmationModal(true)
  }

  const handleChangeRoleStatus = (roleId, isEnabled) => {
    props.actions.partiallyUpdateRole(roleId, { isEnabled: isEnabled }, true)
  }

  const closeAccModal = () => {
    setShowAccountModal(false)
  }

  const closeRoleModal = () => {
    setShowRoleModal(false)
  }

  const closeRoleConfirmationModal = () => {
    setRoleIdToDelete(null)
    setShowRoleConfirmationModal(false)
  }

  const closeAccountConfirmationModal = () => {
    setAccountIdToDelete(null)
    setShowAccountConfirmationModal(false)
  }

  const handleChangeTab = (accountId) => {
    setActiveTab(accountId)

    handleResetFilters()
    setSearchQuery(null)

    let queryParams = new URLSearchParams(history.location.search)
    queryParams.set("accountId", accountId)
    history.push({ search: queryParams.toString() })
  }

  const handleDeleteRole = () => {
    setShowRoleConfirmationModal(false)
    setShowRoleModal(false)
    props.actions.delRole(roleIdToDelete)
  }

  const handleDeleteAccount = async () => {
    setDeleteAccountInput()
    try {
      await props.actions.delAccount(editableAccount.id)
      setShowAccountConfirmationModal(false)
      setShowAccountModal(false)
      handleChangeTab(accounts[0].id)
    } catch (error) {
      console.log(error)
    }
  }

  const toggleShowActionsMenu = (identifier) => {
    setShowActionsMenu(showActionsMenu === identifier ? null : identifier)
  }
  
  const handleLoadMore = () => {
    let params = new URLSearchParams(location.search)
    setSelectedPage(selectedPage + 1)
    let queryParams = {
      startPage: selectedPage + 1,
      maxResults: process.env.REACT_APP_PAGINATION_SIZE,
      accountId: params.get("accountId"),
    }

    if (selectedFilterStatus.length === 1) {
      queryParams = { ...queryParams, isEnabled: selectedFilterStatus[0] === statuses[0] }
    }

      props.actions.loadRoles(queryParams, false)
        .then(() => setIsFirstLoad(false))
  }
  
  return (
    <div className="accounts-management-wrapper">
      <Breadcrumb path={window.location.pathname} />
      <DashboardHeader
        headerText={t("account_management.account.accounts")}
      >
        <SimpleButton 
          className="submit-button" 
          onClick={() => openCreateAccountModal()}
        >
          {t("account_management.account.add_account")}
        </SimpleButton>
      </DashboardHeader>

      {accounts && (
        <>
          <WrappedTabs
            tabs={accounts.map((account) => ({ id: account.id, name: account.name }))}
            activeTab={activeTab}
            changeTab={handleChangeTab} 
          />

          <div className="actions-wrapper">
            <SearchWithFilter
              onSearch={setSearchQuery}
              onResetFilters={() => handleResetFilters()}
              showFiltersModal={() => setShowFiltersModal(true)}
              counterBadge={selectedFilterStatus?.length || 0}
              showCounterBadge={selectedFilterStatus?.length ? true : false}
              searchText={searchQuery}
            />
            <div className="account-actions">
              <SimpleButton 
                onClick={() => openEditAccountModal(accounts.find((acc) => acc.id === activeTab))}
              >
                {t("account_management.account.edit_account")}
              </SimpleButton>
              <SimpleButton
                className="action-button"
                onClick={() => openCreateRoleModal()}
                disabled={!isAccountEnabled()}
              >
                {t("account_management.account.add_role")}
              </SimpleButton>
            </div>
          </div>
          {!isMobile && rolesTableData.length > 0 &&
            <GenericTable
              data={rolesTableData}
              headers={[
                {
                  title: t("table.headers.role"),
                },
                {
                  title: t("table.headers.descriptions")
                },
                {
                  title: t("table.headers.users")
                },
                {
                  title: t("table.headers.status-accounts")
                },
                {
                  title: ''
                },
              ]}
              keys={[
                'name',
                'description',
                'userCount',
                'status',
                'action',
              ]}
              keyRenderer={{
                name: (item) => {
                  return (
                    <div className="name-wrapper">
                      <ExpandableText text={item.name} />
                    </div>
                  )
                },
                status: (item) => {
                  return (
                    <div className="status-wrapper">
                      {item.status ?
                        <div className="status-active">
                          <EnabledIcon /> 
                          <p>{t("labels.enabled")}</p>
                        </div>
                      : 
                        <div className="status-inactive">
                          <DisabledIcon /> 
                          <p>{t("labels.disabled")}</p>
                        </div>
                      }
                    </div>
                  )
                },
                action: (item) => {
                  return (
                    <div className="table-actions-wrapper" onClick={(e) => { e.stopPropagation(); toggleShowActionsMenu(item["identifier"]) }}>
                      {showActionsMenu === item["identifier"] ? <MenuKebabVerticalBackgroundIcon /> : <MenuKebabVerticalIcon />}
                      {showActionsMenu === item["identifier"] &&
                        <ActionsMenu
                          setHideMenu={() => setShowActionsMenu(null)}
                          actions={item.actions}
                          isLoading={showSpinner}
                        />
                      }
                    </div>
                  )
                }
              }}
              onRowClick={(role) => openViewRoleModal(role.identifier, true)}
            />
          }
          {isMobile && rolesTableData.map((role, index) =>
            <div 
              key={index}
              className="card" 
              onClick={() => setExpandedRole(index)}
            >
              <div className={"card-item" + (expandedRole !== index ? " align-center" : "")}>
                <div className="card-item-title">{t("table.headers.role")}</div>
                <div className={"card-item-body" + (expandedRole !== index ? " align-center" : "")}>
                  <div>{role.name}</div>
                  <div 
                    className="card-actions"
                    onClick={(e) => { e.stopPropagation(); setShowActionsMenu(index) }}
                  >
                    {showActionsMenu === index ? <MenuKebabVerticalBackgroundIcon /> : <MenuKebabVerticalIcon />}
                    {showActionsMenu === index &&
                      <ActionsMenu
                        setHideMenu={() => setShowActionsMenu(null)}
                        actions={role.actions}
                        isLoading={showSpinner}
                      />
                    }
                  </div>
                </div>
              </div>
              {expandedRole === index && (
                <>
                  <div className="card-item">
                    <div className="card-item-title">{t("table.headers.descriptions")}</div>
                    <div className="card-item-body">{role.description}</div>
                  </div>
                  <div className="card-item">
                    <div className="card-item-title">{t("table.headers.users")}</div>
                    <div className="card-item-body">{role.userCount}</div>
                  </div>
                  <div className="card-item">
                    <div className="card-item-title">{t("table.headers.status-accounts")}</div>
                    <div className="card-item-body">
                      <div className="card-item-body-status">
                        {role.status ? 
                          <div className="status-active">
                            <EnabledIcon /> 
                            <p>{t("labels.enabled")}</p>
                          </div>
                        : 
                          <div className="status-inactive">
                            <DisabledIcon /> 
                            <p>{t("labels.disabled")}</p>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>)
          }
          {canLoadMore && rolesTableData.length !== 0 && (
            <div className="buttons-wrapper">
              <SimpleButton className="load-more-button" onClick={handleLoadMore}>
                {t("buttons.load_more")}
              </SimpleButton>
            </div>
          )}
          {!showSpinner && !isFirstLoad && rolesTableData.length === 0 && (
            <>
              {isAccountEnabled() ?
                <EmptyPageResults
                  title={t("account_management.account.no_roles")}
                  subtitle={t("account_management.account.no_roles_tips")}
                />
              :
                <EmptyPageResults
                  title={t("account_management.account.disabled_account")}
                  subtitle={t("account_management.account.please_enable")}
                />
              }
            </>
          )}
        </>
      )}
      {showSpinner && (
        <div className="spinner-wrapper">
          <LoadingSpinner />
        </div>
      )}
      {showFiltersModal && (
        <FiltersModal
          filters={[
            {
              name: t("filters.status"),
              type: FilterType.multiSelect,
              dataset: statuses,
              input: selectedFilterStatus,
              output: (filterStatuses) => {
                setSelectedFilterStatus(filterStatuses)
              },
            },
          ]}
          shouldFilter={(shouldApply) => {
            setSelectedPage(1)
            setShouldApplyFilters(shouldApply)
          }}
          resetFilters={() => handleResetFilters()}
          closeFilters={() => setShowFiltersModal(false)}
        />
      )}
      {showAccountModal && (
        <AccountModal
          onToggle={closeAccModal}
          toggle={showAccountModal}
          type={accountModalType}
          account={editableAccount}
          openConfirmation={openAccountConfirmationModal}
          isLoading={showSpinner}
        />
      )}
      {showRoleModal && (
        <RoleModal
          onToggle={closeRoleModal}
          toggle={showRoleModal}
          type={roleModalType}
          role={roleModal}
          openConfirmation={openRoleConfirmationModal}
          isLoading={showSpinner}
        />
      )}
      {showRoleConfirmationModal && (
        <ConfirmationModal
          onToggle={closeRoleConfirmationModal}
          message={t("account_management.account.delete_confirmation")}
          acceptButtonText={t("labels.delete")}
          onCancel={closeRoleConfirmationModal}
          onAccept={handleDeleteRole}
          isWarning
          requiredPermission={rolesDeletePermission}
        />
      )}
      {showAccountConfirmationModal && (
        <ConfirmationModal
          className="delete-account-confirmation"
          onToggle={closeAccountConfirmationModal}
          message={t("account_management.account.account_delete_confirmation")}
          acceptButtonText={t("labels.delete")}
          acceptDisabled={!canDeleteAccount}
          onCancel={closeAccountConfirmationModal}
          onAccept={handleDeleteAccount}
          isWarning
          requiredPermission={accountsDeletePermission}
        >
          <div className="confirmation-modal-input">
            <div className="tips">{t("account_management.account.account_delete_confirmation_tips")}</div>
            <div className="title">{t("labels.name")}</div>
            <input value={deleteAccountInput} onChange={handleAccountInputChange} />
          </div>
        </ConfirmationModal>
      )}
    </div>
  )
}

function stateToProps({ accounts }) {
  return {
    accounts: accounts?.accounts || [],
    roles: accounts?.roles || [],
    isLoading: accounts?.accountsLoading || accounts?.rolesLoading || accounts?.roleLoading || accounts?.policiesLoading || accounts?.rolePoliciesLoading,
    successMessage: accounts?.successMessage,
    errorMessage: accounts?.errorMessage,
    canLoadMoreRoles: accounts?.canLoadMoreRoles,
  }
}

function dispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        loadAccounts,
        loadPolicies,
        loadPolicyTypes,
        loadRoles,
        delAccount,
        delRole,
        partiallyUpdateRole,
        clearAccountsMessages,
      },
      dispatch
    ),
  }
}

export default connect(stateToProps, dispatchToProps)(ManageAccounts)
