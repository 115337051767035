import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify"
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Modal, Paper } from "@mui/material";
import SelectField from "../common/SelectField";
import TextInputField from "../common/TextInputField";
import LoadingSpinner from "../common/LoadingSpinner";
import Button from "../common/Button";
import { loadShippingAddress, generateCheckoutLink, loadGooglePlace, loadGooglePlaceDetails, clearStoreMessages } from "../../actions/colteneStore";
import { ArrowLeftIcon, ColtenePlaceholderProduct } from "../../icons";
import { ScicanLogoBlack } from "../../images";
import countries from "../../utils/countries.json";
import { formatStoreProductPrice } from "../../utils/filters/filters";
import "./shipping-address.scss";
import { useRef } from "react"
import { useOnClickOutside } from "../../hooks/useOnClickOutside"
import { formatPhone } from "../../utils/functions";

const ShippingAddress = (props) => {
  const { t } = useTranslation();
  const ref = useRef()
  let timeoutAddress = useRef(null)
  const { isLoading, basket } = props;
  const [fields, handleFieldChange] = useState({});
  const [open, setOpen] = useState(false);
  const [googlePlaces, setGooglePlaces] = React.useState([]);
  let [searchAddress, setSearchAddress] = React.useState('');
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  // const [checkoutOpen, setCheckoutOpen] = useState(false);
  const [saveAddressInDB, setSaveAddressInDB] = useState(false);
  const [billingSameAsShipping, setBillingSameAsShipping] = useState(false);
  const [isValidFirstName, setIsValidFirstName] = useState(true);
  const [isValidLastName, setIsValidLastName] = useState(true);
  const [isValidCountry, setIsValidCountry] = useState(true);
  const [isValidAddress, setIsValidAddress] = useState(true);
  const [isValidZipCode, setIsValidZipCode] = useState(true);
  const [isValidCity, setIsValidCity] = useState(true);
  const [isValidState, setIsValidState] = useState(true);
  const [isValidPhone, setIsValidPhone] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const zipCodePattern = new RegExp(/^[a-zA-Z0-9\- ]*$/)
  const phonePattern = new RegExp(/^\d*$/)

  const shippingAddressErrorMsg = {
    firstName: t("error.firstname"),
    lastName: t("error.lastname"),
    country: t("error.country"),
    address: t("error.address"),
    zipCode: t("error.zipCode"),
    city: t("error.city"),
    state: t("error.state"),
    phone: t("error.phone"),
  }

  useEffect(() => {
    setOpen(props.isOpen)
  }, [props.isOpen]);


  useEffect(() => {
    props.actions.loadShippingAddress();
  }, [props.actions]);

  useEffect(() => {
    if (!isFirstLoad && props.googlePlace?.data) {
      setGooglePlaces(props.googlePlace.data.predictions)
    }
  }, [props.googlePlace]);

  useOnClickOutside(ref, () => {
    setGooglePlaces([])
  })

  useEffect(() => {
    handleFieldChange({
      firstName: props.addressShipping?.firstName || "",
      lastName: props.addressShipping?.lastName || "",
      phone: props.addressShipping?.phone || "",
      addressLineOne: props.addressShipping?.addressLineOne || "",
      addressLineTwo: props.addressShipping?.addressLineTwo || "",
      countryCode: props.authedUser?.country,
      city: props.addressShipping?.city || "",
      stateProvinceRegion: props.addressShipping?.stateProvinceRegion || "",
      zipPostalCode: props.addressShipping?.zipPostalCode || "",
      company: props.addressShipping?.company || "",
    });
  }, [props.addressShipping]);

  useEffect(() => {
    if (props.errorMessage) {
      toast.dismiss()
      toast.error(props.errorMessage)

      props.actions.clearStoreMessages()
    }
  }, [props.errorMessage])

  useEffect(() => {
    timeoutAddress = window.setTimeout(
      () => {
        getGooglePlace(fields.addressLineOne, props.authedUser?.country)
      },
       250
    )

    return () => {
      clearTimeout(timeoutAddress)
    }
  }, [searchAddress])

  const handleInputChange = (e) => {
    setIsFirstLoad(false)
    let elementValue = e.target.value?.toString() || ''
    let isValidInput = true
    let maxInputLength = 40

    switch(e.target.name) {
      case 'company':
        maxInputLength = 100
        break
      case 'stateProvinceRegion':
        maxInputLength = 2
        break
      case 'zipPostalCode':
        maxInputLength = 15
        isValidInput = zipCodePattern.test(elementValue)
        break
      case 'phone':
        maxInputLength = 20
        isValidInput = phonePattern.test(elementValue.replace(/[^\d]/g, ""))
        break
      default:
        break
    }

    elementValue = elementValue.slice(0, maxInputLength)

    if (e.target.name === 'addressLineOne' && elementValue) {
      setSearchAddress(elementValue)
    } else if (e.target.name === 'addressLineOne' && !elementValue) {
      setSearchAddress('')
      setGooglePlaces([])
    }
    if (e.target.name === 'phone' && elementValue && isValidInput) {
      return handleFieldChange({
        ...fields,
        [e.target.name]: elementValue.replaceAll("-",""),
      });
    } else if (isValidInput) {
      return handleFieldChange({
        ...fields,
        [e.target.name]: elementValue,
      });
    }
  };

  const getGooglePlace = (elementValue, country) => {
    if (elementValue) {
      let queryData = {
        query: elementValue,
        country: country,
        types: "address",
      }
      props.actions.loadGooglePlace(queryData)
    } else {
      setGooglePlaces([])
    }
  }

  useEffect(() => {
    let placeData = {}
    props?.googlePlaceDetails?.data?.result?.address_components?.map((placeDetails) => {
      if (placeDetails.types.includes('postal_code')) {
        placeData['zipPostalCode'] = placeDetails.long_name ? placeDetails.long_name : ''
      }

      if (placeDetails.types.includes('locality')) {
        placeData['city'] = placeDetails.long_name ? placeDetails.long_name : ''
      }

      if (placeDetails.types.includes('administrative_area_level_1')) {
        placeData['stateProvinceRegion'] = placeDetails.short_name ? placeDetails.short_name : ''
      }

      if (placeDetails.types.includes('street_number')) {
        placeData['street_number'] = placeDetails.long_name ? placeDetails.long_name : ''
      }

      if (placeDetails.types.includes('route')) {
        placeData['route'] = placeDetails.long_name ? placeDetails.long_name : ''
      }
    })

    let streetName = ''
    if (placeData.street_number) {
      streetName = placeData.street_number
    }

    if (placeData.route) {
      streetName = placeData.street_number ? streetName + ' ' + placeData.route : placeData.route
    }

    return handleFieldChange({
      ...fields,
      ['zipPostalCode']: placeData.zipPostalCode,
      ['city']: placeData.city,
      ['stateProvinceRegion']: placeData.stateProvinceRegion,
      ['addressLineOne']: streetName,
    })
  }, [props.googlePlaceDetails]);

  const getGooglePlaceDetails = (placeId) => {
    clearInterval(timeoutAddress);
    if (placeId) {
      let queryData = {
        place_id: placeId
      }
      props.actions.loadGooglePlaceDetails(queryData)
    }
    setGooglePlaces([])
  }

  const handleSelectChange = (e) => {
    return handleFieldChange({
      ...fields,
      [e.detail.name]: e.detail.value,
    });
  };

  const getAmount = (prices) => {
    const prodAmount = prices.find((plan) => plan.isSelectedPlan) || prices[0]
    return prodAmount.amount || 0
  };

  const total = Object.keys(basket)
    .map((p) => basket[p].quantity * getAmount(basket[p].prices))
    .reduce((a, b) => a + b, 0)
    .toFixed(2);

  const getCountryOptions = () => {
    const countryData = {};

    countries.map((item) => {
      if (item.code === fields?.countryCode) {
        countryData[item.code] = item.name;
      }
    });

    return (
      <SelectField
        name="countryCode"
        value={fields.countryCode}
        options={countryData}
        selectedIndex={fields.countryCode}
        onChange={handleSelectChange}
        isSubmitting={isSubmitting}
        error={!isValidCountry ? shippingAddressErrorMsg.country : null}
      />
    );
  };

  const validateInput = () => {
    let isAllInputValid = true;

    if (fields.firstName?.length === 0) {
      setIsValidFirstName(false)
      isAllInputValid = false;
    } else {
      setIsValidFirstName(true)
    }

    if (fields.lastName?.length === 0) {
      setIsValidLastName(false)
      isAllInputValid = false;
    } else {
      setIsValidLastName(true)
    }

    if (fields.countryCode?.length === 0) {
      setIsValidCountry(false)
      isAllInputValid = false;
    } else {
      setIsValidCountry(true)
    }

    if (fields.addressLineOne?.length === 0) {
      setIsValidAddress(false)
      isAllInputValid = false;
    } else {
      setIsValidAddress(true)
    }

    if (fields.zipPostalCode?.length === 0) {
      setIsValidZipCode(false)
      isAllInputValid = false;
    } else {
      setIsValidZipCode(true)
    }

    if (fields.city?.length === 0) {
      setIsValidCity(false)
      isAllInputValid = false;
    } else {
      setIsValidCity(true)
    }

    if (fields.stateProvinceRegion?.length === 0) {
      setIsValidState(false)
      isAllInputValid = false;
    } else {
      setIsValidState(true)
    }

    if (fields.phone?.length === 0) {
      setIsValidPhone(false)
      isAllInputValid = false;
    } else {
      setIsValidPhone(true)
    }

    return isAllInputValid;
  }

  const handleCheckout = () => {
    setIsSubmitting(true)
    const isValidInput = validateInput()

    if (isValidInput) {
      setIsSubmitting(false)

      if (props.actions.generateCheckoutLink && basket) {
        const shippingAddressFields = {
          ...fields,
          addressLineTwo: fields?.addressLineTwo?.length > 0 ? fields.addressLineTwo : undefined,
          company: fields?.company?.length > 0 ? fields.company : undefined,
        }

        props.actions.generateCheckoutLink(basket, shippingAddressFields, saveAddressInDB, billingSameAsShipping);
      }
    }
  };

  const handleClose = () => {
    props.handleVisibility(false);
  }

  const getPrice = (prices) => {
    const price = prices.find((plan) => plan.isSelectedPlan) || prices[0]

    if (!price) {
      return t("coltene_store.cart.price_not_available")
    }

    return formatStoreProductPrice(price.amount, price.currencyCode, price.recurrenceInterval, price.recurrenceIntervalCount)
  }

  return (
    <Modal open={open} onClose={(e) => handleClose(e)} aria-labelledby="keep-mounted-modal-title" aria-describedby="keep-mounted-modal-description">
      <Paper className="checkout-paper">
        <div className="checkout-wrapper">
          <div className="left-side-wrapper">
            <div className="left-side-container">
              <NavLink to="/" activeClassName="active">
                <img src={ScicanLogoBlack} alt="Scican Logo" className="company-logo-checkout" />
              </NavLink>
  
              <div className="checkout-basket-title">
                <div className="title-basket">
                  {t("coltene_store.cart.my_items")}
                </div>
              </div>
  
              <div className="checkout-products-container">
                {Object.keys(basket).map((p) => (
                  <div key={"item-checkout-" + basket[p].id} className="checkout-product">
                    <div className="item-container">
                      <div className="checkout-image-wrapper">
                        <div className="checkout-product-image">
                          {basket[p].imageUrl ? <img alt="complex" src={basket[p].imageUrl} /> : <ColtenePlaceholderProduct />}
                        </div>
                        <div className="product-quantity">{basket[p].quantity}</div>
                      </div>
  
                      <div className="product-details">
                        <div className="checkout-product-name">{basket[p].title}</div>
                        {basket[p].subtitle && <div className="checkout-product-description">{basket[p].subtitle}</div>}
                        <div className="checkout-product-price-wrapper">
                          <div className="checkout-product-price">
                            {getPrice(basket[p].prices)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
  
              <div className="checkout-footer-wrapper">
                <div className="divider-line" />
                  <div className="checkout-footer-line">
                    <div className="footer-line">{t("coltene_store.cart.subtotal")}</div>
                    <div className="footer-line-right">{formatStoreProductPrice(total, basket[Object.keys(basket)[0]]?.prices[0].currencyCode)}</div>
                  </div>
                  {/* <div className="checkout-footer-line">
                    <div className="footer-line">{t("coltene_store.cart.shipping")}</div>
                    <div className="footer-line-right">{t("coltene_store.cart.calculated_at_next_step")}</div>
                  </div> */}
                  <div className="divider-line" />
                  <div className="checkout-footer-total">
                    <div className="total-line">{t("coltene_store.cart.total")}</div>
                    <div className="total-line-right">{formatStoreProductPrice(total, basket[Object.keys(basket)[0]]?.prices[0].currencyCode)}</div>
                  </div>
                </div>
              </div>
            </div>
  
            <div className="right-side-wrapper">
              {isLoading && <LoadingSpinner />}
  
            {!isLoading && (
            <div className="right-side-container">
              <div className="shipping-title">
                {t("coltene_store.cart.shipping_address")}
              </div>
  
              <div className="shipping-address-form-container">
                <div className="item-comp">
                  <div className="label-input">
                    {t("select_option_label.select_country")}
                    <span className="field-required">*</span>
                  </div>
                  {getCountryOptions()}
                </div>
  
                <div className="item-comp-group">
                  <div className="item-comp space-field">
                    <div className="label-input">
                      {t("profile-labels.first_name")}
                      <span className="field-required">*</span>
                    </div>
                    <TextInputField
                      className="profile-fields-one"
                      type="text"
                      value={fields.firstName}
                      onChange={handleInputChange}
                      name="firstName"
                      isSubmitting={isSubmitting}
                      error={!isValidFirstName ? shippingAddressErrorMsg.firstName : null}
                    />
                  </div>
  
                    <div className="item-comp">
                      <div className="label-input">
                        {t("profile-labels.last_name")}
                        <span className="field-required">*</span>
                      </div>
                      <TextInputField
                        className="profile-fields"
                        type="text"
                        value={fields.lastName}
                        onChange={handleInputChange}
                        name="lastName"
                        isSubmitting={isSubmitting}
                        error={!isValidLastName ? shippingAddressErrorMsg.lastName : null}
                      />
                    </div>
                  </div>
  
                  <div className="item-comp">
                    <div className="label-input">
                      {t("coltene_store.cart.company")}
                    </div>
                    <TextInputField
                      className="profile-fields"
                      type="text"
                      value={fields.company}
                      isSubmitting={isSubmitting}
                      onChange={handleInputChange}
                      name="company"
                    />
                  </div>
  
                  <div className="item-comp address-field">
                    <div className="label-input">
                      {t("coltene_store.cart.address")}
                      <span className="field-required">*</span>
                    </div>
                    <div ref={ref}>
                      <TextInputField
                        className="profile-fields"
                        type="text"
                        isSubmitting={isSubmitting}
                        value={fields.addressLineOne}
                        onChange={handleInputChange}
                        name="addressLineOne"
                        autocomplete="do-not-autofill"
                        error={!isValidAddress ? shippingAddressErrorMsg.address : null}
                      />
  
                      {googlePlaces?.length > 0 && (
                        <div className="google-places-wrapper">
                          {googlePlaces.map((placeItem) => (
                            <div key={placeItem.place_id} className="google-place" onClick={() => {getGooglePlaceDetails(placeItem.place_id)}}>{placeItem.description}</div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
  
                  <div className="item-comp">
                    <div className="label-input">
                      {t("coltene_store.cart.appartment_suite_etc")}
                    </div>
                    <TextInputField
                      className="profile-fields"
                      type="text"
                      isSubmitting={isSubmitting}
                      value={fields.addressLineTwo}
                      onChange={handleInputChange}
                      name="addressLineTwo"
                    />
                  </div>
  
                  <div className="item-comp-group">
                    <div className="item-comp space-field">
                      <div className="label-input">
                        {t("profile-labels.postal_code")}
                        <span className="field-required">*</span>
                      </div>
                      <TextInputField
                        className="profile-fields"
                        type="text"
                        isSubmitting={isSubmitting}
                        value={fields.zipPostalCode}
                        onChange={handleInputChange}
                        name="zipPostalCode"
                        error={!isValidZipCode ? shippingAddressErrorMsg.zipCode : null}
                      />
                    </div>
  
                    <div className="item-comp space-field">
                      <div className="label-input">
                        {t("profile-labels.city")}
                        <span className="field-required">*</span>
                      </div>
                      <TextInputField
                        className="profile-fields"
                        type="text"
                        value={fields.city}
                        isSubmitting={isSubmitting}
                        onChange={handleInputChange}
                        name="city"
                        error={!isValidCity ? shippingAddressErrorMsg.city : null}
                      />
                    </div>
  
                    <div className="item-comp">
                      <div className="label-input">
                        {t("profile-labels.state_province")}
                        <span className="field-required">*</span>
                      </div>
                      <TextInputField
                        className="profile-fields"
                        type="text"
                        isSubmitting={isSubmitting}
                        value={fields.stateProvinceRegion}
                        onChange={handleInputChange}
                        name="stateProvinceRegion"
                        error={!isValidState ? shippingAddressErrorMsg.state : null}
                      />
                    </div>
                  </div>
  
                  <div className="item-comp">
                    <div className="label-input">
                      {t("profile-labels.phone")}
                      <span className="field-required">*</span>
                    </div>
                    <TextInputField
                      className="profile-fields"
                      type="text"
                      value={fields.phone ? formatPhone(fields.phone) : fields.phone}
                      onChange={handleInputChange}
                      name="phone"
                      isSubmitting={isSubmitting}
                      error={!isValidPhone ? shippingAddressErrorMsg.phone : null}
                    />
                  </div>
  
                  <div className="d-flex flex-align-center">
                    <input
                      type="checkbox"
                      id="saveAddressInDB"
                      name="saveAddressInDB"
                      className="billingSameAsShipping"
                      checked={saveAddressInDB}
                      onChange={(e) => setSaveAddressInDB((prev) => !prev)}
                    />
                    <span className="billing-same-as-shipping">
                      {t("coltene_store.cart.save_this_information")}
                    </span>
                  </div>
  
                  <div className="d-flex flex-align-center">
                    <input
                      type="checkbox"
                      id="billingSameAsShipping"
                      name="billingSameAsShipping"
                      className="billingSameAsShipping"
                      checked={billingSameAsShipping}
                      onChange={(e) => setBillingSameAsShipping((prev) => !prev)}
                    />
                    <span className="billing-same-as-shipping">
                      {t("coltene_store.cart.billing_same_shipping")}
                    </span>
                  </div>
                </div>
  
                <div className="checkout-btn-container">
                  <Button className="continue-checkout-button" onClick={() => handleCheckout()}>
                    {t("coltene_store.cart.continue_checkout")}
                  </Button>
                  <div className="go-shopping-button" onClick={handleClose}>
                    <ArrowLeftIcon className="arrow-left-icon" />
                    {t("coltene_store.cart.continue_shopping")}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </Paper>
    </Modal>
  );
};

function stateToProps({ colteneStore, authedUser }) {
  const rawBasket = colteneStore?.basket || {};
  const filteredBasket = Object.fromEntries(
    Object.entries(rawBasket).filter(([key, value]) => value.quantity !== 0)
  );
  
  return {
    authedUser: authedUser,
    basket: filteredBasket || {},
    addressShipping: colteneStore?.addressShipping,
    googlePlace: colteneStore?.googlePlace,
    googlePlaceDetails: colteneStore?.googlePlaceDetails,
    isLoading: colteneStore?.isShippingPageLoading,
    errorMessage: colteneStore?.errorMessage
  };
}

function dispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        loadShippingAddress,
        loadGooglePlace,
        loadGooglePlaceDetails,
        generateCheckoutLink,
        clearStoreMessages
      },
      dispatch
    ),
  };
}

export default connect(stateToProps, dispatchToProps)(ShippingAddress);
