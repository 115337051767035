import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { toast } from "react-toastify"
import { Storage } from "aws-amplify"
import SimpleButton from "../common/SimpleButton"
import ClickOutside from "../common/ClickOutside"
import LoadingSpinner from "../common/LoadingSpinner"
import EmptyPageResults from "../common/EmptyPageResults"
import { CaretDownIcon, CloseIcon, DownloadModernIcon, PdfModernIcon } from "../../icons"
import { getDocuments, getDocumentTypes } from "../../utils/requests/documentsApi"
import "./documents.scss"

const Documents = (props) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { model } = props
  const [documentTypes, setDocumentTypes] = useState([])
  const [documents, setDocuments] = useState([])
  const [selectedType, setSelectedType] = useState(null)
  const [selectedPage, setSelectedPage] = useState(1)
  const [canLoadMore, setCanLoadMore] = useState(false)
  const [showSpinner, setShowSpinner] = useState(null)
  const [isFirstLoad, setIsFirstLoad] = useState(true)

  useEffect(() => {
    if (model) {
      getDocumentTypes()
        .then((data) => {
          setDocumentTypes(data.data)
        })
    }    
  }, [])

  useEffect(() => {
    if (selectedPage && selectedPage > 1) {
      handleLoadDocuments(false)
    }
  }, [selectedPage])

  useEffect(() => {
    let changeValueTimeout = window.setTimeout(
      () => {
        setSelectedPage(1)
        handleLoadDocuments(true)
      },
      selectedType ? 1000 : 0
    )
    return () => {
      clearTimeout(changeValueTimeout)
    }
  }, [selectedType])

  const handleLoadDocuments = (withReset) => {
    if (!model) {
      setIsFirstLoad(false)

      return
    }

    let queryParams = {
      model: model,
      page: withReset ? 1 : selectedPage,
      limit: process.env.REACT_APP_PAGINATION_SIZE,
    }

    if (selectedType) {
      queryParams = { ...queryParams, document_type: selectedType }
    }

    setShowSpinner(true)

    getDocuments(queryParams)
      .then((data) => {
        if (data?.data) {
          setDocuments(withReset ? data.data : [...documents, ...data.data])
          setCanLoadMore(data.data.length === Number(process.env.REACT_APP_PAGINATION_SIZE))
        }
      })
      .catch(() => {
        toast.dismiss()
        toast.error(t("error.failure_msg"))

        setCanLoadMore(false)
      })
      .finally(() => {
        setShowSpinner(false)
        setIsFirstLoad(false)
      })
  }

  const handleLoadMore = () => {
    setSelectedPage((prevValue) => (prevValue ? prevValue + 1 : 2))
  }

  const handleNavigateToDocuments = () => {
    history.push({
      pathname: `/documents`,
      state: {
        model: model,
        withBackNavigation: true
      },
    })
  }

  const onSelectTypeInput = (isComponentVisible, toggleVisibility) => {
    if (toggleVisibility) {
      toggleVisibility(!isComponentVisible)
    }
  }

  const selectType = (type, toggleVisibility) => {
    setSelectedType(type)

    if (toggleVisibility) {
      toggleVisibility(false)
    }
  }

  const selectTypeInput = ({ toggleVisibility, isComponentVisible }) => (
    <div className="select-input">
      <div className="height d-flex flex-align-center flex-justify-between" onClick={() => onSelectTypeInput(isComponentVisible, toggleVisibility)}>
        <div className="text-wrapper d-flex flex-align-center h-100">{selectedType ? selectedType : t("videos.all_categories")}</div>
        {selectedType && (
          <button
            className="reset-filter-button"
            onClick={(e) => {
              e.stopPropagation()
              setSelectedType(null)
            }}
          >
            <CloseIcon />
          </button>
        )}
        <CaretDownIcon className={"mr-15 caret-dropdown-icon" + (isComponentVisible ? " icon-dropdown-open" : "")} />
      </div>
    </div>
  )

  const selectTypeOptions = ({ toggleVisibility }) => (
    <div className="options-wrapper d-flex flex-align-center flex-column">
      {documentTypes?.map((documentType, index) => 
        <div
          className={"option cursor-pointer no-wrap" + (documentType === selectedType ? " selected-option" : "")}
          key={index}
          onClick={() => selectType(documentType, toggleVisibility)}
        >
          {documentType}
        </div>)
      }
    </div>
  )

  async function handleDownload(filePath, fileName) {
    Storage.configure({
      customPrefix: {
        public: "",
        protected: "",
        private: "",
      },
    })

    const result = await Storage.get(`${filePath}${fileName}`)
    window.open(result, "_blank")
  }

  return (
    <div className="products-documents-wrapper">
      <div className="documents-header d-flex">
        <div className="type-wrapper d-flex">
          <div className="type-title">{t("documents.type")}</div>
          <ClickOutside
            itemRef="click-outside-wrapper"
            eventItem={selectTypeInput}
            toDisplayItem={selectTypeOptions}
          />
        </div>
        <SimpleButton className="action-button" onClick={() => handleNavigateToDocuments()}>
          {t("labels.view_all_documents")}
        </SimpleButton>
      </div>
      {documents.length > 0 &&
        <>
          <div className="documents-container d-flex">
            <div className="documents-content d-flex">
              {documents.map((item, index) =>
                <div
                  key={index}
                  className={"item-wrapper" + (index === documents.length - 1 ? " no-border" : "")}
                  onClick={() => handleDownload(item?.file_path, item?.file_name)}
                >
                  <div className="image-wrapper movable-image">
                    <DownloadModernIcon />
                  </div>
                  <div className="image-wrapper rounded-image">
                    <PdfModernIcon />
                  </div>
                  <div className="name-wrapper">
                    <p className="title">{item?.document_title ? item?.document_title : item?.file_name}</p>
                    <p className="section">{item?.section_name}</p>
                  </div>
                </div>)
              }
            </div>
            {canLoadMore &&
              <div className="buttons-wrapper">
                <SimpleButton className="load-more-button" onClick={() => handleLoadMore()}>
                  {t("buttons.load_more")}
                </SimpleButton>
              </div>
            }
          </div>
        </>
      }
      {!isFirstLoad && documents.length === 0 &&
        <EmptyPageResults className="empty-list" title={t("no_data_available")} />
      }
      {showSpinner && 
        <div className="spinner-wrapper">
          <LoadingSpinner />
        </div>
      }
    </div>
  )
}

export default Documents
