import { _baseGetFetch, _basePostFetch, ApiEnum } from "./baseFetch"

const path = "/dealers"

export const getDealerBulletins = async () => {
  return _baseGetFetch(`${path}/bulletins`, {}, ApiEnum.admin)
}

// -------------------------------------------------
//             REQUEST HELP API'S
// -------------------------------------------------

export const getProblemType = async () => {
  return _baseGetFetch(`${path}/request-help/problem-type`, {}, ApiEnum.admin)
}

export const postRequestHelp = async (payload) => {
  return _basePostFetch(`${path}/request-help`, payload, ApiEnum.admin)
}

// -------------------------------------------------
//             PRICE LISTS API'S
// -------------------------------------------------

export const getPriceLists = (params) => {
  const urlSearchParams = new URLSearchParams(params)
  return _baseGetFetch(`${path}/pricelist?${urlSearchParams.toString()}`, {},  ApiEnum.admin)
}

export const getPriceListsFilterOptions = (params) => {
  return _baseGetFetch(`${path}/pricelist/filter-options`, {},  ApiEnum.admin)
}

export const getPriceListsRequests = (params) => {
  const urlSearchParams = new URLSearchParams(params)
  return _baseGetFetch(`${path}/current/requests?${urlSearchParams.toString()}`, {},  ApiEnum.admin)
}

export const getRequestReviewOptions = () => {
  return _baseGetFetch(`${path}/requests/review-options`, {},  ApiEnum.admin)
}

export const getRequestReviewById = (id) => {
  return _baseGetFetch(`${path}/current/requests/review/${id}`, {},  ApiEnum.admin)
}

export const getRequestMissingItemById = (id) => {
  return _baseGetFetch(`${path}/requests/missing-item/${id}`, {},  ApiEnum.admin)
}

export const postRequestReview = (payload) => {
  return _basePostFetch(`${path}/current/requests/review`, payload,  ApiEnum.admin)
}

export const postRequestMissingItems = (payload) => {
  return _basePostFetch(`${path}/requests/missing-item`, payload,  ApiEnum.admin)
}

export const downloadPricelists = (params) => {
  const urlSearchParams = new URLSearchParams(params)
  return _baseGetFetch(`${path}/pricelist/download?${urlSearchParams.toString()}`, {},  ApiEnum.admin)
}
