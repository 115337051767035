import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useLocation } from 'react-router-dom';
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import CycleModal from "./CycleModal";
import CycleDetailsModal from "../common/CycleDetailsModal";
import Breadcrumb from "../common/Breadcrumb";
import DashboardHeader from "../common/DashboardHeader/DashboardHeader";
import LoadingSpinner from "../common/LoadingSpinner";
import {
  CheckCircleFilledIcon,
  ErrorCircleActiveIcon,
  ArrowDownIcon,
  SterilizerSmallIcon,
  WasherSmallIcon,
} from "../../icons";
import {
  extDaysOfWeek,
  formatDate,
  months,
  formatVal
} from "../../utils/filters/date";
import { useWindowSize } from "../../libs/hooks";
import { loadCycleDetails, loadLegacyCycleDetails, loadTrackingData, clearTrackingMessages, clearCycleDetails } from "../../actions/instrumentTracking";
import "./instrument-tracking.scss"

function InstrumentTracking(props) {
  const { t } = useTranslation()
  const location = useLocation();
  const isMobile = useWindowSize()[0] <= 768
  const calendarRef = useRef();
  const canGoBack = location.state && location.state.withBackNavigation;
  const todayTime = new Date();
  const today = new Date(new Date(todayTime).setHours(23, 59, 59));
  const [dropdownFilters, setDropdownFilters] = useState(true)
  const [cycleDetailsDeviceData, setCycleDetailsDeviceData] = useState({})
  const [btnView, setBtnView] = useState(today)
  const [events, setEvents] = useState([])
  const [showedDevicesList, setShowedDevicesList] = useState([])
  const [cycleDetails, setCycleDetails] = useState({})
  const [showCycleModal, setShowCycleModal] = useState(false)
  const [showCycleDetails, setShowCycleDetails] = useState(false)
  const [checkboxFilters, setCheckboxFilters] = useState({
    in_progress: true,
    error: true,
    cycle_interrupted: true,
    complete: true,
  })

  useEffect(() => {
    let startDate = new Date(today)
    startDate = new Date(startDate.setDate(today.getDate() - 6))

    props.actions.loadTrackingData({
      "cycleStart": `${startDate.getFullYear()}${formatVal(startDate.getMonth() +1)}${formatVal(startDate.getDate())}`,
      "cycleEnd": `${today.getFullYear()}${formatVal(today.getMonth() + 1)}${formatVal(today.getDate())}`
    })
  }, [props.actions]);

  useEffect(() => {
    if (props.instrumentTracking) {
      filterDevicesList()
    }
  }, [props.instrumentTracking]);

  useEffect(() => {
    getEvents()
  }, [showedDevicesList]);

  useEffect(() => {
    if (props.instrumentTracking) {
      filterDevicesList()
    }
  }, [checkboxFilters, btnView]);

  const filterDevicesList = () => {
    let showedDevicesList = []
    let devicesListFiltered = {
      in_progress: [],
      error: [],
      cycle_interrupted: [],
      complete: [],
    }

    props.instrumentTracking.forEach(cycle => {
      const minDate = new Date(new Date(btnView || dayNumberMonth(6)).setHours(0, 0, 0))
      const maxDate = btnView || today
      if (new Date(cycle.cycleStartTime).getTime() <= maxDate.getTime() && new Date(cycle.cycleStartTime).getTime() >= minDate.getTime()) {
        devicesListFiltered = getDeviceList(devicesListFiltered, cycle)
      }
    })

    Object.keys(devicesListFiltered).forEach(key => {
      if (devicesListFiltered[key]) {
        showedDevicesList.push(...devicesListFiltered[key])
      }
    })

    showedDevicesList = [...new Set(showedDevicesList)]
    setShowedDevicesList(showedDevicesList)
  }

  const getDeviceList = (devicesListFiltered, key) => {
    if (key.status?.toLowerCase().includes('complete')) {
      if (checkboxFilters['complete']) {
        devicesListFiltered['complete'].push(key)
      }
    } else if ((key.status?.toLowerCase().includes('fault') || key.status?.toLowerCase() === 'cycle_aborted' || key.status?.toLowerCase() === 'door_open')) {
      if (checkboxFilters['error']) {
        devicesListFiltered['error'].push(key)
      }
    } else if (key.status?.toLowerCase() === 'cycle_power_interrupt') {
      if (checkboxFilters['cycle_interrupted']) {
        devicesListFiltered['cycle_interrupted'].push(key)
      }
    } else if (checkboxFilters['in_progress']) {
      devicesListFiltered['in_progress'].push(key)
    }
    
    return devicesListFiltered
  }

  const handleOpenCycleGraph = () => {
    if (props.userProducts.hasOwnProperty(cycleDetailsDeviceData.deviceSerialNumber)) {
      if (props.userProducts[cycleDetailsDeviceData.deviceSerialNumber].is_g4_plus) {
        props.actions.loadCycleDetails(cycleDetailsDeviceData.recordsUuid, cycleDetailsDeviceData.deviceSerialNumber)
        
        return
      }

      props.actions.loadLegacyCycleDetails(cycleDetailsDeviceData.printout_file_name)
    }
  };

  useEffect(() => {
    if (props.userProducts.hasOwnProperty(cycleDetailsDeviceData.deviceSerialNumber)) {
      if (props.userProducts[cycleDetailsDeviceData.deviceSerialNumber].is_g4_plus && props.cycleDetails && Object.keys(props.cycleDetails).length > 0) {
        setCycleDetails(props.cycleDetails)
        setShowCycleDetails(true)
        
        return
      }

      if (props.cycleLegacyDetails && Object.keys(props.cycleLegacyDetails).length > 0) {
        setCycleDetails(props.cycleLegacyDetails.data)
        setShowCycleDetails(true)
      }
    }
  }, [props.cycleDetails, props.cycleLegacyDetails]);

  useEffect(() => {
    if (props.hasError && cycleDetailsDeviceData.recordsUuid) {
      toast.dismiss()
      toast.error(t("tracking.no_data_available"))
    }
  }, [props.hasError])

  useEffect(() => {
    if (props.errorMessage) {
      toast.dismiss()
      toast.error(props.errorMessage)

      props.actions.clearTrackingMessages()
    }
  }, [props.errorMessage])

  const dayNumberMonth = (day) => {
    const dayDate = new Date(today)
    dayDate.setDate(today.getDate() - day)
    return dayDate
  }

  function changeViewOneDay(day) {
    const dayDate = new Date(today)
    if (day) {
      dayDate.setDate(today.getDate() - day)
      const nrDay = dayNumberMonth(day)
      setBtnView(nrDay)
    } else {
      setBtnView(today)
    }

    let elementCalendar = document.getElementById('instrument-tracking-calendar-container')
    elementCalendar.scroll(0, 0)

    calendarRef.current.getApi().changeView('timeGridDay', formatDate(dayDate))
  }

  function changeViewOneWeek(eventInfo) {
    setBtnView(null)

    const startDate = new Date(today)
    startDate.setDate(today.getDate() - 6)

    let elementCalendar = document.getElementById('instrument-tracking-calendar-container')
    elementCalendar.scroll(0, 0)

    calendarRef.current.getApi().changeView('timeGrid', {
      start: startDate,
      end: today
    })
  }

  const getWeekDaysLetters = (nrDay) => {
    const dayDate = new Date(today)
    dayDate.setDate(today.getDate() - nrDay)
    const dayLetter = extDaysOfWeek()[dayDate.getDay()]
    return dayLetter.substring(0, 3)
  }

  const handleInputChange = (e) => {
    return setCheckboxFilters({
      ...checkboxFilters,
      [e.target.name]: e.target.checked,
    });
  };

  const renderMoreLinkContent = (eventInfo) => {
    return (
      <>
        <span>{eventInfo.shortText}</span>
        <span> {t("labels.more")}</span>
      </>
    )
  }
  
  function renderEventContent(eventInfo) {
    return (
        <div 
          className="calendar-event-wrapper"
          onClick={() => handleCycleDetails(eventInfo.event._def.extendedProps.eventDetails)}
        >
          <div className="d-flex h-100 flex-justify-between">
            <div className="d-flex container-event">
              <div className="device-icon-image">
                {eventInfo.event._def.extendedProps.eventDetails?.deviceType === 'sterilizer'
                    ? <SterilizerSmallIcon/>
                    : <WasherSmallIcon/>
                }
              </div>

              <div className="calendar-event-details">
                <div className="calendar-event-title">
                  <span className="calendar-event-model">{eventInfo.event._def.extendedProps.model.toLowerCase().includes('aperture') ? t(`products.ic_track`) : eventInfo.event._def.extendedProps.model}</span>
                  <span className="calendar-event-sn">{eventInfo.event._def.extendedProps.serialNumber?.length > 0 ? 'S/N: ' + eventInfo.event.title : eventInfo.event.title}</span>
                </div>
                <div className="calendar-event-subtitle">
                  {Object.keys(eventInfo.event._def.extendedProps.test_cycle).length === 0 ? (
                    <>
                      {eventInfo.event._def.extendedProps.pouches > 0 && (
                        <span>{`${eventInfo.event._def.extendedProps.pouches} ${t("tracking.pouches")}`}</span>
                      )}
                      {eventInfo.event._def.extendedProps.cassettes > 0 && (
                        <>
                          {eventInfo.event._def.extendedProps.pouches !== 0 && <span> • </span>}
                          <span>{`${eventInfo.event._def.extendedProps.cassettes} ${t("tracking.cassettes")}`}</span>
                        </>
                      )}
                      {eventInfo.event._def.extendedProps.looseInstruments > 0 && (
                        <>
                          {eventInfo.event._def.extendedProps.cassettes !== 0 && <span> • </span>}
                          <span>{`${eventInfo.event._def.extendedProps.looseInstruments} ${t("tracking.loose_instruments")}`}</span>
                        </>
                      )}
                    </>
                  ) : (
                    t("reports.test_cycle")
                  )}
                </div>

              </div>
            </div>

            {btnView !== null && (<div className="calendar-event-status">
              {eventInfo.event._def.extendedProps.success
                  ? <CheckCircleFilledIcon className="success"/>
                  : ''
              }
              {eventInfo.event._def.extendedProps.error
                  ? <ErrorCircleActiveIcon className="error"/>
                  : ''
              }
            </div>)}
          </div>
        </div>
    )
  }

  const getEvents = () => {
    const eventsList = []
    showedDevicesList.forEach(key => {
      let colorBG = '#e3e6ea'
      let error = false
      let success = false
      if (key.status?.toLowerCase()?.includes('complete')) {
        colorBG = '#d1fadf'
        success = true
      }

      if (key.status?.toLowerCase() === 'cycle_power_interrupt') {
        colorBG = '#fdf1c8'
      }

      if (key.status?.toLowerCase()?.includes('fault') || key.status?.toLowerCase() === 'cycle_aborted' || key.status?.toLowerCase() === 'door_open') {
        colorBG = '#fee4e2'
        error = true
      }

      let startDate = new Date(key.cycleStartTime)
      let minToEnd = startDate.getMinutes() + 30

      let eventData = {
        title: key.deviceSerialNumber || key.deviceNickname,
        color: colorBG,
        className: key.deviceType,
        textColor: '#000000',
        end: new Date(new Date(key.cycleStartTime).setMinutes(minToEnd)),
        extendedProps: {
          serialNumber: key.deviceSerialNumber,
          model: key.model,
          success: success,
          error: error,
          pouches: key.pouches?.length ? key.pouches?.length : 0,
          cassettes: key.tags?.length && key.manualTags?.length
              ? key.tags?.length + key.manualTags?.length
              : key.tags?.length && !key.manualTags?.length
                  ? key.tags.length
                  : !key.tags?.length && key.manualTags?.length
                    ? key.manualTags?.length
                    : 0,
          looseInstruments: key.looseInstruments?.length ? key.looseInstruments?.length : 0,
          eventDetails: key,
          test_cycle: key.test_cycle || {}
        },
        start: new Date(key.cycleStartTime),
      }
      eventsList.push(eventData)
    })

    setEvents(eventsList)
  }

  const handleCycleDetails = (selectedElement) => {
    setCycleDetailsDeviceData(selectedElement)
    setShowCycleModal(true)
  }

  const namesFilters = {
    in_progress: t("tracking.in_progress"),
    error: t("tracking.error"),
    cycle_interrupted: t("tracking.cycle_interrupted"),
    complete: t("tracking.completed"),
  }

  const hasCyclesMatchingDay = (date) => {
    return props.instrumentTracking?.some((cycle) => {
      const cycleDate = new Date(cycle.cycleStartTime)
      return cycleDate.getDate() === date.getDate() && cycleDate.getMonth() === date.getMonth() && cycleDate.getFullYear() === date.getFullYear()
    })
  }

  const handleCloseTrackingCycleDetails = () => {
    setShowCycleModal(false)
    setShowCycleDetails(false)
    setCycleDetails({})
    setCycleDetailsDeviceData({})

    props.actions.clearCycleDetails()
  }

  const mapSerialToModel = (serialNumber) => {
    const productValues = Object.values(props.userProducts);
    const matchedProduct = productValues.find(product => product.serial_number === serialNumber);
    return matchedProduct ? matchedProduct.model : "aperture"
  }
  
  const getCurrentTime = () => {
    const currentTime = new Date();
    const hours = currentTime.getHours();
    const minutes = currentTime.getMinutes();
    const seconds = currentTime.getSeconds();
    
    return hours + ":" + minutes + ":" + seconds;
  }
  
  return (
    <div className="tracking-wrapper">
      <Breadcrumb path={window.location.pathname} withBackNavigation={canGoBack} />
      <DashboardHeader headerText={t("nav.instrument-tracking")} />
      <div className="separator"/>
      {!props.isLoading &&
        <div className="instrument-tracking-wrapper">
          <div className={`instrument-tracking-left-container ${isMobile ? dropdownFilters ? 'extend-filters' : 'shrink-filters' : ''}`}>
            <div className="d-flex w-100 flex-justify-between">
              <div className="it-filters-wrapper">
                <div className="it-interval-current-date">
                  {`${months()[today.getMonth()].substring(0, 3)}, ${today.getFullYear()}`}
                </div>

                <div className="it-week-days-container">
                  <div className="week-day-letter">{getWeekDaysLetters(6)}</div>
                  <div className="week-day-letter">{getWeekDaysLetters(5)}</div>
                  <div className="week-day-letter">{getWeekDaysLetters(4)}</div>
                  <div className="week-day-letter">{getWeekDaysLetters(3)}</div>
                  <div className="week-day-letter">{getWeekDaysLetters(2)}</div>
                  <div className="week-day-letter">{getWeekDaysLetters(1)}</div>
                  <div className="week-day-letter">{getWeekDaysLetters(0)}</div>
                </div>

                <div className="it-days-nr-container">
                  <div 
                    className={`week-day-nr ${btnView !== null && new Date(btnView).getDate() === dayNumberMonth(6).getDate() ? "selected-day" : ""}`} 
                    onClick={() => changeViewOneDay(6)}
                  >
                    {dayNumberMonth(6).getDate()}
                    <div className={"cycles-dot-container" + (hasCyclesMatchingDay(dayNumberMonth(6)) ? " cycles-dot-visible" : "")}/>
                  </div>
                  <div 
                    className={`week-day-nr ${btnView !== null && new Date(btnView).getDate() === dayNumberMonth(5).getDate() ? "selected-day" : ""}`} 
                    onClick={() => changeViewOneDay(5)}
                  >
                    {dayNumberMonth(5).getDate()}
                    <div className={"cycles-dot-container" + (hasCyclesMatchingDay(dayNumberMonth(5)) ? " cycles-dot-visible" : "")}/>
                  </div>
                  <div 
                    className={`week-day-nr ${btnView !== null && new Date(btnView).getDate() === dayNumberMonth(4).getDate() ? "selected-day" : ""}`} 
                    onClick={() => changeViewOneDay(4)}
                  >
                    {dayNumberMonth(4).getDate()}
                    <div className={"cycles-dot-container" + (hasCyclesMatchingDay(dayNumberMonth(4)) ? " cycles-dot-visible" : "")}/>
                  </div>
                  <div 
                    className={`week-day-nr ${btnView !== null && new Date(btnView).getDate() === dayNumberMonth(3).getDate() ? "selected-day" : ""}`} 
                    onClick={() => changeViewOneDay(3)}
                  >
                    {dayNumberMonth(3).getDate()}
                    <div className={"cycles-dot-container" + (hasCyclesMatchingDay(dayNumberMonth(3)) ? " cycles-dot-visible" : "")}/>
                  </div>
                  <div 
                    className={`week-day-nr ${btnView !== null && new Date(btnView).getDate() === dayNumberMonth(2).getDate() ? "selected-day" : ""}`} 
                    onClick={() => changeViewOneDay(2)}
                  >
                    {dayNumberMonth(2).getDate()}
                    <div className={"cycles-dot-container" + (hasCyclesMatchingDay(dayNumberMonth(2)) ? " cycles-dot-visible" : "")}/>
                  </div>
                  <div 
                    className={`week-day-nr ${btnView !== null && new Date(btnView).getDate() === dayNumberMonth(1).getDate() ? "selected-day" : ""}`} 
                    onClick={() => changeViewOneDay(1)}
                  >
                    {dayNumberMonth(1).getDate()}
                    <div className={"cycles-dot-container" + (hasCyclesMatchingDay(dayNumberMonth(1)) ? " cycles-dot-visible" : "")}/>
                  </div>
                  <div 
                    className={`week-day-nr current-day ${btnView !== null && new Date(btnView).getDate() === dayNumberMonth(0).getDate() ? "selected-day" : ""}`} 
                    onClick={() => changeViewOneDay()}
                  >
                    {dayNumberMonth(0).getDate()}
                    <div className={"cycles-dot-container" + (hasCyclesMatchingDay(dayNumberMonth(0)) ? " cycles-dot-visible" : "")}/>
                  </div>
                </div>

                <div className="it-show-in-calendar-text">{t("tracking.show_in_calendar")}</div>

                <div className="filters-event-container">

                  {Object.keys(checkboxFilters).map((item, i) => (
                      <div key={i} className={`checkbox-rapport ${item}`}>
                        <label>
                          <input
                              className={`checkbox-filter ${item}`}
                              name={item}
                              type="checkbox"
                              value={checkboxFilters[item]}
                              checked={checkboxFilters[item]}
                              onChange={handleInputChange}
                          />
                          <span className="checkmark"></span>

                          <div className="label-text content">
                            {namesFilters[item]}
                          </div>
                        </label>
                      </div>
                  ))}
                </div>

                <div className="it-show-in-calendar-text">{t("tracking.view")}</div>

                <div className="buttons-view-container">
                  <div className={`button-view-change ${btnView !== null ? 'active-btn' : ''}`} onClick={() => changeViewOneDay()}>
                    {t("tracking.one_day")}
                  </div>

                  <div className={`button-view-change ${btnView === null ? 'active-btn' : ''}`} onClick={changeViewOneWeek}>
                    {t("tracking.one_week")}
                  </div>
                </div>
              </div>

              {isMobile && (<div className="arrow-dropdown-container">
                <div className="arrow-dropdown" onClick={() => setDropdownFilters(!dropdownFilters)}>
                  <ArrowDownIcon className={`it-caret ${ dropdownFilters ? 'arrow-down' : 'arrow-up'}`} />
                </div>
              </div>)}
            </div>
          </div>

          <div className="instrument-tracking-right-container">
            <div id="instrument-tracking-calendar-container" className={`instrument-tracking-calendar-container ${btnView === null ? 'week' : 'day'}`}>
              <FullCalendar
                  ref={calendarRef}
                  //locale={langCode}
                  plugins={[dayGridPlugin, timeGridPlugin]}
                  nowIndicator={true}
                  scrollTime={getCurrentTime()}
                  scrollTimeReset={true}
                  headerToolbar={{
                    left: "title",
                    center: "",
                    right: "",
                  }}
                  initialView="timeGridDay"
                  dayMaxEvents={true}
                  stickyHeaderDates={true}
                  // contentHeight={2000}
                  moreLinkContent={renderMoreLinkContent}
                  eventContent={renderEventContent}
                  views={{
                    timeGrid: {
                      dayMaxEventRows: 3,
                      eventMaxStack: isMobile || btnView === null ? 1 : 3
                    },
                    dayGrid: {
                      //eventDisplay: 'block',
                      //dayMaxEvents: 2,
                    },
                  }}
                  expandRows={true}
                  events={events}
                  eventTimeFormat={{
                    hour: "numeric",
                    minute: "2-digit",
                    meridiem: "short",
                  }}
                  allDaySlot={false}
              />
            </div>
          </div>
        </div>
      }
      {showCycleModal && (
        <CycleModal
          selectedEvent={cycleDetailsDeviceData}
          selectedModel={mapSerialToModel(cycleDetailsDeviceData.deviceSerialNumber)}
          isLoading={props.isLoadingDetails}
          handleOpenCycleGraph={() => handleOpenCycleGraph()}
          closeModal={() => setShowCycleModal(false)}
        />
      )}
      {showCycleDetails &&
        <CycleDetailsModal
          deviceData={cycleDetailsDeviceData}
          selectedModel={mapSerialToModel(cycleDetailsDeviceData.deviceSerialNumber)}
          cycleDetails={cycleDetails}
          closeModal={() => handleCloseTrackingCycleDetails()}
          isInstrumentTrackingCycleData
        />
      }
      {(props.isLoading || props.isLoadingDetails) &&
        <div className={"spinner-wrapper"}>
          <LoadingSpinner/>
        </div>
      }
    </div>
  )
}

function stateToProps({ instrumentTracking, authedUser, userProducts }) {
  return {
    instrumentTracking: instrumentTracking.instrumentTracking,
    isLoading: instrumentTracking?.isLoading,
    authedUser: authedUser,
    cycleDetails: instrumentTracking.cycleDetails,
    cycleLegacyDetails: instrumentTracking.cycleLegacyDetails,
    isLoadingDetails: instrumentTracking?.isLoadingDetails,
    hasError: instrumentTracking?.hasError,
    errorMessage: instrumentTracking?.errorMessage,
    userProducts: userProducts?.products || {}
  };
}

function dispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
        {
          loadTrackingData, 
          loadCycleDetails, 
          loadLegacyCycleDetails,
          clearTrackingMessages,
          clearCycleDetails
        },
        dispatch
    ),
  };
}

export default connect(stateToProps, dispatchToProps)(InstrumentTracking)
