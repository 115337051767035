import React, { useEffect, useState, Fragment } from "react"
import { useTranslation } from "react-i18next"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { toast } from "react-toastify"
import PaymentHistory from "./PaymentHistory"
import SubscriptionCard from "./SubscriptionCard"
import LoadingSpinner from "../../common/LoadingSpinner"
import EmptyPageResults from "../../common/EmptyPageResults"
import ConfirmationModal from "../../common/ConfirmationModal"
import SimpleButton from "../../common/SimpleButton"
import { getStoreProductById } from "../../../utils/requests/productsAPI"
import { deleteSubscription, renewSubscription, loadUserSubscriptions, setInBasket, clearStoreMessages } from "../../../actions/colteneStore"
import { ExpandLessIcon, ExpandMoreIcon } from "../../../icons"
import "./my-subscriptions.scss"

const activeStatuses = ["active", "unpaid", "incomplete", "trialing"]

const MySubscriptions = (props) => {
  const { t } = useTranslation()
  const [activeSubscriptions, setActiveSubscriptions] = useState([])
  const [historySubscriptions, setHistorySubscriptions] = useState([])
  const [expandedSubscriptionHistory, setExpandedSubscriptionHistory] = useState(0)
  const [cancelSubscriptionId, setCancelSubscriptionId] = useState(null)
  const [renewSubscriptionId, setRenewlSubscriptionId] = useState(null)
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const [isPaymentHistory, setIsPaymentHistory] = useState(false)
  const [showSpinner, setShowSpinner] = useState(false)

  useEffect(() => {
    props.actions.loadUserSubscriptions()
      .then(() => setIsFirstLoad(false))
  }, [props.actions])

  useEffect(() => {
    setShowSpinner(props.isLoading)
  }, [props.isLoading])

  useEffect(() => {
    if (props.errorMessage) {
      toast.dismiss()
      toast.error(props.errorMessage)

      props.actions.clearStoreMessages()
    }
  }, [props.errorMessage])

  useEffect(() => {
    if (props.successMessage) {
      toast.dismiss()
      toast.success(props.successMessage)

      props.actions.clearStoreMessages()
    }
  }, [props.successMessage])

  useEffect(() => {
    if (props.subscriptions) {
      const activeFilteredSubscriptions = []
      const historyFilteredSubscriptions = []

      props.subscriptions.forEach((subscription) => {
        if (activeStatuses.includes(subscription.status)) {
          activeFilteredSubscriptions.push(subscription)
        } else {
          historyFilteredSubscriptions.push(subscription)
        }
      })

      setActiveSubscriptions(activeFilteredSubscriptions)
      setHistorySubscriptions(historyFilteredSubscriptions)
    }
  }, [props.subscriptions])

  const handleShowCancelSubscriptionConfirmation = (subscriptionId) => {
    setCancelSubscriptionId(subscriptionId)
  }

  const handleCancelSubscription = (subscriptionId) => {
    if (showSpinner) {
      return
    }

    const subscription = props.subscriptions.find((subscription) => subscription.id === subscriptionId)

    if (subscription) {
      const isTestDriveSubscription = subscription.orderId === null

      props.actions.deleteSubscription(subscriptionId, isTestDriveSubscription)
        .then(() => {
          if (isTestDriveSubscription) {
            window.location.reload(true)
          }
        })
    }

    setCancelSubscriptionId(null)
  }

  const handleShowRenewSubscriptionConfirmation = (subscriptionId) => {
    setRenewlSubscriptionId(subscriptionId)
  }

  const handleRenewSubscription = (subscriptionId) => {
    if (showSpinner) {
      return
    }

    props.actions.renewSubscription(subscriptionId, {
      cancel_at_end: false,
    })

    setRenewlSubscriptionId(null)
  }

  const handleReorder = (subscription) => {
    if (showSpinner) {
      return
    }
    
    setShowSpinner(true)

    getStoreProductById(subscription.product?.id)
      .then((product) => {
        const prices = product.prices.map((p) => p.recurrenceInterval === subscription.priceRecurrenceInterval && p.recurrenceIntervalCount === subscription.priceRecurrenceIntervalCount
         ? {...p, isSelectedPlan: true}
         : {...p, isSelectedPlan: false})

        props.actions.setInBasket({ ...product, prices: prices }, 1)

        setShowSpinner(false)
      })
      .catch(() => {
        toast.dismiss()
        toast.error(t("error.something_wrong"))

        setShowSpinner(false)
      })
  }

  const toggleSubscriptionHistory = (expandedSubscriptionHistoryId) => {
    setExpandedSubscriptionHistory(expandedSubscriptionHistoryId !== expandedSubscriptionHistory ? expandedSubscriptionHistoryId : null)
  }

  return (
    <div className={`${showSpinner && `my-subscriptions-wrapper-loading`} my-subscriptions-wrapper`}>
      {isPaymentHistory && 
        <div className="section-wrapper with-min-height payment-history-wrapper">
          <PaymentHistory
            handleBack={() => setIsPaymentHistory(false)} 
          />
        </div>
      }
      {!isPaymentHistory &&
        <>
          <div className="section-wrapper">
            <div className="wrapper-header">
              <p className="wrapper-title">{t("coltene_store.subscription_history.active-subscriptions")}</p>
              <SimpleButton 
                className="action-button"
                onClick={() => setIsPaymentHistory(true)}
              >
                {t("coltene_store.subscription_history.payment-history")}
              </SimpleButton>
            </div>
            {activeSubscriptions.map((subscription, index) =>
              <Fragment key={`subscription-${index}`}>
                <SubscriptionCard
                  subscription={subscription}
                  handleCancel={handleShowCancelSubscriptionConfirmation}
                  handleRenew={handleShowRenewSubscriptionConfirmation}
                />
                <div className={"separator" + (index === activeSubscriptions.length - 1 ? " hidden": "")}/>
              </Fragment>)
            }
            {!isFirstLoad && activeSubscriptions.length === 0 && 
              <EmptyPageResults 
                title={t("coltene_store.subscription_history.no-active-subscriptions")} 
              />
            }
          </div>
          <div className="section-wrapper">
            <div className="wrapper-header">
              <p className="wrapper-title">{t("coltene_store.subscription_history.subscriptions-history")}</p>
            </div>
            {historySubscriptions.map((subscription, index) =>
              <Fragment key={`subscriptionHistory-${index}`}>
                <div 
                  className={"subscriptions-expand-header" + (expandedSubscriptionHistory === index ? " expanded-header" : "") + (index === historySubscriptions.length - 1 ? " without-border" : "")}
                  onClick={() => toggleSubscriptionHistory(index)}
                >
                  <div className="expanding-title">{subscription.product?.title || "-"}</div>
                  <div className="icon">{expandedSubscriptionHistory === index ? <ExpandLessIcon /> : <ExpandMoreIcon />}</div>
                </div>
                {expandedSubscriptionHistory === index && 
                  <SubscriptionCard
                    subscription={subscription}
                    handleCancel={handleShowCancelSubscriptionConfirmation}
                    handleRenew={handleShowRenewSubscriptionConfirmation}
                    handleReorder={handleReorder}
                    hasActiveSubscription={activeSubscriptions.find((activeSubscription) => activeSubscription.product?.id === subscription.product?.id) ? true : false}
                  />
                }
              </Fragment>)
            }
            {!isFirstLoad && historySubscriptions.length === 0 && 
              <EmptyPageResults 
                title={t("coltene_store.subscription_history.no-expired-subscriptions")} 
              />
            }
          </div>
        </>
      }
      {showSpinner && (
        <div className="spinner-wrapper">
          <LoadingSpinner />
        </div>
      )}
      {cancelSubscriptionId && (
        <ConfirmationModal
          onToggle={() => setCancelSubscriptionId(null)}
          message={t("coltene_store.subscription_history.cancel-subscription")}
          onCancel={() => setCancelSubscriptionId(null)}
          onAccept={() => handleCancelSubscription(cancelSubscriptionId)}
        />
      )}
      {renewSubscriptionId && (
        <ConfirmationModal
          onToggle={() => setRenewlSubscriptionId(null)}
          message={t("coltene_store.subscription_history.renew-subscription")}
          onCancel={() => setRenewlSubscriptionId(null)}
          onAccept={() => handleRenewSubscription(renewSubscriptionId)}
        />
      )}
    </div>
  )
}

function stateToProps({ colteneStore }) {
  return {
    subscriptions: colteneStore?.subscriptions || [],
    isLoading: colteneStore?.isLoading,
    successMessage: colteneStore?.successMessage,
    errorMessage: colteneStore?.errorMessage,
  }
}

function dispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        loadUserSubscriptions,
        deleteSubscription,
        renewSubscription,
        setInBasket,
        clearStoreMessages
      },
      dispatch
    ),
  }
}

export default connect(stateToProps, dispatchToProps)(MySubscriptions)
