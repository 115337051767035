import {
  CanceledIcon,
  CheckCircleFilledIcon,
  DisabledIcon,
  EnabledIcon,
  MenuKebabVerticalBackgroundIcon,
  MenuKebabVerticalIcon,
  PendingIcon,
} from "../../icons"
import ActionsMenu from "../common/ActionsMenu"
import ExpandableText from "../common/ExpandableText"

export function getRequestListHeaders(t) {
  return [
    {
      title: t("dealer-portal.email"),
      orderKey: "email",
    },
    {
      title: t("dealer-portal.request_type"),
      orderKey: "requestType",
    },
    {
      title: t("dealer-portal.status"),
      orderKey: "status",
    },
    {
      title: "",
    },
  ]
}

export const requestListTableKeys = ["email", "requestType", "status", "actions"]
const statuses = ["approved", "accepted", "pending", "rejected", "invited", "requested"]

const renderUserStatus = (t, status) => {
  switch (status) {
    case statuses[0]:
      return (
        <div className="status-active">
          <EnabledIcon />
          <p>{t("status.approved")}</p>
        </div>
      )
    case statuses[1]:
      return (
        <div className="status-active">
          <EnabledIcon />
          <p>{t("status.accepted")}</p>
        </div>
      )
    case statuses[2]:
      return (
        <div className="status-pending">
          <PendingIcon />
          <p>{t("status.pending")}</p>
        </div>
      )
    case statuses[3]:
      return (
        <div className="status-inactive">
          <DisabledIcon />
          <p>{t("status.rejected")}</p>
        </div>
      )
    case statuses[4]:
      return (
        <div className="status-pending">
          <PendingIcon />
          <p>{t("status.invited")}</p>
        </div>
      )
    case statuses[5]:
      return (
        <div className="status-pending">
          <PendingIcon />
          <p>{t("status.requested")}</p>
        </div>
      )
    default:
      return null
  }
}

export const requestListRenderer = ({ t, toggleShowActionsMenu, showActionsMenu, setShowActionsMenu, rowIdentifier, handleApprove, handleReject }) => {
  return {
    email: (item) => {
      return (
        <div className="email-wrapper">
          <ExpandableText text={item.email} />
        </div>
      )
    },
    requestType: (item) => {
      return (
        <div className="type-wrapper">
          <ExpandableText text={t(`dealer-portal.${item.requestType}`)} />
        </div>
      )
    },
    status: (item) => {
      return (
        <div className={`status-wrapper ${item.status}`}>
          <ExpandableText text={renderUserStatus(t, item.status)} />
        </div>
      )
    },
    actions: (item) => {
      return (
        <div
          className="table-actions-wrapper"
          onClick={(e) => {
            e.stopPropagation()
            toggleShowActionsMenu(item[rowIdentifier])
          }}
        >
          {showActionsMenu === item[rowIdentifier] ? <MenuKebabVerticalBackgroundIcon /> : <MenuKebabVerticalIcon />}
          {showActionsMenu === item[rowIdentifier] && (
            <ActionsMenu
              setHideMenu={() => setShowActionsMenu(null)}
              actions={[
                {
                  label: (
                    <span className="label-wrapper">
                      <CheckCircleFilledIcon />
                      {t("labels.approve")}
                    </span>
                  ),
                  onActionClick: (e) => {
                    e.stopPropagation()
                    handleApprove(item)
                  },
                  isDisabled: item.status === "approved",
                },
                {
                  label: (
                    <span className="label-wrapper">
                      <CanceledIcon className="reject-icon" />
                      {t("labels.reject")}
                    </span>
                  ),
                  onActionClick: (e) => {
                    e.stopPropagation()
                    handleReject(item)
                  },
                  isDisabled: item.status === "rejected",
                },
              ]}
              // isLoading={showSpinner}
            />
          )}
        </div>
      )
    },
  }
}
