import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import { toast } from "react-toastify"
import { Storage } from "aws-amplify"
import LoadingSpinner from "../../common/LoadingSpinner"
import SimpleButton from "../../common/SimpleButton"
import { Address, BuildingIcon, CompanyBuilding, CompanyWebsite, PhotoIcon } from "../../../icons"
import { updateUser } from "../../../utils/requests/usersAPI"
import { checkPermission, profileWritePermission } from "../../../utils/permissionValidation"
import "./my-company.scss"
import { bindActionCreators } from "redux"
import { getUserInviteRequest, getUsersCurrentDetails, userAcceptRejectCompanyInvitation, usersLeaveCompany } from "../../../utils/requests/companyAPI"
import { InviteUserModal } from "./InviteUserModal"

const MyCompany = (props) => {
  const { t } = useTranslation()
  const [branchName, setBranchName] = useState("")
  const [userAvatar, setUserAvatar] = useState(null)
  const [showSpinner, setShowSpinner] = useState(null)
  const [inviteList, setInviteList] = React.useState([])
  const [joined, setJoined] = useState(false)
  const [showInviteUserModal, setShowInviteUserModal] = useState(false)
  const [companyAddress, setCompanyAddress] = useState("")

  const company = props.authedUser?.company
  const companyWebsite = props.authedUser?.companywebsite
  
  useEffect(() => {
    toast.dismiss();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getUsersCurrentDetails()

        setJoined(Boolean(response.branch))
        
        if(Boolean(response.branch)) {
          setBranchName(response.branch.branchName)
  
          const address = formatAddress(response)
          setCompanyAddress(address)
        }
      } catch (error) {
        console.error("Error fetching invites data:", error)
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getUserInviteRequest()
        setInviteList(response)
      } catch (error) {
        console.error("Error fetching invites data:", error)
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    let isMounted = true

    if (props.authedUser == null) {
      return
    }

    setShowSpinner(true)

    Storage.list("", { level: "protected", pageSize: 1000 })
      .then((files) => {
        if (isMounted) {
          const avatar = files?.results?.filter((file) => file.key.match(/my_avatar.*/i))
          if (avatar.length > 0) {
            Storage.get(avatar[0].key, { level: "protected" }).then((response) => {
              if (isMounted) {
                setUserAvatar(response)
              }
            })
          }
        }
      })
      .finally(() => setShowSpinner(false))

    return () => {
      isMounted = false
    }
  }, [props.authedUser])

  const updateDatabase = (avatarUrl, showToast, updateCategory) => {
    let updatedAvatarUrl = userAvatar

    switch (updateCategory) {
      case "avatar":
        updatedAvatarUrl = avatarUrl
        break
      default:
        break
    }

    const cognitoSub = props.authedUser?.cognitoSub

    updateUser(cognitoSub, {
      avatar_url: updatedAvatarUrl,
    })
      .then(() => {
        setShowSpinner(false)

        if (showToast) {
          toast.dismiss()
          toast.success(t("profile-labels.profile_updated"))
        }
      })
      .catch((e) => {
        setShowSpinner(false)

        console.log("Error updating backend -- ", e.message || e)

        toast.dismiss()
        toast.error(t("error.failure_msg"))
      })
  }

  const handleRemoveAvatar = () => {
    setShowSpinner(true)

    try {
      Storage.list("", {
        identityId: props.authedUser?.identity,
        level: "protected",
        pageSize: 1000,
      }).then((files) => {
        const avatars = files?.results?.filter((file) => file.key.match(/my_avatar.*/i))
        avatars.forEach((avatar) => {
          Storage.remove(avatar.key, {
            level: "protected",
          }).then(() => {
            toast.dismiss()
            toast.success("Profile picture removed successfully")

            setUserAvatar(null)
            updateDatabase(null, false, "avatar")
          })
        })
      })
    } catch (error) {
      console.log("error", error)

      toast.dismiss()
      toast.error("An error occured while removing the profile picture")

      setShowSpinner(false)
    }
  }

  const handleUploadAvatar = async (file) => {
    let isSuccesfulUpload = true

    try {
      toast.dismiss()

      if (!file) {
        return
      }

      if (file?.size > 2 * 1024 * 1024) {
        toast.error("File size should be less than 2 MB")
        return
      }

      Storage.list("", {
        identityId: props.authedUser?.identity,
        level: "protected",
        pageSize: 1000,
      }).then((files) => {
        const avatars = files?.results?.filter((file) => file.key.match(/my_avatar.*/i))
        avatars.forEach((avatar) => {
          Storage.remove(avatar.key, {
            level: "protected",
          })
        })
      })

      const reader = new FileReader()
      reader.addEventListener("load", function () {
        setUserAvatar(reader.result)
      })
      reader.readAsDataURL(file)

      var ext = file.name.split(".").pop()
      var { key } = await Storage.put(`my_avatar.${ext}`, file, {
        level: "protected",
        contentType: "image/*",
      })
      toast.success("Profile picture updated successfully")
      var img = Storage.get(key, {
        level: "protected",
      })
    } catch (error) {
      console.log("error", error)

      toast.dismiss()
      toast.error("An error occured while updating profile picture")

      isSuccesfulUpload = false
    }

    if (isSuccesfulUpload) {
      const avatarUrl = `protected/${props.authedUser.identity}/my_avatar.${ext}`
      updateDatabase(avatarUrl, false, "avatar")
    }
  }

  const handleFileInput = async (event) => {
    if (!checkPermission(props.userPermissions, profileWritePermission)) {
      return
    }

    const file = event?.target?.files[0]

    await handleUploadAvatar(file)
  }

  const handleInviteAccept = async (inviteId) => {
    toast.dismiss()
    const bodyParams = {
      accept: true,
    }
    try {
      setShowSpinner(true)
      await userAcceptRejectCompanyInvitation(inviteId, bodyParams)
      
      const requests = await getUserInviteRequest()
      setInviteList(requests)
      
      const details = await getUsersCurrentDetails()
      setJoined(Boolean(details.branch))
      setBranchName(details.branch.branchName)

      const address = formatAddress(details)
      setCompanyAddress(address)
      
      toast.success(t("account.invitation_accepted"))
      setShowSpinner(false)
    } catch (error) {
      setShowSpinner(false)
      toast.error(t("error.something_wrong"))
    }
  }

  const handleInviteReject = async (inviteId) => {
    toast.dismiss()
    const bodyParams = {
      accept: false,
    }
    try {
      setShowSpinner(true)
      await userAcceptRejectCompanyInvitation(inviteId, bodyParams)
      
      const response = await getUserInviteRequest()
      setInviteList(response)
      
      toast.success(t("account.invitation_rejected"))
      setShowSpinner(false)
    } catch (error) {
      setShowSpinner(false)
      toast.error(t("error.something_wrong"))
    }
  }

  const handleLeaveCompany = async () => {
    try {
      setShowSpinner(true)
      const response = await usersLeaveCompany()
      
      setJoined(false)
      setCompanyAddress("")
      
      toast.success(t("account.leave_company_message"))
      setShowSpinner(false)
    } catch (error) {
      setJoined(true)
      setShowSpinner(false)
      toast.error(t("error.something_wrong"))
    }
  }

  return (
    <div className="company-details-wrapper d-flex flex-column">
      {showSpinner && (
        <div className="spinner-wrapper">
          <LoadingSpinner />
        </div>
      )}
      <div className="sections-wrapper">
        <p className="wrapper-title">{t("account.company_details")}</p>
        <div className="section">
          <div className="section-title">
            <p>{t("account.company_logo")}</p>
            <p className="disclaimer">{t("account.photo_discalimer")}</p>
          </div>
          <div className="section-content avatar">
            <div className="avatar-wrapper">
              <label className="file-input-wrapper" htmlFor="file-input">
                <div className="avatar-img">
                  {userAvatar ? (
                    <img className="image" src={userAvatar} alt="avatar" />
                  ) : (
                    <div className="avatar-initials">
                      <span></span>
                    </div>
                  )}
                </div>
                <PhotoIcon />
              </label>
              <input className="file-input" id="file-input" onChange={(e) => handleFileInput(e)} type="file" accept="image/*" />
            </div>
            <SimpleButton
              className="action-button remove-image-button"
              onClick={() => handleRemoveAvatar()}
              disabled={!userAvatar || showSpinner}
              requiredPermission={profileWritePermission}
            >
              {t("account.remove")}
            </SimpleButton>
          </div>
        </div>
        <div className="separator" />
        <div className="section">
          <div className="section-title">{t("account.company_information")}</div>
          <div className="section-content">
            {inviteList
              .filter((i) => i.status === "invited")
              .map((invite) => (
                <div className="info-card">
                  <div className="title">{t("account.company_invite")}</div>
                  <div className="body">
                    <div>
                      You have been invited to <b>{invite.companyName}</b> by <b>{invite.createdBy}</b>
                    </div>
                    <div className="btn-container">
                      <button onClick={() => handleInviteReject(invite.id)}>Reject</button>
                      <button className="accept-btn" onClick={() => handleInviteAccept(invite.id)}>
                        Accept
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            <div className="form-wrapper">
              <div className="company-inputs">
                <div className="input-item-wrapper">
                  <div className="input-item-icon">
                    <CompanyBuilding />
                  </div>
                  <input type="text" name="company" className="input-item" value={company} placeholder={t("account.company")} readOnly={true} />
                </div>
                <div className="input-item-wrapper">
                  <div className="input-item-icon">
                    <CompanyWebsite />
                  </div>
                  <input type="text" name="company-website" className="input-item" value={companyWebsite} placeholder={t("account.website")} readOnly={true} />
                </div>
              </div>
              <div>
                <div className="input-item-wrapper">
                  <div className="input-item-icon">
                    <Address />
                  </div>
                  <input type="text" name="address" className="input-item" value={companyAddress} placeholder={t("account.website")} readOnly={true} />
                </div>
              </div>
              {!joined && (
                <div>
                  <button
                    className="link"
                    onClick={() => {
                      setShowInviteUserModal(true)
                    }}
                  >
                    {t("account.request_to_join")}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        {joined && (
          <div className="section">
            <div className="section-title">Branch</div>
            <div className="section-content">
              <div style={{ width: "37%" }}>
                <div className="input-item-wrapper">
                  <div className="input-item-icon">
                    <BuildingIcon />
                  </div>
                  <input type="text" name="company-branch" className="input-item" value={branchName} readOnly={true} />
                </div>
              </div>
              <button className="link destructive" onClick={() => handleLeaveCompany()}>
                Leave Company
              </button>
            </div>
          </div>
        )}
        {/* {!joined && (
          <>
            <div className="separator" />
            <div className="section">
              <div className="section-title">Members</div>
              <div className="section-content">
                <div>
                  <button className="link">Members</button>
                  <button className="link">Branches</button>
                </div>
              </div>
            </div>
          </>
        )} */}
      </div>

      {showInviteUserModal && (
        <InviteUserModal
          isRequestListTable={false}
          handleClose={() => {
            setShowInviteUserModal(false)
          }}
        />
      )}

      {showSpinner && (
        <div className={"spinner-wrapper"}>
          <LoadingSpinner />
        </div>
      )}
    </div>
  )
}

function formatAddress(response) {
  return response.branch.officeAddressOne +
    " " +
    response.branch.officeAddressTwo +
    " " +
    response.branch.city +
    " " +
    response.branch.stateProvinceRegion +
    " " +
    response.branch.country +
    " " +
    response.branch.zipPostalCode
}

function stateToProps({ authedUser, userPermissions }) {
  return {
    authedUser,
    userPermissions,
  }
}

function dispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({}, dispatch),
  }
}

export default connect(stateToProps, dispatchToProps)(MyCompany)
