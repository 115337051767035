import { combineReducers } from "redux"
import productTypes from "./productTypes"
import authedUser from "./authedUser"
import userProducts from "./userProducts"
import onlineAccess from "./onlineAccess"
import ticket from "./reportTicket"
import instrumentTracking from "./instrumentTracking"
import warranty from "./warranty"
import colteneStore from "./colteneStore"
import adminStore from "./adminStore"
import userPermissions from "./userPermissions"
import systemEvents from "./events"
import notifications from "./notifications"
import accounts from "./accountsManagement"
import businessRules from "./businessRules"
import services from "./services"
import policies from "./policies"
import firmwareDownload from "./firmware"
import adminOrderHistory from "./adminOrderHistory"
import apertures from './apertures'
import documents from "./documents"
import users from "./users"
import queryParams from "./queryParams"
import backNavigationState from "./backNavigationState"
import videos from "./videos"
import priceLists from "./priceLists"
import requestHelp from "./requestHelp"
import { LOGOUT } from "../actions/root"

const appReducer = combineReducers({
  authedUser,
  apertures,
  userProducts,
  productTypes,
  onlineAccess,
  ticket,
  instrumentTracking,
  warranty,
  colteneStore,
  adminStore,
  userPermissions,
  systemEvents,
  firmwareDownload,
  notifications,
  accounts,
  businessRules,
  services,
  policies,
  adminOrderHistory,
  documents,
  users,
  queryParams,
  backNavigationState,
  videos,
  priceLists,
  requestHelp,
})

const rootReducer = (state, action) => {
  if (action.type === LOGOUT) {
    state = undefined
  }

  return appReducer(state, action)
}

export default rootReducer