import {
  getPolicies,
  getPolicyTypes,
  getPolicyPermissions,
  getPermissions,
  createPolicy,
  createPolicyType,
  putPolicy,
  putPolicyType,
  deletePolicy,
  deletePolicyType, putPolicyPermissions
} from "../utils/requests/policiesAPI"

export const LOAD_POLICIES = "LOAD_POLICIES"
export const LOAD_POLICIES_PENDING = "LOAD_POLICIES_PENDING"
export const LOAD_POLICIES_FULFILLED = "LOAD_POLICIES_FULFILLED"
export const LOAD_POLICIES_REJECTED = "LOAD_POLICIES_REJECTED"

export const PUT_POLICY = "PUT_POLICY"
export const PUT_POLICY_PENDING = "PUT_POLICY_PENDING"
export const PUT_POLICY_FULFILLED = "PUT_POLICY_FULFILLED"
export const PUT_POLICY_REJECTED = "PUT_POLICY_REJECTED"

export const DELETE_POLICY = "DELETE_POLICY"
export const DELETE_POLICY_PENDING = "DELETE_POLICY_PENDING"
export const DELETE_POLICY_FULFILLED = "DELETE_POLICY_FULFILLED"
export const DELETE_POLICY_REJECTED = "DELETE_POLICY_REJECTED"

export const LOAD_POLICY_TYPES = "LOAD_POLICY_TYPES"
export const LOAD_POLICY_TYPES_PENDING = "LOAD_POLICY_TYPES_PENDING"
export const LOAD_POLICY_TYPES_FULFILLED = "LOAD_POLICY_TYPES_FULFILLED"
export const LOAD_POLICY_TYPES_REJECTED = "LOAD_POLICY_TYPES_REJECTED"

export const PUT_POLICY_TYPE = "PUT_POLICY_TYPE"
export const PUT_POLICY_TYPE_PENDING = "PUT_POLICY_TYPE_PENDING"
export const PUT_POLICY_TYPE_FULFILLED = "PUT_POLICY_TYPE_FULFILLED"
export const PUT_POLICY_TYPE_REJECTED = "PUT_POLICY_TYPE_REJECTED"

export const DELETE_POLICY_TYPE = "DELETE_POLICY_TYPE"
export const DELETE_POLICY_TYPE_PENDING = "DELETE_POLICY_TYPE_PENDING"
export const DELETE_POLICY_TYPE_FULFILLED = "DELETE_POLICY_TYPE_FULFILLED"
export const DELETE_POLICY_TYPE_REJECTED = "DELETE_POLICY_TYPE_REJECTED"

export const LOAD_POLICY_PERMISSIONS = "LOAD_POLICY_PERMISSIONS"
export const LOAD_POLICY_PERMISSIONS_PENDING = "LOAD_POLICY_PERMISSIONS_PENDING"
export const LOAD_POLICY_PERMISSIONS_FULFILLED = "LOAD_POLICY_PERMISSIONS_FULFILLED"
export const LOAD_POLICY_PERMISSIONS_REJECTED = "LOAD_POLICY_PERMISSIONS_REJECTED"

export const LOAD_PERMISSIONS = "LOAD_PERMISSIONS"
export const LOAD_PERMISSIONS_PENDING = "LOAD_PERMISSIONS_PENDING"
export const LOAD_PERMISSIONS_FULFILLED = "LOAD_PERMISSIONS_FULFILLED"
export const LOAD_PERMISSIONS_REJECTED = "LOAD_PERMISSIONS_REJECTED"

export const PUT_POLICY_PERMISSIONS = "PUT_POLICY_PERMISSIONS"
export const PUT_POLICY_PERMISSIONS_PENDING = "PUT_POLICY_PERMISSIONS_PENDING"
export const PUT_POLICY_PERMISSIONS_FULFILLED = "PUT_POLICY_PERMISSIONS_FULFILLED"
export const PUT_POLICY_PERMISSIONS_REJECTED = "PUT_POLICY_PERMISSIONS_REJECTED"

export const POST_POLICY_TYPE = "POST_POLICY_TYPE"
export const POST_POLICY_TYPE_PENDING = "POST_POLICY_TYPE_PENDING"
export const POST_POLICY_TYPE_FULFILLED = "POST_POLICY_TYPE_FULFILLED"
export const POST_POLICY_TYPE_REJECTED = "POST_POLICY_TYPE_REJECTED"

export const POST_POLICY = "POST_POLICY"
export const POST_POLICY_PENDING = "POST_POLICY_PENDING"
export const POST_POLICY_FULFILLED = "POST_POLICY_FULFILLED"
export const POST_POLICY_REJECTED = "POST_POLICY_REJECTED"

export const CLEAR_POLICIES_MESSAGES = "CLEAR_POLICIES_MESSAGES"
export const CLEAR_POLICY_TYPE_MESSAGES = "CLEAR_POLICY_TYPE_MESSAGES"
export const CLEAR_POLICIES = "CLEAR_POLICIES"

export function loadPolicies(queryParams = null) {
  return {
    type: LOAD_POLICIES,
    payload: getPolicies(),
    meta: { queryParams }
  }
}

export function loadPolicyTypes(queryParams = null) {
  return {
    type: LOAD_POLICY_TYPES,
    payload: getPolicyTypes(),
    meta: { queryParams }
  }
}

export function loadPolicyPermissions(id) {
  return {
    type: LOAD_POLICY_PERMISSIONS,
    payload: getPolicyPermissions(id),
  }
}

export function loadPermissions() {
  return {
    type: LOAD_PERMISSIONS,
    payload: getPermissions(),
  }
}

export function updatePolicy(id, bodyParams) {
  return {
    type: PUT_POLICY,
    payload: putPolicy(id, bodyParams)
  }
}

export function removePolicy(id) {
  return {
    type: DELETE_POLICY,
    payload: deletePolicy(id),
    meta: { id }
  }
}

export function updatePolicyType(id, bodyParams) {
  return {
    type: PUT_POLICY_TYPE,
    payload: putPolicyType(id, bodyParams)
  }
}

export function removePolicyType(id) {
  return {
    type: DELETE_POLICY_TYPE,
    payload: deletePolicyType(id),
    meta: { id }
  }
}

export function updatePolicyPermissions(id, bodyParams) {
  return {
    type: PUT_POLICY_PERMISSIONS,
    payload: putPolicyPermissions(id, bodyParams)
  }
}

export function addPolicyType(bodyParams) {
  return {
    type: POST_POLICY_TYPE,
    payload: createPolicyType(bodyParams)
  }
}

export function addPolicy(bodyParams) {
  return {
    type: POST_POLICY,
    payload: createPolicy(bodyParams)
  }
}

export function clearPoliciesMessages() {
  return {
    type: CLEAR_POLICIES_MESSAGES
  }
}

export function clearPolicyTypeMessages() {
  return {
    type: CLEAR_POLICY_TYPE_MESSAGES
  }
}
