import { LOAD_PRICE_LIST_FILTERS_FULFILLED, LOAD_PRICE_LIST_FILTERS_PENDING, LOAD_PRICE_LIST_FILTERS_REJECTED } from "../actions/priceLists"

function priceLists(state = {}, action) {
  switch (action.type) {
    case LOAD_PRICE_LIST_FILTERS_PENDING:
      return {
        ...state,
        loading: true,
      }
    case LOAD_PRICE_LIST_FILTERS_FULFILLED:
      return {
        ...state,
        filters: action.payload.data,
        loading: false,
      }
    case LOAD_PRICE_LIST_FILTERS_REJECTED:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    default:
      return state
  }
}

export default priceLists
