import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Auth } from "aws-amplify"
import { toast } from 'react-toastify'
import './signup.scss'
import Button from '../common/Button'
import ConfirmationCode from './ConfirmationCode'
import { processError } from "../../libs/processError"
import { useFormFields } from "../../libs/hooks"
import { useTranslation } from 'react-i18next'
import PublicPageHeading from "../common/PublicPageHeading"
import TextInputField from '../common/TextInputField'
import SelectField from '../common/SelectField'
import languages from '../../utils/languages'
import countries from "../../utils/countries.json";
import { getUserValidationShema, validateForm } from '../../utils/formValidation'
import {
  PostBoxIcon,
  WWWIcon,
  EmailIcon,
  BuildingIcon,
  PersonIcon,
  PhoneIcon,
  WorldIcon,
  LockIcon,
  FaxIcon,
  CityIcon,
  ProvinceIcon,
  FlagIcon,
  EyeIcon,
  EyeOffIcon
} from '../../icons'
import {connect} from "react-redux";
import { useHistory } from 'react-router-dom'

import { formatPhone } from '../../utils/functions'
import { getUserAccountTypes } from '../../utils/requests/usersAPI'
const CreateAccount = ( props ) => {
  const [error, setError] = useState({})
  const [authError, setAuthError] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [newUser, setNewUser] = useState(null)
  const [isSubmitting, setSubmitting] = useState(false)
  const [hidePassword, setHidePassword] = useState(true)
  const [hideConfirmPassword, setHideConfirmPassword] = useState(true)
  const { t } = useTranslation()
  const { userGroup } = props.match.params || ''
  const typeKey = userGroup.replace('-', '_')
  const [fields, handleFieldChange] = useFormFields({
    email: props.querystring && props.querystring?.email ? props.querystring?.email : "",
    password: "",
    confirmpassword: "",
    firstname: "",
    lastname: "",
    language: "",
    company: "",
    companywebsite: "",
    phone: "",
    fax: "",
    city: "",
    stateprovince: "",
    postalcode: "",
    usergroup: "",
    isUserGroup: false
  })
  
  const history = useHistory()
  const [subGroups, setSubGroups] = useState([])
  const [accountType, setAccountTypes] = React.useState([])
  
  useEffect(() => {
    if (history.location.state) {
      setSubGroups(history.location.state?.userGroups)
    } else {
      const parts = history.location.pathname.split("/")
      const afterCreateAccount = parts[parts.indexOf("create-account") + 1].replaceAll("-", "_")
      
      getUserAccountTypes().then((res) => {
        let nextSubGroups=Object.values(res).filter(value => {
          if(afterCreateAccount === "dentist") {
            return value.name === "End User"
          }else if(afterCreateAccount === "dealer_technician") {
            return value.name === "Dealer"
          }else {
            return value.name === "Coltene"
          }
        }).flatMap(value => value.userGroups)
        
        setSubGroups(nextSubGroups)
      })
    }
  }, [])
  
  const userErrorMsg = {
    firstname: t("error.firstname"),
    lastname: t("error.lastname"),
    language: t("error.language"),
    country: t("error.country"),
    company: t("error.company"),
    phone: t("error.phone"),
    email: t("error.email"),
    password: t("error.password"),
    confirmpassword: t("error.confirm_password"),
    privacy: t("error.privacy"),
    usergroup: t("error.user_group")
  }

  // const privacy = t("privacy_link")
  // const terms = t("terms_link")

  useEffect(() => {
    if(userGroup.indexOf('employee') > -1) {
      const elem = document.getElementById('isUserGroup')
      const event = new Event('click', { 'bubbles': true })
      elem.dispatchEvent(event)
    } else {
      fields.usergroup = userGroup.replace('-', '_')
    }
  }, [fields.userGroup, userGroup])
  
  const getUserSubGroups = () => {
    const groups = {}
    const originalGroups = {}
      Object.keys(subGroups).forEach( (index) => {
        return groups[subGroups[index]["id"]] = t(`register.${(subGroups[index]["name"]).toLowerCase().replaceAll(" ","_")}`)
      })
      Object.keys(subGroups).forEach( (index) => {
        return originalGroups[subGroups[index]["id"]] = subGroups[index]["name"].toLowerCase().replaceAll(" ","_")
      })

    const initialGroup = Object.keys(groups).length === 1 ? Object.keys(groups)[0] : fields.usergroup
    
    return (
      <div className="input-fields">
        <SelectField
          name="usergroup"
          value={initialGroup}
          options={groups}
          selectLbl={t("select_option_label.select_user_group")}
          onChange={(e) => {
            e.detail.value = originalGroups[e.detail.value].toLowerCase().replaceAll(" ", "_")
            handleFieldChange(e)
          }}
          error= {error.usergroup ? error.usergroup : null}
          isRequired={true}
          isSubmitting={isSubmitting}
        />
      </div>
    )
  }

  const getLanguageOptions = () => {
    const langs = {}
    Object.keys(languages).forEach( (k) => langs[k] = t(`language.${languages[k]}`) )

    return (
      <SelectField
          name="language"
          value={fields.language}
          options={langs}
          selectLbl={t("select_option_label.select_language")}
          onChange={handleFieldChange}
          error= {error.language ? error.language : null}
          isRequired={true}
          isSubmitting={isSubmitting}
          selectIcon={<WorldIcon className="globe-icon" />}
        />
    )
  }

  const getCountryOptions = () => {
    const countryData = {}
    Object.keys(countries).forEach( (index) => {
      return countryData[countries[index]["code"]] = countries[index]["name"]
    })

    return (
      <SelectField
          name="country"
          value={fields.country}
          options={countryData}
          selectLbl={t("select_option_label.select_country")}
          onChange={handleFieldChange}
          error= {error.country ? error.country : null}
          isRequired={true}
          isSubmitting={isSubmitting}
          selectIcon={<FlagIcon className="flag-icon" />}
        />
    )
  }

  const handleSignup = async (e) => {
    e.preventDefault()
    setError({})
    setSubmitting(true)

    toast.dismiss()

    const userSchema = getUserValidationShema(userErrorMsg)
    const validateData = await validateForm(userSchema, fields)

    setError(validateData)

    if(Object.keys(validateData).length === 0) {
      setLoading(true)

      try {
        const user = await Auth.signUp({
          'username': fields.email,
          'password': fields.password,
          'attributes': {
            'email': fields.email,
            'given_name': fields.firstname || '',
            'family_name': fields.lastname || '',
            'locale': fields.language || '',
            'website': fields.companywebsite || '',
            'custom:phone_number': fields.phone || '',
            'custom:fax': fields.fax || '',
            'custom:company': fields.company || '',
            'custom:postal_code': fields.postalcode || '',
            'custom:city': fields.city || '',
            'custom:state': fields.stateprovince || '',
            'custom:country': fields.country || '',
            'custom:user_group': fields.usergroup ? fields.usergroup : (userGroup ? userGroup.replace('-','_') : 'dentist'),
            'custom:privacy': fields.privacy ? fields.privacy.toString() : 'false',
            'custom:newsletter': fields.subscribe ? fields.subscribe.toString() : 'false'
          }
        })

        setNewUser(user)
        setLoading(false)
        setSubmitting(false)
      } catch (e) {
        processError(e)
        // const em = e.message
        // toast.error(e.message)
    // toast.success(e.message)
    // toast.warn(e.message)
    // toast.info(e.message)
    // toast(e.message)
        // setAuthError(em)
        setLoading(false)
        setSubmitting(false)
      }
    }
  }

  const getCreateAccountSubHeading = () => (
    <div>
      { t("register.already_have_account") + " " }
      <Link to="/" className="login-link">
        {t("register.login_account")}
      </Link>
    </div>
  )

  const toggleShowHidePassword = (e) => {
    document.getElementById('password').type = hidePassword ? "text" : "password"
    setHidePassword(!hidePassword)
  }

  const toggleShowHideConfirmPassword = (e) => {
    document.getElementById('confirmpassword').type = hideConfirmPassword ? "text" : "password"
    setHideConfirmPassword(!hideConfirmPassword)
  }

  //user type is required
  // if(Object.keys(userGroups).indexOf(typeKey) === -1 ) {
  //   return <Redirect to="/register" />
  // }

  return (
    newUser !== null
    ?  <ConfirmationCode
        username={fields.email}
        user={fields}
      />
    :
    <div className="center signup-container public-page">
      <div className="center form-container">
        <div>
          <PublicPageHeading heading={t(`register.${typeKey}_registration`)} subheading={getCreateAccountSubHeading()} />
          {
            authError &&
            <div className="error-message">{authError}</div>
          }
          <div className="signup-form">
            <form onSubmit={ handleSignup } autoComplete="off" noValidate>
              <div>
                {getUserSubGroups()}
                <input type="hidden" id="isUserGroup" onClick={handleFieldChange} name="isUserGroup" value={true} />
              </div>
              <div className="input-fields">
                <TextInputField
                    type="text"
                    name="firstname"
                    value={fields.firstname}
                    autoFocus
                    placeholder={t("profile-labels.first_name")}
                    onChange = {handleFieldChange}
                    error= {error.firstname ? error.firstname : null}
                    isRequired={true}
                    isSubmitting={isSubmitting}
                    inputIcon={<PersonIcon className="user-icon" />}
                  />
                  <TextInputField
                    type="text"
                    name="lastname"
                    value={fields.lastname}
                    placeholder={t("profile-labels.last_name")}
                    onChange = {handleFieldChange}
                    error= {error.lastname ? error.lastname : null}
                    isRequired={true}
                    isSubmitting={isSubmitting}
                    inputIcon={<PersonIcon className="user-icon" />}
                  />
              </div>
              <div className="input-fields">
                  <TextInputField
                    type="text"
                    name="phone"
                    value={fields.phone}
                    placeholder={t("profile-labels.phone")}
                    onChange={(e) => {
                      e.target.value = formatPhone(e.target.value)
                      handleFieldChange(e)
                    }}
                    error= {error.phone ? error.phone : null}
                    isRequired={true}
                    isSubmitting={isSubmitting}
                    inputIcon={<PhoneIcon className="phone-icon" />}
                  />
                  <TextInputField
                    type="text"
                    name="fax"
                    value={fields.fax}
                    placeholder={t("profile-labels.fax")}
                    onChange = {handleFieldChange}
                    isSubmitting={isSubmitting}
                    inputIcon={<FaxIcon className="fax-icon" />}
                  />
              </div>
              <div className="input-fields">
                <TextInputField
                    type="text"
                    name="company"
                    value={fields.company}
                    placeholder={t("profile-labels.company")}
                    onChange = {handleFieldChange}
                    error= {error.company ? error.company : null}
                    isRequired={true}
                    isSubmitting={isSubmitting}
                    inputIcon={<BuildingIcon className="building-icon" />}
                  />
                <TextInputField
                    type="text"
                    name="companywebsite"
                    value={fields.companywebsite}
                    placeholder={t("profile-labels.company_website")}
                    onChange = {handleFieldChange}
                    isSubmitting={isSubmitting}
                    inputIcon={<WWWIcon className="icon" />}
                  />
              </div>
              <div className="input-fields">
                  <TextInputField
                    type="text"
                    name="city"
                    value={fields.city}
                    placeholder={t("profile-labels.city")}
                    onChange = {handleFieldChange}
                    isSubmitting={isSubmitting}
                    inputIcon={<CityIcon className="city-icon" />}
                  />
                  <TextInputField
                    type="text"
                    name="stateprovince"
                    value={fields.stateprovince}
                    placeholder={t("profile-labels.state_province")}
                    onChange = {handleFieldChange}
                    isSubmitting={isSubmitting}
                    inputIcon={<ProvinceIcon className="at-icon" />}
                  />

              </div>
              <div className="input-fields row">
                <TextInputField
                  type="text"
                  name="postalcode"
                  value={fields.postalcode}
                  placeholder={t("profile-labels.postal_code")}
                  onChange = {handleFieldChange}
                  isSubmitting={isSubmitting}
                  inputIcon={<PostBoxIcon className="icon" />}
                />
                {
                  getCountryOptions()
                }
              </div>
              <div className="input-fields row">
                <TextInputField
                  type="email"
                  name="email"
                  value={fields.email}
                  placeholder={t("profile-labels.email")}
                  onChange = {handleFieldChange}
                  error= {error.email ? error.email : null}
                  isRequired={true}
                  isSubmitting={isSubmitting}
                  inputIcon={<EmailIcon className="at-icon" />}
                />
                {
                  getLanguageOptions()
                }
              </div>
              <div className="input-fields row">
                <TextInputField
                    type="password"
                    name="password"
                    id="password"
                    value={fields.password}
                    placeholder={t("profile-labels.password")}
                    autoComplete="new-password"
                    onChange = {handleFieldChange}
                    error= {error.password ? error.password : null}
                    sublabel={t("profile-labels.password_message")}
                    isRequired={true}
                    isSubmitting={isSubmitting}
                    inputIcon={<LockIcon className="lock-icon" />}
                    rightIcon={ hidePassword
                      ? <EyeIcon className="eye-icon" onClick={toggleShowHidePassword} />
                      : <EyeOffIcon className="eye-icon" onClick={toggleShowHidePassword} />
                    }
                  />
                <TextInputField
                    type="password"
                    name="confirmpassword"
                    id="confirmpassword"
                    value={fields.confirmpassword}
                    placeholder={t("profile-labels.confirm_password")}
                    onChange = {handleFieldChange}
                    error= {error.confirmpassword ? error.confirmpassword : null}
                    isRequired={true}
                    isSubmitting={isSubmitting}
                    inputIcon={<LockIcon className="lock-icon" />}
                    rightIcon={ hideConfirmPassword
                      ? <EyeIcon className="eye-icon" onClick={toggleShowHideConfirmPassword} />
                      : <EyeOffIcon className="eye-icon" onClick={toggleShowHideConfirmPassword} />
                    }
                  />

              </div>

              <div className="privacy-wrapper">
                <div className="privacy">
                  <input
                  type="checkbox"
                  id="privacy-term"
                  name="privacy"
                  className="privacy"
                  onChange = {handleFieldChange}
                  />
                  <label htmlFor="privacy-term">
                    {/* <Trans i18nKey="register.accept_privacy_terms">
                    {t("register.accept_privacy_terms")}
                    <a href={t("privacy_link")} target="_blank" rel="noreferrer" className="external-link">{t("privacy_policy")}</a>
                  </Trans> */}
                  </label>
                  <label htmlFor="privacy-term">
                    {t("register.i_accept_scican")} <span><a href="https://www.scican.com/privacy/" target="_blank" rel="noreferrer" className="external-link">{t("register.privacy_statement")}</a></span> &
                    <span><a href="https://www.scican.com/terms/" target="_blank" rel="noreferrer" className="external-link">{t("register.terms_of_use")}</a></span>.
                  </label>
                  <span className="required">*</span>
                </div>
                {error.privacy && <div className="error-message">{error.privacy}</div>}
              </div>

              <div className="subscribe-wrapper">
                <div className="subscribe">
                  <input
                  type="checkbox"
                  name="subscribe"
                  id="subscribe"
                  className="subscribe"
                  onChange = {handleFieldChange}
                  />
                  <label htmlFor="subscribe">
                    {t("newsletter_agreement")} <span><a href={t("privacy_link")} target="_blank" rel="noreferrer" className="external-link">{t("privacy_policy")}</a>, <a href={t("contact_link")} target="_blank" rel="noreferrer" className="external-link">{t("contact_us")}</a></span>.
                  </label>
                </div>
              </div>
              <Button
                type="submit"
                isLoading={isLoading}
                className='large'
              >
                {t("register.create_account")}
              </Button>
            </form>

          </div>
        </div>
      {/* } */}
      </div>
    </div>
  )
}

function mapStateToProps({ onlineAccess }) {
  return {
    querystring: onlineAccess ? onlineAccess : null
  }
}

export default connect(mapStateToProps)(CreateAccount)
