import React from "react"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
import SimpleButton from "../SimpleButton"
import { QuestionIcon } from "../../../icons"
import "./confirmation-modal.scss"

const ConfirmationModal = (props) => {
  const { t } = useTranslation()
  const { onToggle, onCancel, onAccept, message, acceptButtonText, cancelButtonText, isWarning, requiredPermission, acceptDisabled = false } = props

  const closeModal = () => {
    onToggle()
  }

  return (
    <div className={`confirmation-modal-wrapper ${props.className}`}>
      <div className="overflow" onClick={closeModal} />
      <div className="modal">
        <div className="confirmation-modal-message">
          <QuestionIcon />
          {message}
        </div>
        {props.children}
        <div className="confirmation-modal-buttons">
          <SimpleButton className="cancel-button" onClick={() => onCancel()}>
            {cancelButtonText || t("buttons.cancel")}
          </SimpleButton>
          <SimpleButton 
            className={isWarning ? "delete-button" : "submit-button"} 
            onClick={() => onAccept()} 
            disabled={acceptDisabled}
            requiredPermission={requiredPermission}
          >
            {acceptButtonText || t("labels.yes")}
          </SimpleButton>
        </div>
      </div>
    </div>
  )
}

ConfirmationModal.propTypes = {
  onToggle: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
  message: PropTypes.string,
  acceptButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  isWarning: PropTypes.bool,
}

export default ConfirmationModal