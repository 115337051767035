import React, { useEffect } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useTranslation } from "react-i18next"
import { useAppContext } from "../../../libs/contextLib"

const PublicRoute = ({component: Component, ...rest}) => {
  const {isAuthenticated } = useAppContext()
  const { t } = useTranslation()

  useEffect(() => {
    if (isAuthenticated && rest?.path === "/forgot-password") {
      toast.error(t("error.forgot_password_already_logged_in"))
    }
  },[rest.path])

  return (
    <Route
      {...rest}
      render={ props => (
        (!isAuthenticated || isAuthenticated === undefined || isAuthenticated === null)
          ? <Component {...props} />
          : <Redirect
              to={{
                pathname: "/",
                state: {from: props.location}
              }}
            />
      )}
    />
  )
}

export default PublicRoute