import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useLocation } from 'react-router-dom'
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { toast } from "react-toastify"
import DashboardHeader from "../common/DashboardHeader/DashboardHeader"
import Breadcrumb from "../common/Breadcrumb"
import ViewEditUser from "./ViewEditUser"
import LoadingSpinner from "../common/LoadingSpinner"
import SearchWithFilter from "../common/SearchWithFilter"
import FiltersModal, { FilterType } from "../common/FiltersModal"
import ClickOutside from "../common/ClickOutside"
import EmptyPageResults from "../common/EmptyPageResults"
import SimpleButton from "../common/SimpleButton"
import ConfirmationModal from '../common/ConfirmationModal'
import GenericTable from "../common/GenericTable"
import ActionsMenu from "../common/ActionsMenu"
import ExpandableText from "../common/ExpandableText"
import { useWindowSize } from "../../libs/hooks"
import { DisabledIcon, EnabledIcon, CaretDownIcon, MenuKebabVerticalIcon, PendingIcon, MenuKebabVerticalBackgroundIcon } from "../../icons"
import { loadUsers, partiallyUpdateUser, removeUser, clearUsersMessages, clearUserDevices, clearUserSubscriptions, confirmUser } from "../../actions/users"
import { loadAccounts } from "../../actions/accountsManagement"
import { setBackNavigationState, clearBackNavigationState } from "../../actions/backNavigationState"
import { getRoles } from "../../utils/requests/accountManagementAPI"
import { usersDeletePermission, usersWritePermission } from "../../utils/permissionValidation"
import { convertToSimpleDateWithTime } from '../../utils/filters/date'
import { useDeepCompareEffect } from '../../hooks/useDeepCompareEffect'
import "./users.scss"

const orderingFields = [
  {
    field: "email",
    headerField: "email",
    order: "asc",
    key: "email_asc",
  },
  {
    field: "email",
    headerField: "email",
    order: "desc",
    key: "email_desc",
  },
  {
    field: "name",
    headerField: "name",
    order: "asc",
    key: "name_asc",
  },
  {
    field: "name",
    headerField: "name",
    order: "desc",
    key: "name_desc",
  },
  {
    field: "account",
    headerField: "account",
    order: "asc",
    key: "account_asc",
  },
  {
    field: "account",
    headerField: "account",
    order: "desc",
    key: "account_desc",
  },
  {
    field: "role",
    headerField: "role",
    order: "asc",
    key: "role_asc",
  },
  {
    field: "role",
    headerField: "role",
    order: "desc",
    key: "role_desc",
  },
  {
    field: "confirmationStatus",
    headerField: "confirmationStatus",
    order: "asc",
    key: "confirmationStatus_asc",
  },
  {
    field: "confirmationStatus",
    headerField: "confirmationStatus",
    order: "desc",
    key: "confirmationStatus_desc",
  },
  {
    field: "status",
    headerField: "status",
    order: "asc",
    key: "status_asc",
  },
  {
    field: "status",
    headerField: "status",
    order: "desc",
    key: "status_desc",
  },
]

const Users = (props) => {
  const { t } = useTranslation()
  const location = useLocation()
  const isMobile = useWindowSize()[0] <= 768
  const statuses = ["activated", "pending", "disabled"]
  const [usersTableData, setUsersTableData] = useState([])
  const [selectedUser, setSelectedUser] = useState(null)
  const [accountsWithRoles, setAccountsWithRoles] = useState([])
  const [accountsWithRolesActive, setAccountsWithRolesActive] = useState([])
  const [shouldApplyFilters, setShouldApplyFilters] = useState(true)
  const [selectedFilterAccountsWithRoles, setSelectedFilterAccountsWithRoles] = useState([])
  const [selectedFilterStatuses, setSelectedFilterStatuses] = useState([])
  const [filterQuery, setFilterQuery] = useState(null)
  const [orderBy, setOrderBy] = useState(null)
  const [showSpinner, setShowSpinner] = useState(null)
  const [showFiltersModal, setShowFiltersModal] = useState(false)
  const [showViewEditUserModal, setShowViewEditUserModal] = useState({ isOpen: false, isEdit: false })
  const [canLoadMore, setCanLoadMore] = useState(false)
  const [selectedPage, setSelectedPage] = useState(1)
  const [showDeleteUserConfirmModal, setShowDeleteUserConfirmModal] = useState(false)
  const [userIdToDelete, setUserIdToDelete] = useState(null)
  const [emailConfirmation, setEmailConfirmation] = useState("")
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const [expandedUser, setExpandedUser] = useState(0)
  const [showActionsMenu, setShowActionsMenu] = useState(null)
  const unconfirmedStatus = "unconfirmed"

  useEffect(() => {
    const tableData = props.users.map((user) => {
      return {
        identifier: user.uuid,
        email: user.email,
        name: `${user.firstName} ${user.lastName}`,
        account: user.account?.name,
        role: user.role?.name,
        lastLogin: convertToSimpleDateWithTime(user.lastLoginDate),
        confirmationStatus: user.confirmationStatus?.toLowerCase(),
        status: user.status,
        actions: [
          ...(user.confirmationStatus?.toLowerCase() === unconfirmedStatus ?
            [
              {
                label: t("users-management.confirm_user"),
                onActionClick: (e) => { e.stopPropagation(); handleConfirmUser(user.uuid) },
                requiredPermission: usersWritePermission
              }
            ]
          :
            []
          ),
          {
            label: t("labels.view_details"),
            onActionClick: (e) => { e.stopPropagation(); handleOpenViewEditUserModal(user.uuid, false) },
          },
          {
            label: t("labels.edit"),
            onActionClick: (e) => { e.stopPropagation(); handleOpenViewEditUserModal(user.uuid, true) },
            isDisabled: user.confirmationStatus?.toLowerCase() === unconfirmedStatus,
            requiredPermission: usersWritePermission
          },
          {
            label: (user.status === "pending" || user.status === "disabled") ? t("labels.enable") : t("labels.disable"),
            onActionClick: (e) => { e.stopPropagation(); handleChangeStatusUser(user.uuid, user.status)},
            isDisabled: user.confirmationStatus?.toLowerCase() === unconfirmedStatus,
            requiredPermission: usersWritePermission
          },
          {
            label: t("labels.delete"),
            onActionClick: (e) => { e.stopPropagation(); showConfirmationModal(user.uuid) },
            isDisabled: user.status !== "disabled" && user.confirmationStatus?.toLowerCase() !== unconfirmedStatus,
            requiredPermission: usersDeletePermission
          },
        ]
      }
    })

    setUsersTableData(tableData)
  }, [props.users])

  useEffect(() => {
    if (props.accounts.length === 0 && !props.accountsLoading) {
      props.actions.loadAccounts()
    }
  }, [])

  useEffect(async () => {
    if (props.accounts.length === 0) {
      return
    }

    setShowSpinner(true)

    const mappedAccountsWithRoles = []
    const mappedAccountsWithRolesActive = []

    const roles = await getRoles()

    props.accounts.forEach((account) => {
      const mappedRoles = roles?.filter((role) => role.accountId === account.id)?.map((role) => {
          return { id: role.id, name: role.name, isEnabled: role.isEnabled }
        }) || []

      if (mappedRoles.length === 0) {
        return
      }

      const accountWithRole = {
        id: account.id,
        name: account.name,
        children: mappedRoles,
      }

      mappedAccountsWithRoles.push(accountWithRole)

      if (account.isEnabled) {
        const mappedActiveRoles = mappedRoles.filter((role) => role.isEnabled)

        if (mappedActiveRoles.length > 0) {
          mappedAccountsWithRolesActive.push({ ...accountWithRole, children: mappedActiveRoles })
        }
      }
    })

    setAccountsWithRolesActive(mappedAccountsWithRolesActive)
    setAccountsWithRoles(mappedAccountsWithRoles)
    setShowSpinner(false)
  }, [props.accounts])

  useEffect(() => {
    setCanLoadMore(props.canLoadMore)
  }, [props.canLoadMore])

  useEffect(() => {
    if (props.errorMessage) {
      toast.dismiss()
      toast.error(props.errorMessage)

      props.actions.clearUsersMessages()
    }
  }, [props.errorMessage])

  useEffect(() => {
    if (props.successMessage) {
      toast.dismiss()
      toast.success(props.successMessage)

      props.actions.clearUsersMessages()
    }
  }, [props.successMessage])

  useEffect(() => {
    if (selectedPage && selectedPage > 1 && !props.backNavigationState) {
      handleLoadUsers(false)
    }
  }, [selectedPage])

  useDeepCompareEffect(() => {
    let changeValueTimeout = window.setTimeout(
      () => {
        if (shouldApplyFilters && !props.backNavigationState) {
          setSelectedPage(1)
          handleLoadUsers(true)
        }

        setShouldApplyFilters(true)
      },
      filterQuery ? 1000 : 0
    )
    return () => {
      clearTimeout(changeValueTimeout)
    }
  }, [filterQuery, selectedFilterAccountsWithRoles, selectedFilterStatuses, orderBy])

  useEffect(async () => {
    if (props.backNavigationState) {
      const backNavigationFilterQuery = props.backNavigationState.filterQuery
      const backNavigationFilterAccountsWithRoles = props.backNavigationState.selectedFilterAccountsWithRoles || []
      const backNavigationFilterStatus = props.backNavigationState.selectedFilterStatuses || []
      const backNavigationOrderBy = props.backNavigationState.orderBy
      const backNavigationSelectedPage = props.backNavigationState.selectedPage

      setFilterQuery(backNavigationFilterQuery)
      setSelectedFilterAccountsWithRoles(backNavigationFilterAccountsWithRoles)
      setSelectedFilterStatuses(backNavigationFilterStatus)
      setOrderBy(backNavigationOrderBy)
      setSelectedPage(backNavigationSelectedPage)

      let queryParams = {
        offset: 0,
      }
  
      if (backNavigationFilterQuery) {
        queryParams = { ...queryParams, query: backNavigationFilterQuery }
      }
  
      if (backNavigationFilterAccountsWithRoles.length > 0) {
        const accountIds = []
        const roleIds = []
        for (let idx = 0; idx < backNavigationFilterAccountsWithRoles.length; idx++) {
          accountIds.push(backNavigationFilterAccountsWithRoles[idx].id)
  
          const currentAccountRoleIds = backNavigationFilterAccountsWithRoles[idx].children?.map((roleId) => roleId) ?? []
          roleIds.push(...currentAccountRoleIds)
        }
  
        queryParams = { ...queryParams, accountId: accountIds, roleId: roleIds }
      }
  
      if (backNavigationFilterStatus.length > 0) {
        queryParams = { ...queryParams, status: backNavigationFilterStatus }
      }
  
      if (backNavigationOrderBy?.field && backNavigationOrderBy?.order) {
        queryParams = { ...queryParams, orderBy: backNavigationOrderBy.field, order: backNavigationOrderBy.order }
      }
  
      const limit = backNavigationSelectedPage * process.env.REACT_APP_PAGINATION_SIZE
      props.actions.loadUsers(queryParams, true, limit)
        .then(() => {
          setIsFirstLoad(false)

          if (props.backNavigationState.userId) {
            handleOpenViewEditUserModal(props.backNavigationState.userId, false)
          }

          props.actions.clearBackNavigationState()
        })
    }
  }, [props.backNavigationState])

  useEffect(() => {
    setShowSpinner(props.isLoading)
  }, [props.isLoading])

  const handleLoadUsers = (withReset) => {
    let queryParams = {
      offset: withReset ? 0 : (selectedPage - 1) * process.env.REACT_APP_PAGINATION_SIZE,
    }

    if (filterQuery) {
      queryParams = { ...queryParams, query: filterQuery }
    }

    if (selectedFilterAccountsWithRoles.length > 0) {
      const accountIds = []
      const roleIds = []
      for (let idx = 0; idx < selectedFilterAccountsWithRoles.length; idx++) {
        accountIds.push(selectedFilterAccountsWithRoles[idx].id)

        const currentAccountRoleIds = selectedFilterAccountsWithRoles[idx].children?.map((roleId) => roleId) ?? []
        roleIds.push(...currentAccountRoleIds)
      }

      queryParams = { ...queryParams, accountId: accountIds, roleId: roleIds }
    }

    if (selectedFilterStatuses.length > 0) {
      queryParams = { ...queryParams, status: selectedFilterStatuses }
    }

    if (orderBy?.field && orderBy?.order) {
      queryParams = { ...queryParams, orderBy: orderBy.field, order: orderBy.order }
    }

    props.actions.loadUsers(queryParams, withReset).then(() => setIsFirstLoad(false))
  }

  const handleSearch = (value) => {
    if (value?.length > 2) {
      setShowSpinner(true)
      setFilterQuery(value)
    } else if (value?.length === 0 && filterQuery?.length > 0) {
      setShowSpinner(true)
      setFilterQuery(null)
    }
  }

  const handleSort = (orderingField) => {
    setOrderBy(orderingField)
  }

  const handleLoadMore = () => {
    setSelectedPage((prevValue) => (prevValue ? prevValue + 1 : 2))
  }

  const handleResetFilters = () => {
    setSelectedFilterAccountsWithRoles([])
    setSelectedFilterStatuses([])
  }

  const onSelectInput = (isComponentVisible, toggleVisibility) => {
    if (toggleVisibility) {
      toggleVisibility(!isComponentVisible)
    }
  }

  const selectOrderByOptions = (orderingField, toggleVisibility) => {
    handleSort(orderingField)

    if (toggleVisibility) {
      toggleVisibility(false)
    }
  }

  const selectOrderByOptionsInput = ({ toggleVisibility, isComponentVisible }) => (
    <div className={"select-input"}>
      <div className="height d-flex flex-align-center flex-justify-between" onClick={() => onSelectInput(isComponentVisible, toggleVisibility)}>
        <div className="d-flex flex-align-center h-100">{orderBy ? t(`users-management.${orderBy.key}`) : t("events.select_ordering")}</div>
        <CaretDownIcon className={"mr-15 caret-dropdown-icon" + (isComponentVisible ? " icon-dropdown-open" : "")} />
      </div>
    </div>
  )

  const selectOrderByDropdownOptions = ({ toggleVisibility }) => (
    <div className="options-wrapper d-flex flex-align-center flex-column">
      {orderingFields?.map((orderingField, index) => (
        <div
          className={"option cursor-pointer no-wrap" + (orderingField === orderBy ? " selected-option" : "")}
          key={index}
          onClick={() => selectOrderByOptions(orderingField, toggleVisibility)}
        >
          {t(`users-management.${orderingField.key}`)}
        </div>
      ))}
    </div>
  )

  const handleNavigationWithState = (userId) => {
    props.actions.setBackNavigationState({
      backNavPage: location.pathname,
      userId: userId,
      filterQuery: filterQuery,
      selectedFilterAccountsWithRoles: selectedFilterAccountsWithRoles,
      selectedFilterStatuses: selectedFilterStatuses,
      orderBy: orderBy,
      selectedPage: selectedPage,
    })
  }

  const handleOpenViewEditUserModal = (userId, isEditEnabled) => {
    const userData = props.users.find((u) => u.uuid === userId)

    if (userData) {
      setSelectedUser(userData)
      setShowViewEditUserModal({ isOpen: true, isEdit: isEditEnabled })
    }    
  }

  const handleChangeStatusUser = (id, currentStatus) => {
    let newStatus = statuses[0]
    if (currentStatus === newStatus) {
      newStatus = statuses[2]
    }

    props.actions.partiallyUpdateUser(id, { status: newStatus })
  }

  const handleConfirmUser = (id) => {
    props.actions.confirmUser(id)
  }

  const showConfirmationModal = (id) => {
    setUserIdToDelete(id)
    setEmailConfirmation("")
    setShowDeleteUserConfirmModal(true)
  }

  const handleDeleteUser = () => {
    setShowDeleteUserConfirmModal(false)
    props.actions.removeUser(userIdToDelete)
    props.actions.clearUserDevices()
    props.actions.clearUserSubscriptions()
    setShowViewEditUserModal({ isOpen: false, isEdit: false })
    setSelectedUser(null)
  }

  const countSelectedAccountsWithRoles = () => {
    let count = 0

    for (let idx = 0; idx < selectedFilterAccountsWithRoles.length; idx++) {
      count += selectedFilterAccountsWithRoles[idx].children?.length + 1 ?? 1
    }

    return count
  }

  const renderUserStatus = (status) => {
    switch (status) {
      case statuses[0]:
        return (
          <div className="status-active">
            <EnabledIcon />
            <p>{t("filters.enabled")}</p>
          </div>
        )
      case statuses[1]:
        return (
          <div className="status-pending">
            <PendingIcon />
            <p>{t("filters.pending")}</p>
          </div>
        )
      case statuses[2]:
        return (
          <div className="status-inactive">
            <DisabledIcon />
            <p>{t("filters.disabled")}</p>
          </div>
        )
      default:
        return null
    }
  }

  const toggleShowActionsMenu = (identifier) => {
    setShowActionsMenu(showActionsMenu === identifier ? null : identifier)
  }

  return (
    <div className="users-management-wrapper">
      <Breadcrumb path={window.location.pathname} />
      <DashboardHeader headerText={t("nav.users-management")} />
      <div className="separator" />
      <div className="actions-wrapper">
        <SearchWithFilter
          searchText={filterQuery}
          onSearch={(e) => handleSearch(e)}
          showFiltersModal={() => setShowFiltersModal(true)}
          onResetFilters={() => handleResetFilters()}
          showCounterBadge={selectedFilterAccountsWithRoles.length > 0 || selectedFilterStatuses.length > 0}
          counterBadge={countSelectedAccountsWithRoles() + selectedFilterStatuses.length}
        />
        {isMobile && usersTableData.length > 0 && (
          <div className="order-by-mobile-wrapper">
            <ClickOutside 
              itemRef="click-outside-wrapper" 
              eventItem={selectOrderByOptionsInput} 
              toDisplayItem={selectOrderByDropdownOptions} 
            />
          </div>
        )}
      </div>
      {!isMobile && usersTableData.length > 0 &&
        <GenericTable
          data={usersTableData}
          headers={[
            {
              title: t('users-management.email'),
              orderKey: 'email'
            },
            {
              title: t('users-management.name'),
              orderKey: 'name'
            },
            {
              title: t('users-management.account'),
              orderKey: 'account'
            },
            {
              title: t('users-management.role'),
              orderKey: 'role'
            },
            {
              title: t('users-management.last-login'),
            },
            {
              title: t('users-management.confirmation_status'),
              orderKey: 'confirmationStatus'
            },
            {
              title: t('users-management.status'),
              orderKey: 'status'
            },
            {
              title: ''
            },
          ]}
          keys={[
            'email',
            'name',
            'account',
            'role',
            'lastLogin',
            'confirmationStatus',
            'status',
            'action',
          ]}
          keyRenderer={{
            email: (item) => {
              return (
                <div className="email-wrapper">
                  <ExpandableText text={item.email} />
                </div>
              )
            },
            name: (item) => {
              return (
                <div className="name-wrapper">
                  <ExpandableText text={item.name} />
                </div>
              )
            },
            confirmationStatus: (item) => {
              return (
                <div className="confirmation-status-wrapper">
                  {item.confirmationStatus}
                </div>
              )
            },
            status: (item) => {
              return (
                <div className="status-wrapper">
                  {renderUserStatus(item.status)}
                </div>
              )
            },
            action: (item) => {
              return (
                <div className="table-actions-wrapper" onClick={(e) => { e.stopPropagation(); toggleShowActionsMenu(item["identifier"]) }}>
                  {showActionsMenu === item["identifier"] ? <MenuKebabVerticalBackgroundIcon /> : <MenuKebabVerticalIcon />}
                  {showActionsMenu === item["identifier"] &&
                    <ActionsMenu
                      setHideMenu={() => setShowActionsMenu(null)}
                      actions={item.actions}
                      isLoading={showSpinner}
                    />
                  }
                </div>
              )
            }
          }}
          onRowClick={(user) => handleOpenViewEditUserModal(user.identifier,false)}
          activeSort={orderBy}
          onSort={(orderingField) => handleSort(orderingField)}
          isLoading={showSpinner}
        />
      }
      {isMobile && usersTableData.map((user, index) =>
        <div 
          key={index}
          className="card" 
          onClick={() => setExpandedUser(index)}
        >
          <div className={"card-item" + (expandedUser !== index ? " align-center" : "")}>
            <div className="card-item-title">{t("users-management.email")}</div>
            <div className={"card-item-body" + (expandedUser !== index ? " align-center" : "")}>
              <div>{user.email}</div>
              <div 
                className="card-actions"
                onClick={(e) => { e.stopPropagation(); setShowActionsMenu(index) }}
              >
                {showActionsMenu === index ? <MenuKebabVerticalBackgroundIcon /> : <MenuKebabVerticalIcon />}
                {showActionsMenu === index &&
                  <ActionsMenu
                    setHideMenu={() => setShowActionsMenu(null)}
                    actions={user.actions}
                    isLoading={showSpinner}
                  />
                }
              </div>
            </div>
          </div>
          {expandedUser === index && (
            <>
              <div className="card-item">
                <div className="card-item-title">{t("users-management.name")}</div>
                <div className="card-item-body">{user.name}</div>
              </div>
              <div className="card-item">
                <div className="card-item-title">{t("users-management.account")}</div>
                <div className="card-item-body">{user.account}</div>
              </div>
              <div className="card-item">
                <div className="card-item-title">{t("users-management.role")}</div>
                <div className="card-item-body">{user.role}</div>
              </div>
              <div className="card-item">
                <div className="card-item-title">{t("users-management.last-login")}</div>
                <div className="card-item-body">{user.lastLogin}</div>
              </div>
              <div className="card-item">
                <div className="card-item-title">{t("users-management.confirmation_status")}</div>
                <div className="card-item-body confirmation-status">{user.confirmationStatus?.toLowerCase()}</div>
              </div>
              <div className="card-item">
                <div className="card-item-title">{t("users-management.status")}</div>
                <div className="card-item-body">
                  <div className="card-item-body-status">{renderUserStatus(user.status)}</div>
                </div>
              </div>
            </>
          )}
        </div>)
      }
      {!showSpinner && !isFirstLoad && usersTableData.length === 0 && (
        <EmptyPageResults 
          title={t("users-management.no-users-found")} 
          subtitle={t("users-management.no-users-found-extra")} 
        />
      )}
      {canLoadMore && (
        <div className="buttons-wrapper">
          <SimpleButton className="load-more-button" onClick={() => handleLoadMore()}>
            {t("buttons.load_more")}
          </SimpleButton>
        </div>
      )}
      {showViewEditUserModal?.isOpen && (
        <ViewEditUser
          isEditMode={showViewEditUserModal.isEdit}
          selectedUser={selectedUser}
          accountsWithRoles={accountsWithRolesActive}
          statuses={statuses}
          deleteUser={(userId) => showConfirmationModal(userId)}
          confirmUser={(userId) => handleConfirmUser(userId)}
          closeModal={() => {
            setShowViewEditUserModal({ isOpen: false, isEdit: false })
            setSelectedUser(null)
          }}
          isLoading={showSpinner}
          showSpinner={() => setShowSpinner(true)}
          hideSpinner={() => setShowSpinner(false)}
          handleNavigationWithState={(userId) => handleNavigationWithState(userId)}
        />
      )}
      {showFiltersModal && (
        <FiltersModal
          filters={[
            {
              name: t("users-management.account-and-role"),
              type: FilterType.multiSelectWithChildren,
              dataset: accountsWithRoles,
              input: selectedFilterAccountsWithRoles,
              output: (filterAccountsWithRoles) => {
                setSelectedFilterAccountsWithRoles(filterAccountsWithRoles)
              },
            },
            {
              name: t("users-management.status"),
              type: FilterType.multiSelect,
              dataset: statuses,
              input: selectedFilterStatuses,
              output: (filterStatuses) => {
                setSelectedFilterStatuses(filterStatuses)
              },
            },
          ]}
          shouldFilter={(shouldApply) => setShouldApplyFilters(shouldApply)}
          resetFilters={() => handleResetFilters()}
          closeFilters={() => setShowFiltersModal(false)}
        />
      )}
      {showDeleteUserConfirmModal && (
        <ConfirmationModal
          onToggle={() => setShowDeleteUserConfirmModal(false)}
          onAccept={() => handleDeleteUser()}
          onCancel={() => setShowDeleteUserConfirmModal(false)}
          message={t("users-management.confirm-delete-user")}
          acceptButtonText={t("labels.delete")}
          isWarning
          acceptDisabled={emailConfirmation !== props.users?.find((u) => u.uuid === userIdToDelete)?.email}
          requiredPermission={usersDeletePermission}
        >
          <div className="delete-user-tooltip">
            <p>{t("users-management.confirm-delete-user-extra")}</p>
            <p className="input-title">{t("users-management.type-email-confirm")}</p>
            <input
              className="confirm-email-input"
              type="text" 
              value={emailConfirmation}
              onChange={(e) => setEmailConfirmation(e.target.value)} 
            />
          </div>
        </ConfirmationModal>)
      }
      {showSpinner && (
        <div className={"spinner-wrapper"}>
          <LoadingSpinner />
        </div>
      )}
    </div>
  )
}

function stateToProps({ users, accounts, backNavigationState}) {
  return {
    users: users?.users || [],
    accounts: accounts?.accounts || [],
    isLoading: users?.usersLoading || accounts?.accountsLoading,
    accountsLoading: accounts?.accountsLoading,
    canLoadMore: users?.canLoadMoreUsers,
    errorMessage: users?.errorMessage,
    successMessage: users?.successMessage,
    backNavigationState,
  }
}

function dispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        loadUsers,
        loadAccounts,
        partiallyUpdateUser,
        confirmUser,
        removeUser,
        clearUsersMessages,
        clearUserDevices,
        clearUserSubscriptions,
        setBackNavigationState,
        clearBackNavigationState,
      },
      dispatch
    ),
  }
}

export default connect(stateToProps, dispatchToProps)(Users)
