import { useEffect, useState } from "react";

import "./price-list.scss";
import SimpleButton from "../common/SimpleButton";
import PageModal from "../common/PageModal";
import { useTranslation } from "react-i18next";
import { SuccessTick } from "../../icons";
import { getRequestReviewById } from "../../utils/requests/dealersAPI";

export function RequestReviewRowModal({ row, options, handleClose }) {
	const { t } = useTranslation();
	const [currentOptions, setCurrentOptions] = useState([]);

	useEffect(() => {
    async function fetchRequestReviewById() {
      const response = await getRequestReviewById(row.id);
			const selectedOptions = options.filter((option) => {
				return response.data[0].options.includes(String(option.id));
			});
			setCurrentOptions(selectedOptions);
		}
		fetchRequestReviewById()
	}, [options, row.id]);

	function getFooter() {
		return (
			<>
				<SimpleButton className="cancel-button" onClick={handleClose}>
					{t("labels.cancel")}
				</SimpleButton>
				<SimpleButton className="submit-button">
					{t("labels.submit")}
				</SimpleButton>
			</>
		);
	}

	return (
		<div className="hide-footer">
			<PageModal
				toggle
				onToggle={handleClose}
				title={t("dealer-portal.request-review")}
				headerContent={<div></div>}
				footerContent={getFooter()}
			>
				<div className="request-review-row-modal">
					<div className="header-info">
						<p>
							Hello, Thank you for contacting us. We’ve received your query and
							our team is looking into it.
						</p>
						<p>
							We do our best to respond to every email within 24 hours, so you
							should be hearing back from one of our customer service team
							members soon.
						</p>
						<p>Many thanks, Gepy</p>
					</div>
					{[row].map(({ partNo, description }) => (
						<div className="request-info" key={partNo}>
							<div className="header">
								<span className="title">{partNo}</span>
								<span className="subtitle">{description}</span>
							</div>
							<div className="options">
								{currentOptions.map(({ id, reviewOptions }) => {
									return (
										<div key={id} className="option">
											<SuccessTick />
											<span>{reviewOptions}</span>
										</div>
									);
								})}
							</div>
							<div className="comments-info">
								<p className="title">{t("dealer-portal.comments")}</p>
								<div>{row.comments}</div>
							</div>
						</div>
					))}
				</div>
			</PageModal>
		</div>
	);
}
