import {ApiEnum, _baseGetFetchNoLang} from "./baseFetch"

const path = "/search/place"

export const getGooglePlace = async (queryParams) => {
  const selectedLang = localStorage.getItem("i18nextLng")
  return _baseGetFetchNoLang(`${path}/autocomplete`, {...queryParams, locale: selectedLang}, ApiEnum)
}

export const getGooglePlaceDetails = async (queryParams) => {
  const selectedLang = localStorage.getItem("i18nextLng")
  return _baseGetFetchNoLang(`${path}/detail`, {...queryParams, locale: selectedLang}, ApiEnum)
}
