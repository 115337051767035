import React, { useMemo, useState } from "react"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"
import DashboardHeader from "../common/DashboardHeader/DashboardHeader"
import Breadcrumb from "../common/Breadcrumb"
import SimpleButton from "../common/SimpleButton"

import "./price-list.scss"
import WrappedTabs from "../common/WrappedTabs"
import { getPriceListsRequests, getPriceLists } from "../../utils/requests/dealersAPI"
import TabTable from "./TabTable"

const getTabs = (t) => [
  {
    id: 0,
    name: t("dealer-portal.active-list"),
    tabType: "Active",
  },
  {
    id: 1,
    name: t("dealer-portal.new-items"),
    tabType: "New",
  },
  {
    id: 2,
    name: t("dealer-portal.discontinued-items"),
    tabType: "Discontinued",
  },
  {
    id: 3,
    name: t("dealer-portal.updated-items"),
    tabType: "Updated",
  },
  {
    id: 4,
    name: t("dealer-portal.requests"),
    tabType: "Requests",
  },
]

function PriceList(props) {
  const { t } = useTranslation()
  const hideBreadcrumb = props.hideBreadcrumb
  const [activeTabId, setActiveTabId] = useState(0)

  const [showRequestReviewModal, setShowRequestReviewModal] = useState(false)
  const [requestReviewModalDisabled, setRequestReviewModalDisabled] = useState(true)

  const [showRequestReviewRowModal, setShowRequestReviewRowModal] = useState(false)
  const [showRequestMissingItemModal, setShowRequestMissingItemModal] = useState(false)
  const [showRequestMissingItemRowModal, setShowRequestMissingItemRowModal] = useState(false)
  const [showFiltersModal, setShowFiltersModal] = useState(false)

  const tabs = useMemo(() => {
    return getTabs(t)
  }, [t])

  function handleTabSwitch(tabId) {
    setActiveTabId(tabId)
    setRequestReviewModalDisabled(true)
  }

  const tabType = tabs.find((tab) => tab.id === activeTabId).tabType
  
  return (
    <div className={`price-list-wrapper`}>
      {!hideBreadcrumb && <Breadcrumb path={window.location.pathname} />}
      <DashboardHeader headerText={t("nav.price-list")}>
        <div className="modal-buttons">
          {tabType !== "Requests" && (
            <>
              <SimpleButton
                className="submit-button"
                disabled={requestReviewModalDisabled}
                onClick={() => {
                  setShowRequestReviewModal(true)
                }}
              >
                {t("dealer-portal.request-review")}
              </SimpleButton>
              <SimpleButton
                className="submit-button"
                onClick={() => {
                  setShowRequestMissingItemModal(true)
                }}
              >
                {t("dealer-portal.request-missing")}
              </SimpleButton>
            </>
          )}
        </div>
      </DashboardHeader>
      <div className="price-list-content-wrapper">
        <div>
          <WrappedTabs tabs={tabs.map((tab) => ({ id: tab.id, name: t(tab.name) }))} activeTab={activeTabId} changeTab={handleTabSwitch} />

          <TabTable
            key={tabType}
            tabType={tabType}
            onSelectAll={() => {
              setRequestReviewModalDisabled(true)
            }}
            onRowSelectionChange={(row, selectionsCount) => {
              if (selectionsCount > 0 && selectionsCount <= 4) {
                setRequestReviewModalDisabled(false)
              } else {
                setRequestReviewModalDisabled(true)
              }
              if (tabType === "Requests") {
                if (row.requestType === "missingItem") {
                  setShowRequestMissingItemRowModal(true)
                } else if (row.requestType === "review") {
                  setShowRequestReviewRowModal(true)
                }
              }
            }}
            showRequestReviewModal={showRequestReviewModal}
            showRequestReviewRowModal={showRequestReviewRowModal}
            showRequestMissingItemModal={showRequestMissingItemModal}
            setShowRequestMissingItemModal={setShowRequestMissingItemModal}
            showRequestMissingItemRowModal={showRequestMissingItemRowModal}
            setShowRequestMissingItemRowModal={setShowRequestMissingItemRowModal}
            setShowRequestReviewModal={setShowRequestReviewModal}
            setShowRequestReviewRowModal={setShowRequestReviewRowModal}
            setRequestReviewModalDisabled={setRequestReviewModalDisabled}
            showFiltersModal={showFiltersModal}
            setShowFiltersModal={setShowFiltersModal}
          />
        </div>
      </div>
    </div>
  )
}

export async function fetchPriceListsByTabType({
  tabType,
  query,
  orderBy,
  page,
  selectedProductGroupFilters = [],
  selectedProductLineFilters = [],
  selectedTrademarkFilters = [],
  selectedSegmentFilters = [],
  selectedSellingEntityFilters = [],
  selectedRequestTypeFilters = []
}) {
  let response

  const PAGE_LIMIT = 10
  let commonParams = {
    ...(query ? { query } : {}),
    ...(selectedProductGroupFilters.length > 0 ? { productGroup: selectedProductGroupFilters } : {}),
    ...(selectedProductLineFilters.length > 0 ? { productLine: selectedProductLineFilters } : {}),
    ...(selectedTrademarkFilters.length > 0 ? { trademark: selectedTrademarkFilters } : {}),
    ...(selectedSegmentFilters.length > 0 ? { segment: selectedSegmentFilters } : {}),
    ...(selectedSellingEntityFilters.length > 0 ? { sellingEntity: selectedSellingEntityFilters } : {}),
    ...(selectedRequestTypeFilters.length > 0 ? { type: selectedRequestTypeFilters } : {}),
    ...(orderBy ? {  orderBy: orderBy.field } : {}),
    ...(orderBy ? { order: orderBy.order } : {}),
    // orderBy: orderBy.field,
    // order: orderBy.order,
    offset: (page - 1) * PAGE_LIMIT,
    limit: PAGE_LIMIT,
  }
  if (tabType === "Requests") {
    response = await getPriceListsRequests({
      ...commonParams,
    })

    response = response.data
  } else {
    response = await getPriceLists({ ...commonParams, status: tabType })
  }

  return {
    canLoadMore: response.currentPage < response.totalPages,
    data: response.data,
    ...response,
  }
}

function mapStateToProps({ priceLists }) {
  return {
    priceLists
  }
}

export default connect(mapStateToProps)(PriceList)