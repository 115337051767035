export const SET_QUERY_PARAMS = 'SET_QUERY_PARAMS'

export function setQueryParams(querystring) {
  return {
    type: SET_QUERY_PARAMS,
    querystring
  }
}

export function handleSetQueryParams(params) {
  return (dispatch) => {
    return dispatch(setQueryParams(params))
  }
}
