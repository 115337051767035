import React, {useState} from 'react'
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import './online-access.scss'
import { onlineActivation, onlineActivationWithSN} from "../../utils/requests/onlineActivation";
import {publishAccountUpdate} from "../../utils/requests/mqttPublishAPI";
import {toast} from 'react-toastify';
import Button from '../common/Button'

const OnlineAccess = (props) => {
  const {t} = useTranslation()
  const [activationByToken] = useState(!!props?.querystring?.token || props.isBravoG4OnlineAccess)
  const [formData, updateFormData] = useState(() => ({token_1: props?.querystring?.token ? (props?.querystring?.token === "000-000" ? "" :  formatToken(props?.querystring?.token)) : ""}))
  const [fieldNumber, setFieldNumber] = useState([1])
  const [isLoading, setIsLoading] = useState(false)
  const {toggleShowOnlineAccess, setIsSuccessOnlineActivation} = props
  const handleChange = (e) => {
    if(e.target.value.length > 7) {
      return
    }
    updateFormData({
      ...formData,
      [e.target.name]: formatToken(e.target.value)
    })
  }

  function formatToken(token) {
    const tokenWithoutHyphens = token.split("-").join("")
    return tokenWithoutHyphens.match(/.{1,3}/g) ? tokenWithoutHyphens.toUpperCase().match(/.{1,3}/g).join("-") : tokenWithoutHyphens.toUpperCase()
  }

  const addField = () => {
    if (fieldNumber.length >= 3) {
      return toast.error(t('online-access.limit'))
    }
    const incrementer = fieldNumber.length + 1
    setFieldNumber([...fieldNumber, incrementer])
  }

  const removeField = (index) => {
    if (fieldNumber.length <= 1) {
      return
    }
    fieldNumber.splice(index, 1)
    setFieldNumber([...fieldNumber])
  }

  const findAssociatedProducts = () => {
    let arr = []
    for (const [key, value] of Object.entries(props.userProducts.products)) {
      if (value.association_active) {
        arr.push({serial_number: value.serial_number})
      }
    }
    return (arr)
  }

  const submitActivation = () => {
    if(!props.authedUser.cognitoSub) {
      toast.error(t("error.failure_msg"))
      return;
    }

    setIsLoading(true)
    toast.dismiss();

    const model_id = props.querystring?.model_id ?? ""

    if (activationByToken) {
      for (let i = 0; i < fieldNumber.length; i++) {
        const body = {
          token: formData[`token_${i + 1}`],
          email: props.authedUser.email,
          model_id: model_id.length ? model_id : 'BRAVO-G4',
        }
        if(!body.token) {
          toast.error(t('online-access.TokenNotProvided'))
          setIsLoading(false)
          return
        }

        const activationRequest = props.serialNumber ? onlineActivationWithSN(props.serialNumber, body) : onlineActivation(body)
        activationRequest
          .then(data => {
            toast.dismiss();
            if(data[0].status === 1) {
              toast.success(t('online-access.access_activated'))
              setIsSuccessOnlineActivation(true)
            } else {
              toast.error(t(`online-access.${data[0]["error"]["code"]}`))
            }
          })
          .catch((error) => {
            const errorCode = error.response?.data?.code || "failure_msg"
            toast.error(t(`error.${errorCode}`))
          })
          .finally(() => {
            setIsLoading(false)
            toggleShowOnlineAccess()
          })
      }
    } else {
      const body = {
        serial_number: props.querystring?.sn,
        email: props.authedUser.email,
        model_id: model_id
      }
      onlineActivation(body)
        .then(data => {
          if (data[0].status === 1) {
            toast.success(t('online-access.access_activated'))
            setIsSuccessOnlineActivation(true)
            if (props.querystring && props.querystring.publish) {
              const unixTimestampSeconds = Math.round(new Date().getTime() / 1000)
              const body = [
                {
                  "account_state": "associated",
                  "account_state_time_modified_utc_seconds": unixTimestampSeconds,
                  "account_email": props.authedUser.email ? props.authedUser.email : '',
                  "account_company_name": props.authedUser.company ? props.authedUser.company : '',
                  "account_contact_name": `${props.authedUser.firstname ?
                    props.authedUser.firstname
                    : null
                  } ${props.authedUser.lastname ?
                    props.authedUser.lastname :
                    null}`,
                  "account_phone_number": props.authedUser.phone ? props.authedUser.phone : '',
                  "account_address": props.authedUser.address1 ? props.authedUser.address1 : '',
                  "account_city": props.authedUser.city ? props.authedUser.city : '',
                  "account_subregion": props.authedUser.stateprovince ? props.authedUser.stateprovince : '',
                  "account_country": props.authedUser.country ? props.authedUser.country : '',
                  "account_zip_code": props.authedUser.postalcode ? props.authedUser.postalcode : '',
                  "units": findAssociatedProducts(props.userProducts.products)
                }
              ]
              
              publishAccountUpdate(props.querystring?.sn, body)
                .then((data) => {
                  toast.success(data.message)
                })
                .catch(() => toast.error(t("error.failure_msg")))
            }
          } else {
            toast.error(t(`online-access.${data[0]["error"]["code"]}`))
          }
        })
        .catch(() => toast.error(t("error.failure_msg")))
        .finally(() => {
          setIsLoading(false)
          toggleShowOnlineAccess()
        })
    }
  }

  const handleFields = (index) => {
    if (index === 0) {
      addField()
      return
    }

    removeField(index)
  }

  return (
    <div className="online-activation-wrapper d-flex flex-align-center flex-column flex-justify-start">
      <h3 className="title">{t('online-access.online-activation')}</h3>
      {activationByToken ?
        <div className="modal-content d-flex flex-justify-center flex-align-center flex-column">
          <div className="sub-title token">
            {t('online-access.token-subtitle')}
          </div>
          <div className="label">
            {t('online-access.activation-code')}
          </div>
          {fieldNumber.length && fieldNumber.map((field, index) => (
            <div className="input d-flex flex-justify-center" key={index}>
              <input type="text" name={`token_${index + 1}`} placeholder={t('online-access.enter-code')}
                     onChange={handleChange} value={formData && formData[`token_${index + 1}`] || ''}
                     className="token-input"/>
              {!props.isBravoG4OnlineAccess &&
                <div 
                  className={index === 0 ? "plus" : "minus"}
                  onClick={() => handleFields(index)}
                >
                  {`${index === 0 ? '+' : '-'}`}
                </div>
              }
            </div>
          ))
          }
        </div>
        :
        <div className="modal-content d-flex flex-justify-center flex-align-center flex-column">
          <div className="sub-title">
            {t('online-access.sn-subtitle')}
          </div>
          <div className="label">
            {t('online-access.serial_number')}
          </div>
          <div className="serial-number">
            {props.querystring?.sn}
          </div>
        </div>
      }
      <div className="onlineaccess-btn">
        <Button
          className="submit btn"
          isLoading={isLoading}
          onClick={() => submitActivation()}
        >
          {t('online-access.submit')}
        </Button>
      </div>
    </div>
  )
}

function mapStateToProps({onlineAccess, userProducts, authedUser}) {
  return {
    querystring: onlineAccess,
    userProducts: userProducts,
    authedUser: authedUser
  }
}

export default connect(mapStateToProps)(OnlineAccess)