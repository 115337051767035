import React, {useState} from "react";
import PropTypes from "prop-types";
import ClickOutside from "../ClickOutside";
import {ArrowBackIcon, CaretDownIcon} from "../../../icons";
import {months, daysOfWeek, formatVal} from "../../../utils/filters/date";
import {uniqBy} from "../../../utils/filters/filters";
import "./calendar.scss"

const Calendar = (props) => {
  const {onChangeDay, onChangeMonth, printouts, selectedDay, hideYearPicker, hasFixedMonth} = props
  const [selectedDate, setSelectedDate] = useState(selectedDay ? selectedDay : null)
  const [date, setDate] = useState({
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
  })

  const localMonths = months()
  const currentYear = (new Date()).getFullYear();
  const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step));
  const localDaysOfWeek = daysOfWeek();
  const currentDay = {
    day: new Date().getDate(),
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
  }

  const datesForGrid = (year, month) => {
    const dates = []
    const firstDay = new Date(year, month).getDay()
    const lastDay = new Date(year, month + 1, 0).getDay()
    const totalDaysInMonth = new Date(year, month + 1, 0).getDate()
    const totalDaysInPrevMonth = new Date(year, month, 0).getDate()

    const currentDate = selectedDate ? selectedDate :  `${(new Date()).getFullYear()}-${formatVal((new Date()).getMonth()+1)}-${formatVal((new Date()).getDate())}`

    for (let i = 1; i <= firstDay; i++) {
      const prevMonthDate = totalDaysInPrevMonth - firstDay + i
      const key = `${date.year}-${formatVal(date.month)}-${formatVal(prevMonthDate)}`
      dates.push({key: key, date: prevMonthDate, monthClass: 'prev'})
    }

    for (let i = 1; i <= totalDaysInMonth; i++) {
      const key = `${date.year}-${formatVal(date.month + 1)}-${formatVal(i)}`
      let object = {key: key, date: i, monthClass: 'current'}
      if (key === currentDate) {
        object.selectedClass = 'selected'
      }

      if (key === `${currentDay.year}-${formatVal(currentDay.month)}-${formatVal(currentDay.day)}`) {
        object.currentDateClass = "current-date"
      }

      if (i < 10) {
        object.date = `0${object.date}`
      }

      let arr = []
      printouts?.forEach(it => {
        const item = arr.find(it2 => it2.date.substr(8,10) === it.date.substr(8,10))
        if (item && item.cycles && item.cycles.length) {
          item.cycles.push(it.cycle_event)
        }

        arr.push({
          ...it,
          day: it.date.substr(8,10),
          cycles: [it.cycle_event]
        })
      })


      const cycles = uniqBy(arr, 'day')

      cycles.forEach(it => {
        const item = {...it}
        if (it.cycles && it.cycles.includes('CYCLE_FAULT')) {
          item.cycle_event = 'CYCLE_FAULT'
          item.dot = 'failed'
        } else if (it.cycles && it.cycles.includes('CYCLE_INTERRUPTED')) {
          item.cycle_event = 'CYCLE_INTERRUPTED'
          item.dot = 'warning'
        } else if (it.cycles && it.cycles.includes('CYCLE_ABORTED')) {
          item.cycle_event = 'CYCLE_ABORTED'
          item.dot = 'warning'
        } else if (it.cycles && it.cycles.includes('DOOR_OPEN')) {
          item.cycle_event = 'DOOR_OPEN'
          item.dot = 'warning'
        } else {
          item.cycle_event = 'CYCLE_COMPLETED'
          item.dot = 'ok'
        }

        if (object.key === item.date) {
          object.dot = item.dot
        }
      })

      dates.push(object)
    }

    const diff = daysOfWeek().length - (lastDay + 1)

    if (dates.length < (dates.length + diff)) {
      const count = (dates.length + diff) - dates.length
      for (let i = 1; i <= count; i++) {
        const key = `${date.year}-${formatVal(date.month + 2)}-${formatVal(i)}`

        if (i < 10) {
          i = `0${i}`
        }

        dates.push({key: key, date: i, monthClass: 'next'})
      }
    }
    return dates
  }

  const prevMonth = () => {
    const localDate = new Date(date.year, date.month - 1)
    setDate({
      month: localDate.getMonth(),
      year: localDate.getFullYear(),
    })
    onChangeMonth(localDate)
  }

  const nextMonth = () => {
    const localDate = new Date(date.year, date.month + 1)
    setDate({
      month: localDate.getMonth(),
      year: localDate.getFullYear(),
    })
    onChangeMonth(localDate)
  }

  const selectDay = (e) => {
    const value = e.target.id
    setSelectedDate(value)
    onChangeDay(value)
  }

  const handleClick = (option, toggleVisibility, setter, type = null) => {
    if (type === 'year') {
      setter({...date, year: option})
      return toggleVisibility(false)
    }

    setter({...date, month: localMonths.indexOf(option)})
    toggleVisibility(false)
  }

  const inputMonth = ({toggleVisibility, isComponentVisible}, value, hasKeys) => (
    <div className="select-input cursor-pointer w-100">
      <div className="height d-flex flex-align-center flex-justify-between">
        <div
          className="d-flex flex-align-center h-100"
          onClick={() => isComponentVisible ? toggleVisibility(false) : toggleVisibility(true)}>
          {!hasKeys ? localMonths[value] :  localMonths[value] }
        </div>
        <CaretDownIcon className="mr-10 caret-dropdown-icon"/>
      </div>
    </div>
  )

  const optionsMonth = ({toggleVisibility}, arr, setter, hasKeys) => (
    <div className="options-wrapper d-flex flex-align-center flex-column">
      {arr?.map((option, index) => (
        <div className="option cursor-pointer no-wrap" key={index}
             onClick={(e) => handleClick(option, toggleVisibility, setter, 'month')}>{!hasKeys ? option : (option.model || option.name)}</div>
      ))
      }
    </div>
  )

  const inputYear = ({toggleVisibility, isComponentVisible}, value, hasKeys) => (
    <div className="select-input cursor-pointer w-100">
      <div className="height d-flex flex-align-center flex-justify-between">
        <div
          className="d-flex flex-align-center h-100"
          onClick={() => isComponentVisible ? toggleVisibility(false) : toggleVisibility(true)}>
          {!hasKeys ? value :  localMonths[value] }
        </div>
        <CaretDownIcon className="mr-10 caret-dropdown-icon"/>
      </div>
    </div>
  )

  const optionsYear = ({toggleVisibility}, arr, setter, hasKeys) => (
    <div className="options-wrapper d-flex flex-align-center flex-column">
      {arr?.map((option, index) => (
        <div className="option cursor-pointer no-wrap" key={index}
             onClick={(e) => handleClick(option, toggleVisibility, setter, 'year')}>{!hasKeys ? option : (option.model || option.name)}</div>
      ))
      }
    </div>
  )

  return (
    <div className="calendar">
      <div className="calendar-header">
        <button onClick={prevMonth}>
          <ArrowBackIcon className="left"/>
        </button>
        {hasFixedMonth ?
          (
            <div className="current-month">
              {localMonths[date.month]}
            </div>
          )
        :
          (
            <ClickOutside
              itemRef="month-select"
              eventItem={(e) => inputMonth(e, date.month, false)}
              toDisplayItem={(e) => optionsMonth(e, localMonths, setDate, false)}
            />
          )
        }
        {!hideYearPicker &&
          <ClickOutside
            itemRef="year-select"
            eventItem={(e) => inputYear(e, date.year, false)}
            toDisplayItem={(e) => optionsYear(e, range(currentYear, currentYear - 50, -1), setDate, false)}
          />
        }
        <button onClick={nextMonth}>
          <ArrowBackIcon className="right"/>
        </button>
      </div>
      <div className='calendar-content'>
        {
          localDaysOfWeek.map((day, index) => (
            <div className="text-gray day-of-week" key={`day-${index}`}>{day}</div>
          ))
        }
        {
          datesForGrid(date.year, date.month).map((date, index) => (
            date.key ? 
              <div
                key={date.key}
                className="date"
              >
                <div
                  id={date.key}
                  onClick={date.monthClass === 'current' ? selectDay : date.monthClass === 'next' ? nextMonth : prevMonth}
                  className={`
                    ${date.monthClass} 
                    ${
                      date.selectedClass
                        ? `${date.selectedClass} cursor-pointer`
                        : 'cursor-pointer'
                    }
                    ${
                      date.currentDateClass ? date.currentDateClass : ''
                    }
                  `}>
                  {date.date}
                </div>
                <div className="dots d-flex">
                  <div className={`dot ${date.dot ? date.dot : ''}`}/>
                </div>
              </div> 
            :
              ''
          ))
        }
      </div>
    </div>
  )
}
Calendar.propTypes = {
  onChangeDay: PropTypes.func.isRequired,
  onChangeMonth: PropTypes.func,
  printouts: PropTypes.array
}

export default Calendar
